import { Box, Divider, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import {
  AssetType,
  CaseReportError,
  CaseReportStatusType,
  CaseRiskAssessmentType,
  format,
  IAsset,
  ICase,
  IUser,
  PlanFeedbackType,
  SagittalGoalType,
  StatementType,
  UserRoleType,
  MeasurementType,
} from '@workflow-nx/common';
import { absoluteDifference } from '@workflow-nx/math';
import { ErrorMessageRHF, ProgressButton, SelectFieldRHF } from '@workflow-nx/ui';
import { date } from '@workflow-nx/utils';
import { isNumber, toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import RadioGroupRHF from '../../../../../components/RadioGroupRHF';
import useAuth from '../../../../../hooks/useAuth';
import {
  CaseReportAlignmentGoalType,
  generateCaseAlignmentGoals,
} from '../../../../../utils/caseReport/caseReportAlignmentGoals';
import { formatCaseReportStatusType } from '../../../../../utils/caseReport/caseReportFormat';
import { CasePathologiesEditTable } from './CasePathologiesEditTable';
import { CaseReportAlignmentGoalViewTable } from './CaseReportAlignmentGoalViewTable';
import { CaseReportCorrectionGoals } from './CaseReportCorrectionGoals';
import { CaseReportDesignerFeedback } from './CaseReportDesignerFeedback';
import { CaseReportDialogStateType, FormActionType } from './CaseReportDialog.reducer';
import { CaseReportImagingAndSegmentation } from './CaseReportImagingAndSegmentation';
import { CaseReportImplantDesign } from './CaseReportImplantDesign';
import { caseRiskAssessmentTypeOptions } from './caseReportMenuOptions';
import { CaseReportPreOpXrayMeasurements } from './CaseReportPreOpXrayMeasurements';
import { CaseReportStatementsEditTable } from './CaseReportStatementsEditTable';

export function CaseReportForm(props: {
  activeCase: ICase;
  state: CaseReportDialogStateType;
  surgeonUser: IUser;
  disabled: boolean;
  errors: CaseReportError[];
  loading: boolean;
  Users: IUser[];
  handleFormSubmit: (
    actionType: FormActionType,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  activeButton: FormActionType | null;
  selfApproveCaseReportEnabled: boolean;
  sendCaseReportReviewEnabled: boolean;
}) {
  const { user: currentUser } = useAuth();
  const [Assets, setCaseReportAssets] = useState<IAsset[]>([]);
  const [segmentationOperators, setSegmentationOperators] = useState<
    { key: string; value: string }[]
  >([]);
  const [segmentationReviewers, setSegmentationReviewers] = useState<
    { key: string; value: string }[]
  >([]);
  const [caseReportReviewers, setCaseReportReviewers] = useState<{ key: string; value: string }[]>(
    [],
  );
  const [caseReportAlignmentGoals, setCaseReportAlignmentGoals] = useState<
    CaseReportAlignmentGoalType[]
  >([]);
  const [disablePeerReviewer, setDisablePeerReviewer] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);

  const isEditingAllowed = !props.disabled && !props.loading;

  const {
    control,
    handleSubmit,
    reset,
    setError,
    resetField,
    setValue,
    formState,
    getValues,
    watch,
  } = useFormContext<CaseReportDialogStateType>();

  const FormData = getValues();
  const levelAssets = FormData?.levelAssets ?? [];
  const deletedAssetIds = FormData.deletedAssetIds;

  // Function to generate watch fields dynamically
  const generateWatchFields = (): any[] => {
    const levelAssetFields: string[] = [];

    levelAssets?.forEach((levelAsset, levelIndex) => {
      levelAsset.inputAssets.forEach((_, inputIndex) => {
        levelAssetFields.push(`levelAssets[${levelIndex}].inputAssets[${inputIndex}].inputAsset`);
      });
    });

    return [
      'riskAssessment',
      'correctionGoalSagittal',
      'correctionGoalDiscHeightOnly',
      'correctionGoalCoronal',
      'measurementLordosisDifference',
      'measurementLordosisDifferenceRationale',
      'patientRecord.lumbarLordosis',
      'segmentedByQualifiedOperator',
      'patientRecord.pelvicIncidence',
      'caseReportStandingXrayLateralMeasured',
      'caseReportVertebraePreEditImage',
      'caseReportVertebraePostEditImage',
      'caseReportImplantPreEditImage',
      'caseReportImplantPostEditImage',
      'reportReviewedBy',
      'planFeedback',
      ...levelAssetFields,
    ];
  };

  const watchFields = watch(generateWatchFields() as any);

  const theme = useTheme();
  const disabled = props.loading || props.disabled;

  const lumbarLordosisMeasurementCt = FormData?.preOpMeasurements?.caseAnalysisMeasurements.find(
    (m) => m.measurementType === MeasurementType.LumbarLordosis && m.levelType === null,
  )?.value;

  const lumbarLordosisCt = isNumber(lumbarLordosisMeasurementCt)
    ? toNumber(lumbarLordosisMeasurementCt.toFixed(0))
    : undefined;

  const lumbarLordosis = FormData?.patientRecord?.lumbarLordosis;

  function getAsset(assetType: AssetType) {
    return Assets.find((Asset) => Asset.assetType === assetType);
  }

  function clearAsset(assetType: AssetType) {
    const foundAsset = Assets.find((Asset) => Asset.assetType === assetType);
    const updatedCaseReportAssets = Assets.filter((Asset) => Asset.assetType !== assetType);
    setCaseReportAssets(updatedCaseReportAssets);
    setValue('assets' as any, updatedCaseReportAssets);

    if (foundAsset) {
      deletedAssetIds?.push(foundAsset.assetId);
      setValue('deletedAssetIds' as any, deletedAssetIds);
    }
  }

  const showCaseReportReviewSection =
    currentUser?.userId !== FormData?.reportReviewedByUser?.userId ||
    FormData.status === CaseReportStatusType.Approved;

  const handleChangeRiskAssessment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const riskAssessment = event.target.value;
    if (riskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
      setValue('reportReviewedBy' as keyof CaseReportDialogStateType, '' as any);
      setValue(
        'riskAssessment' as keyof CaseReportDialogStateType,
        CaseRiskAssessmentType.QaReviewOnly as any,
      );
      setDisablePeerReviewer(true);
    } else {
      setValue(
        'riskAssessment' as keyof CaseReportDialogStateType,
        CaseRiskAssessmentType.CaseReviewQaReview as any,
      );
      setDisablePeerReviewer(false);
    }
  };

  const handleFeedbackChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];

    if (value.includes(PlanFeedbackType.NoFeedback)) {
      setSelectedFeedback([PlanFeedbackType.NoFeedback]);
      setValue(
        'planFeedback' as keyof CaseReportDialogStateType,
        [PlanFeedbackType.NoFeedback] as any[],
      );
    } else {
      const filteredValues = value.filter((item) => item !== PlanFeedbackType.NoFeedback);
      setSelectedFeedback(filteredValues);
      setValue('planFeedback' as keyof CaseReportDialogStateType, filteredValues as any[]);
    }
  };

  useEffect(() => {
    const segmentationOperatorsAndReportReviewers: { key: string; value: string }[] = [];
    const segmentationReviewers: { key: string; value: string }[] = [];

    for (const user of props.Users) {
      if (user.role !== UserRoleType.Clinical) {
        segmentationOperatorsAndReportReviewers.push({
          key: user.userId as unknown as string,
          value: format.formatName(user),
        });
      }

      if (user.role === UserRoleType.CaseEngineer) {
        segmentationReviewers.push({
          key: user.userId as unknown as string,
          value: format.formatName(user),
        });
      }
    }

    setSegmentationOperators(segmentationOperatorsAndReportReviewers);
    setSegmentationReviewers(segmentationReviewers);
    setCaseReportReviewers(segmentationOperatorsAndReportReviewers);
    setCaseReportAssets((FormData?.assets as unknown as IAsset[]) ?? []);
  }, []);

  useEffect(() => {
    const patientAge = date.distanceInYears(props.activeCase?.patient?.birthDate as string);
    const pelvicIncidence = FormData?.patientRecord?.pelvicIncidence ?? 0;
    const caseAlignmentGoals = generateCaseAlignmentGoals(
      props.activeCase.levels,
      FormData.correctionGoalSagittal as SagittalGoalType,
      patientAge,
      pelvicIncidence,
      FormData.correctionGoalCoronal,
      FormData?.preOpMeasurements,
    );

    setCaseReportAlignmentGoals(caseAlignmentGoals);
    setValue('caseReportAlignmentGoalTypes' as any, caseAlignmentGoals);
  }, [
    FormData.correctionGoalCoronal,
    FormData.correctionGoalSagittal,
    FormData?.patientRecord?.pelvicIncidence,
  ]);

  useEffect(() => {
    if (lumbarLordosis !== undefined || lumbarLordosisCt !== undefined) {
      const rationaleDifference = absoluteDifference(lumbarLordosisCt, lumbarLordosis);
      setValue('measurementLordosisDifference', rationaleDifference);
    }
  }, [lumbarLordosis, lumbarLordosisCt, setValue]);

  useEffect(() => {
    if (FormData?.riskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
      setDisablePeerReviewer(true);
    } else {
      setDisablePeerReviewer(false);
    }
  }, [FormData?.riskAssessment]);

  return (
    <Box display={'flex'}>
      <Box width={'100%'}>
        <Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Report Status</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {formatCaseReportStatusType(FormData.status)}
              </Typography>
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <CaseReportImagingAndSegmentation
            control={control as unknown as Control<FieldValues>}
            segmentedByQualifiedOperator={FormData?.segmentedByQualifiedOperator}
            segmentationOperators={segmentationOperators}
            segmentationReviewers={segmentationReviewers}
            disabled={disabled}
          />
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Patient Pathologies Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={900} justifyContent={'left'}>
              <CasePathologiesEditTable
                casePathologies={FormData?.casePathologies}
                disabled={disabled}
              />
            </Box>
          </Box>
          <CaseReportPreOpXrayMeasurements
            control={control as unknown as Control<FieldValues>}
            FormData={FormData}
            disabled={disabled}
            isEditingAllowed={isEditingAllowed}
            lumbarLordosisCt={lumbarLordosisCt}
            getAsset={getAsset}
            clearAsset={clearAsset}
          />
          <CaseReportCorrectionGoals
            control={control as unknown as Control<FieldValues>}
            correctionGoalDiscHeightOnly={FormData.correctionGoalDiscHeightOnly}
            correctionGoalSagittal={FormData?.correctionGoalSagittal}
            correctionGoalCoronal={FormData?.correctionGoalCoronal}
            disabled={disabled}
          />
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Correction Planning Section</strong>
              </Typography>
            </Box>
          </Box>
          {caseReportAlignmentGoals.length > 0 ? (
            <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
              <Box display={'flex'} width={900} justifyContent={'left'}>
                <CaseReportAlignmentGoalViewTable
                  caseReportAlignmentGoals={caseReportAlignmentGoals}
                />
              </Box>
            </Box>
          ) : null}
          <Box>
            <CaseReportStatementsEditTable
              statementType={StatementType.CorrectionPlanning}
              caseReportStatements={FormData?.caseReportStatements}
              disabled={disabled}
            />
          </Box>
          <CaseReportImplantDesign
            isEditingAllowed={isEditingAllowed}
            control={control as unknown as Control<FieldValues>}
            getAsset={getAsset}
            clearAsset={clearAsset}
            levelAssets={FormData?.levelAssets ?? []}
            caseReportVertebraePreEditImage={FormData?.caseReportVertebraePreEditImage}
            caseReportVertebraePostEditImage={FormData?.caseReportVertebraePostEditImage}
            caseReportImplantPreEditImage={FormData?.caseReportImplantPreEditImage}
            caseReportImplantPostEditImage={FormData?.caseReportImplantPostEditImage}
          />
          <Box>
            <CaseReportStatementsEditTable
              statementType={StatementType.ImplantDesign}
              caseReportStatements={FormData?.caseReportStatements}
              disabled={disabled}
            />
          </Box>
          <CaseReportDesignerFeedback
            control={control as unknown as Control<FieldValues>}
            planFeedback={FormData?.planFeedback}
            disabled={disabled}
            handleFeedbackChange={handleFeedbackChange}
          />
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Risk Assessment Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Risk Assessment</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <RadioGroupRHF
                name={'riskAssessment'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideLabel={false}
                disabled={
                  props.disabled ||
                  (FormData?.initialCaseRiskAssessment ===
                    CaseRiskAssessmentType.CaseReviewQaReview &&
                    FormData?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview)
                }
                row={false}
                options={caseRiskAssessmentTypeOptions}
                onChange={handleChangeRiskAssessment}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Case Report Reviewer</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <SelectFieldRHF
                name={'reportReviewedBy'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideNone={false}
                menuItems={caseReportReviewers}
                disabled={disabled || disablePeerReviewer}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'reportReviewedBy'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>

          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Case Report Review Section</strong>
              </Typography>
            </Box>
          </Box>
          {FormData?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
          showCaseReportReviewSection ? (
            <>
              {FormData?.reportReviewNote && (
                <>
                  <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                    <Box display={'flex'} width={240} justifyContent={'left'}>
                      <Typography variant={'body1'}>Report Review Note</Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      width={650}
                      justifyContent={'left'}
                      flexDirection="column"
                    >
                      <Typography variant={'body1'}>{FormData?.reportReviewNote}</Typography>
                      {[CaseReportStatusType.Approved, CaseReportStatusType.Rejected].includes(
                        FormData?.status as CaseReportStatusType,
                      ) && (
                        <>
                          <br />
                          <Typography variant={'body1'} mt={1}>
                            {formatCaseReportStatusType(FormData?.status as CaseReportStatusType)}{' '}
                            by {format.formatName(FormData?.reportReviewedByUser)} on{' '}
                            {format.formatDate(FormData?.reportReviewedAt as any)} at{' '}
                            {format.formatTime(FormData?.reportReviewedAt as any)}.
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box display={'flex'} width={240} justifyContent={'left'}></Box>
                  </Box>
                  <Divider variant={'middle'} />
                </>
              )}
              {props.sendCaseReportReviewEnabled && (
                <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Report Review</Typography>
                  </Box>
                  <Box display={'flex'} width={650} flexDirection="column">
                    <Box>
                      <Typography variant={'body1'}>
                        Based on the risk assessment matrix, a case report review is required.
                        Please submit a case report review request to the case report reviewer by
                        clicking the 'Send for Case Report Review' button.
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent="flex-end" mt={1}>
                      <ProgressButton
                        variant={'contained'}
                        disabled={disabled}
                        onClick={props.handleFormSubmit(
                          FormActionType.CreateCaseReportPeerReviewRequest,
                        )}
                        label={'Send for Case Report Review'}
                        loading={
                          formState.isSubmitting &&
                          props.activeButton === FormActionType.CreateCaseReportPeerReviewRequest
                        }
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} width={240} />
                </Box>
              )}
            </>
          ) : (
            props.selfApproveCaseReportEnabled && (
              <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Report Review</Typography>
                </Box>
                <Box display={'flex'} width={650} flexDirection="column">
                  <Box>
                    <Typography variant={'body1'}>
                      Based on the risk assessment matrix, a case report review is not required.
                      Please approve the case report by clicking the 'Approve Case Report' button.
                    </Typography>
                  </Box>
                  <Box display={'flex'} justifyContent="flex-end" mt={1}>
                    <ProgressButton
                      variant={'contained'}
                      disabled={disabled}
                      onClick={props.handleFormSubmit(FormActionType.ApproveCaseReportPeerReview)}
                      label={'Approve Case Report'}
                      loading={
                        formState.isSubmitting &&
                        props.activeButton === FormActionType.ApproveCaseReportPeerReview
                      }
                    />
                  </Box>
                </Box>
                <Box display={'flex'} width={240} />
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
}
