import { Box, Typography, useTheme } from '@mui/material';
import { AssetType, IAsset, ICase, IPlan } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { Dispatch, useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import CustomDialog from '../../../components/CustomDialog';
import EditedVertebralAssetsGridTable from './CasePlanningTab/EditedVertebralAssetsGridTable';

const editAssetFilter = [
  AssetType.L1Edited,
  AssetType.L2Edited,
  AssetType.L3Edited,
  AssetType.L4Edited,
  AssetType.L5Edited,
  AssetType.L6Edited,
  AssetType.S1Edited,
];

const EditedVertebralAssetsBanner = (props: {
  dispatch: Dispatch<any>;
  assets: IAsset[];
  activeCase: ICase;
  plan?: IPlan;
  disabled?: boolean;
  readOnly: boolean;
}) => {
  const theme = useTheme();
  const [showEditedAssetsDialog, setShowEditedAssetsDialog] = useState(false);
  const uploadedEditedVertebrae = sortBy(
    props.assets
      .filter((asset) => editAssetFilter.includes(asset.assetType))
      .map((vertebrae) => {
        return vertebrae.assetType.slice(0, 2);
      }),
  );

  const bannerMessage = () => {
    const length = uploadedEditedVertebrae.length;
    switch (true) {
      case length === 0:
      default: {
        return 'There are no edited vertebrae for this case';
      }
      case length === 1: {
        return `The ${uploadedEditedVertebrae[0]} has an edited vertebra for this case`;
      }
      case length === 2: {
        return `The ${uploadedEditedVertebrae[0]} and ${uploadedEditedVertebrae[1]} have edited vertebrae for this case`;
      }
      case length > 2: {
        let msg = 'The ';
        uploadedEditedVertebrae.forEach((vertebra, index) => {
          if (index < length - 1) {
            msg = msg.concat(`${vertebra}, `);
          } else {
            msg = msg.concat(`and ${vertebra} `);
          }
        });
        return (msg = msg.concat('have edited vertebrae for this case'));
      }
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        borderRadius={'5px'}
        sx={{ border: `1px solid ${theme.palette.success.main}` }}
        alignItems={'center'}
        mb={2}
        p={2}
      >
        <Typography variant={'body1'}>{bannerMessage()}</Typography>
        <Box flexGrow={1} />
        <ActionButton
          color={'secondary'}
          disabled={props.disabled}
          onClick={() => setShowEditedAssetsDialog(true)}
          variant={'outlined'}
        >
          <Typography color={'secondary'} variant={'button'} noWrap={true}>
            Manage Edited Vertebrae
          </Typography>
        </ActionButton>
      </Box>

      {showEditedAssetsDialog ? (
        <CustomDialog
          maxWidth={'md'}
          fullHeight={false}
          open={showEditedAssetsDialog}
          title={`Manage Edited Vertebrae Asset - ${props.activeCase.number}`}
          onClose={() => {
            setShowEditedAssetsDialog(false);
          }}
          positiveActionButtons={[]}
        >
          <EditedVertebralAssetsGridTable
            dispatch={props.dispatch}
            assets={props.assets || []}
            activeCase={props.activeCase}
            readOnly={props.readOnly}
            plan={props.plan}
          />
        </CustomDialog>
      ) : null}
    </>
  );
};

export default EditedVertebralAssetsBanner;
