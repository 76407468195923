import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  caseUtils,
  format,
  ImplantDirection,
  ImplantMeasurementRange,
  ImplantType,
  IPatientRecord,
  IPlanImplant,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { ProgressButton } from '@workflow-nx/ui';
import { Form, Formik, FormikValues } from 'formik';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import CustomDialog from '../../../../../../components/CustomDialog';
import { ISettingsForm19 } from '../../../../../../utils/form19';
import { ImplantEditorDialogContext } from '../ImplantEditorDialog.context';
import { getImplantHasScrews, getValidImplantMeasurementRanges } from '../utils/implantEditor';

type EditImplantDialogProps = {
  open?: boolean;
  onClose: (editedImplant?: IPlanImplant, reloadImplant?: boolean) => Promise<void>;
  level: LevelType;
  form19: ISettingsForm19 | undefined;
  patientRecord?: IPatientRecord;
};

export const editImplantDialogSchema = function (
  form19: ISettingsForm19 | undefined,
  partType: PartType,
) {
  if (!form19) return;

  return Yup.object().shape({
    screwLength: Yup.string()
      .test('screwLengthMissing', 'Screw length cannot be none', function (value) {
        const partType = this.parent.partType;
        const hasScrews = getImplantHasScrews(partType);

        if (!hasScrews) {
          return true;
        }
        return ['14', '17', '20', '25', '30'].includes(value ?? '');
      })
      .required('Screw length is required'),
    ap: Yup.number()
      .test('isInRangeAP', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(partType, form19);
        if (value && implantMeasurementRange) {
          result =
            value >= implantMeasurementRange?.minAP && value <= implantMeasurementRange?.maxAP;
        }
        return result;
      })
      .required(),
    ml: Yup.number()
      .test('isInRangeML', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(partType, form19);
        if (value && implantMeasurementRange) {
          result =
            value >= implantMeasurementRange?.minML && value <= implantMeasurementRange?.maxML;
        }
        return result;
      })
      .required(),
    cageTaper: Yup.number()
      .test('isInRangeCageTaper', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(
          this.parent.partType,
          form19,
        );

        const checkValue = value ?? 0;
        if (isNumber(checkValue) && implantMeasurementRange) {
          const minCageTaper = implantMeasurementRange?.minCageTaper ?? 0;
          const maxCageTaper = implantMeasurementRange?.maxCageTaper ?? 10;
          result = checkValue >= minCageTaper && checkValue <= maxCageTaper;
        }

        return result;
      })
      .required(),
  });
};

function getImplantTemplateOptionDirection(partType: PartType) {
  let implantTemplateOptionDirection = null;

  switch (partType) {
    case PartType.LLIF_RIGHT:
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_RIGHT:
      implantTemplateOptionDirection = ImplantDirection.Right;
      break;
    case PartType.LLIF_LEFT:
    case PartType.TLIFO_LEFT:
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_INLINE_LEFT:
      implantTemplateOptionDirection = ImplantDirection.Left;
      break;
  }

  return implantTemplateOptionDirection;
}

function getDefaultScrewLength(partType: PartType) {
  switch (partType) {
    case PartType.ACDF:
    case PartType.ACDF_X_TWO_UP:
    case PartType.ACDF_X_TWO_DOWN:
    case PartType.ACDF_X_LEFT_UP:
    case PartType.ACDF_X_LEFT_DOWN:
    case PartType.ACDF_X_NO_CAM_TWO_UP:
    case PartType.ACDF_X_NO_CAM_TWO_DOWN:
    case PartType.ACDF_X_NO_CAM_LEFT_UP:
    case PartType.ACDF_X_NO_CAM_LEFT_DOWN:
      return '17';
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      return '25';
  }
  return '25';
}

export default function EditImplantDialog({
  open,
  onClose,
  level,
  form19,
  patientRecord,
}: EditImplantDialogProps) {
  const context = useContext(ImplantEditorDialogContext);
  const { enqueueSnackbar } = useSnackbar();
  const [implantMeasurementRange, setImplantMeasurementRange] =
    useState<ImplantMeasurementRange | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<{
    partType: PartType;
    ap: number;
    ml: number;
    cageTaper: number;
    graftWindow: string;
    direction: ImplantDirection | null;
    screwLength: string | number;
    obliqueThreadAngle: string | number;
    cranialCaudalThreadAngle: string | number;
  }>({
    partType: PartType.NONE,
    ap: 0,
    ml: 0,
    cageTaper: 0,
    graftWindow: 'GRAFT_SMALL',
    direction: null,
    screwLength: 0,
    obliqueThreadAngle: '',
    cranialCaudalThreadAngle: '',
  });
  const [planImplant, setPlanImplant] = useState<IPlanImplant | null>();

  const handleSubmit = async (values: FormikValues) => {
    if (!planImplant) return;

    try {
      setIsLoading(true);

      const hasScrews = getImplantHasScrews(planImplant.partType);
      const editedImplant: IPlanImplant | undefined = {
        planImplantId: planImplant.planImplantId,
        planId: planImplant.planId,
        level: planImplant.level,
        position: planImplant.position,
        rotation: planImplant.rotation,
        referencePoints: planImplant.referencePoints,
        cageTaper: values.cageTaper,
        graftWindow: values.graftWindow,
        ap: values.ap,
        ml: values.ml,
        partType: planImplant.partType,
        screwLength: hasScrews ? Number(values.screwLength) : undefined,
        bullet: planImplant.bullet,
        threadHeight: planImplant.threadHeight,
        obliqueThreadAngle: Number(values.obliqueThreadAngle) ?? null,
        cranialCaudalThreadAngle: Number(values.cranialCaudalThreadAngle) ?? null,
      };

      const implantType = caseUtils.getImplantType(planImplant.partType ?? ImplantType.None);
      let reloadImplant = false;

      if (planImplant.ap !== values.ap) {
        reloadImplant = true;
      }
      if (planImplant.ml !== values.ml) {
        reloadImplant = true;
      }

      if ([ImplantType.LLIF].includes(implantType)) {
        if (planImplant.obliqueThreadAngle !== values.obliqueThreadAngle) {
          reloadImplant = true;
        }

        if (planImplant.cranialCaudalThreadAngle !== values.cranialCaudalThreadAngle) {
          reloadImplant = true;
        }
      }

      if ([ImplantType.ALIF].includes(implantType)) {
        if (planImplant.cranialCaudalThreadAngle !== values.cranialCaudalThreadAngle) {
          reloadImplant = true;
        }
      }

      if ([ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM, ImplantType.ALIFX].includes(implantType)) {
        if (planImplant.screwLength !== values.screwLength) {
          reloadImplant = true;
        }
      }

      if ([ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM, ImplantType.ACDF].includes(implantType)) {
        if (planImplant.cageTaper !== values.cageTaper) {
          reloadImplant = true;
        }
        if (planImplant.graftWindow !== values.graftWindow) {
          reloadImplant = true;
        }
      }

      await onClose(editedImplant, reloadImplant);
    } catch (err) {
      console.error('Error loading implant', err);
      enqueueSnackbar(`${level} Implant failed to load`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoad = useCallback(async () => {
    const planImplant = await context.findPlanImplant(level);

    if (!planImplant) return;

    setPlanImplant(planImplant);

    setInitialValues({
      partType: planImplant.partType,
      ap: planImplant?.ap ?? 0,
      ml: planImplant?.ml ?? 0,
      cageTaper: planImplant?.cageTaper ?? 0,
      graftWindow: planImplant?.graftWindow ?? 'GRAFT_SMALL',
      direction: getImplantTemplateOptionDirection(planImplant?.partType),
      screwLength: planImplant?.screwLength ?? getDefaultScrewLength(planImplant?.partType),
      obliqueThreadAngle: planImplant.obliqueThreadAngle
        ? planImplant.obliqueThreadAngle.toString()
        : '',
      cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle
        ? planImplant.cranialCaudalThreadAngle.toString()
        : '',
    });
    setImplantMeasurementRange(getValidImplantMeasurementRanges(planImplant?.partType, form19));
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return open ? (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={editImplantDialogSchema(form19, planImplant?.partType ?? PartType.NONE)}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        resetForm,
        submitForm,
        isSubmitting,
        isValid,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => {
        const formattedLevelType = format.formatLevelType(level as LevelType);
        const implantType = caseUtils.getImplantType(planImplant?.partType ?? PartType.NONE);

        return (
          <CustomDialog
            maxWidth={'sm'}
            open={open}
            title={`Edit Implant Level ${formattedLevelType} ${format.formatPartType(
              planImplant?.partType ?? PartType.NONE,
            )}`}
            onClose={() => {
              resetForm();
              onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || !isValid}
                onClick={submitForm}
                label={'Update'}
                loading={isLoading}
              />,
            ]}
          >
            <Form>
              <Box mb={2}>
                <Alert severity={'info'}>
                  <Typography color={'textPrimary'}>
                    Valid {format.formatPartType(planImplant?.partType ?? PartType.NONE)} ranges are
                    &mdash; AP {format.formatImplantRange('AP', implantMeasurementRange)} ML
                    {format.formatImplantRange('ML', implantMeasurementRange)}
                  </Typography>
                </Alert>
              </Box>
              <Box display={'flex'}>
                <Box>
                  <Box display={'flex'} alignItems={'center'} my={1}>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <Typography variant={'body1'}>AP</Typography>
                    </Box>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <TextField
                        data-testid={'edit-implant-input-ap'}
                        size={'small'}
                        name={'ap'}
                        label={''}
                        disabled={isSubmitting}
                        error={Boolean(errors.ap)}
                        helperText={errors.ap ? 'Required' : ''}
                        fullWidth
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ap}
                        variant={'outlined'}
                        InputProps={{
                          inputProps: {
                            min: implantMeasurementRange ? implantMeasurementRange.minAP : null,
                            max: implantMeasurementRange ? implantMeasurementRange.maxAP : null,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems={'center'} my={1}>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <Typography variant={'body1'}>ML</Typography>
                    </Box>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <TextField
                        data-testid={'edit-implant-input-ml'}
                        size={'small'}
                        name={'ml'}
                        label={`ML`}
                        disabled={isSubmitting}
                        error={Boolean(errors.ml)}
                        helperText={errors.ml ? 'Required' : ''}
                        fullWidth
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ml}
                        variant={'outlined'}
                        InputProps={{
                          inputProps: {
                            min: implantMeasurementRange ? implantMeasurementRange.minML : null,
                            max: implantMeasurementRange ? implantMeasurementRange.maxML : null,
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {[ImplantType.ALIFX].includes(implantType ?? ImplantType.None) ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>ALIF/X Screw Size</Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <InputLabel id="direction">ALIF/X Screw Size</InputLabel>
                          <Select
                            name={'screwLength'}
                            value={values.screwLength}
                            error={Boolean(errors.screwLength)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'edit-implant-screw-length-select-menu-direction'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>No screws</MenuItem>
                            <MenuItem value={'20'}>20mm screws</MenuItem>
                            <MenuItem value={'25'}>25mm screws</MenuItem>
                            <MenuItem value={'30'}>30mm screws</MenuItem>
                          </Select>
                          {errors.screwLength ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {[ImplantType.ACDF, ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM].includes(
                    implantType ?? ImplantType.None,
                  )
                    ? [
                        <Box display={'flex'} alignItems={'center'} my={1}>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <Typography variant={'body1'}>Cage Taper</Typography>
                          </Box>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <TextField
                              data-testid={'add-implant-input-cage-taper'}
                              size={'small'}
                              name={'cageTaper'}
                              label={``}
                              disabled={isSubmitting}
                              error={Boolean(errors.cageTaper)}
                              helperText={errors.cageTaper ? 'Required' : ''}
                              fullWidth
                              type="number"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.cageTaper}
                              variant={'outlined'}
                              InputProps={{
                                inputProps: {
                                  min: implantMeasurementRange
                                    ? implantMeasurementRange.minCageTaper
                                    : null,
                                  max: implantMeasurementRange
                                    ? implantMeasurementRange.maxCageTaper
                                    : null,
                                },
                              }}
                            />
                          </Box>
                        </Box>,
                        <Box display={'flex'} alignItems={'center'} my={1}>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <Typography variant={'body1'}>Graft Window</Typography>
                          </Box>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                              <Select
                                name={'graftWindow'}
                                value={values.graftWindow}
                                error={Boolean(touched.graftWindow && errors.graftWindow)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                data-testid={'add-implant-graft-window-select-menu'}
                                defaultValue={'20'}
                              >
                                <MenuItem value={'GRAFT_SMALL'}>Small</MenuItem>
                                <MenuItem value={'GRAFT_BIG'}>Big</MenuItem>
                              </Select>
                              {errors.screwLength ? (
                                <FormHelperText error>Required</FormHelperText>
                              ) : null}
                            </FormControl>
                          </Box>
                        </Box>,
                      ]
                    : null}
                  {[ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM].includes(
                    implantType ?? ImplantType.None,
                  ) ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>ACDF/X Screw Size</Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'screwLength'}
                            value={values.screwLength}
                            error={Boolean(touched.screwLength && errors.screwLength)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'add-implant-screw-length-select-menu-direction'}
                            defaultValue={'20'}
                          >
                            <MenuItem value={''}>No screws</MenuItem>
                            <MenuItem value={'14'}>14mm screws</MenuItem>
                            <MenuItem value={'17'}>17mm screws</MenuItem>
                            <MenuItem value={'20'}>20mm screws</MenuItem>
                          </Select>
                          {errors.screwLength ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}

                  {[ImplantType.LLIF].includes(implantType) && patientRecord?.hasPelvisOblique ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Oblique Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          Oblique Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'obliqueThreadAngle'}
                            value={values.obliqueThreadAngle}
                            error={Boolean(touched.obliqueThreadAngle && errors.obliqueThreadAngle)}
                            onChange={(e) => {
                              setFieldValue(`cranialCaudalThreadAngle`, '0', true);

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            data-testid={'add-implant-oblique-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                          </Select>
                          {errors.obliqueThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {[ImplantType.LLIF].includes(implantType) && patientRecord?.hasPelvisHighCrest ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Cranial-Caudal Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          High Crest Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'cranialCaudalThreadAngle'}
                            value={values.cranialCaudalThreadAngle}
                            error={Boolean(
                              touched.cranialCaudalThreadAngle && errors.cranialCaudalThreadAngle,
                            )}
                            onChange={(e) => {
                              setFieldValue(`obliqueThreadAngle`, '0', true);

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            data-testid={'add-implant-cranial-caudal-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                            <MenuItem value={'-5'}>-5°</MenuItem>
                            <MenuItem value={'-10'}>-10°</MenuItem>
                            <MenuItem value={'-15'}>-15°</MenuItem>
                            <MenuItem value={'-20'}>-20°</MenuItem>
                          </Select>
                          {errors.cranialCaudalThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {[ImplantType.ALIF, ImplantType.ALIFX].includes(implantType) &&
                  patientRecord?.hasPelvisHighPelvicIncidence ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Cranial-Caudal Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          High PI Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'cranialCaudalThreadAngle'}
                            value={values.cranialCaudalThreadAngle}
                            error={Boolean(
                              touched.cranialCaudalThreadAngle && errors.cranialCaudalThreadAngle,
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'add-implant-cranial-caudal-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                          </Select>
                          {errors.cranialCaudalThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Form>
          </CustomDialog>
        );
      }}
    </Formik>
  ) : null;
}
