import { faArrowsRotate, faPenToSquare, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import {
  format,
  IAxes,
  IMeasure,
  IPlanImplant,
  LevelType,
  measurements as measurementUtils,
  PartType,
} from '@workflow-nx/common';
import { Mesh, Scene, Vector3 } from 'babylonjs';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { ComponentProps, useCallback, useContext, useEffect, useState } from 'react';
import ActionButton from '../../../../../../components/ActionButton';
import { setPointOfRotation } from '../../../../../../components/SceneComponent/renderer';
import { Select } from '../../../../../../components/Select';
import {
  getForm19ImplantAttribute,
  ISettingsForm19,
  ISettingsForm19ImplantAttributes,
} from '../../../../../../utils/form19';
import BulletForm, { BulletFormValues } from '../Dialogs/BulletForm';
import { ImplantEditorDialogContext } from '../ImplantEditorDialog.context';
import {
  getCentroidPlane,
  getTargetImplantDataFromScene,
  hasImplantBulletChanged,
  hasImplantPositionChanged,
} from '../utils/implantEditor';
import { CoordinateTypes, CoordinateValueTypes } from './CoordinateInputContainer';
import IDEAccordionSummary from './IDEAccordionSummary';
import ImplantTransformationControls from './ImplantTransformationControls';
import {
  getInitialBulletValues,
  getPositionChange,
  getRotationChange,
  setupMoveLevelView,
  updateMeshPositionAndRotation,
} from './MoveImplantView.helpers';

type MoveImplantViewProps = {
  caseId: number;
  disabled?: boolean;
  form19: ISettingsForm19 | undefined;
  levelType: LevelType;
  partType: PartType;
  measurements: IMeasure[];
  scene: Scene;
  onApply: (shouldUpdate: boolean) => void;
  handleApply: (loading: boolean) => void;
  loading: boolean;
};

export type SelectedItemType =
  | 'NONE'
  | 'IMPLANT'
  | 'MINI'
  | 'CUT'
  | 'SUPERIOR'
  | 'INFERIOR'
  | 'SCREWS'
  | 'SCREW_GUIDE'
  | 'PELVIS'
  | 'INSTRUMENT';

export default function MoveImplantView({
  form19,
  disabled,
  levelType,
  partType,
  measurements,
  scene,
  onApply,
  handleApply,
  loading,
}: MoveImplantViewProps) {
  const context = useContext(ImplantEditorDialogContext);
  const [superior, inferior] = levelType.split('_');
  const [transformingMeshes, setTransformingMeshes] = useState<SelectedItemType[]>(['IMPLANT']);
  const [form19ImplantAttributes, setForm19ImplantAttributes] = useState<
    ISettingsForm19ImplantAttributes | undefined
  >();

  const implant = context.state.moveImplant;

  const [editBullets, setEditBullets] = useState(false);
  const [targetImplantAssetPositionState, setTargetImplantAssetPositionState] =
    useState<IPlanImplant | null>(null);
  const [originalImplantAssetPositionState, setOriginalImplantAssetPositionState] =
    useState<IPlanImplant | null>(null);
  const [originalImplantDelta, setOriginalImplantDelta] = useState<{
    position: Vector3;
    rotation: Vector3;
  }>();
  const [implantStateLoaded, setImplantStateLoaded] = useState(false);
  const [axes, setAxes] = useState<IAxes | undefined>(undefined);
  const [bulletFormValues, setBulletFormValues] = useState<BulletFormValues>();
  const [transformationExpanded, setTransformationExpanded] = useState(false);
  const [bulletExpanded, setBulletExpanded] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const confirm = useConfirm();

  const isDirty = context.state.moveImplantIsDirty;

  const onlyMoveSecondary = transformingMeshes.includes('MINI') && transformingMeshes.length === 1;

  const handleLoad = useCallback(
    async (newLevelType: LevelType) => {
      setTransformationExpanded(false);
      setBulletExpanded(false);
      setImplantStateLoaded(false);

      const foundImplant = await context.findPlanImplant(newLevelType);

      if (!foundImplant) {
        context.dispatch({
          type: 'EDIT_IMPLANT_POSITION',
          data: {
            level: newLevelType,
            implant: undefined,
          },
        });

        return;
      }

      context.dispatch({
        type: 'EDIT_IMPLANT_POSITION',
        data: {
          level: newLevelType,
          implant: foundImplant,
        },
      });

      setupMoveLevelView(newLevelType, foundImplant, scene);

      if (form19) {
        setForm19ImplantAttributes(getForm19ImplantAttribute(form19, foundImplant.partType));
      }

      const mesh = getMesh(levelType);

      if (!mesh) return;

      const targetImplantAssetPosition = getTargetImplantDataFromScene(mesh);

      context.dispatch({
        type: 'SET_IMPLANT_ORIGINAL_POSITION',
        data: {
          position: mesh.position.clone(),
          rotation: mesh.absoluteRotationQuaternion.clone(),
        },
      });

      if (targetImplantAssetPosition) {
        const targetImplantDeepClone = cloneDeep(targetImplantAssetPosition);

        setTargetImplantAssetPositionState(targetImplantDeepClone as IPlanImplant);

        const { xAxis, yAxis, zAxis }: IAxes =
          measurementUtils.evaluateOrthogonalAxisFromMeasurementPoints(
            measurements,
            superior,
            inferior,
          );

        setAxes({ xAxis, yAxis, zAxis });

        setOriginalImplantAssetPositionState(cloneDeep(targetImplantAssetPosition as IPlanImplant));
      }

      const positionChange = getPositionChange(mesh, scene);

      const rotationChange = getRotationChange(mesh, scene);

      // NOTE - The Y and Z values are switched as this is the expected transformation using the UI
      const initialImplantDeltaPosition = new Vector3(
        parseFloat(positionChange.x),
        parseFloat(positionChange.z),
        parseFloat(positionChange.y),
      );

      const initialImplantDeltaRotation = new Vector3(
        parseFloat(rotationChange.x),
        parseFloat(rotationChange.z),
        parseFloat(rotationChange.y),
      );

      setMeshCoordinates({
        [CoordinateValueTypes.XPosition]: initialImplantDeltaPosition.x,
        [CoordinateValueTypes.YPosition]: initialImplantDeltaPosition.y,
        [CoordinateValueTypes.ZPosition]: initialImplantDeltaPosition.z,
        [CoordinateValueTypes.XRotation]: initialImplantDeltaRotation.x,
        [CoordinateValueTypes.YRotation]: initialImplantDeltaRotation.y,
        [CoordinateValueTypes.ZRotation]: initialImplantDeltaRotation.z,
      });

      setOriginalImplantDelta({
        position: initialImplantDeltaPosition,
        rotation: initialImplantDeltaRotation,
      });

      handleResetBullet();

      context.dispatch({
        type: 'SET_IMPLANT_MESH_DIRTY',
        data: false,
      });

      setTransformationExpanded(true);
      setBulletExpanded(true);
      setImplantStateLoaded(true);
    },
    [form19, implant, levelType, inferior, measurements, scene, superior],
  );

  useEffect(() => {
    handleLoad(levelType);
  }, [levelType]);

  function getMesh(levelType: LevelType) {
    const meshName = `${levelType}_CYBORG_IMPLANT`;

    return scene?.getMeshByName(meshName) as Mesh;
  }

  function getMiniMesh(levelType: LevelType) {
    const meshName = `${levelType}_${partType}_MINI`;

    return scene?.getMeshByName(meshName) as Mesh;
  }

  function checkIsDirty(levelType: LevelType, bulletFormValues: BulletFormValues | undefined) {
    const originalImplantState = originalImplantAssetPositionState;

    const mesh = getMesh(levelType);

    const implantSizeChanged =
      mesh?.metadata?.ap !== originalImplantState?.ap ||
      mesh?.metadata?.ml !== originalImplantState?.ml ||
      mesh?.metadata?.screwLength !== originalImplantState?.screwLength;

    const implantPositionChanged = hasImplantPositionChanged(
      mesh,
      originalImplantState?.position,
      originalImplantState?.rotation,
    );

    const implantBulletChanged = hasImplantBulletChanged(
      bulletFormValues,
      implant?.bullet,
      implant?.threadHeight,
    );

    const isDirty = implantSizeChanged || implantPositionChanged || implantBulletChanged;

    context.dispatch({
      type: 'SET_IMPLANT_MESH_DIRTY',
      data: isDirty,
    });
  }

  const handleApplyClick = async () => {
    const mesh = getMesh(levelType);

    if (!mesh || !implant) return;

    const implantToApply = { ...implant };

    try {
      setTransformationExpanded(false);
      setBulletExpanded(false);
      handleApply(true);

      const meshByName = scene.getMeshByName(`${levelType}_APP`);
      const cutMesh = meshByName as Mesh;

      if (cutMesh) {
        await confirm({
          allowClose: false,
          title: `${format.formatLevelType(levelType)} cut will be deleted!`,
          description: `The ${format.formatPartType(
            implantToApply?.partType as PartType,
          )} implant at ${format.formatLevelType(
            levelType,
          )} has already been cut, applying this change will delete that cut implant and will require it to be re-cut. Do you wish to continue?.`,
        });
      }

      implantToApply.bullet = {
        insertionSide: {
          angle: bulletFormValues?.angleInsertion ?? 0,
          height: bulletFormValues?.heightInsertion ?? 0,
        },
        threadedSide: {
          angle: bulletFormValues?.angleThreaded ?? 0,
          height: bulletFormValues?.heightThreaded ?? 0,
        },
      };

      implantToApply.threadHeight = bulletFormValues?.threadHeight ?? 0;

      const rotationQuaternion = mesh?.rotationQuaternion;
      const rotation = rotationQuaternion?.toEulerAngles()?.clone() ?? implantToApply.rotation;
      const position = mesh?.position ?? implantToApply.position;

      // dispose of cut assets since they aren't valid anymore
      // dispose of any cut assets
      scene.getMeshByName(`${levelType}_APP`)?.dispose();
      scene.getMeshByName(`${levelType}_APP_MINUS`)?.dispose();

      await context.upsertPlanImplant(implantToApply.planImplantId, implantToApply.planId, {
        ...implantToApply,
        position: { x: position.x, y: position.y, z: position.z },
        rotation: { x: rotation.x, y: rotation.y, z: rotation.z },
        referencePoints: getCentroidPlane(position, rotation),
      });

      await context.uploadAssets(implantToApply.level, implantToApply.partType, mesh, scene);

      context.dispatch({
        type: 'SET_IMPLANT_SHOULD_UPDATE',
        data: true,
      });

      context.dispatch({
        type: 'SET_IMPLANT_MESH_DIRTY',
        data: false,
      });

      onApply(true);
    } catch (err) {
      console.error(err);
      handleApply(false);
    }
  };

  const handleResetImplantClick = () => {
    const mesh = getMesh(levelType);

    if (!mesh || !originalImplantDelta) return;

    setTargetImplantAssetPositionState(originalImplantAssetPositionState);

    setMeshCoordinates({
      [CoordinateValueTypes.XPosition]: originalImplantDelta.position.x,
      [CoordinateValueTypes.YPosition]: originalImplantDelta.position.y,
      [CoordinateValueTypes.ZPosition]: originalImplantDelta.position.z,
      [CoordinateValueTypes.XRotation]: originalImplantDelta.rotation.x,
      [CoordinateValueTypes.YRotation]: originalImplantDelta.rotation.y,
      [CoordinateValueTypes.ZRotation]: originalImplantDelta.rotation.z,
    });

    if (originalImplantAssetPositionState) {
      updateMeshPositionAndRotation(
        new Vector3(
          originalImplantAssetPositionState.position.x,
          originalImplantAssetPositionState.position.y,
          originalImplantAssetPositionState.position.z,
        ),
        new Vector3(
          originalImplantAssetPositionState.rotation.x,
          originalImplantAssetPositionState.rotation.y,
          originalImplantAssetPositionState.rotation.z,
        ),
        mesh,
      );

      checkIsDirty(levelType, bulletFormValues);
    }
  };

  function handleBulletFormChange(values: BulletFormValues) {
    setBulletFormValues(values);

    checkIsDirty(levelType, values);
  }

  const handleResetBullet = () => {
    if (!implant) return;

    const initialBulletValues = getInitialBulletValues(
      implant?.partType,
      implant?.bullet,
      implant?.threadHeight,
    );

    setBulletFormValues(initialBulletValues);
  };

  const [meshCoordinates, setMeshCoordinates] = useState({
    [CoordinateValueTypes.XPosition]: 0,
    [CoordinateValueTypes.YPosition]: 0,
    [CoordinateValueTypes.ZPosition]: 0,
    [CoordinateValueTypes.XRotation]: 0,
    [CoordinateValueTypes.YRotation]: 0,
    [CoordinateValueTypes.ZRotation]: 0,
  });

  const [secondaryMeshCoordinates, setSecondaryMeshCoordinates] = useState({
    [CoordinateValueTypes.XPosition]: 0,
    [CoordinateValueTypes.YPosition]: 0,
    [CoordinateValueTypes.ZPosition]: 0,
    [CoordinateValueTypes.XRotation]: 0,
    [CoordinateValueTypes.YRotation]: 0,
    [CoordinateValueTypes.ZRotation]: 0,
  });

  const handleInputComplete: ComponentProps<
    typeof ImplantTransformationControls
  >['handleInputComplete'] = (axes, levelType) => {
    setAxes(axes);
    checkIsDirty(levelType, bulletFormValues);
    // NOTE - Set camera target as original position
    const pos = originalImplantAssetPositionState?.position;

    if (pos) {
      setPointOfRotation(new Vector3(pos?.x, pos?.y, pos.z), scene);
    }
  };

  const handleInputChange: ComponentProps<
    typeof ImplantTransformationControls
  >['handleInputChange'] = (value, coordinateValueType) => {
    const newValue = Number.isNaN(value) ? 0 : value;
    if (transformingMeshes.includes('IMPLANT')) {
      setMeshCoordinates((old) => ({
        ...old,
        [coordinateValueType]: newValue,
      }));
    }

    if (transformingMeshes.includes('MINI')) {
      setSecondaryMeshCoordinates((old) => ({
        ...old,
        [coordinateValueType]: newValue,
      }));
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const mesh = getMesh(levelType);

  const secondaryMesh = getMiniMesh(levelType);

  const accordionsDisabled = loading || disabled || !implantStateLoaded;

  if (implant) {
    return (
      <Box>
        <Box borderRadius={1} overflow="hidden">
          <>
            <Accordion
              disabled={accordionsDisabled}
              expanded={transformationExpanded}
              onChange={(_, expanded) => setTransformationExpanded(expanded)}
            >
              <IDEAccordionSummary>
                <Box
                  flexGrow={1}
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box px={1}>
                    <Typography variant="h6">Transformation</Typography>
                  </Box>
                  {transformationExpanded ? (
                    <IconButton
                      size="medium"
                      onClick={(e) => {
                        e.stopPropagation();

                        handleResetImplantClick();
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowsRotate} size="xs" />
                    </IconButton>
                  ) : null}
                </Box>{' '}
              </IDEAccordionSummary>
              <AccordionDetails>
                <Box my={2} overflow={'visible'}>
                  <Select
                    name="target-mesh"
                    menuItems={[
                      { key: 'implant+mini', value: 'Implant' },
                      { key: 'mini', value: 'Mini Implant' },
                    ]}
                    value={transformingMeshes.includes('IMPLANT') ? 'implant+mini' : 'mini'}
                    fullWidth
                    hideNone
                    onChange={(e) => {
                      const newTargetMesh = e.target.value;

                      switch (newTargetMesh) {
                        case 'mini':
                          setTransformingMeshes(['MINI']);
                          secondaryMesh.visibility = 1;
                          break;
                        case 'implant+mini':
                          setTransformingMeshes(['IMPLANT', 'MINI']);
                          secondaryMesh.visibility = 0;
                          break;
                        default:
                          break;
                      }
                    }}
                    label="Target Mesh"
                  />
                </Box>

                <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                  <Tab label="Position (mm)" />
                  <Tab label="Rotation (°)" />
                </Tabs>
                <Box display={tabValue === 0 ? undefined : 'none'}>
                  <ImplantTransformationControls
                    active={tabValue === 0}
                    controlsType={CoordinateTypes.Position}
                    coordinates={meshCoordinates}
                    secondaryCoordinates={secondaryMeshCoordinates}
                    onlyMoveSecondary={onlyMoveSecondary}
                    mesh={mesh}
                    secondaryMesh={secondaryMesh}
                    originalTransform={originalImplantAssetPositionState}
                    axes={axes}
                    measurements={measurements}
                    levelType={levelType}
                    handleInputComplete={handleInputComplete}
                    handleInputChange={handleInputChange}
                  />
                </Box>
                <Box display={tabValue === 1 ? undefined : 'none'}>
                  <ImplantTransformationControls
                    active={tabValue === 1}
                    controlsType={CoordinateTypes.Rotation}
                    coordinates={meshCoordinates}
                    mesh={mesh}
                    secondaryMesh={secondaryMesh}
                    secondaryCoordinates={secondaryMeshCoordinates}
                    onlyMoveSecondary={onlyMoveSecondary}
                    originalTransform={originalImplantAssetPositionState}
                    axes={axes}
                    measurements={measurements}
                    levelType={levelType}
                    handleInputComplete={handleInputComplete}
                    handleInputChange={handleInputChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            {[
              PartType.ACDF,
              PartType.ACDF_X_TWO_UP,
              PartType.ACDF_X_TWO_DOWN,
              PartType.ACDF_X_LEFT_UP,
              PartType.ACDF_X_LEFT_DOWN,
              PartType.ACDF_X_NO_CAM_TWO_UP,
              PartType.ACDF_X_NO_CAM_TWO_DOWN,
              PartType.ACDF_X_NO_CAM_LEFT_UP,
              PartType.ACDF_X_NO_CAM_LEFT_DOWN,
              PartType.ALIF,
              PartType.ALIF_X_TWO_UP,
              PartType.ALIF_X_TWO_DOWN,
              PartType.LLIF_LEFT,
              PartType.LLIF_RIGHT,
            ].includes(implant.partType) &&
            targetImplantAssetPositionState &&
            form19ImplantAttributes &&
            bulletFormValues ? (
              <Accordion
                disabled={accordionsDisabled}
                expanded={bulletExpanded}
                onChange={(_, expanded) => setBulletExpanded(expanded)}
              >
                <IDEAccordionSummary>
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box px={1}>
                      <Typography variant="h6">Bullet</Typography>
                    </Box>
                    {!disabled && bulletExpanded ? (
                      <Box display="flex">
                        {editBullets ? (
                          <>
                            <IconButton
                              size="medium"
                              onClick={(e) => {
                                e.stopPropagation();

                                handleResetBullet();
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowsRotate} size="xs" />
                            </IconButton>
                            <Box mr={1} />
                          </>
                        ) : null}

                        <IconButton
                          size="medium"
                          disabled={loading}
                          onClick={(e) => {
                            e.stopPropagation();

                            setEditBullets(!editBullets);
                          }}
                        >
                          {!editBullets ? (
                            <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                          ) : (
                            <FontAwesomeIcon icon={faXmark} size="xs" />
                          )}
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                </IDEAccordionSummary>
                <AccordionDetails>
                  <Box px={1}>
                    <BulletForm
                      plusLevelSize={context.plan.plusLevelSize}
                      disabled={!editBullets}
                      planImplant={targetImplantAssetPositionState}
                      implantAttributes={form19ImplantAttributes}
                      bullet={bulletFormValues}
                      onChange={handleBulletFormChange}
                      scene={scene}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : null}
            {isDirty && !disabled ? (
              <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                <ActionButton
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  loading={loading}
                  onClick={async () => {
                    setEditBullets(false);

                    handleApplyClick();
                  }}
                  data-testid={`implant-orientation-apply-btn`}
                  sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                >
                  Apply Changes
                </ActionButton>
              </Box>
            ) : null}
          </>
        </Box>
      </Box>
    );
  } else return null;
}
