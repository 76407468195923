import { useMutation, useQuery } from '@apollo/client';
import { faBrainCircuit } from '@fortawesome/pro-light-svg-icons';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Checkbox as MuiCheckbox,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AutoCorrectionLevelGoalsType } from '@workflow-nx/auto-correct';
import {
  AutoCorrectStatus,
  CaseApproachType,
  CoronalCorrectionGoalType,
  format,
  HeightRestorationGoalType,
  IAutoCorrectQueue,
  ICase,
  ILevels,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { IconFontButton } from '@workflow-nx/ui';
import CustomDialog from 'apps/workflow-client/src/app/components/CustomDialog';
import {
  formatCaseApproach,
  formatCoronalCorrectionGoalType,
  formatHeightRestorationGoalType,
  formatImplantType,
  formatInterbodyLevel,
} from 'libs/common/src/lib/utils/format';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import ActionButton from '../../../../../components/ActionButton';
import {
  CREATE_AUTO_CORRECT_QUEUE_ENTRY,
  DELETE_AUTO_CORRECT_QUEUE_ENTRY,
  FIND_AUTO_CORRECT_QUEUE,
} from '../../../../../gql';

function formatAutoCorrectStatus(status: AutoCorrectStatus) {
  switch (status) {
    case AutoCorrectStatus.Processing:
      return 'Creating correction plan';
    case AutoCorrectStatus.Error:
      return 'Error creating correction plan';
    case AutoCorrectStatus.Completed:
      return 'Completed creating correction plan';
    default:
      return status;
  }
}

function getAutoCorrectSeverity(status: AutoCorrectStatus) {
  switch (status) {
    case AutoCorrectStatus.Error:
      return 'error';
    case AutoCorrectStatus.Completed:
      return 'success';
    default:
      return 'info';
  }
}

export function AutoCorrectView(props: { activeCase: ICase; onChange: () => void }) {
  const [showAutoCorrectDialog, setShowAutoCorrectDialog] = useState<boolean>(false);
  const [autoCorrectQueue, setAutoCorrectQueue] = useState<IAutoCorrectQueue | null>(null);
  const [shouldGenerateMeasurements, setShouldGenerateMeasurements] = useState<boolean>(false);
  const [sagittalGoal, setSagittalGoal] = useState<CaseApproachType>(CaseApproachType.GapScore);
  const [coronalGoal, setCoronalGoal] = useState<CoronalCorrectionGoalType>(
    CoronalCorrectionGoalType.DiscSpaceParallelization,
  );
  const [heightRestorationGoal, setHeightRestorationGoal] = useState<HeightRestorationGoalType>(
    HeightRestorationGoalType.TEM013,
  );
  const [activeLevelGoals, setActiveLevelGoals] = useState<AutoCorrectionLevelGoalsType[]>([]);

  const [createAutoCorrectionQueueEntry, { loading: loadingCreateAutoCorrectQueueEntry }] =
    useMutation(CREATE_AUTO_CORRECT_QUEUE_ENTRY);
  const [deleteAutoCorrectionQueueEntry, { loading: loadingDeleteAutoCorrectQueueEntry }] =
    useMutation(DELETE_AUTO_CORRECT_QUEUE_ENTRY);
  const { refetch, startPolling, stopPolling } = useQuery(FIND_AUTO_CORRECT_QUEUE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { caseId: props.activeCase.caseId },
    onCompleted: (data) => {
      setAutoCorrectQueue(data.autoCorrectQueue);
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const initializeActiveLevelGoals = (): AutoCorrectionLevelGoalsType[] => {
    const levelGoals: AutoCorrectionLevelGoalsType[] = [];

    const activeLevels: ILevels = props.activeCase.levels;
    for (let level in activeLevels) {
      if (activeLevels[level] && activeLevels[level] !== PartType.NONE && level !== '__typename') {
        levelGoals.push({
          lordosis: 0,
          level: level as LevelType,
          posteriorHeightRange: {
            min: null,
            max: null,
          },
          anteriorHeightRange: {
            min: null,
            max: null,
          },
          implantType: formatImplantType(activeLevels[level] as PartType),
        });
      }
    }

    return levelGoals;
  };

  useEffect(() => {
    const levelGoals = initializeActiveLevelGoals();
    setActiveLevelGoals(levelGoals);
  }, []);

  useEffect(() => {
    if (autoCorrectQueue) {
      const { status } = autoCorrectQueue;
      if ([AutoCorrectStatus.Completed, AutoCorrectStatus.Error].includes(status)) {
        stopPolling();
      } else {
        startPolling(15000);
      }
    }
  }, [autoCorrectQueue]);

  const handleAutoCorrectClicked = async () => {
    try {
      await createAutoCorrectionQueueEntry({
        variables: {
          caseId: props.activeCase.caseId,
          shouldGenerateMeasurements,
          sagittalGoal,
          coronalGoal,
          heightRestorationGoal,
          activeLevelGoals: JSON.stringify(activeLevelGoals),
        },
      });

      refetch();

      enqueueSnackbar('Case sent for auto-correct successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error sending case to auto-correct', {
        variant: 'error',
      });
    }
  };

  const handleDeleteAutoCorrectClicked = async () => {
    try {
      await deleteAutoCorrectionQueueEntry({
        variables: {
          autoCorrectQueueId: autoCorrectQueue?.autoCorrectQueueId,
        },
      });

      refetch();

      enqueueSnackbar('Auto-correct cancelled successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error cancelling auto-correct', {
        variant: 'error',
      });
    }
  };

  const renderLevelSpecificGoalForm = (): ReactNode => {
    let result: ReactNode[] = [];

    const activeLevels: ILevels = props.activeCase.levels;

    if (
      sagittalGoal === CaseApproachType.Other ||
      heightRestorationGoal === HeightRestorationGoalType.Specified
    ) {
      for (let level in activeLevels) {
        if (
          activeLevels[level] &&
          activeLevels[level] !== PartType.NONE &&
          level !== '__typename'
        ) {
          result.push(
            <Grid item xs={4}>
              <Typography>
                <strong>{formatInterbodyLevel(level as LevelType)}</strong>
              </Typography>
            </Grid>,
            <Grid item xs={8}>
              <Stack direction={'row'} gap={1}>
                {sagittalGoal === CaseApproachType.Other ? (
                  <TextField
                    id="outlined-basic"
                    label={`Lordosis`}
                    variant="outlined"
                    type="number"
                    size={'small'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const targetActiveLevel = activeLevelGoals.find(
                        (activeLevel: AutoCorrectionLevelGoalsType) => activeLevel.level === level,
                      ) as AutoCorrectionLevelGoalsType;
                      targetActiveLevel.lordosis = parseFloat(event.target.value);
                      setActiveLevelGoals(cloneDeep(activeLevelGoals));
                    }}
                  />
                ) : null}
                {heightRestorationGoal === HeightRestorationGoalType.Specified
                  ? [
                      <TextField
                        id="outlined-basic"
                        label={`Post. min`}
                        variant="outlined"
                        type="number"
                        size={'small'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const targetActiveLevel = activeLevelGoals.find(
                            (activeLevel: AutoCorrectionLevelGoalsType) =>
                              activeLevel.level === level,
                          ) as AutoCorrectionLevelGoalsType;
                          targetActiveLevel.posteriorHeightRange.min = parseFloat(
                            event.target.value,
                          );
                          setActiveLevelGoals(cloneDeep(activeLevelGoals));
                        }}
                      />,
                      <TextField
                        id="outlined-basic"
                        label={`Post. max`}
                        variant="outlined"
                        size={'small'}
                        type="number"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const targetActiveLevel = activeLevelGoals.find(
                            (activeLevel: AutoCorrectionLevelGoalsType) =>
                              activeLevel.level === level,
                          ) as AutoCorrectionLevelGoalsType;
                          targetActiveLevel.posteriorHeightRange.max = parseFloat(
                            event.target.value,
                          );
                          setActiveLevelGoals(cloneDeep(activeLevelGoals));
                        }}
                      />,
                      <TextField
                        id="outlined-basic"
                        label={`Ant. min`}
                        variant="outlined"
                        size={'small'}
                        type="number"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const targetActiveLevel = activeLevelGoals.find(
                            (activeLevel: AutoCorrectionLevelGoalsType) =>
                              activeLevel.level === level,
                          ) as AutoCorrectionLevelGoalsType;
                          targetActiveLevel.anteriorHeightRange.min = parseFloat(
                            event.target.value,
                          );
                          setActiveLevelGoals(cloneDeep(activeLevelGoals));
                        }}
                      />,
                      <TextField
                        id="outlined-basic"
                        label={`Ant. max`}
                        variant="outlined"
                        size={'small'}
                        type="number"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const targetActiveLevel = activeLevelGoals.find(
                            (activeLevel: AutoCorrectionLevelGoalsType) =>
                              activeLevel.level === level,
                          ) as AutoCorrectionLevelGoalsType;
                          targetActiveLevel.anteriorHeightRange.max = parseFloat(
                            event.target.value,
                          );
                          setActiveLevelGoals(cloneDeep(activeLevelGoals));
                        }}
                      />,
                    ]
                  : null}
              </Stack>
            </Grid>,
          );
        }
      }
    }

    return result;
  };

  const isProcessing = autoCorrectQueue?.status === AutoCorrectStatus.Processing;

  return (
    <>
      <CustomDialog
        maxWidth={'md'}
        fullHeight={false}
        open={showAutoCorrectDialog}
        title={`aprevo® Digital Planning - ${props.activeCase.number}`}
        onClose={() => setShowAutoCorrectDialog(false)}
        positiveActionButtons={[
          <ActionButton
            color={'secondary'}
            variant={'outlined'}
            onClick={!isProcessing ? handleAutoCorrectClicked : handleDeleteAutoCorrectClicked}
            loading={
              !isProcessing
                ? loadingCreateAutoCorrectQueueEntry
                : loadingDeleteAutoCorrectQueueEntry
            }
          >
            {!isProcessing ? 'Run' : 'Cancel'}
          </ActionButton>,
        ]}
      >
        <Grid container justifyContent={'center'} alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Typography>
              <strong>Coronal Goal</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl>
              <Select
                size={'small'}
                disabled={
                  loadingCreateAutoCorrectQueueEntry ||
                  loadingDeleteAutoCorrectQueueEntry ||
                  isProcessing
                }
                name="coronalGoal"
                onChange={(event: SelectChangeEvent<string>) => {
                  setCoronalGoal(event.target.value as CoronalCorrectionGoalType);
                }}
                value={coronalGoal}
                labelId={'coronalGoal-label'}
              >
                <MenuItem value={CoronalCorrectionGoalType.DiscSpaceParallelization}>
                  {formatCoronalCorrectionGoalType(
                    CoronalCorrectionGoalType.DiscSpaceParallelization,
                  )}
                </MenuItem>
                <MenuItem value={CoronalCorrectionGoalType.FloorParallelization}>
                  {formatCoronalCorrectionGoalType(CoronalCorrectionGoalType.FloorParallelization)}
                </MenuItem>
                <MenuItem value={CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization}>
                  {formatCoronalCorrectionGoalType(
                    CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization,
                  )}
                </MenuItem>
                <MenuItem value={CoronalCorrectionGoalType.ZeroCoronal}>
                  {formatCoronalCorrectionGoalType(CoronalCorrectionGoalType.ZeroCoronal)}
                </MenuItem>
                <MenuItem value={CoronalCorrectionGoalType.None}>
                  {formatCoronalCorrectionGoalType(CoronalCorrectionGoalType.None)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Sagittal Goal</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl>
              <InputLabel sx={{ backgroundColor: 'white' }} id="sagittalGoal-label"></InputLabel>
              <Select
                size={'small'}
                disabled={
                  loadingCreateAutoCorrectQueueEntry ||
                  loadingDeleteAutoCorrectQueueEntry ||
                  isProcessing
                }
                name={'sagittalGoal'}
                onChange={(event: SelectChangeEvent<string>) => {
                  setSagittalGoal(event.target.value as CaseApproachType);
                }}
                value={sagittalGoal}
                labelId={'sagittalGoal-label'}
              >
                <MenuItem value={CaseApproachType.AgeAdjusted}>
                  {formatCaseApproach(CaseApproachType.AgeAdjusted)}
                </MenuItem>
                <MenuItem value={CaseApproachType.GapScore}>
                  {formatCaseApproach(CaseApproachType.GapScore)}
                </MenuItem>
                <MenuItem value={CaseApproachType.ZeroMismatch}>
                  {formatCaseApproach(CaseApproachType.ZeroMismatch)}
                </MenuItem>
                <MenuItem value={CaseApproachType.Other}>Level specific</MenuItem>
                <MenuItem value={CaseApproachType.None}>
                  {formatCaseApproach(CaseApproachType.None)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Height Restoration Goal</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl>
              <InputLabel
                sx={{ backgroundColor: 'white' }}
                id="heightRestorationGoal-label"
              ></InputLabel>
              <Select
                size={'small'}
                disabled={
                  loadingCreateAutoCorrectQueueEntry ||
                  loadingDeleteAutoCorrectQueueEntry ||
                  isProcessing
                }
                name={'height-restoration'}
                onChange={(event: SelectChangeEvent<string>) => {
                  setHeightRestorationGoal(event.target.value as HeightRestorationGoalType);
                }}
                value={heightRestorationGoal}
                labelId={'heightRestorationGoal-label'}
              >
                <MenuItem value={HeightRestorationGoalType.TEM013}>
                  {formatHeightRestorationGoalType(HeightRestorationGoalType.TEM013)}
                </MenuItem>
                <MenuItem value={HeightRestorationGoalType.Specified}>
                  {formatHeightRestorationGoalType(HeightRestorationGoalType.Specified)}
                </MenuItem>
                <MenuItem value={HeightRestorationGoalType.None}>
                  {formatHeightRestorationGoalType(HeightRestorationGoalType.None)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {renderLevelSpecificGoalForm()}
          <Grid item xs={4}>
            <Typography>
              <strong>Generate Landmarks</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <MuiCheckbox
              disabled={
                loadingCreateAutoCorrectQueueEntry ||
                loadingDeleteAutoCorrectQueueEntry ||
                isProcessing
              }
              name="shouldGenerateMeasurements"
              checked={shouldGenerateMeasurements}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShouldGenerateMeasurements(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
        {autoCorrectQueue ? (
          <Alert severity={getAutoCorrectSeverity(autoCorrectQueue.status)}>
            <Typography
              variant={'body1'}
              sx={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              {formatAutoCorrectStatus(autoCorrectQueue.status)}
              {autoCorrectQueue?.finishedAt ? (
                <>
                  {' '}
                  &mdash; Process finished at{' '}
                  {format.formatDateTime(autoCorrectQueue.finishedAt as Date)} (
                  {autoCorrectQueue.minutesProcessing}min)
                </>
              ) : null}
            </Typography>
          </Alert>
        ) : null}
      </CustomDialog>
      <IconFontButton
        loading={isProcessing}
        size={'large'}
        onClick={() => setShowAutoCorrectDialog(true)}
        icon={faBrainCircuit}
      />
    </>
  );
}
