import { date } from '@workflow-nx/utils';
import lodash from 'lodash';
import { DateTime } from 'luxon';
import {
  CaseSpineProfileConfig,
  DEFAULT_ASSET_EXPIRY_DATE_MONTHS,
  DEFAULT_SPINE_PROFILE,
  LEVEL_CONFIG_MAP,
  MONTHS_BEFORE_EXPIRATION_TO_SHOW_WARNING,
  SPINE_PROFILE_CONFIG_MAP,
  VERTEBRAL_BODY_ASSET_MAP,
  VERTEBRAL_BODY_HIERARCHY_INDEX,
} from '../common';
import {
  AssetType,
  CaseRiskAssessmentType,
  CaseSpineProfile,
  CaseSpineType,
  CaseStageType,
  DistributionProductionSubstageType,
  EndPlate,
  EventType,
  Form20ProductStatusType,
  Form20ProductType,
  HIPProductionSubstageType,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  InterbodyScrewLengthTypes,
  LevelResult,
  LevelType,
  ManufacturingProductionSubstageType,
  PackagingProductionSubstageType,
  PartType,
  PlanVersionType,
  Position,
  ProductionStageType,
  SterilizationProductionSubstageType,
  ThreadType,
  VertebralBody,
} from '../enums';
import {
  IAsset,
  ICase,
  ICaseLevel,
  ICaseResult,
  IEvent,
  IForm20Product,
  ILevels,
  IPatientRecord,
  IPlanImplant,
  ProductionStageEstimate,
} from '../interfaces';
import { formatISODate } from './format';
const { isNull, isNumber } = lodash;

const EXCLUDABLE_INSTRUMENTS_LIST = [
  '1034',
  '1045',
  '1060',
  '1080',
  '1162',
  '1180',
  '1210',
  '1220',
  '1230',
  '1240',
  '1270',
];

export const getCaseNumberPrefix = (
  firstName: string,
  lastName: string,
  receivedAt: Date,
): string => {
  const firstNameInitial = (firstName || '').length > 0 ? firstName[0].toUpperCase() : 'X';
  const lastNameInitial = (lastName || '').length > 0 ? lastName[0].toUpperCase() : 'X';

  let caseNumber = '';
  try {
    const date = DateTime.fromJSDate(receivedAt, { zone: 'UTC' }).toFormat('yyMMdd');
    caseNumber = `${date}.${firstNameInitial}${lastNameInitial}`;
  } catch (e) {
    console.error(e);
  }
  return caseNumber;
};

/*
  Gets the number of months until a particular asset type expires. Before Sept 1st, 2022
  the values were 12 months for a DICOM CT and 6 months for a DICOM X-RAY. After that date,
  both were set to expire after 18 months
 */
export const getMonthsUntilAssetTypeExpiry = (
  assetType?: AssetType,
  caseReceivedAt?: Date | string,
) => {
  // month 8 = September - JS dates are dumb
  const dateSeptStart = new Date(2022, 8, 1);
  if (caseReceivedAt && caseReceivedAt < dateSeptStart) {
    if (assetType === AssetType.DicomCt) {
      return 12;
    }

    if (assetType === AssetType.DicomXray) {
      return 6;
    }

    return DEFAULT_ASSET_EXPIRY_DATE_MONTHS;
  }

  // otherwise, the following are the defaults
  if (assetType === AssetType.DicomCt || assetType === AssetType.DicomXray) {
    return 18;
  }

  return DEFAULT_ASSET_EXPIRY_DATE_MONTHS;
};

export const isCaseStageOpenComplete = (assignedId: number): boolean => {
  // if the case is OPEN has someone assigned to it, move it to SEGMENTATION
  return !!assignedId;
};

export const isCaseStageSegmentationComplete = (
  caseAssets: IAsset[],
  caseSpineProfile: CaseSpineProfile,
  isXrayOptional: boolean,
): boolean => {
  // if the case is in SEGMENTATION and has all required segmented assets + a DICOM file, move it to PLANNING
  const requiredAssets = [
    AssetType.DicomCt,
    ...(isXrayOptional ? [] : [AssetType.DicomXray]),
    ...getCaseVertebralBodyAssets(caseSpineProfile).standard,
  ];

  const foundAssets = caseAssets.filter((asset) => {
    const assetType = asset.assetType as AssetType;

    return requiredAssets.includes(assetType) && !asset.planId;
  });

  return foundAssets.length === requiredAssets.length;
};

export const getCaseImplantMeasurementImageAssets = (caseLevels: ILevels): AssetType[] => {
  const caseLevelsWithInterbodies = getValidCaseLevels(caseLevels);

  return caseLevelsWithInterbodies.map((value) => {
    return `${value}_IMPLANT_MEASUREMENT_IMAGE` as AssetType;
  });
};

export const isCaseStagePlanningComplete = (
  caseLevels: ILevels,
  caseSpineProfile: CaseSpineProfile,
  planAssets: IAsset[],
  planVersion: PlanVersionType,
  patientRecord: IPatientRecord,
  planImplants: IPlanImplant[],
): boolean => {
  // if the case is in PLANNING and has:
  // - all required planned assets (depends on spine profile)
  // - a proposed plan, imported from cyborg
  // - all preop and planned assets added as OBJ files
  // - all levels for the interbody
  // - all levels for the interbody measurement assets
  // then the case can be moved to PROPOSED

  const errors = getCaseStagePlanningErrors(
    caseLevels,
    planAssets,
    planVersion,
    patientRecord,
    planImplants,
  );
  return errors.length === 0;
};

export const getCaseStagePlanningErrors = (
  caseLevels: ILevels,
  planAssets: IAsset[],
  planVersion: PlanVersionType,
  patientRecord: IPatientRecord,
  planImplants: IPlanImplant[],
): { key: string; level: string; value: string }[] => {
  // if the case is in PLANNING and has:
  // - all required planned assets (depends on spine profile)
  // - a proposed plan, imported from cyborg
  // - all preop and planned assets added as OBJ files
  // - all levels for the interbody
  // - all levels for the interbody measurement assets
  // then the case can be moved to PROPOSED
  function validateAsset(level: LevelType, assetType: AssetType) {
    const foundAsset = planAssets.find((planAsset) => planAsset.assetType === assetType);

    if (!foundAsset) {
      errors.push({ key: 'MISSING_ASSET', level, value: assetType });
    }
  }

  const { hasPelvisHighPelvicIncidence, hasPelvisOblique, hasPelvisHighCrest } = patientRecord;

  const errors: { key: string; level: string; value: string }[] = [];

  const caseLevelsWithInterbodies = getValidCaseLevels(caseLevels);
  caseLevelsWithInterbodies.forEach((level) => {
    validateAsset(level, `${level}_APP` as AssetType);
    validateAsset(level, `${level}_IMPLANT_MEASUREMENT_IMAGE` as AssetType);

    if (planVersion === 2) {
      validateAsset(level, `${level}_CYBORG_IMPLANT` as AssetType);
    }

    const partType = caseLevels[level] as PartType;
    const implantType = getImplantType(partType);

    // Note: make sure the following implant types have screws
    if ([ImplantType.ALIFX, ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM].includes(implantType)) {
      validateAsset(level, `${level}_IMPLANT_SCREW` as AssetType);
      if (planVersion === 2) {
        validateAsset(level, `${level}_IMPLANT_SCREW_GUIDE` as AssetType);
      }
    }

    const foundPlanImplant = planImplants.find((planImplant) => planImplant.level === level);

    if ([ImplantType.ALIF, ImplantType.ALIFX].includes(implantType)) {
      if (hasPelvisHighPelvicIncidence) {
        validateAsset(level, `${level}_IMPLANT_INSTRUMENT` as AssetType);

        if (!isNumber(foundPlanImplant?.cranialCaudalThreadAngle)) {
          errors.push({
            key: 'MISSING_PLAN_IMPLANT_PROPERTY',
            level,
            value: 'CRANIAL_CAUDAL_THREAD_ANGLE',
          });
        }
      }
    }

    if ([ImplantType.LLIF].includes(implantType)) {
      if (hasPelvisOblique) {
        validateAsset(level, `${level}_IMPLANT_INSTRUMENT` as AssetType);

        if (!isNumber(foundPlanImplant?.obliqueThreadAngle)) {
          errors.push({
            key: 'MISSING_PLAN_IMPLANT_PROPERTY',
            level,
            value: 'OBLIQUE_THREAD_ANGLE',
          });
        }
      }

      if (hasPelvisHighCrest) {
        validateAsset(level, `${level}_IMPLANT_INSTRUMENT` as AssetType);

        if (!isNumber(foundPlanImplant?.cranialCaudalThreadAngle)) {
          errors.push({
            key: 'MISSING_PLAN_IMPLANT_PROPERTY',
            level,
            value: 'CRANIAL_CAUDAL_THREAD_ANGLE',
          });
        }
      }
    }

    if (planVersion === 3 && !foundPlanImplant?.metadata) {
      errors.push({
        key: 'MISSING_PLAN_IMPLANT_METADATA',
        level,
        value: 'METADATA',
      });
    }
  });

  return errors;
};

export const isCaseStageProposedComplete = (
  planAssets?: IAsset[],
  planApprovalDate?: Date | string | null,
): boolean => {
  return (
    (planAssets || []).filter(
      (planAsset: IAsset) => planAsset.assetType === AssetType.Form19ApprovalSignature,
    ).length === 1 && Boolean(planApprovalDate)
  );
};

export const isCaseStageProductionComplete = (events: IEvent[]): boolean => {
  const deliveredToHospitalEvent = events.find(
    (event: IEvent) => event.eventType === EventType.HospitalDeliveryComplete,
  );

  return !!deliveredToHospitalEvent;
};

export const isCaseStageDesignComplete = (
  includeRodTemplates: boolean,
  caseLevels: ILevels,
  planAssets?: IAsset[],
  isForm19Approved?: boolean,
  isTem013Required?: boolean,
): boolean => {
  const caseLevelsWithInterbodies = getValidCaseLevels(caseLevels);

  let isForm18Complete = false;
  let isTem013Complete = false;
  let isForm19Complete = false;
  let hasRodTemplates = true;
  let hasRequiredAssets = true;

  if (!!planAssets && planAssets.length > 0) {
    for (const caseLevel of caseLevelsWithInterbodies) {
      const partsPerLevel = planAssets.filter(
        (planAsset: IAsset) =>
          planAsset.assetType === caseLevel.toString() ||
          planAsset.assetType === `${caseLevel}_PLUS` ||
          planAsset.assetType === `${caseLevel}_MINUS`,
      );
      if (partsPerLevel.length !== 3) {
        hasRequiredAssets = false;
        break;
      }
    }

    if (includeRodTemplates) {
      hasRodTemplates =
        planAssets.filter(
          (planAsset: IAsset) =>
            planAsset.assetType === AssetType.RodTemplateRight ||
            planAsset.assetType === AssetType.RodTemplateLeft,
        ).length === 2;
    }

    isForm19Complete =
      !!planAssets.find((planAsset: IAsset) => planAsset.assetType === AssetType.Form19) ||
      !!isForm19Approved;

    isForm18Complete = !!planAssets.find(
      (planAsset: IAsset) => planAsset.assetType === AssetType.Form18,
    );

    isTem013Complete = !!planAssets.find(
      (planAsset: IAsset) => planAsset.assetType === AssetType.Tem013,
    );
  }

  if (isTem013Required) {
    return hasRequiredAssets && hasRodTemplates && isForm19Complete && isTem013Complete;
  }

  return hasRequiredAssets && hasRodTemplates && isForm19Complete && isForm18Complete;
};

export const isCaseStageQaReviewComplete = (planAssets?: IAsset[]): boolean => {
  let isForm19Complete = false;

  if (!!planAssets && planAssets.length > 0) {
    isForm19Complete = !!planAssets.find(
      (planAsset: IAsset) => planAsset.assetType === AssetType.Form19,
    );
  }

  return isForm19Complete;
};

export function isValidLevelPartType(levelPartType: string) {
  return (
    Boolean(levelPartType) &&
    !['NONE', PartType.FUSED].includes(levelPartType) &&
    Object.values(PartType).includes(levelPartType as PartType)
  );
}

export const getCompletedCaseLevelCount = (caseResults: ICaseResult[]) =>
  caseResults?.filter((caseResult: ICaseResult) => caseResult.result === LevelResult.Used)
    ?.length ?? 0;

export const getCompletedCaseLevels = (caseResults: ICaseResult[]) =>
  caseResults
    ?.filter((caseResult: ICaseResult) => caseResult.result === LevelResult.Used)
    ?.map((caseResult) => caseResult.level) || [];

export const getUsedCompletedCaseResults = (caseResults: ICaseResult[]) =>
  caseResults?.filter((caseResult: ICaseResult) => caseResult.result === LevelResult.Used);

export const getBillableCaseLevelCount = (caseResults: ICaseResult[]) =>
  caseResults?.filter(
    (caseResult: ICaseResult) =>
      caseResult.result === LevelResult.Used || caseResult.result === LevelResult.NotUsedBillable,
  )?.length ?? 0;

export const getValidCaseLevels = (levels: ILevels): LevelType[] => {
  const caseLevels: LevelType[] = [];

  const profileLevelAssets = Object.values(LevelType).map(
    (level) => LEVEL_CONFIG_MAP[level as LevelType].asset,
  );

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as AssetType)) {
      if (isValidLevelPartType(levels[key])) {
        caseLevels.push(key as LevelType);
      }
    }
  });
  return caseLevels;
};

export const convertCyborgPlanMeasurements = (measurements: any) => {
  const measurementTypes = [];

  for (const [key, value] of Object.entries(measurements)) {
    const [body, position, endPlate] = key.split('.');
    const parsedBody = body.replace('_PREOP', '');
    const parsedEndPlate = endPlate === 'TOP_PLATE' ? 'SUPERIOR' : 'INFERIOR';

    let parsedPosition = position;
    switch (parsedPosition) {
      case 'LATERAL_LEFT':
        parsedPosition = 'PATIENT_RIGHT';
        break;
      case 'LATERAL_RIGHT':
        parsedPosition = 'PATIENT_LEFT';
        break;
    }

    measurementTypes.push({
      body: parsedBody,
      endPlate: parsedEndPlate,
      position: parsedPosition,
      point: value,
    });
  }

  return measurementTypes;
};

export const convertCyborgPlanOperations = (operations: any[]) => {
  for (const operation of operations) {
    operation.body = operation.body.replace('_PREOP', '');
  }

  return operations;
};

export const convertCyborgMeasurement = (measurement: any) => {
  const convertedMeasurement: any = {};

  convertedMeasurement.body = measurement.body.replace('_PREOP', '');
  convertedMeasurement.endPlate =
    measurement.endPlate === 'TOP_PLATE' ? EndPlate.Superior : EndPlate.Inferior;
  if (measurement.position === 'LATERAL_RIGHT') {
    convertedMeasurement.position = Position.PatientLeft;
  } else if (measurement.position === 'LATERAL_LEFT') {
    convertedMeasurement.position = Position.PatientRight;
  } else {
    convertedMeasurement.position = measurement.position;
  }

  return convertedMeasurement;
};

export const convertCyborgPlanMeasurementValues = (measurements: any) => {
  const convertedMeasurements: any = {};

  for (const measurement of Object.keys(measurements)) {
    const value = measurements[measurement];
    const key = measurement
      .replace('_PREOP', '')
      .replace('_PREOP', '')
      .replace('TOP_PLATE', EndPlate.Superior)
      .replace('BOTTOM_PLATE', EndPlate.Inferior)
      .replace('LATERAL_RIGHT', Position.PatientLeft)
      .replace('LATERAL_LEFT', Position.PatientRight);
    convertedMeasurements[key] = value;
  }

  return convertedMeasurements;
};

export const isCaseInPreProduction = (stage: CaseStageType) => {
  return [
    CaseStageType.Open,
    CaseStageType.Segmentation,
    CaseStageType.Planning,
    CaseStageType.Proposed,
    CaseStageType.Design,
    CaseStageType.QaReview,
  ].includes(stage);
};

export function getPartTypeCode(partType: PartType): string {
  let partTypeCode = '';

  switch (partType) {
    case PartType.ALIF:
      partTypeCode = 'A';
      break;
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      partTypeCode = 'D';
      break;
    case PartType.LLIF_RIGHT:
    case PartType.LLIF_LEFT:
      partTypeCode = 'C';
      break;
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFO_LEFT:
      partTypeCode = 'H';
      break;
    case PartType.TLIFC_OFFSET_RIGHT: //This is Offset part
    case PartType.TLIFC_OFFSET_LEFT: //This is Offset part
    case PartType.TLIFC_INLINE_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
      partTypeCode = 'G';
      break;
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
      partTypeCode = 'J';
      break;
  }
  return partTypeCode;
}

export const getImplantTypeAndDirectionAndOrientation = (partType: PartType): string[] => {
  let bodyLevelTypeDirection: any[];
  switch (partType) {
    case PartType.ACDF:
      bodyLevelTypeDirection = [ImplantType.ACDF, ImplantDirection.None, ImplantOrientation.None];
      break;
    case PartType.ACDF_X_TWO_UP:
      bodyLevelTypeDirection = [ImplantType.ACDFX, ImplantDirection.None, ImplantOrientation.TwoUp];
      break;
    case PartType.ACDF_X_TWO_DOWN:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX,
        ImplantDirection.None,
        ImplantOrientation.TwoDown,
      ];
      break;
    case PartType.ACDF_X_LEFT_UP:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX,
        ImplantDirection.None,
        ImplantOrientation.LeftUp,
      ];
      break;
    case PartType.ACDF_X_LEFT_DOWN:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX,
        ImplantDirection.None,
        ImplantOrientation.LeftDown,
      ];
      break;
    case PartType.ACDF_X_NO_CAM_TWO_UP:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX_NO_CAM,
        ImplantDirection.None,
        ImplantOrientation.TwoUp,
      ];
      break;
    case PartType.ACDF_X_NO_CAM_TWO_DOWN:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX_NO_CAM,
        ImplantDirection.None,
        ImplantOrientation.TwoDown,
      ];
      break;
    case PartType.ACDF_X_NO_CAM_LEFT_UP:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX_NO_CAM,
        ImplantDirection.None,
        ImplantOrientation.LeftUp,
      ];
      break;
    case PartType.ACDF_X_NO_CAM_LEFT_DOWN:
      bodyLevelTypeDirection = [
        ImplantType.ACDFX_NO_CAM,
        ImplantDirection.None,
        ImplantOrientation.LeftDown,
      ];
      break;
    case PartType.ALIF:
      bodyLevelTypeDirection = [ImplantType.ALIF, ImplantDirection.None, ImplantOrientation.None];
      break;
    case PartType.ALIF_X_TWO_UP:
      bodyLevelTypeDirection = [ImplantType.ALIFX, ImplantDirection.None, ImplantOrientation.TwoUp];
      break;
    case PartType.ALIF_X_TWO_DOWN:
      bodyLevelTypeDirection = [
        ImplantType.ALIFX,
        ImplantDirection.None,
        ImplantOrientation.TwoDown,
      ];
      break;
    case PartType.LLIF_RIGHT:
      bodyLevelTypeDirection = [ImplantType.LLIF, ImplantDirection.Right, ImplantOrientation.None];
      break;
    case PartType.LLIF_LEFT:
      bodyLevelTypeDirection = [ImplantType.LLIF, ImplantDirection.Left, ImplantOrientation.None];
      break;
    case PartType.TLIFC_INLINE_RIGHT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFC,
        ImplantDirection.Right,
        ImplantOrientation.Inline,
      ];
      break;
    case PartType.TLIFC_INLINE_LEFT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFC,
        ImplantDirection.Left,
        ImplantOrientation.Inline,
      ];
      break;
    case PartType.TLIFC_OFFSET_RIGHT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFC,
        ImplantDirection.Right,
        ImplantOrientation.Offset,
      ];
      break;
    case PartType.TLIFC_OFFSET_LEFT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFC,
        ImplantDirection.Left,
        ImplantOrientation.Offset,
      ];
      break;
    case PartType.TLIFCA_INLINE_RIGHT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFCA,
        ImplantDirection.Right,
        ImplantOrientation.Inline,
      ];
      break;
    case PartType.TLIFCA_INLINE_LEFT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFCA,
        ImplantDirection.Left,
        ImplantOrientation.Inline,
      ];
      break;
    case PartType.TLIFCA_OFFSET_RIGHT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFCA,
        ImplantDirection.Right,
        ImplantOrientation.Offset,
      ];
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
      bodyLevelTypeDirection = [
        ImplantType.TLIFCA,
        ImplantDirection.Left,
        ImplantOrientation.Offset,
      ];
      break;
    case PartType.TLIFO_RIGHT:
      bodyLevelTypeDirection = [ImplantType.TLIFO, ImplantDirection.Right, ImplantOrientation.None];
      break;
    case PartType.TLIFO_LEFT:
      bodyLevelTypeDirection = [ImplantType.TLIFO, ImplantDirection.Left, ImplantOrientation.None];
      break;
    default:
      bodyLevelTypeDirection = [];
      break;
  }

  return bodyLevelTypeDirection;
};

export const getInterbodyScrewLength = (screwLength: number): InterbodyScrewLengthTypes => {
  switch (screwLength) {
    case 20:
      return InterbodyScrewLengthTypes.AlifXScrewLength20mm;
    case 25:
      return InterbodyScrewLengthTypes.AlifXScrewLength25mm;
    case 30:
      return InterbodyScrewLengthTypes.AlifXScrewLength30mm;
    default:
      return InterbodyScrewLengthTypes.None;
  }
};

export const getPartTypesFromImplantType = (implantTypes: ImplantType[]): PartType[] => {
  const allPartTypes: PartType[] = [];

  implantTypes.forEach((approach) => {
    switch (approach) {
      case ImplantType.ALIF:
        allPartTypes.push(PartType.ALIF);
        break;
      case ImplantType.ALIFX:
        allPartTypes.push(PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP);
        break;
      case ImplantType.LLIF:
        allPartTypes.push(PartType.LLIF_LEFT, PartType.LLIF_RIGHT);
        break;
      case ImplantType.TLIFC:
        allPartTypes.push(
          PartType.TLIFC_OFFSET_LEFT,
          PartType.TLIFC_OFFSET_RIGHT,
          PartType.TLIFC_INLINE_LEFT,
          PartType.TLIFC_INLINE_RIGHT,
        );
        break;
      case ImplantType.TLIFCA:
        allPartTypes.push(
          PartType.TLIFCA_OFFSET_LEFT,
          PartType.TLIFCA_OFFSET_RIGHT,
          PartType.TLIFCA_INLINE_LEFT,
          PartType.TLIFCA_INLINE_RIGHT,
        );
        break;
      case ImplantType.TLIFO:
        allPartTypes.push(PartType.TLIFO_LEFT, PartType.TLIFO_RIGHT);
        break;
      default:
        return;
    }
  });

  return allPartTypes;
};

export const getImplantTypeFromPartType = (partType?: PartType) => {
  switch (partType) {
    case PartType.ALIF:
      return ImplantType.ALIF;
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      return ImplantType.ALIFX;
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      return ImplantType.LLIF;
    case PartType.TLIFO_LEFT:
    case PartType.TLIFO_RIGHT:
      return ImplantType.TLIFO;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
      return ImplantType.TLIFC;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
      return ImplantType.TLIFCA;
    default:
      return ImplantType.None;
  }
};

export const getValidCaseLevelsWithPartTypes = (
  levels: ILevels,
): {
  levelType: LevelType;
  partType: PartType;
  levelCode: string;
  direction: string;
  implantLevelType: string;
  implantNameDimFile: string;
  implantNamePart: string;
  orientation?: ImplantOrientation | null;
}[] => {
  const caseLevels: {
    levelType: LevelType;
    partType: PartType;
    levelCode: string;
    direction: string;
    implantLevelType: string;
    implantNameDimFile: string;
    implantNamePart: string;
    orientation?: ImplantOrientation | null;
  }[] = [];

  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      if (isValidLevelPartType(levels[key])) {
        const partType = levels[key] as PartType;
        const levelCode = getPartTypeCode(partType);
        const partTypeLevelCode = `${levelCode}.${key.slice(0, 2)}${key.slice(-1)}.XX`;
        const implantNameDimFile = levelCode + '.' + key.replace('_', '');
        const implantNamePart = levelCode + '.' + key.slice(0, 2) + key.slice(-1);
        const [implantLevelType, direction, orientation] = getImplantTypeAndDirectionAndOrientation(
          levels[key] as PartType,
        );
        caseLevels.push({
          levelType: key as LevelType,
          partType: partType,
          levelCode: partTypeLevelCode,
          implantLevelType: implantLevelType,
          direction: direction,
          implantNameDimFile,
          implantNamePart,
          orientation: orientation as ImplantOrientation,
        });
      }
    }
  });

  return caseLevels;
};

export function getLevelPartTypesCodes(levels: ILevels): string[] {
  const levelPartCodes: string[] = [];
  let partTypeLevelCode = '';

  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      if (isValidLevelPartType(levels[key])) {
        partTypeLevelCode = getPartTypeCode(levels[key] as PartType);
        partTypeLevelCode += '.' + key.slice(0, 2) + key.slice(-1) + '.' + 'XX';
        levelPartCodes.push(partTypeLevelCode);
      }
    }
  });

  return levelPartCodes;
}

export function getTlifLevelPartTypesCodes(levels: ILevels): string[] {
  const levelPartCodes: string[] = [];
  let partTypeLevelCode = '';

  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      const partType = levels[key] as PartType;

      if (isValidLevelPartType(partType)) {
        if (
          [
            PartType.TLIFO_LEFT,
            PartType.TLIFO_RIGHT,
            PartType.TLIFC_OFFSET_LEFT,
            PartType.TLIFC_OFFSET_RIGHT,
            PartType.TLIFC_INLINE_LEFT,
            PartType.TLIFC_INLINE_RIGHT,
            PartType.TLIFCA_OFFSET_LEFT,
            PartType.TLIFCA_OFFSET_RIGHT,
            PartType.TLIFCA_INLINE_LEFT,
            PartType.TLIFCA_INLINE_RIGHT,
          ].includes(partType)
        ) {
          partTypeLevelCode = getPartTypeCode(partType);
          partTypeLevelCode += `.${key.slice(0, 2)}${key.slice(-1)}.XX`;
          levelPartCodes.push(partTypeLevelCode);
        }
      }
    }
  });
  return levelPartCodes;
}

export function getValidCaseTlifLevels(levels: ILevels): LevelType[] {
  const caseTlifLevels: LevelType[] = [];

  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      const partType = levels[key] as PartType;

      if (isValidLevelPartType(partType)) {
        if (
          [
            PartType.TLIFO_LEFT,
            PartType.TLIFO_RIGHT,
            PartType.TLIFC_OFFSET_LEFT,
            PartType.TLIFC_OFFSET_RIGHT,
            PartType.TLIFC_INLINE_LEFT,
            PartType.TLIFC_INLINE_RIGHT,
            PartType.TLIFCA_OFFSET_LEFT,
            PartType.TLIFCA_OFFSET_RIGHT,
            PartType.TLIFCA_INLINE_LEFT,
            PartType.TLIFCA_INLINE_RIGHT,
          ].includes(partType)
        ) {
          caseTlifLevels.push(key as LevelType);
        }
      }
    }
  });
  return caseTlifLevels;
}

export function getValidCaseAlifXLevels(levels: ILevels): LevelType[] {
  const caseAlifXLevels: LevelType[] = [];
  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      const partType = levels[key] as PartType;

      if (
        isValidLevelPartType(partType) &&
        [PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP].includes(partType)
      ) {
        caseAlifXLevels.push(key as LevelType);
      }
    }
  });
  return caseAlifXLevels;
}

export const getValidCaseLevelsTypes = (levels: ILevels): LevelType[] => {
  const caseLevelsTypes: LevelType[] = [];

  const profileLevelAssets = Object.values(LevelType);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      if (isValidLevelPartType(levels[key])) {
        caseLevelsTypes.push(key as LevelType);
      }
    }
  });

  return caseLevelsTypes;
};

export function getCaseProductionStageName(
  stage: ProductionStageEstimate['stage'],
  subStage?: ProductionStageEstimate['subStage'],
) {
  const mergedName = stage + (subStage || '');

  switch (mergedName) {
    case ProductionStageType.PreProduction:
      return 'Pre-Production';
    case ProductionStageType.SurgeonApproval:
      return 'Surgeon Approval';
    case ProductionStageType.Manufacturing + ManufacturingProductionSubstageType.BuildFileCreation:
      return 'Manufacturing - Build File Creation';
    case ProductionStageType.Manufacturing +
      ManufacturingProductionSubstageType.AdditiveManufacturing:
      return 'Manufacturing - Additive Manufacturing';
    case ProductionStageType.Manufacturing +
      ManufacturingProductionSubstageType.BuildPlateCheckAndRemoval:
      return 'Manufacturing - Build Plate Check / Removal';
    case ProductionStageType.HIP + HIPProductionSubstageType.Transit:
      return 'HIP - Transit';
    case ProductionStageType.HIP + HIPProductionSubstageType.Hipping:
      return 'HIP - Hipping';
    case ProductionStageType.Manufacturing + ManufacturingProductionSubstageType.ReceiveHIP:
      return 'Manufacturing - Receive HIP';
    case ProductionStageType.Manufacturing + ManufacturingProductionSubstageType.PackageAndRelease:
      return 'Manufacturing - Package & Release';
    case ProductionStageType.Packaging + PackagingProductionSubstageType.Receive:
      return 'Packaging - Receive';
    case ProductionStageType.Packaging + PackagingProductionSubstageType.PackagingAndLabeling:
      return 'Packaging - Packaging & Labeling';
    case ProductionStageType.Sterilization + SterilizationProductionSubstageType.Receive:
      return 'Sterilization - Receive';
    case ProductionStageType.Sterilization + SterilizationProductionSubstageType.Sterilization:
      return 'Sterilization - Sterilization';
    case ProductionStageType.Packaging + PackagingProductionSubstageType.ReceiveShipSterilized:
      return 'Packaging - Receive & Ship Sterilized';
    case ProductionStageType.Distribution + DistributionProductionSubstageType.ReceivePackage:
      return 'Distribution - Receive Package';
    case ProductionStageType.Distribution + DistributionProductionSubstageType.ReadyForSurgery:
      return 'Distribution - Ready for Surgery';
    default:
      return 'Unknown';
  }
}

export function getCaseSpineProfile(spineProfile?: CaseSpineProfile): CaseSpineProfileConfig {
  if (spineProfile && SPINE_PROFILE_CONFIG_MAP[spineProfile]) {
    return SPINE_PROFILE_CONFIG_MAP[spineProfile];
  } else {
    return SPINE_PROFILE_CONFIG_MAP[DEFAULT_SPINE_PROFILE];
  }
}

export function getCaseVertebralBodyAssets(
  spineProfile?: CaseSpineProfile,
  direction: 'desc' | 'asc' = 'desc',
): {
  standard: AssetType[];
  daisy: AssetType[];
} {
  const validVertebralBodies = getVertebralBodiesSortedByHierarchy(spineProfile, direction);

  return {
    standard: validVertebralBodies.map((vBody) => VERTEBRAL_BODY_ASSET_MAP[vBody].asset),
    daisy: validVertebralBodies.map((vBody) => VERTEBRAL_BODY_ASSET_MAP[vBody].daisyAsset),
  };
}

export function getVertebralBodiesSortedByHierarchy(
  spineProfile?: CaseSpineProfile,
  direction: 'desc' | 'asc' = 'desc',
) {
  const spineType = getCaseSpineType(spineProfile);
  let currentBody: VertebralBody =
    spineType === CaseSpineType.Lumbar ? VertebralBody.L1 : VertebralBody.C2;

  const sortedBodies: VertebralBody[] = [currentBody];

  const maxLength = Object.values(VertebralBody).length;

  const spineProfileConfig = getCaseSpineProfile(spineProfile);

  let parentBody = spineProfileConfig.vertebralInfoMap[currentBody]?.parent;

  do {
    if (!parentBody) break;

    sortedBodies.push(parentBody);

    currentBody = parentBody;

    parentBody = spineProfileConfig.vertebralInfoMap[currentBody]?.parent;
  } while (sortedBodies.length < maxLength);

  return direction === 'asc' ? sortedBodies.reverse() : sortedBodies;
}

export const getNextShortNumber = (numDigits: number, lastCode?: string): string => {
  const characters = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
  let carry = 1;

  if (!lastCode) {
    lastCode = characters[0].repeat(numDigits);
  } else if (lastCode?.length !== numDigits) {
    throw new Error(`The length of lastCode must be equal to numDigits.`);
  }

  let nextCodeArray = lastCode.split('');

  for (let i = nextCodeArray.length - 1; i >= 0; i--) {
    if (carry === 0) {
      break;
    }
    let index = characters.indexOf(nextCodeArray[i]) + carry;
    carry = Math.floor(index / characters.length);
    nextCodeArray[i] = characters[index % characters.length];
  }

  if (carry > 0) {
    throw new Error('Overflow error. Unable to generate next code.');
  }

  return nextCodeArray.join('');
};

export function getCaseSpineType(spineProfile?: CaseSpineProfile) {
  switch (spineProfile) {
    case 'CERVICAL_STANDARD':
    case 'CERVICAL_STANDARD_MINUS_C7':
    case 'CERVICAL_STANDARD_PLUS_C8':
      return CaseSpineType.Cervical;
    case 'LUMBAR_STANDARD':
    case 'LUMBAR_STANDARD_MINUS_L5':
    case 'LUMBAR_STANDARD_PLUS_L6':
    default:
      return CaseSpineType.Lumbar;
  }
}

export function getLevelsSortedByHierarchy(
  spineProfile?: CaseSpineProfile,
  direction: 'desc' | 'asc' = 'asc',
): LevelType[] {
  const spineType = getCaseSpineType(spineProfile);
  let currentBody: VertebralBody =
    spineType === CaseSpineType.Lumbar ? VertebralBody.S1 : VertebralBody.T1;

  const spineProfileConfig = getCaseSpineProfile(spineProfile);

  const sortedBodies: LevelType[] = [];

  const maxLength = Object.values(LevelType).length;

  do {
    const nextLevel = spineProfileConfig.validLevels.find(
      (level) => LEVEL_CONFIG_MAP[level].superiorVertebrae === currentBody,
    ) as LevelType;

    if (!nextLevel) break;

    sortedBodies.push(nextLevel);

    currentBody = LEVEL_CONFIG_MAP[nextLevel].inferiorVertebrae;
  } while (sortedBodies.length < maxLength);

  return direction === 'desc' ? sortedBodies.reverse() : sortedBodies;
}

export function isValidDaisySegmentationCase(spineProfile?: CaseSpineProfile) {
  return [
    CaseSpineProfile.LumbarStandard,
    CaseSpineProfile.LumbarStandardPlusL6,
    CaseSpineProfile.LumbarStandardMinusL5,
  ].includes(spineProfile || DEFAULT_SPINE_PROFILE);
}

export const getSpineProfileHierarchy = (spineProfile: CaseSpineProfile) => {
  return VERTEBRAL_BODY_HIERARCHY_INDEX.filter((vBody) =>
    // TODO - Update when spine profile includes more than just the lumbar spine
    vBody.startsWith('L') || vBody.startsWith('S')
      ? getCaseSpineProfile(spineProfile).validVertebralBodies.includes(vBody as VertebralBody)
      : true,
  );
};

export const getLevelsBetweenVertebralBodies = (options: {
  upper: string;
  lower: string;
  spineProfile: CaseSpineProfile;
}) => {
  const spineProfileHierarchy = getSpineProfileHierarchy(options.spineProfile);

  const upperVIndex = spineProfileHierarchy.indexOf(options.upper);

  const lowerVIndex = spineProfileHierarchy.indexOf(options.lower);

  if (upperVIndex < 0 || lowerVIndex < 0) {
    return 0;
  }

  return lowerVIndex - upperVIndex;
};

export const getExpiryDateFromStudyDate = (
  studyDate: string,
  caseReceivedAt?: Date | string,
  assetType?: AssetType,
): string | undefined => {
  const monthsUntilExpiry = getMonthsUntilAssetTypeExpiry(assetType, caseReceivedAt);

  const studyDateISO = date.parseISO(studyDate);
  const expiryDateISO = date
    .add(studyDateISO, {
      months: monthsUntilExpiry,
    })
    .toISOString();
  return studyDate ? formatISODate(expiryDateISO) : undefined;
};
export type StudyDateExpiryStatus = 'DEFAULT' | 'EXPIRED' | 'NEAR_EXPIRY';

export const getStudyDateExpiryStatus = (
  surgeryDate: ICase['surgeryDate'] | null,
  expiryDate: string | null,
): StudyDateExpiryStatus => {
  let status: StudyDateExpiryStatus = 'DEFAULT';

  let comparisonDate: string | null;

  if (!surgeryDate) {
    comparisonDate = new Date().toISOString();
  } else {
    if (surgeryDate instanceof Date) {
      comparisonDate = surgeryDate.toISOString();
    } else {
      comparisonDate = surgeryDate;
    }
  }

  if (expiryDate) {
    if (date.isAfter(comparisonDate, expiryDate)) {
      status = 'EXPIRED';
    } else if (date.isClose(comparisonDate, expiryDate, MONTHS_BEFORE_EXPIRATION_TO_SHOW_WARNING)) {
      status = 'NEAR_EXPIRY';
    }
  }

  return status;
};

export function getValidDimAssets(levelTypes: LevelType[]) {
  let txtLevelsAssetTypes: AssetType[] = [];

  for (const levelType of levelTypes) {
    const levelConfig = LEVEL_CONFIG_MAP[levelType];
    const txtAssetTypes: AssetType[] = levelConfig
      ? Object.values(levelConfig.implantAssets.dimensions)
      : [];
    txtLevelsAssetTypes = [...txtLevelsAssetTypes, ...txtAssetTypes];
  }
  return txtLevelsAssetTypes;
}

export function isCaseStageTabDisabled(stage: CaseStageType, currentStage?: CaseStageType) {
  if (!currentStage) return true;

  const stagesPlanningDisabled: CaseStageType[] = [CaseStageType.Open, CaseStageType.Segmentation];

  const stagesProposedDisabled: CaseStageType[] = [
    ...stagesPlanningDisabled,
    CaseStageType.Planning,
  ];

  const stagesDesignDisabled: CaseStageType[] = [...stagesProposedDisabled, CaseStageType.Proposed];

  const stagesQaReviewDisabled: CaseStageType[] = [...stagesDesignDisabled, CaseStageType.Design];

  const stagesProductionDisabled: CaseStageType[] = [
    ...stagesQaReviewDisabled,
    CaseStageType.QaReview,
  ];

  const stagesReadyDisabled: CaseStageType[] = [
    ...stagesProductionDisabled,
    CaseStageType.Production,
  ];

  const stagesCompleteDisabled: CaseStageType[] = [...stagesReadyDisabled, CaseStageType.Ready];

  const enabledStagesMapping: Record<CaseStageType, CaseStageType[]> = {
    [CaseStageType.Open]: [],
    [CaseStageType.Segmentation]: [],
    [CaseStageType.Planning]: stagesPlanningDisabled,
    [CaseStageType.Proposed]: stagesProposedDisabled,
    [CaseStageType.Design]: stagesDesignDisabled,
    [CaseStageType.QaReview]: stagesQaReviewDisabled,
    [CaseStageType.Production]: stagesProductionDisabled,
    [CaseStageType.Ready]: stagesReadyDisabled,
    [CaseStageType.Complete]: stagesCompleteDisabled,
    [CaseStageType.Distributor]: [],
    [CaseStageType.Packaging]: [],
    [CaseStageType.Shipping]: [],
  };

  return enabledStagesMapping[stage].includes(currentStage);
}

export function areArraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((element, index) => element === sortedArr2[index]);
}

export const getImplantType = (partType: PartType): ImplantType => {
  let implantType: ImplantType;
  switch (partType) {
    case PartType.ACDF:
      implantType = ImplantType.ACDF;
      break;
    case PartType.ACDF_X_TWO_UP:
    case PartType.ACDF_X_TWO_DOWN:
    case PartType.ACDF_X_LEFT_UP:
    case PartType.ACDF_X_LEFT_DOWN:
      implantType = ImplantType.ACDFX;
      break;
    case PartType.ACDF_X_NO_CAM_TWO_UP:
    case PartType.ACDF_X_NO_CAM_TWO_DOWN:
    case PartType.ACDF_X_NO_CAM_LEFT_UP:
    case PartType.ACDF_X_NO_CAM_LEFT_DOWN:
      implantType = ImplantType.ACDFX_NO_CAM;
      break;
    case PartType.ALIF:
      implantType = ImplantType.ALIF;
      break;
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      implantType = ImplantType.ALIFX;
      break;
    case PartType.LLIF_RIGHT:
    case PartType.LLIF_LEFT:
      implantType = ImplantType.LLIF;
      break;
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFO_LEFT:
      implantType = ImplantType.TLIFO;
      break;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
      implantType = ImplantType.TLIFC;
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
      implantType = ImplantType.TLIFCA;
      break;
    default:
      implantType = ImplantType.None;
  }
  return implantType;
};

export const getValidImplantTypes = (levels: ILevels): ImplantType[] => {
  const caseImplantType: ImplantType[] = [];
  const profileLevelAssets = Object.values(LevelType);
  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as LevelType)) {
      if (isValidLevelPartType(levels[key])) {
        const implantLevelType = getImplantType(levels[key] as PartType);
        caseImplantType.push(implantLevelType as ImplantType);
      }
    }
  });
  return caseImplantType;
};

export function findThreadType(
  partType: PartType,
  isM4lExpandedAvailable: boolean,
): ThreadType | null {
  let threadType: ThreadType | null = null;

  if (
    !partType ||
    [
      PartType.NONE,
      PartType.FUSED,
      PartType.TLIFCA_OFFSET_LEFT,
      PartType.TLIFCA_OFFSET_RIGHT,
      PartType.TLIFCA_INLINE_LEFT,
      PartType.TLIFCA_INLINE_RIGHT,
    ].includes(partType)
  ) {
    return threadType;
  }

  if ([PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(partType)) {
    return ThreadType.M4;
  }

  if (
    [
      PartType.ACDF,
      PartType.ACDF_X_TWO_UP,
      PartType.ACDF_X_TWO_DOWN,
      PartType.ACDF_X_LEFT_UP,
      PartType.ACDF_X_LEFT_DOWN,
      PartType.ACDF_X_NO_CAM_TWO_UP,
      PartType.ACDF_X_NO_CAM_TWO_DOWN,
      PartType.ACDF_X_NO_CAM_LEFT_UP,
      PartType.ACDF_X_NO_CAM_LEFT_DOWN,
    ].includes(partType)
  ) {
    return ThreadType.M2_2;
  }

  if (isM4lExpandedAvailable) {
    if (
      [
        PartType.ALIF,
        PartType.LLIF_LEFT,
        PartType.LLIF_RIGHT,
        PartType.TLIFC_OFFSET_LEFT,
        PartType.TLIFC_OFFSET_RIGHT,
        PartType.TLIFC_INLINE_LEFT,
        PartType.TLIFC_INLINE_RIGHT,
        PartType.TLIFO_LEFT,
        PartType.TLIFO_RIGHT,
      ].includes(partType)
    ) {
      threadType = ThreadType.M4L;
    }
  } else {
    if (
      [
        PartType.ALIF,
        PartType.LLIF_LEFT,
        PartType.LLIF_RIGHT,
        PartType.TLIFO_LEFT,
        PartType.TLIFO_RIGHT,
      ].includes(partType)
    ) {
      threadType = ThreadType.M4L;
    } else if ([PartType.TLIFC_OFFSET_LEFT, PartType.TLIFC_OFFSET_RIGHT].includes(partType)) {
      threadType = ThreadType.M5;
    }
  }

  return threadType;
}

export function checkM4lExpandedAvailabilityForThreadType(
  tlifCM4lStartDate: Date,
  featureFlagM4lExpandedInventoryAvailable: boolean,
): boolean {
  return !!(
    featureFlagM4lExpandedInventoryAvailable &&
    tlifCM4lStartDate &&
    tlifCM4lStartDate <= new Date()
  );
}

export function getStageSortOrder(stage: CaseStageType): number {
  switch (stage) {
    case CaseStageType.Open:
      return 0;
    case CaseStageType.Segmentation:
      return 1;
    case CaseStageType.Planning:
      return 2;
    case CaseStageType.Proposed:
      return 3;
    case CaseStageType.Design:
      return 4;
    case CaseStageType.QaReview:
      return 5;
    case CaseStageType.Production:
      return 6;
    case CaseStageType.Packaging:
      return 7;
    case CaseStageType.Shipping:
      return 8;
    case CaseStageType.Distributor:
      return 9;
    case CaseStageType.Ready:
      return 10;
    case CaseStageType.Complete:
      return 11;
  }
}
export function getScrewLengthSku(
  screwLengthTypes?: InterbodyScrewLengthTypes,
  is11453ScrewAvailable?: boolean,
): string {
  if (is11453ScrewAvailable) {
    switch (screwLengthTypes) {
      case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
        return '1145.20.3';
      case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
        return '1145.25.3';
      case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
        return '1145.30.3';
      default:
        return '';
    }
  } else {
    switch (screwLengthTypes) {
      case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
        return '1053.20';
      case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
        return '1053.25';
      case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
        return '1053.30';
      default:
        return '';
    }
  }
}

export const isCaseExpiryDateApproachingOrAlreadyExpired = (
  expiryDate: string,
  monthsUntil = 1,
) => {
  return expiryDate ? date.isApproaching(expiryDate, monthsUntil) : false;
};

export const canGeneratePelvis = (patientRecord: IPatientRecord) => {
  if (patientRecord.hasPelvisHighCrest || patientRecord.hasPelvisOblique) {
    return (
      !!patientRecord.pelvicTilt &&
      !!patientRecord.crestHeight &&
      !!patientRecord.biIliacWidth &&
      !!patientRecord.posteriorIliacCrestWidth
    );
  }
  if (patientRecord.hasPelvisHighPelvicIncidence) {
    return (
      !!patientRecord.pelvicIncidence &&
      !!patientRecord.pelvicTilt &&
      !!patientRecord.s1AnteriorPubisLength &&
      !!patientRecord.s1AnteriorPubisAngle &&
      !!patientRecord.biFemoralAxisLength &&
      !!patientRecord.biFemoralAxisLength
    );
  }
  return false;
};

export function getValidExcludableInstruments(form20Products: IForm20Product[]) {
  return (form20Products ?? []).filter(
    (form20Product: IForm20Product) =>
      EXCLUDABLE_INSTRUMENTS_LIST.includes(form20Product.sku) &&
      form20Product.status === Form20ProductStatusType.InUse &&
      form20Product.type === Form20ProductType.Instrument,
  );
}

export function getValidCaseLevelRecords(caseLevels: ICaseLevel[]): ICaseLevel[] {
  return caseLevels.filter((level) => level.partType && isValidLevelPartType(level.partType));
}

export const CASE_RISK_ASSESSMENT_MATRIX = [
  PartType.ALIF,
  PartType.ALIF_X_TWO_UP,
  PartType.ALIF_X_TWO_DOWN,
  PartType.LLIF_LEFT,
  PartType.LLIF_RIGHT,
  PartType.TLIFO_LEFT,
  PartType.TLIFO_RIGHT,
  PartType.TLIFC_INLINE_LEFT,
  PartType.TLIFC_INLINE_RIGHT,
  PartType.TLIFC_OFFSET_LEFT,
  PartType.TLIFC_OFFSET_RIGHT,
  PartType.TLIFCA_INLINE_LEFT,
  PartType.TLIFCA_INLINE_RIGHT,
  PartType.TLIFCA_OFFSET_LEFT,
  PartType.TLIFCA_OFFSET_RIGHT,
];

export function findCaseRiskAssessment(caseLevels: ICaseLevel[]): CaseRiskAssessmentType {
  const validCaseLevels = getValidCaseLevelRecords(caseLevels);
  let caseRiskAssessment = CaseRiskAssessmentType.CaseReviewQaReview;

  if (validCaseLevels?.length === 1) {
    const partType = validCaseLevels[0]?.partType as PartType;
    if (CASE_RISK_ASSESSMENT_MATRIX.includes(partType)) {
      caseRiskAssessment = CaseRiskAssessmentType.QaReviewOnly;
    }
  }
  return caseRiskAssessment;
}

export const convertNullOrNumberString = (val: any, defaultValue?: null | undefined) => {
  if (isNull(val)) return null;

  return val ? Number(val) : defaultValue;
};
