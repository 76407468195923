import { useQuery } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { IOrganization, IUser, OrganizationType, UserRoleType, format } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { Dispatch, useState } from 'react';
import { Select } from '../../../components/Select';
import { FIND_ORGANIZATIONS, FIND_USERS_WITH_TERRITORIES } from '../../../gql';
import { ListSurgeonsActionType } from './ListSurgeons.reducer';

export function SurgeonTableFilter(props: {
  state: {
    organization?: string;
    distributor?: string;
    fieldRepId?: string;
  };
  loading: boolean;
  dispatch: Dispatch<ListSurgeonsActionType>;
}) {
  const [organizations, setOrganizations] = useState<{ key: string; value: string }[]>([]);
  const [distributors, setDistributors] = useState<{ key: string; value: string }[]>([]);
  const [fieldReps, setFieldReps] = useState<
    { menuItem: { key: string; value: string }; territoryId: number | null }[]
  >([]);

  useQuery(FIND_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (orgData) => {
      const organizations = orgData.organizations.organizations || [];
      setOrganizations(
        sortBy(
          organizations
            .filter((org: IOrganization) => org.organizationType === OrganizationType.Hospital)
            .map((org: IOrganization) => {
              return { key: org.organizationId, value: org.name };
            }),
          ['value'],
        ),
      );

      setDistributors(
        sortBy(
          organizations
            .filter((org: IOrganization) => org.organizationType === OrganizationType.Distributor)
            .map((org: IOrganization) => {
              return { key: org.organizationId, value: org.name };
            }),
          ['value'],
        ),
      );
    },
  });

  useQuery(FIND_USERS_WITH_TERRITORIES, {
    fetchPolicy: 'network-only',
    variables: {
      roleFilter: [UserRoleType.FieldRep],
    },
    onCompleted: (data) => {
      const fieldRepUsers = data?.users?.users || [];
      setFieldReps(
        fieldRepUsers.map((user: IUser) => {
          return {
            menuItem: { key: user.userId, value: format.formatName(user) },
            territoryId: user?.userRegionTerritories?.[0]?.territoryId ?? -1,
          };
        }),
      );
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'fieldRepId'}
          label={'Sales Leader'}
          value={props.state.fieldRepId ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'USER_CHANGED',
              data: fieldReps.find((fieldRep) => fieldRep?.menuItem?.key === event.target.value),
            });
          }}
          menuItems={fieldReps.map((fieldRep) => fieldRep.menuItem)}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'organization'}
          label={'Health System'}
          value={props.state.organization?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'ORGANIZATION_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={organizations}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'distributor'}
          label={'Distributor'}
          value={props.state.distributor?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'DISTRIBUTOR_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={distributors}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
