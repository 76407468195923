import * as math from '@workflow-nx/math';
import * as babylon from 'babylonjs';
import {
  AssetPositionsPoints,
  caseUtils,
  Coordinate,
  EndPlate,
  IMeasure,
  IMeasurementPointValues,
  ImplantType,
  IPlanImplant,
  LevelSize,
  LevelType,
  measurements,
  NtopAlifReferencePoints,
  NtopLlifReferencePoints,
  PartType,
  Position,
  VertebralBody,
} from '@workflow-nx/common';
import { getImplantSizeNumber } from './nTopCuts';

export const acdfTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\210524.KB.01 A.L5S1.01 rev1',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'NewALIF - L5S1 - 28x38.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'Aprevo ACDF CL_Lattice',
};

export const acdfXTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\210524.KB.01 A.L5S1.01 rev1',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'NewALIF - L5S1 - 28x38.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'Aprevo ACDF_X CL_Lattice',
};

export const alifTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\210524.KB.01 A.L5S1.01 rev1',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'NewALIF - L5S1 - 28x38.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'Aprevo ALIF CL_Lattice',
};

export const alifXTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\210524.KB.01 D.L5S1.01 rev1',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_D.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'NewALIFX - L5S1 - 28x38.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'Aprevo ALIF/X CL_Lattice',
};

export const llifTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\210524.KB.01 A.L5S1.01 rev1',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'NewALIF - L5S1 - 28x38.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Right Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Right Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Left Taper Angle',
      type: 'real',
      units: 'deg',
      value: 30.0,
    },
    {
      description: '',
      name: 'Left Bullet Height',
      type: 'real',
      units: 'mm',
      value: 5.0,
    },
    {
      description: '',
      name: 'Insertion Direction',
      type: 'real',
      value: 0.0,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'LLIF_CL_Lattice',
};

export const tlifOTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'size.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Insertion Direction',
      type: 'real',
      value: -1.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'TLIFO_CL_Lattice',
};

export const tlifCTemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'size.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Insertion Direction',
      type: 'real',
      value: -1.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'TLIFC_CL_Lattice',
};

export const tlifCATemplate = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: '210524.KB.01_A.L5S1.01_rev1',
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: 'size.x_t',
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'deg',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 2.0],
    },
    {
      description: '',
      name: 'Insertion Direction',
      type: 'real',
      value: -1.0,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: -0.5,
    },
  ],
  title: 'TLIFCA_CL_Lattice',
};

function getImplantPositionAndRotation(
  points: AssetPositionsPoints[],
  level: LevelType,
  position: Coordinate,
  rotation: Coordinate,
) {
  const [inferior, superior] = level.split('_');
  const assetPositionMeasurements = measurements.getMeasurementsFromAssetPositions(points);
  const { supA, supP, supPR, supPL, infA, infP, infPR, infPL } =
    measurements.getMeasurementPositionVectors(assetPositionMeasurements, superior, inferior);

  const { centroid } = measurements.calculatePlaneBetweenPoints(
    supA,
    supP,
    supPR,
    supPL,
    infA,
    infP,
    infPR,
    infPL,
  );

  // subtract implant position from centroid so nTop can calculate how much to move
  // the implant from the centroid when positioning
  let positionX = Number(centroid.x) - position.x;
  let positionY = Number(centroid.y) - position.y;
  let positionZ = Number(centroid.z) - position.z;
  const implantPosition = `${positionX.toFixed(2)}, ${positionZ.toFixed(2)}, ${positionY.toFixed(
    2,
  )}`;

  // subtract implant rotation from initial plane rotation to compensate for the fact
  // that cyborg calculates rotation from 0 deg and nTop calculates it from the initial
  // plane
  let x = radiansToDegrees(rotation.x);
  let y = radiansToDegrees(rotation.y * -1);
  let z = radiansToDegrees((rotation.z += Math.PI));

  return {
    implantPosition,
    implantRotation: `${x.toFixed(2)}, ${z.toFixed(2)}, ${y.toFixed(2)}`,
  };
}

function getImplantSize(size: LevelSize, plusLevelSize: number) {
  let implantSizeValue = 0; // plan
  switch (size) {
    case LevelSize.Plus:
      // default is 2 (for 2mm) bigger than the plan size
      // the implant will grow (plusLevelSize / 2) in
      // each direction
      implantSizeValue = 1 * (plusLevelSize / 2);
      break;
    case LevelSize.Minus:
      implantSizeValue = -0.5;
      break;
  }
  return implantSizeValue;
}

function vectorFromArray(point?: number[]): babylon.Vector3 {
  if (!point) {
    return babylon.Vector3.Zero();
  }
  return new babylon.Vector3(point[0], point[1], point[2]);
}

function radiansToDegrees(radians: number) {
  return radians * (180 / Math.PI);
}

export const createNtopCliTxt = (
  caseNumber: string,
  level: LevelType,
  size: LevelSize,
  planImplant: IPlanImplant,
  points: AssetPositionsPoints[],
  partType: PartType,
  plusLevelSize: number,
): string => {
  const [child, parent]: string[] = level.split('_');
  const partTypeCode: string = caseUtils.getPartTypeCode(partType);
  const threaded = planImplant?.bullet?.threadedSide;
  const insertion = planImplant?.bullet?.insertionSide;
  // after threadHeight implant size is
  const implantSizeValue = getImplantSize(size, plusLevelSize);

  // calculate initial position and rotation of the implant
  const { implantPosition, implantRotation } = getImplantPositionAndRotation(
    points,
    level,
    planImplant.position,
    planImplant.rotation,
  );

  let nTopCliContent: string = '';
  // Please reference TP-058 Cloud Design Validation
  switch (partType) {
    case PartType.ALIF:
      nTopCliContent = `File Path
${caseNumber}
${child}
${parent}
${planImplant.ap}
${planImplant.ml}
Plan Name
Rev0
${implantRotation}
${implantPosition}
${insertion?.angle}
${insertion?.height}
${planImplant?.threadHeight ?? 0}
${implantSizeValue}
${caseNumber}_${partTypeCode}.${child}${parent}.02_Rev0`;
      break;
    case PartType.LLIF_LEFT:
      nTopCliContent = `File Path
${caseNumber}
${child}
${parent}
${planImplant.ap}
${planImplant.ml}
Plan Name
Rev0
${implantRotation}
${implantPosition}
${insertion?.angle}
${insertion?.height}
${threaded?.angle}
${threaded?.height}
0
${planImplant?.threadHeight ?? 0}
${implantSizeValue}
${caseNumber}_${partTypeCode}.${child}${parent}.02_Rev0`;
      break;
    case PartType.LLIF_RIGHT:
      nTopCliContent = `File Path
${caseNumber}
${child}
${parent}
${planImplant.ap}
${planImplant.ml}
Plan Name
Rev0
${implantRotation}
${implantPosition}
${threaded?.angle}
${threaded?.height}
${insertion?.angle}
${insertion?.height}
0
${planImplant?.threadHeight ?? 0}
${implantSizeValue}
${caseNumber}_${partTypeCode}.${child}${parent}.02_Rev0`;
      break;
    case PartType.TLIFO_LEFT:
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
      nTopCliContent = `File Path
${caseNumber}
${child}
${parent}
${planImplant.ap}
${planImplant.ml}
Plan Name
Rev0
${implantRotation}
${implantPosition}
0
0
${caseNumber}_${partTypeCode}.${child}${parent}.02_Rev0`;
      break;
    default:
      break;
  }

  return nTopCliContent;
};

export const getNTopMeasurementsForExport = (
  superior: VertebralBody,
  inferior: VertebralBody,
  measurements: IMeasure[],
  measurementPointValues: IMeasurementPointValues,
): string[][] => {
  const rows = [];
  const supA = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Anterior,
  );
  const supP = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Posterior,
  );
  const supPR = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientRight,
  );
  const supPL = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientLeft,
  );
  const infA = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Anterior,
  );
  const infP = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Posterior,
  );
  const infPR = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientRight,
  );
  const infPL = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientLeft,
  );
  if (supA && supP && supPR && supPL && infA && infP && infPR && infPL) {
    // calculating the origin (center) between the anterior and posterior for the superior and inferior bodies
    // divisor divides vector by 2 to get halfway point
    const divisor = new babylon.Vector3(2, 2, 2);

    const midpointA = math.getMidpoint(vectorFromArray(supA.point), vectorFromArray(infA.point));

    const midpointP = math.getMidpoint(vectorFromArray(supP.point), vectorFromArray(infP.point));

    const origin = math.getMidpoint(midpointP, midpointA);

    // calculating the AP mean between the superior and inferior bodies
    const superiorAP = vectorFromArray(supA.point).subtract(vectorFromArray(supP.point));

    const inferiorAP = vectorFromArray(infA.point).subtract(vectorFromArray(infP.point));

    const apMean = superiorAP.add(inferiorAP).divide(divisor);

    // calculating the ML mean between the superior and inferior bodies
    const superiorML = vectorFromArray(supPL.point).subtract(vectorFromArray(supPR.point));

    const inferiorML = vectorFromArray(infPL.point).subtract(vectorFromArray(infPR.point));

    const mlMean = superiorML.add(inferiorML).divide(divisor);

    const anteriorHeightKey = `ANTERIOR_HEIGHT.${inferior}.INFERIOR.${superior}.SUPERIOR`;
    const anteriorHeight = measurementPointValues[anteriorHeightKey];

    rows.push(
      [origin.x.toFixed(2), origin.z.toFixed(2), origin.y.toFixed(2)],
      [apMean.x.toFixed(2), apMean.z.toFixed(2), apMean.y.toFixed(2)],
      [mlMean.x.toFixed(2), mlMean.z.toFixed(2), mlMean.y.toFixed(2)],
      [anteriorHeight.toFixed(2), '0.00', '0.00'],
      [
        supPL.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supPL.point[2].toFixed(2),
        supPL.point[1].toFixed(2),
      ],
      [
        supPR.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supPR.point[2].toFixed(2),
        supPR.point[1].toFixed(2),
      ],
      [
        infPL.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infPL.point[2].toFixed(2),
        infPL.point[1].toFixed(2),
      ],
      [
        infPR.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infPR.point[2].toFixed(2),
        infPR.point[1].toFixed(2),
      ],
      [
        supA.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supA.point[2].toFixed(2),
        supA.point[1].toFixed(2),
      ],
      [
        supP.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supP.point[2].toFixed(2),
        supP.point[1].toFixed(2),
      ],
      [
        infA.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infA.point[2].toFixed(2),
        infA.point[1].toFixed(2),
      ],
      [
        infP.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infP.point[2].toFixed(2),
        infP.point[1].toFixed(2),
      ],
    );
  }

  return rows;
};

export const getImplantPartTypeCode = (partType: PartType) => {
  let implantTypeCode = 'A';
  if ([PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(partType)) {
    implantTypeCode = 'D';
  }
  if ([PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(partType)) {
    implantTypeCode = 'C';
  }
  if (
    [
      PartType.TLIFC_OFFSET_LEFT,
      PartType.TLIFC_OFFSET_RIGHT,
      PartType.TLIFC_INLINE_LEFT,
      PartType.TLIFC_INLINE_RIGHT,
    ].includes(partType)
  ) {
    implantTypeCode = 'G';
  }
  if (
    [
      PartType.TLIFCA_OFFSET_LEFT,
      PartType.TLIFCA_OFFSET_RIGHT,
      PartType.TLIFCA_INLINE_LEFT,
      PartType.TLIFCA_INLINE_RIGHT,
    ].includes(partType)
  ) {
    implantTypeCode = 'J';
  }
  if ([PartType.TLIFO_LEFT, PartType.TLIFO_RIGHT].includes(partType)) {
    implantTypeCode = 'H';
  }
  return implantTypeCode;
};

export const getNTopCallFile = (partType: PartType, size: string, environment: string) => {
  let nTopLatticeFile = 'ALIF_CL_Lattice.ntop';

  switch (partType) {
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      nTopLatticeFile = 'ALIFX_CL_Lattice.ntop';
      break;
    case PartType.LLIF_RIGHT:
      nTopLatticeFile = 'LLIF_RIGHT_CL_Lattice.ntop';
      break;
    case PartType.LLIF_LEFT:
      nTopLatticeFile = 'LLIF_LEFT_CL_Lattice.ntop';
      break;
    case PartType.TLIFO_RIGHT:
      nTopLatticeFile = 'TLIFO_RIGHT_CL_Lattice.ntop';
      break;
    case PartType.TLIFO_LEFT:
      nTopLatticeFile = 'TLIFO_LEFT_CL_Lattice.ntop';
      break;
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_RIGHT:
      nTopLatticeFile = 'TLIFC_RIGHT_CL_Lattice.ntop';
      break;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_INLINE_LEFT:
      nTopLatticeFile = 'TLIFC_LEFT_CL_Lattice.ntop';
      break;
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_RIGHT:
      nTopLatticeFile = 'TLIFCA_RIGHT_CL_Lattice.ntop';
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_INLINE_LEFT:
      nTopLatticeFile = 'TLIFCA_LEFT_CL_Lattice.ntop';
      break;
  }

  if (environment) {
    nTopLatticeFile = `${environment}/${nTopLatticeFile}`;
  }

  return `ntopcl.exe -v 2 -u $env:NTOP_USERNAME -w $env:NTOP_PASSWORD -j input_${size}.json --logfile=ntopcl_${size}.log ${nTopLatticeFile}`;
};

export const getNTopJsonFileV2 = (inputLines: string[], plusLevelSize: number) => {
  /*
  ALIF: 0: Directory, 1: Case ID, 2: top v, 3: bottom v, 4: AP, 5: ML, 6: plan name, 7: rev, 8: rotations, 9: translations
  10: taper angle, 11: bullet height, 12: translate thread z, 13: size, 14: implant name

  LLIF: 0: Directory, 1: Case ID, 2: top v, 3: bottom v, 4: AP, 5: ML, 6: plan name, 7: rev, 8: rotations, 9: translations
  10: right taper angle, 11: right bullet height, 12: left taper angle, 13: left bullet height, 14: insertion direction,
  15: translate thread z, 16: size, 17: implant name

  TLIF-O:
  */
  inputLines = inputLines.filter(Boolean); //removes empty strings from array
  const implantname = inputLines[inputLines.length - 1]; // stores implant name as a variable
  const implantType = implantname.split('_')[1].split('.')[0];

  // extract text for part marking for height (-, +, or blank)
  let parasolidName = 'unknown_size';
  let size = Number(inputLines[inputLines.length - 2]);
  if (size === -0.5) {
    parasolidName = 'minus';
  } else if (size === 0) {
    parasolidName = 'plan';
  } else if (size === 1) {
    parasolidName = 'plus';
  }

  // Read in JSON template file according to implant type. A = ALIF, D = ALIF/X, C = LLIF, H = TLIFO, G = TLIFC, J = TLIFCA
  let jsonTemplate: {
    inputs: {
      description: string;
      name: string;
      type: string;
      value: string | number | number[];
    }[];
  } = { inputs: [] };

  if (implantType === 'A') {
    jsonTemplate = alifTemplate;
  } else if (implantType === 'D') {
    jsonTemplate = alifXTemplate;
  } else if (implantType === 'K') {
    jsonTemplate = acdfTemplate;
  } else if (implantType === 'M') {
    jsonTemplate = acdfXTemplate;
  } else if (implantType === 'C') {
    jsonTemplate = llifTemplate;
  } else if (implantType === 'H') {
    jsonTemplate = tlifOTemplate;
  } else if (implantType === 'G') {
    jsonTemplate = tlifCTemplate;
  } else if (implantType === 'J') {
    jsonTemplate = tlifCATemplate;
  }

  // Reassign values to JSON file

  /*
  ALIF: 0: AMI Directory, 1: Implant Name, 2: Parasolid, 3: Rotations, 4: Translations,
  5: Bullet Angle, 6: Bullet Height, 7: Translate Thread Z, 8: Implant Size

  LLIF: 0: AMI Directory, 1: Implant Name, 2: Parasolid, 3: Rotations, 4: Translations,
  5: Right Bullet Angle, 6: Right Bullet Height, 7: Left Bullet Angle, 8: Left Bullet Height,
  9: Insertion Direction, 10: Translate Thread Z, 11: Implant Size
  */

  jsonTemplate.inputs[0].value = 'C:\\Users\\Administrator\\project\\implant_level';
  jsonTemplate.inputs[1].value = implantname;
  jsonTemplate.inputs[2].value = parasolidName;

  // Assign the txt inputs to the JSON parameters
  for (let i = 3; i < jsonTemplate.inputs.length; i++) {
    let j = i + 5;
    let input = jsonTemplate.inputs[i];
    let inputLineValue = inputLines[j];

    if (i <= 4) {
      // @ts-ignore
      inputLines[j] = inputLines[j].split(',').map(Number);
      input.value = inputLines[j];
    } else {
      if (input.name === 'implant size' && parasolidName === 'plus') {
        // default is 2 (for 2mm) bigger than the plan size
        // the implant will grow (plusLevelSize / 2) in
        // each direction
        inputLineValue = (1 * (plusLevelSize / 2)).toString();
      }

      input.value = +inputLineValue;
    }
  }

  // Convert json object to string
  return JSON.stringify(jsonTemplate, null, 4);
};
