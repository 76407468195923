import React, { useState, useEffect, ChangeEvent } from 'react';
import { format, StatementType, Statement } from '@workflow-nx/common';
import { useFormContext, Control, FieldValues, SetFieldValue } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../../components/TableCell';
import { CheckboxRHF, TextFieldRHF, ErrorMessageRHF } from '@workflow-nx/ui';
import { CaseReportDialogStateType } from './CaseReportDialog.reducer';
import RadioGroupRHF from '../../../../../components/RadioGroupRHF';
import { formatCaseStatementType } from '../../../../../utils/caseReport/caseReportFormat';

export function CaseReportStatementTableRow(props: {
  statementType: StatementType;
  statement: Statement;
  response?: boolean;
  statementIndex: number;
  disabled: boolean;
}) {
  const { control, setValue, resetField } = useFormContext<CaseReportDialogStateType>();
  const [disabledStatementNote, setDisabledStatementNote] = useState(true);
  const formattedStatement = formatCaseStatementType(props.statement).split('\n');

  const handleResponseChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value === 'true';
    setDisabledStatementNote(!selectedValue);
    if (!selectedValue) {
      setValue(
        `caseReportStatements[${props.statementIndex}].note` as SetFieldValue<FieldValues>,
        '' as any,
      );
    } else {
      resetField(
        `caseReportStatements[${props.statementIndex}].note` as SetFieldValue<FieldValues>,
      );
    }
  };

  const responseTypeOptions = [
    {
      key: true,
      value: 'Yes',
    },
    {
      key: false,
      value: 'No',
    },
  ];

  useEffect(() => {
    if (props.response) {
      setDisabledStatementNote(false);
    }
  }, [props.response]);

  return (
    <TableRow hover>
      <TableCell>
        {formattedStatement.map((statementLine) => (
          <>
            {statementLine}
            <br />
          </>
        ))}
        <RadioGroupRHF
          name={`caseReportStatements[${props.statementIndex}].response`}
          control={control as unknown as Control<FieldValues>}
          label={''}
          hideLabel={false}
          disabled={props.disabled}
          row={true}
          options={responseTypeOptions}
          onChange={handleResponseChange}
        />
      </TableCell>
      <TableCell>
        <TextFieldRHF
          name={`caseReportStatements[${props.statementIndex}].note`}
          control={control as unknown as Control<FieldValues>}
          disabled={props.disabled}
          multiline={true}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <ErrorMessageRHF
          name={`caseReportStatements[${props.statementIndex}].response`}
          control={control as unknown as Control<FieldValues>}
        />
        <ErrorMessageRHF
          name={`caseReportStatements[${props.statementIndex}].note`}
          control={control as unknown as Control<FieldValues>}
        />
      </TableCell>
    </TableRow>
  );
}
