import { Alert, Box } from '@mui/material';
import { format, ISurgeonPreferenceImplant, ISurgeonPreferences } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import {
  IMPLANT_TYPE_CUSTOM_SORT,
  MEASUREMENT_TYPE_CUSTOM_SORT,
} from '../../../utils/surgeonPreferences';
import { ViewSurgeonPreferencesImplant } from './SurgeonPreferencesView/ViewSurgeonPreferencesImplant';
import { ViewSurgeonPreferencesMain } from './SurgeonPreferencesView/ViewSurgeonPreferencesMain';
import { ViewSurgeonPreferencesMeasurement } from './SurgeonPreferencesView/ViewSurgeonPreferencesMeasurement';

export function ViewSurgeonPreferencesDetails({
  preferences,
}: {
  preferences: ISurgeonPreferences;
}) {
  const sortedSurgeonPreferenceImplants = sortBy(preferences?.surgeonPreferenceImplants, (item) =>
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
  );

  const sortedSurgeonPreferenceImplantMeasurements = sortBy(
    preferences?.surgeonPreferenceImplantMeasurements,
    (item) => [
      IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
      MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
    ],
  );

  return (
    <>
      <Box mb={2} />
      {!preferences && (
        <Alert severity={'warning'}>The surgeon's preferences have not been set up yet.</Alert>
      )}
      {preferences && (
        <Alert severity={'info'}>
          Last updated by: {format.formatName(preferences?.updatedByUser)} at{' '}
          {format.formatDateTime(preferences?.updatedAt)}
        </Alert>
      )}
      {preferences && (
        <Box display={'flex'}>
          <Box width={'calc(100% - 400px)'}>
            <Box>
              <ViewSurgeonPreferencesMain preferences={preferences} />
              {preferences?.preferredProcedures?.length ? (
                <>
                  <Box my={3} />
                  <Box>
                    {sortedSurgeonPreferenceImplants?.map(
                      (selectedImplantType: ISurgeonPreferenceImplant) => {
                        return preferences?.preferredProcedures?.includes(
                          selectedImplantType?.implantType,
                        ) ? (
                          <Box mb={4}>
                            <ViewSurgeonPreferencesImplant
                              selectedImplantType={selectedImplantType}
                            />
                            <ViewSurgeonPreferencesMeasurement
                              surgeonPreferenceImplantMeasurements={
                                sortedSurgeonPreferenceImplantMeasurements
                              }
                              implantType={selectedImplantType?.implantType}
                            />
                          </Box>
                        ) : null;
                      },
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
