import {
  calculateAngleBetweenPointsAlongPlane,
  calculateAngleBetweenVectors,
  calculateDistanceBetweenPointsAlongAxis,
  getMidpoint,
  vectorFromArray,
} from '@workflow-nx/math';
import * as babylon from 'babylonjs';
import { LEVEL_CONFIG_MAP } from '../common';
import {
  Axis,
  CaseSpineProfile,
  EndPlate,
  LevelType,
  MeasurementsVersionType,
  MeasurementType,
  Position,
  VertebralBody,
} from '../enums';
import {
  AssetPositions,
  AssetPositionsInterbodyCenterPlane,
  AssetPositionsPoints,
  IAxes,
  IMeasure,
  IMeasurementPoint,
  IMeasurementPointValues,
  ImplantPosition,
  MeasurementDefinition,
} from '../interfaces';
import { getCaseSpineProfile } from './case';
// import { VertebralBodyMeasurementsType } from '@workflow-nx/auto-correct';
import {
  evaluateCoronalAngle,
  evaluateHeight,
  evaluateLordoticAngle,
} from '@workflow-nx/core-algorithms';
import * as caseUtils from './case';
import * as measurements from './measurements';

function getMeasurementName(measurement: MeasurementDefinition) {
  return `${measurement.type}.${measurement.start.body}.${measurement.start.endPlate}.${measurement.end.body}.${measurement.end.endPlate}`;
}

const getDefaultMeasurements: (spineProfile?: CaseSpineProfile) => MeasurementDefinition[] = (
  spineProfile,
) => [
  {
    type: MeasurementType.LumbarLordosis,
    start: {
      body: VertebralBody.L1 as VertebralBody,
      endPlate: EndPlate.Superior,
    },
    end: {
      body: VertebralBody.S1 as VertebralBody,
      endPlate: EndPlate.Superior,
    },
  },
  {
    type: MeasurementType.LumbarCoronalCobb,
    start: {
      body: VertebralBody.L1 as VertebralBody,
      endPlate: EndPlate.Superior,
    },
    end: {
      body: VertebralBody.S1 as VertebralBody,
      endPlate: EndPlate.Superior,
    },
  },
  ...getCaseSpineProfile(spineProfile).validLevels.flatMap((level) => {
    const superior = LEVEL_CONFIG_MAP[level].superiorVertebrae;

    const inferior = LEVEL_CONFIG_MAP[level].inferiorVertebrae;

    return [
      {
        type: MeasurementType.SegmentalLordoticAngle,
        start: {
          body: inferior,
          endPlate: inferior === VertebralBody.S1 ? EndPlate.Superior : EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.LordoticAngle,
        start: {
          body: inferior,
          endPlate: EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.LumbarAngle,
        start: {
          body: inferior,
          endPlate: EndPlate.Superior,
        },
        end: {
          body: VertebralBody.S1,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.SegmentalCoronalAngle,
        start: {
          body: inferior,
          endPlate: inferior === VertebralBody.S1 ? EndPlate.Superior : EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.CoronalAngle,
        start: {
          body: inferior,
          endPlate: EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.PosteriorHeight,
        start: {
          body: inferior,
          endPlate: EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
      {
        type: MeasurementType.AnteriorHeight,
        start: {
          body: inferior,
          endPlate: EndPlate.Inferior,
        },
        end: {
          body: superior,
          endPlate: EndPlate.Superior,
        },
      },
    ];
  }),
];

export const getPosteriorHeight = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `POSTERIOR_HEIGHT.${inferior}.INFERIOR.${superior}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getAnteriorHeight = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `ANTERIOR_HEIGHT.${inferior}.INFERIOR.${superior}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getLumbarLordosis = (values: IMeasurementPointValues): number | null => {
  const name: string = `LUMBAR_LORDOSIS.${VertebralBody.L1}.SUPERIOR.${VertebralBody.S1}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getLumbarCoronalCobb = (values: IMeasurementPointValues): number | null => {
  const name: string = `LUMBAR_CORONAL_COBB.${VertebralBody.L1}.SUPERIOR.${VertebralBody.S1}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getLumbarAngle = (
  inferior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `LUMBAR_ANGLE.${inferior}.SUPERIOR.${VertebralBody.S1}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getLordoticAngle = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `LORDOTIC_ANGLE.${inferior}.INFERIOR.${superior}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getSegmentalLordoticAngle = (
  superior: VertebralBody | string,
  inferior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `SEGMENTAL_LORDOTIC_ANGLE.${superior}.SUPERIOR.${inferior}.${
    inferior === VertebralBody.S1 ? 'INFERIOR' : 'SUPERIOR'
  }`;
  return getMeasurementValue(name, values);
};

export const getCoronalAngle = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `CORONAL_ANGLE.${inferior}.INFERIOR.${superior}.SUPERIOR`;
  return getMeasurementValue(name, values);
};

export const getMeasurementValue = (
  name: string,
  values: IMeasurementPointValues | null,
): number | null => {
  const value: number | null = values ? values[name] : null;
  if (value) {
    return value;
  }
  return null;
};

export const getPointName = (
  m: { body: VertebralBody; endPlate: EndPlate },
  position: Position,
) => {
  return `${m.body}.${position}.${m.endPlate}.POINT`;
};

export const parseInterMeasurementPoints = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): IMeasurementPoint => {
  const startVertebralBody: VertebralBody = measurement.start.body;
  const startEndPlate: EndPlate = measurement.start.endPlate;
  const endVertebralBody: VertebralBody = measurement.end.body;
  const endEndPlate: EndPlate = measurement.end.endPlate;

  const startMeasurementPoints: IMeasurementPoint = {};
  const endMeasurementPoints: IMeasurementPoint = {};

  for (let key in measurementPoints) {
    if (key.includes(startVertebralBody) && key.includes(startEndPlate)) {
      startMeasurementPoints[key] = measurementPoints[key];
    }
  }

  for (let key in measurementPoints) {
    if (key.includes(endVertebralBody) && key.includes(endEndPlate)) {
      endMeasurementPoints[key] = measurementPoints[key];
    }
  }

  return Object.assign({}, startMeasurementPoints, endMeasurementPoints);
};

function getLine(pointName1: string, pointName2: string, measurementPoints: IMeasurementPoint) {
  const point1 = measurementPoints[pointName1];
  const point2 = measurementPoints[pointName2];

  if (!point1 || !point2) {
    return null;
  }

  return { point1, point2 };
}

export const setMeasurementTypeValues = (
  position1: Position,
  position2: Position,
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
  measurementPointValues: IMeasurementPointValues,
) => {
  const line1 = getLine(
    getPointName(measurement.start, position1),
    getPointName(measurement.start, position2),
    measurementPoints,
  );

  const line2 = getLine(
    getPointName(measurement.end, position1),
    getPointName(measurement.end, position2),
    measurementPoints,
  );

  if (line1 && line2) {
    measurementPointValues[getMeasurementName(measurement)] = calculateAngleBetweenPointsAlongPlane(
      line1,
      line2,
      Axis.Y,
    );
  }
};

export const getMeasurementPointsFromSource = (caseMeasurements: IMeasure[]) => {
  const values: any = {};
  caseMeasurements.forEach((measurement: IMeasure) => {
    values[`${measurement.body}.${measurement.position}.${measurement.endPlate}.POINT`] =
      measurement.point;
  });
  return values;
};

export const getReEvaluatedMeasurementPointValues = (
  caseMeasurements: IMeasure[],
  measurementsVersion: MeasurementsVersionType,
  caseSpineProfile: CaseSpineProfile,
) => {
  const mpv = getMeasurementPointsFromSource(caseMeasurements);
  return getMeasurementPointValues(mpv, measurementsVersion, caseSpineProfile);
};

export const getMeasurementPointValues = (
  measurementPoints: IMeasurementPoint,
  measurementsVersion: MeasurementsVersionType,
  spineProfile: CaseSpineProfile,
): IMeasurementPointValues => {
  return measurementsVersion === MeasurementsVersionType.Version1
    ? getMeasurementPointValuesV1(measurementPoints, spineProfile)
    : getMeasurementPointValuesV2(measurementPoints, spineProfile);
};

export function getMeasurementPointValuesV1(
  measurementPoints: IMeasurementPoint,
  caseSpineProfile?: CaseSpineProfile,
): IMeasurementPointValues {
  const measurementPointValues: IMeasurementPointValues = {};

  const defaultMeasurements = getDefaultMeasurements(caseSpineProfile);

  for (let measurement of defaultMeasurements) {
    switch (measurement.type) {
      case MeasurementType.LumbarAngle:
      case MeasurementType.SegmentalLordoticAngle:
      case MeasurementType.SegmentalCoronalAngle:
      case MeasurementType.LordoticAngle:
      case MeasurementType.CoronalAngle:
      case MeasurementType.LumbarCoronalCobb:
      case MeasurementType.LumbarLordosis: {
        let position1 = Position.Anterior;
        let position2 = Position.Posterior;

        if (
          measurement.type === MeasurementType.SegmentalCoronalAngle ||
          measurement.type === MeasurementType.CoronalAngle ||
          measurement.type === MeasurementType.LumbarCoronalCobb
        ) {
          position1 = Position.PatientRight;
          position2 = Position.PatientLeft;
        }

        setMeasurementTypeValues(
          position1,
          position2,
          measurement,
          measurementPoints,
          measurementPointValues,
        );
        break;
      }
      case MeasurementType.PosteriorHeight:
      case MeasurementType.AnteriorHeight: {
        const position =
          measurement.type === MeasurementType.PosteriorHeight
            ? Position.Posterior
            : Position.Anterior;

        const point1 = measurementPoints[getPointName(measurement.start, position)];
        const point2 = measurementPoints[getPointName(measurement.end, position)];

        if (point1 && point2) {
          let measurementName = getMeasurementName(measurement);
          measurementPointValues[measurementName] = calculateDistanceBetweenPointsAlongAxis(
            point1,
            point2,
            Axis.Y,
          );
        }
        break;
      }
    }
  }
  return measurementPointValues;
}

export function getMeasurementPointValuesV2(
  measurementPoints: IMeasurementPoint,
  spineProfile: CaseSpineProfile,
): IMeasurementPointValues {
  const measurementPointValues: IMeasurementPointValues = {};

  const defaultMeasurements: MeasurementDefinition[] = getDefaultMeasurements(spineProfile);

  for (let measurement of defaultMeasurements) {
    switch (measurement.type) {
      case MeasurementType.SegmentalCoronalAngle:
      case MeasurementType.CoronalAngle:
      case MeasurementType.LumbarCoronalCobb: {
        measurementPointValues[getMeasurementName(measurement)] = evaluateCoronalAngle(
          measurement,
          measurementPoints,
        );
        break;
      }

      case MeasurementType.LumbarAngle:
      case MeasurementType.SegmentalLordoticAngle:
      case MeasurementType.LordoticAngle:
      case MeasurementType.LumbarLordosis: {
        measurementPointValues[getMeasurementName(measurement)] = evaluateLordoticAngle(
          measurement,
          measurementPoints,
        );
        break;
      }
      case MeasurementType.PosteriorHeight:
      case MeasurementType.AnteriorHeight: {
        measurementPointValues[getMeasurementName(measurement)] = evaluateHeight(
          measurement,
          measurementPoints,
        );
        break;
      }
    }
  }

  return measurementPointValues;
}

export const exportMeasurements = (
  name: string,
  measurements: IMeasure[],
  spineProfile: CaseSpineProfile,
): string[][] => {
  const rows = [['Vertebral Body', 'End Plate', 'Position', 'X', 'Y', 'Z']];

  const validVertebralBodies = caseUtils.getVertebralBodiesSortedByHierarchy(spineProfile, 'asc');

  validVertebralBodies.forEach((vertebralBody) => {
    const superior = measurements.filter(
      (v) => v.body === vertebralBody && v.endPlate === EndPlate.Superior,
    );
    if (superior && superior.length) {
      [Position.Anterior, Position.Posterior, Position.PatientRight, Position.PatientLeft].forEach(
        (position) => {
          const p = superior.find((s) => s.position === position);
          if (p) {
            rows.push([
              p.body,
              p.endPlate,
              position
                .replace('LATERAL_LEFT', 'PATIENT_RIGHT')
                .replace('LATERAL_RIGHT', 'PATIENT_LEFT'),
              p.point[0].toFixed(2),
              // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
              p.point[2].toFixed(2),
              p.point[1].toFixed(2),
            ]);
          }
        },
      );
    }

    const inferior = measurements.filter(
      (v) => v.body === vertebralBody && v.endPlate === EndPlate.Inferior,
    );
    if (inferior && inferior.length) {
      [Position.Anterior, Position.Posterior, Position.PatientRight, Position.PatientLeft].forEach(
        (position) => {
          const p = inferior.find((s) => s.position === position);
          if (p) {
            rows.push([
              p.body,
              p.endPlate,
              position
                .replace('LATERAL_LEFT', 'PATIENT_RIGHT')
                .replace('LATERAL_RIGHT', 'PATIENT_LEFT'),
              p.point[0].toFixed(2),
              // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
              p.point[2].toFixed(2),
              p.point[1].toFixed(2),
            ]);
          }
        },
      );
    }
  });

  return rows;
};

export const calculatePlaneBetweenPoints = (
  supA: babylon.Vector3,
  supP: babylon.Vector3,
  supPR: babylon.Vector3,
  supPL: babylon.Vector3,
  infA: babylon.Vector3,
  infP: babylon.Vector3,
  infPR: babylon.Vector3,
  infPL: babylon.Vector3,
): ImplantPosition => {
  // divide by divisor vector by 2 to get halfway point
  const divisor = new babylon.Vector3(2, 2, 2);

  //calculate the AP and ML mean between the superior and inferior bodies
  const superiorAP = supA.subtract(supP);
  const inferiorAP = infA.subtract(infP);
  const apMean = superiorAP.add(inferiorAP).divide(divisor);
  const superiorML = supPL.subtract(supPR);
  const inferiorML = infPL.subtract(infPR);
  const mlMean = superiorML.add(inferiorML).divide(divisor);
  const midpointA = getMidpoint(supA, infA);
  const midpointP = getMidpoint(supP, infP);

  const centroid = getMidpoint(midpointP, midpointA);

  //define disc coordinate system
  const yPrime = mlMean.cross(apMean);
  const zPrime = mlMean.cross(yPrime);
  const xPrime = apMean.cross(yPrime);

  //define origin for each axis
  const xOrigin = new babylon.Vector3(1, 0, 0);
  const yOrigin = new babylon.Vector3(0, 1, 0);
  const zOrigin = new babylon.Vector3(0, 0, 1);

  //define rotational entries
  const A = Math.cos(calculateAngleBetweenVectors(xPrime, xOrigin, Axis.X));
  const B = Math.cos(calculateAngleBetweenVectors(xPrime, yOrigin, Axis.Y));
  const C = Math.cos(calculateAngleBetweenVectors(xPrime, zOrigin, Axis.Z));
  const D = Math.cos(calculateAngleBetweenVectors(yPrime, xOrigin, Axis.X));
  const E = Math.cos(calculateAngleBetweenVectors(yPrime, yOrigin, Axis.Y));
  const F = Math.cos(calculateAngleBetweenVectors(yPrime, zOrigin, Axis.Z));
  const G = Math.cos(calculateAngleBetweenVectors(zPrime, xOrigin, Axis.X));
  const H = Math.cos(calculateAngleBetweenVectors(zPrime, yOrigin, Axis.Y));
  const I = Math.cos(calculateAngleBetweenVectors(zPrime, zOrigin, Axis.Z));

  //create rotational matrix
  const rotationalMatrix = babylon.Matrix.FromValues(
    A,
    B,
    C,
    0,
    D,
    E,
    F,
    0,
    G,
    H,
    I,
    0,
    0,
    0,
    0,
    1,
  );

  const quaternion = new babylon.Quaternion();
  const rotationQuaternion = quaternion.fromRotationMatrix(rotationalMatrix);
  const eulerAngles = rotationQuaternion.toEulerAngles();

  // TODO: temp fix until quaternion is better understood
  eulerAngles.z += Math.PI;

  return {
    eulerAngles: eulerAngles,
    centroid: centroid,
  };
};

export const getMeasurementsFromAssetPositions = (
  assetPositionsPoints: AssetPositionsPoints[],
): IMeasure[] =>
  assetPositionsPoints.map(({ position, name, endPlate, point }) => {
    return {
      position,
      endPlate,
      point: [point.x, point.y, point.z],
      body: name,
    } as IMeasure;
  });

export const getMeasurementPointValuesFromAssetPositions = (
  assetPositionsPoints: AssetPositionsPoints[],
  caseSpineProfile: CaseSpineProfile,
  measurementsVersion: MeasurementsVersionType,
): IMeasurementPointValues => {
  const pointMap: IMeasurementPoint = {};

  assetPositionsPoints.forEach(({ position, name, endPlate, point }: AssetPositionsPoints) => {
    pointMap[`${name}.${position}.${endPlate}.POINT`] = [point.x, point.y, point.z];
  });

  return getMeasurementPointValues(pointMap, measurementsVersion, caseSpineProfile);
};

export function getLordosisAndCoronalAngles(
  assetPositionsPoints: AssetPositionsPoints[],
  levelType: LevelType,
  measurementsVersion: MeasurementsVersionType,
  caseSpineProfile?: CaseSpineProfile,
) {
  const planMeasurements = getMeasurementPointValuesFromAssetPositions(
    assetPositionsPoints ?? [],
    caseSpineProfile ?? CaseSpineProfile.LumbarStandard,
    measurementsVersion,
  );

  function getLordosisValue(level: LevelType) {
    const [inferior, superior] = level.split('_');
    const value = planMeasurements[`LORDOTIC_ANGLE.${inferior}.INFERIOR.${superior}.SUPERIOR`];

    return isFinite(value) ? Number(value).toFixed(0) : undefined;
  }

  function getCoronalValue(level: LevelType) {
    const [inferior, superior] = level.split('_');
    const value = planMeasurements[`CORONAL_ANGLE.${inferior}.INFERIOR.${superior}.SUPERIOR`];

    return isFinite(value) ? Number(value).toFixed(0) : undefined;
  }

  return {
    lordosis: getLordosisValue(levelType),
    coronal: getCoronalValue(levelType),
  };
}

export const getMeasurementPositionVectors = (
  measurements: IMeasure[],
  superior: string,
  inferior: string,
): {
  supA: babylon.Vector3;
  supP: babylon.Vector3;
  supPR: babylon.Vector3;
  supPL: babylon.Vector3;
  infA: babylon.Vector3;
  infP: babylon.Vector3;
  infPR: babylon.Vector3;
  infPL: babylon.Vector3;
} => {
  const supA = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Anterior,
  );
  const supP = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Posterior,
  );
  const supPR = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientRight,
  );
  const supPL = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientLeft,
  );
  const infA = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Anterior,
  );
  const infP = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Posterior,
  );
  const infPR = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientRight,
  );
  const infPL = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientLeft,
  );

  return {
    supA: vectorFromArray(supA?.point ?? [0, 0, 0]),
    supP: vectorFromArray(supP?.point ?? [0, 0, 0]),
    supPR: vectorFromArray(supPR?.point ?? [0, 0, 0]),
    supPL: vectorFromArray(supPL?.point ?? [0, 0, 0]),
    infA: vectorFromArray(infA?.point ?? [0, 0, 0]),
    infP: vectorFromArray(infP?.point ?? [0, 0, 0]),
    infPR: vectorFromArray(infPR?.point ?? [0, 0, 0]),
    infPL: vectorFromArray(infPL?.point ?? [0, 0, 0]),
  };
};

export const evaluateOrthogonalAxisCentroidPlanePoints = (
  centroidPlanePoints: AssetPositionsInterbodyCenterPlane,
): IAxes => {
  const { anteriorPosition, posteriorPosition, patientLeftPosition, patientRightPosition } =
    centroidPlanePoints;

  const anteriorVector = new babylon.Vector3(
    anteriorPosition.x,
    anteriorPosition.y,
    anteriorPosition.z,
  );

  const posteriorVector = new babylon.Vector3(
    posteriorPosition.x,
    posteriorPosition.y,
    posteriorPosition.z,
  );

  const patientLeftVector = new babylon.Vector3(
    patientLeftPosition.x,
    patientLeftPosition.y,
    patientLeftPosition.z,
  );

  const patientRightVector = new babylon.Vector3(
    patientRightPosition.x,
    patientRightPosition.y,
    patientRightPosition.z,
  );

  const apVector = anteriorVector.subtract(posteriorVector);
  const mlVector = patientLeftVector.subtract(patientRightVector);

  // convert to unit vector
  const zNormal = apVector.cross(mlVector);
  const xNormal = apVector.cross(zNormal);
  const yNormal = mlVector.cross(zNormal);

  const zAxis = zNormal;
  const xAxis = xNormal;
  const yAxis = yNormal;

  return {
    xAxis,
    yAxis,
    zAxis,
  };
};

export const evaluateOrthogonalAxisFromMeasurementPoints = (
  measurements: IMeasure[],
  superior: string,
  inferior: string,
): IAxes => {
  const { supA, supP, supPR, supPL, infA, infP, infPR, infPL } = getMeasurementPositionVectors(
    measurements,
    superior,
    inferior,
  );

  const divisor = new babylon.Vector3(2, 2, 2);
  const superiorAP = supA.subtract(supP);
  const inferiorAP = infA.subtract(infP);
  const apMean = superiorAP.add(inferiorAP).divide(divisor);
  const superiorML = supPL.subtract(supPR);
  const inferiorML = infPL.subtract(infPR);
  const mlMean = superiorML.add(inferiorML).divide(divisor);

  const yAxis = mlMean.cross(apMean).normalize();
  const zAxis = mlMean.cross(yAxis).normalize();
  const xAxis = apMean.cross(yAxis).normalize();

  return {
    xAxis,
    yAxis,
    zAxis,
  };
};

export const calculateDoubleMeasurementSet = (measurements: IMeasurementPoint) => {
  let supAMeasurement: number[] = [];
  let supPMeasurement: number[] = [];
  let supPRMeasurement: number[] = [];
  let supPLMeasurement: number[] = [];
  let infAMeasurement: number[] = [];
  let infPMeasurement: number[] = [];
  let infPRMeasurement: number[] = [];
  let infPLMeasurement: number[] = [];

  for (let key in measurements) {
    if (key.includes(Position.Anterior) && key.includes(EndPlate.Superior)) {
      supAMeasurement = measurements[key];
    }
    if (key.includes(Position.Posterior) && key.includes(EndPlate.Superior)) {
      supPMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientRight) && key.includes(EndPlate.Superior)) {
      supPRMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientLeft) && key.includes(EndPlate.Superior)) {
      supPLMeasurement = measurements[key];
    }
    if (key.includes(Position.Anterior) && key.includes(EndPlate.Inferior)) {
      infAMeasurement = measurements[key];
    }
    if (key.includes(Position.Posterior) && key.includes(EndPlate.Inferior)) {
      infPMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientRight) && key.includes(EndPlate.Inferior)) {
      infPRMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientLeft) && key.includes(EndPlate.Inferior)) {
      infPLMeasurement = measurements[key];
    }
  }

  if (
    supAMeasurement &&
    supPMeasurement &&
    supPRMeasurement &&
    supPLMeasurement &&
    infAMeasurement &&
    infPMeasurement &&
    infPRMeasurement &&
    infPLMeasurement
  ) {
    const supA = new babylon.Vector3(...supAMeasurement);
    const supP = new babylon.Vector3(...supPMeasurement);
    const supPR = new babylon.Vector3(...supPRMeasurement);
    const supPL = new babylon.Vector3(...supPLMeasurement);

    const infA = new babylon.Vector3(...infAMeasurement);
    const infP = new babylon.Vector3(...infPMeasurement);
    const infPR = new babylon.Vector3(...infPRMeasurement);
    const infPL = new babylon.Vector3(...infPLMeasurement);

    const divisor = new babylon.Vector3(2, 2, 2);

    //calculate the AP and ML mean between the superior and inferior bodies
    const superiorAP: babylon.Vector3 = supA.subtract(supP);
    const inferiorAP: babylon.Vector3 = infA.subtract(infP);
    const ap: babylon.Vector3 = superiorAP.add(inferiorAP).divide(divisor);
    const superiorML: babylon.Vector3 = supPL.subtract(supPR);
    const inferiorML: babylon.Vector3 = infPL.subtract(infPR);
    const ml: babylon.Vector3 = superiorML.add(inferiorML).divide(divisor);

    const midpointA = getMidpoint(supA, infA);
    const midpointP = getMidpoint(supP, infP);
    const midpointPR = getMidpoint(supPR, infPR);
    const midpointPL = getMidpoint(supPL, infPL);

    const midpointAP = getMidpoint(midpointA, midpointP);
    const midpointML = getMidpoint(midpointPR, midpointPL);
    const centroid = getMidpoint(midpointAP, midpointML);

    return {
      centroid,
      ap,
      ml,
    };
  } else {
    throw new Error('Measurement point not found, cannot evaluate centroid');
  }
};

export const getPreopMeasurements = (
  measurementsVersion: MeasurementsVersionType,
  spineProfile: CaseSpineProfile,
  assetPositions?: AssetPositions,
) => {
  if (assetPositions?.preop?.points) {
    return measurements.getMeasurementPointValuesFromAssetPositions(
      assetPositions.preop.points,
      spineProfile,
      measurementsVersion,
    );
  }
  return undefined;
};

export const getPlanMeasurements = (
  measurementsVersion: MeasurementsVersionType,
  spineProfile: CaseSpineProfile,
  assetPositions?: AssetPositions,
) => {
  if (assetPositions?.plan?.points) {
    return measurements.getMeasurementPointValuesFromAssetPositions(
      assetPositions.plan.points,
      spineProfile,
      measurementsVersion,
    );
  }
  return undefined;
};

export const getMeasurementPointsVertebralBodyMeasurements = (vertebralbodyMeasurements: any) => {
  let measurements: IMeasure[] = [];

  for (const vertebralBodyKey in vertebralbodyMeasurements) {
    if (vertebralbodyMeasurements.hasOwnProperty(vertebralBodyKey)) {
      measurements = measurements.concat(vertebralbodyMeasurements[vertebralBodyKey as keyof any]);
    }
  }

  const measurementPoints: IMeasurementPoint = getMeasurementPointsFromSource(measurements);

  return measurementPoints;
};
