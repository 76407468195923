import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import { testing } from '@workflow-nx/utils';
import React, { memo } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';

const TextFieldRHF = memo(function ({
  fullWidth = true,
  fullHeight = false,
  required,
  disabled,
  label,
  multiline,
  hidden,
  name,
  variant,
  type,
  shrink,
  multiLineRows,
  control,
  onBlur,
  onChange,
  fontColor,
  showErrorText,
  matchHeight,
  helperText,
  startAdornment,
  endAdornment,
}: {
  name: string;
  onBlur?: any;
  onChange?: any;
  control: Control<FieldValues>;
  label?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  hidden?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  shrink?: boolean;
  multiLineRows?: number;
  fontColor?: string;
  showErrorText?: boolean;
  matchHeight?: boolean;
  helperText?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}) {
  const {
    field: {
      onChange: hookFormOnChange,
      onBlur: hookFormOnBlur,
      ref,
      value,
      ...hookFormRemainingProps
    },
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <MuiTextField
      data-test-id={`${testing.toKebabCase(label ?? name)}-text-field`}
      required={required}
      error={invalid}
      helperText={showErrorText && error?.message ? error?.message : helperText}
      fullWidth={fullWidth}
      label={label}
      multiline={multiline || matchHeight}
      minRows={multiline ? multiLineRows || 3 : undefined}
      maxRows={multiline ? multiLineRows || 3 : undefined}
      type={type ?? 'text'}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        hookFormOnBlur();
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
        hookFormOnChange(e);
      }}
      value={value}
      inputRef={ref}
      variant={variant ?? 'outlined'}
      disabled={Boolean(disabled)}
      size={'small'}
      inputProps={{ sx: { whiteSpace: multiline ? 'pre' : undefined } }}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
      InputLabelProps={{
        shrink,
      }}
      sx={{
        input: { color: fontColor },
        '& .MuiInputBase-root': { minHeight: matchHeight ? '100%' : undefined },
      }}
      style={{
        height: fullHeight ? '100%' : undefined,
        display: hidden ? 'none' : undefined,
      }}
      {...hookFormRemainingProps}
    />
  );
});

export { TextFieldRHF };
