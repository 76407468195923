import { Box, Typography } from '@mui/material';
import { AssetType, IAsset, IPlan, LevelType, ValidFileExtensions } from '@workflow-nx/common';
import * as FileSaver from 'file-saver';
import { difference } from 'lodash';
import { Dispatch } from 'react';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import CustomDialog from '../../../../components/CustomDialog';

export function ManageAppAssetsDialog(props: {
  caseLevels: LevelType[];
  caseNumber: string;
  dispatch: Dispatch<any>;
  disabled?: boolean;
  onClose: () => void;
  plan: IPlan;
  planAssets: IAsset[];
  requiredAppAssets: AssetType[];
  implantImageAssets: AssetType[];
}) {
  function getMissingAssetCount(requiredAssets: AssetType[], assets: IAsset[]) {
    return difference(
      requiredAssets,
      assets.map((asset) => asset.assetType),
    ).length;
  }

  return (
    <CustomDialog
      maxWidth={'md'}
      fullHeight={false}
      open={true}
      title={`Manage App Assets - ${props.caseNumber}`}
      onClose={props.onClose}
      positiveActionButtons={[]}
    >
      <Box mb={2}>
        <Typography variant={'h4'}>
          Interbody Assets ({getMissingAssetCount(props.requiredAppAssets, props.planAssets || [])}{' '}
          missing)
        </Typography>
        <AssetGridTable
          showDownloadAll={true}
          onZipComplete={(zipFile) =>
            FileSaver.saveAs(zipFile, `${props.caseNumber}-interbody-obj-assets.zip`)
          }
          dispatch={props.dispatch}
          assets={props.planAssets || []}
          caseId={props.plan.caseId}
          planId={props.plan.planId}
          validFileExtensions={[ValidFileExtensions.OBJ, ValidFileExtensions.STL]}
          validAssets={props.requiredAppAssets ?? []}
          readOnly={props.disabled}
        />
      </Box>
      <Box my={4} />
      <Box mb={2}>
        <Typography variant={'h4'}>
          Implant Measurement Assets (
          {getMissingAssetCount(props.implantImageAssets, props.planAssets || [])} missing)
        </Typography>
      </Box>
      <AssetGridTable
        dispatch={props.dispatch}
        assets={props.planAssets || []}
        caseId={props.plan.caseId}
        planId={props.plan.planId}
        validFileExtensions={[
          ValidFileExtensions.JPEG,
          ValidFileExtensions.JPG,
          ValidFileExtensions.PNG,
        ]}
        validAssets={props.implantImageAssets}
        readOnly={props.disabled}
      />
    </CustomDialog>
  );
}
