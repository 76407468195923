import React from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';

export function NtopMetadataPopover(props: { metadata: string }) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `planImplant-popover${Math.random().toString(36).substring(2)}`,
  });

  return (
    <>
      <Box>
        <FontAwesomeIcon icon={faEye} size="xl" {...bindTrigger(popupState)} />
      </Box>
      <Popover
        style={{ zIndex: 9999 }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack sx={{ p: 2 }} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography>
              <strong>METADATA</strong>
            </Typography>
          </Stack>
          <Stack sx={{ overflowY: 'auto', maxHeight: 200 }}>
            <pre>{props.metadata}</pre>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
