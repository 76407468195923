import {
  ICaseLevel,
  ICaseReportStatement,
  ImplantType,
  Statement,
  StatementType,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { CaseReportStatementType } from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';

export const STATEMENT_CUSTOM_SORT = [
  Statement.AnteriorDiscHeightsReasonable,
  Statement.PosteriorDiscHeightsReasonable,
  Statement.IntervertebralLordosisReasonable,
  Statement.PelvicIncidenceAndLumbarLordosisReasonable,
  Statement.CageFootprintFit,
  Statement.VertebralBodiesEdited,
  Statement.TlifCKyphotic,
  Statement.AlifxScrewTrajectoriesAcceptable,
];

export const getCaseReportStatements = (
  caseReportStatements: ICaseReportStatement[],
  validCaseLevels: ICaseLevel[],
): CaseReportStatementType[] => {
  const reportStatements: CaseReportStatementType[] = [];
  let statements = Object.values(Statement);

  const foundAlifX = validCaseLevels.some((level) => level.implantType === ImplantType.ALIFX);
  const foundTlifC = validCaseLevels.some(
    (level) => level.implantType === ImplantType.TLIFC || level.implantType === ImplantType.TLIFCA,
  );

  if (!foundAlifX) {
    statements = statements.filter(
      (statement) => statement !== Statement.AlifxScrewTrajectoriesAcceptable,
    );
  }
  if (!foundTlifC) {
    statements = statements.filter((statement) => statement !== Statement.TlifCKyphotic);
  }
  statements.forEach((statement) => {
    const caseReportStatement = caseReportStatements.find(
      (caseReportStatement) => caseReportStatement.statement === statement,
    );

    if (caseReportStatement) {
      reportStatements.push({
        statementType: caseReportStatement.statementType,
        statement: caseReportStatement.statement,
        response: caseReportStatement.response,
        note: caseReportStatement.note || '',
      });
    } else {
      let statementType;
      if (
        [
          Statement.AnteriorDiscHeightsReasonable,
          Statement.PosteriorDiscHeightsReasonable,
          Statement.IntervertebralLordosisReasonable,
          Statement.PelvicIncidenceAndLumbarLordosisReasonable,
        ].includes(statement)
      ) {
        statementType = StatementType.CorrectionPlanning;
      } else {
        statementType = StatementType.ImplantDesign;
      }

      reportStatements.push({
        statementType: statementType,
        statement: statement,
        response: undefined,
        note: '',
      });
    }
  });

  return sortBy(reportStatements, (item) => STATEMENT_CUSTOM_SORT.indexOf(item.statement));
};
