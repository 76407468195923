import { ApolloQueryResult } from '@apollo/client/core/types';
import { UserStatusType, UserRoleType } from '@workflow-nx/common';
import { Globals } from '../../../layouts/DashboardLayout';

export const defaultUserRoleFilters = Object.values(UserRoleType)?.filter(
  (role) => role !== UserRoleType.Surgeon,
);

export type ListUsersActionType = {
  type:
    | 'RESET_FILTERS'
    | 'SEARCH_CHANGED'
    | 'ORDER_BY_CHANGED'
    | 'ROLE_CHANGED'
    | 'STATUS_CHANGED'
    | 'REGION_CHANGED'
    | 'TERRITORY_CHANGED'
    | 'UPDATE_PAGINATION'
    | 'USER_CHANGED';
  data?: any;
};

export type ListUsersStateType = {
  role?: string;
  status?: string;
  region?: number;
  territory?: number;
  orderBy: any;
  search?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const ListUsersReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: ListUsersStateType, action: ListUsersActionType): ListUsersStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'RESET_FILTERS':
        updatedState = {
          role: '',
          status: UserStatusType.Active,
          search: '',
          orderBy: { lastName: 'asc' },
          pageSize: Globals.DefaultListPageSize,
        };
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'ROLE_CHANGED':
        updatedState.role = action.data;
        break;
      case 'STATUS_CHANGED':
        updatedState.status = action.data;
        break;
      case 'REGION_CHANGED':
        updatedState.region = action.data;
        break;
      case 'TERRITORY_CHANGED':
        updatedState.territory = action.data;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    const orderBy = updatedState.orderBy;

    if (action.type !== 'UPDATE_PAGINATION') {
      updatedState.pageNumber = 0;
    }

    const variables = {
      roleFilter: updatedState.role ? [updatedState.role] : defaultUserRoleFilters,
      statusFilter: updatedState.status ? [updatedState.status] : undefined,
      regionId: updatedState.region ? updatedState.region : undefined,
      territoryId: updatedState.territory ? updatedState.territory : undefined,
      orderBy,
      search: updatedState.search,
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    };
    refetch(variables);

    return updatedState;
  };
