import React, { useReducer, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, useTheme } from '@mui/material';
import Page from '../../../components/Page';
import UserListTable from './UserListTable';
import { useQuery } from '@apollo/client';
import { FIND_USERS } from '../../../gql';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { CreateUserDialog } from './CreateUserDialog';
import useAuth from '../../../hooks/useAuth';
import { Permission, UserRoleType, UserStatusType } from '@workflow-nx/common';
import { Button } from '@workflow-nx/ui';
import { Heading } from '../../../components/Heading';
import { UserTableFilter } from './UserTableFilter';
import SearchInput from '../../../components/SearchInput';
import { Alert } from '@mui/material';
import { ListUsersReducer, ListUsersStateType } from './ListUsers.reducer';
import { useNavigate } from 'react-router-dom';
import { Globals } from '../../../layouts/DashboardLayout';
import { defaultUserRoleFilters } from './ListUsers.reducer';

interface IListUserQueryVariables {
  search: string;
  orderBy: any;
  roleFilter: UserRoleType[];
  statusFilter: UserStatusType[];
  regionId: number | undefined;
  territoryId: number | undefined;
  skip: number;
  take: number;
}

function getDefaultState() {
  const defaultVariables: IListUserQueryVariables = {
    roleFilter: defaultUserRoleFilters as UserRoleType[],
    statusFilter: [UserStatusType.Active] as UserStatusType[],
    orderBy: { lastName: 'asc' },
    search: '',
    regionId: undefined,
    territoryId: undefined,
    skip: 0,
    take: Globals.DefaultListPageSize,
  };

  const initialState: ListUsersStateType = {
    role: '',
    status: UserStatusType.Active,
    region: undefined,
    territory: undefined,
    orderBy: { lastName: 'asc' },
    search: '',
    pageNumber: 0,
    pageSize: Globals.DefaultListPageSize,
  };

  return [defaultVariables, initialState];
}

const ListUsersView = () => {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [defaultVariables, initialState] = getDefaultState();
  const [pageLoading, setPageLoading] = useState(false);

  const { data, refetch, loading } = useQuery(FIND_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: defaultVariables,
  });

  const [state, dispatch] = useReducer(
    ListUsersReducer(refetch),
    initialState as ListUsersStateType,
  );

  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);

  return (
    <Page title={'Users'}>
      <Container maxWidth={false}>
        <CustomAppBar
          title={`Users`}
          actions={
            auth?.hasPermission?.([Permission.ManageUser])
              ? [
                  <Button
                    variant={'contained'}
                    onClick={() => setShowCreateUserDialog(true)}
                    label={'Create User'}
                  />,
                ]
              : []
          }
        />
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                    <Heading label={'Filters'} />
                  </Box>
                  <Card>
                    <CardContent>
                      <UserTableFilter state={state} dispatch={dispatch} loading={loading} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={10}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mb={1}
                  >
                    <Heading label={`Results (${data?.users?.count ?? 0} users)`} />
                    <Box width={250} bgcolor={theme.palette.background.paper}>
                      <SearchInput
                        value={state.search ?? ''}
                        onChange={(value: string) =>
                          dispatch({ type: 'SEARCH_CHANGED', data: value })
                        }
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Card>
                      <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                        {!loading && data?.users?.count > 0 ? (
                          <UserListTable
                            loading={loading}
                            orderBy={state.orderBy}
                            dispatch={dispatch}
                            users={data?.users?.users}
                            page={state.pageNumber}
                            rowsPerPage={state.pageSize}
                            totalUserCount={data?.users?.count ?? 0}
                            onPagination={(pageSize, pageNumber) => {
                              setPageLoading(true);
                              dispatch?.({
                                type: 'UPDATE_PAGINATION',
                                data: {
                                  pageSize,
                                  pageNumber,
                                },
                              });
                            }}
                          />
                        ) : !loading ? (
                          <Alert severity={'warning'}>No users match the filter criteria. </Alert>
                        ) : null}
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <CreateUserDialog
        open={showCreateUserDialog}
        onCreate={(userId) => {
          setShowCreateUserDialog(false);
          navigate(`/app/user/${userId}`);
        }}
        onClose={() => {
          setShowCreateUserDialog(false);
          refetch();
        }}
      />
    </Page>
  );
};

export default ListUsersView;
