import { SecondaryPatientDiagnosisType } from '../enums';

export const getSecondaryDiagnosisConfig = (
  diagnosisType: SecondaryPatientDiagnosisType | null,
) => {
  let level = false;
  let body = false;
  let grade = false;

  if (!diagnosisType) {
    return {
      level,
      body,
      grade,
    };
  }

  switch (diagnosisType) {
    case SecondaryPatientDiagnosisType.Spondylolisthesis:
      level = true;
      grade = true;
      break;

    case SecondaryPatientDiagnosisType.AdjacentSegmentDisease:
    case SecondaryPatientDiagnosisType.DegenerativeDiscDisease:
    case SecondaryPatientDiagnosisType.Osteomyelitis:
    case SecondaryPatientDiagnosisType.ProximalJunctionalKyphosis:
    case SecondaryPatientDiagnosisType.Retrolisthesis:
    case SecondaryPatientDiagnosisType.LateralListhesis:
    case SecondaryPatientDiagnosisType.Pseudoarthrosis:
    case SecondaryPatientDiagnosisType.SpinalStenosis:
    case SecondaryPatientDiagnosisType.Radiculopathy:
    case SecondaryPatientDiagnosisType.Myleopathy:
      level = true;
      break;

    case SecondaryPatientDiagnosisType.VertebralCompressionFracture:
      body = true;
      break;

    case SecondaryPatientDiagnosisType.AdultDegenerativeScoliosis:
    case SecondaryPatientDiagnosisType.ThoracolumbarKyphosis:
    case SecondaryPatientDiagnosisType.FractionalCurve:
    case SecondaryPatientDiagnosisType.Osteoporosis:
      break;
  }
  return {
    level,
    body,
    grade,
  };
};
