import {
  CaseRiskAssessmentType,
  IAsset,
  ICase,
  ICaseImplantSpecification,
  IForm20Product,
  InterbodyScrewLengthTypes,
  LevelAdditionalImages,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { clone } from 'lodash';
import { findCaseRiskAssessment, getForm19FormData, ProductDates } from '../../../../utils/form19';

export type CaseImplantSpecificationInputType = {
  caseId?: number;
  level?: LevelType;
  partType?: PartType;
  size?: LevelSize;
  apDepth?: number;
  mlWidth?: number;
  maxHeight?: number;
  bulletHeight?: number;
  bulletAngle?: number;
  leftBulletHeight?: number;
  leftBulletAngle?: number;
  rightBulletHeight?: number;
  rightBulletAngle?: number;
  coronalAngle?: number;
  lordoticAngle?: number;
  isBulletMinHeightValid?: boolean;
  isBulletMinDistanceValid?: boolean;
  isCosmeticCheckValid?: boolean;
  isPartMarkingsValid?: boolean;
  screwLength?: InterbodyScrewLengthTypes;
  revision?: number;
  description?: string;
};

export type Form19ActionType = {
  type: 'INIT' | 'ASSETS_READY';
  data?: any;
};

export type Form19StateLevelsValuesType = {
  notes: string;
  additionalImage: LevelAdditionalImages | undefined;
  screwLength?: InterbodyScrewLengthTypes;
  revision: number;
  description?: string;
  attribute: string;
  minus01: number | string | boolean | undefined;
  plan02: number | string | boolean | undefined;
  plus03: number | string | boolean | undefined;
  type:
    | 'ANGLE'
    | 'NOTE'
    | 'MANUAL_ATTRIBUTE'
    | 'PART_ATTRIBUTE'
    | 'ADDITIONAL_IMAGE'
    | 'SCREW_LENGTH'
    | 'REVISION';
};

export type Form19StateType = {
  areAssetsReady: boolean;
  assets: IAsset[];
  caseImplantSpecifications: ICaseImplantSpecification[];
  form19Id: number;
  form19Settings: any; // NOTE: Needs to be typed
  form19Data: any; // NOTE: Needs to be typed
  form19History?: any; // NOTE: Needs to be typed
  caseId: number;
  isImageSliceValid: boolean;
  isNonConformance: boolean;
  nonConformanceReason: string;
  regenerateReason: string;
  caseRiskAssessment?: CaseRiskAssessmentType | string;
  initialCaseRiskAssessment?: CaseRiskAssessmentType | string;
  revision?: number;
  description?: string;
  levels?: {
    level: LevelType | string | any;
    values: Form19StateLevelsValuesType[];
  }[];
  peerReviewerId: number | string;
  isInitialized: boolean;
  plusLevelSize: number;
  planApprovedAt: Date | string | null;
  form20Products: IForm20Product[]; // NOTE: Needs to be typed
  productDates?: ProductDates;
};

export const Form19FillDialogReducer =
  (activeCase: ICase) =>
  (state: Form19StateType, action: Form19ActionType): Form19StateType => {
    let updatedState: Form19StateType = clone(state);
    switch (action.type) {
      case 'ASSETS_READY': {
        updatedState.areAssetsReady = true;
        break;
      }
      case 'INIT': {
        const form19Data = action?.data?.form19;
        const form19History = action?.data?.form19History ?? [];
        const caseImplantSpecifications =
          action.data.caseImplantSpecifications.caseImplantSpecifications ?? [];
        const caseAssets = action.data.assets ?? [];
        const applicationSettings = action?.data?.settings?.application
          ? action?.data?.settings?.application
          : {};

        const productDates: ProductDates = {
          kitCartonStartDate:
            date.parseCalendarDateFromString(
              applicationSettings?.product?.kitCartonStartDate as string,
            ) ?? undefined,
          m4lStartDate:
            date.parseCalendarDateFromString(
              applicationSettings?.product?.m4lStartDate as string,
            ) ?? undefined,
          tlifCM4lStartDate:
            date.parseCalendarDateFromString(
              applicationSettings?.product?.tlifCM4lStartDate as string,
            ) ?? undefined,
          tlifCOrientationStartDate:
            date.parseCalendarDateFromString(
              applicationSettings?.product?.tlifCOrientationStartDate as string,
            ) ?? undefined,
          alifXRotationLockStartDate:
            date.parseCalendarDateFromString(
              applicationSettings?.product?.alifXRotationLockStartDate as string,
            ) ?? undefined,
        };

        const levels = getForm19FormData(
          form19Data,
          caseImplantSpecifications,
          caseAssets,
          activeCase,
        );

        const initialCaseRiskAssessment = findCaseRiskAssessment(activeCase.levels);
        const checkCaseRiskAssessment = form19Data?.caseRiskAssessment ?? initialCaseRiskAssessment;

        updatedState = {
          areAssetsReady: false,
          assets: caseAssets,
          caseImplantSpecifications: caseImplantSpecifications,
          caseId: form19Data?.caseId ?? updatedState.caseId,
          form19Settings: action?.data?.settings?.form19 ?? {},
          form19Data: form19Data,
          form19History: form19History,
          form19Id: form19Data?.form19Id ?? updatedState.form19Id,
          isInitialized: true,
          isImageSliceValid: form19Data?.isImageSliceValid ?? updatedState.isImageSliceValid,
          isNonConformance: form19Data?.isNonConformance ?? updatedState.isNonConformance,
          nonConformanceReason:
            form19Data?.nonConformanceReason ?? updatedState.nonConformanceReason,
          regenerateReason: updatedState.regenerateReason,
          caseRiskAssessment: form19Data?.caseRiskAssessment ?? checkCaseRiskAssessment,
          initialCaseRiskAssessment,
          revision: form19Data?.revision ?? updatedState.revision,
          description: form19Data?.description ?? updatedState.description,
          levels,
          peerReviewerId:
            checkCaseRiskAssessment === CaseRiskAssessmentType.QaReviewOnly
              ? ''
              : form19Data?.peerReviewerUser?.userId ?? updatedState.peerReviewerId,
          plusLevelSize: updatedState.plusLevelSize,
          planApprovedAt: updatedState.planApprovedAt,
          form20Products: action?.data?.settings?.form20 ?? [],
          productDates: productDates ?? {},
        };
      }
    }
    return updatedState;
  };
