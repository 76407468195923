import CustomDialog from '../../../components/CustomDialog';
import { ProgressButton } from '@workflow-nx/ui';
import { Avatar, Backdrop, Box, CircularProgress, Divider, Slider, Stack } from '@mui/material';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from 'react-image-crop';
import { DependencyList, RefObject, useEffect, useRef, useState } from 'react';
import { setCanvasPreview } from './setCanvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import { AssetType, IAsset } from '@workflow-nx/common';
import ActionButton from '../../../components/ActionButton';
import { grey } from '@mui/material/colors';
import { faImageSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import useCreateAndUploadPhotoAsset from '../../../hooks/useCreateAndUploadPhotoAsset';

function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      1,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
function useDebounceEffect(fn: () => void, waitTime: number, deps: DependencyList = []) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [waitTime, ...deps]);
}

const ORIGINAL_CONTAINER_WIDTH = '600px';
const ORIGINAL_CONTAINER_HEIGHT = '400px';
const CROPPED_CONTAINER_SIZE = '307px';

type CroppedImageMetaDataType = {
  originalScale?: number;
  originalTranslateX?: number;
  originalTranslateY?: number;
  crop?: PercentCrop;
  completedCrop?: PixelCrop;
};

enum ArrowKeys {
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  Up = 'ArrowUp',
  Down = 'ArrowDown',
}

export function UploadUserProfileDialog(props: {
  open: boolean;
  userId: number;
  originalPhotoAsset: IAsset | undefined;
  croppedPhotoAsset: IAsset | undefined;
  onCreate?: (caseId: string) => void;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const {
    originalScale,
    originalTranslateX,
    originalTranslateY,
    crop: metadataCrop,
    completedCrop: metadataCompletedCrop,
  } = (props?.croppedPhotoAsset?.metadata as CroppedImageMetaDataType) ?? {};

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { createAndUploadPhotoAsset, uploadProgress } = useCreateAndUploadPhotoAsset();

  const [imgSrc, setImgSrc] = useState(props?.originalPhotoAsset?.signedDownloadUrl ?? '');
  const [originalFileName, setOriginalFileName] = useState('');
  const [crop, setCrop] = useState<PercentCrop | undefined>(metadataCrop ?? undefined);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>(
    metadataCompletedCrop ?? undefined,
  );
  const [scale, setScale] = useState(originalScale ?? 1);
  const [translateX, setTranslateX] = useState(originalTranslateX ?? 0);
  const [translateY, setTranslateY] = useState(originalTranslateY ?? 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOriginalImage, setIsLoadingOriginalImage] = useState(
    props?.originalPhotoAsset?.signedDownloadUrl ? true : false,
  );

  const isSubmitting = uploadProgress < 100 && uploadProgress !== 0;

  function onSelectFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const reader = new FileReader();
        const targetFile = target.files[0];

        setCrop(undefined);

        reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
        reader.readAsDataURL(targetFile);
        setOriginalFileName(
          targetFile.name ? targetFile.name.split('.')?.slice(0, -1).join('.') : '',
        );
      }
    };
    input.click();
  }

  async function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;

    if (e.currentTarget?.currentSrc !== props?.originalPhotoAsset?.signedDownloadUrl) {
      setCrop(centerAspectCrop(width, height));
      resetImageTransforms(undefined, true);
    } else {
      resetImageTransforms();
      if (imgRef.current && previewCanvasRef.current) {
        setCanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop as PixelCrop,
          scale,
          undefined,
          translateX,
          translateY,
        );
      }
    }
    setIsLoadingOriginalImage(false);
  }

  async function imageElementToFile(
    image: HTMLImageElement | HTMLCanvasElement,
    fileName: string,
    scaleX = 1,
    scaleY = 1,
    offscreenCanvas?: PixelCrop,
  ): Promise<File> {
    return new Promise((resolve, reject) => {
      let canvas;
      let ctx;
      let offscreen;
      if (image instanceof HTMLCanvasElement) {
        canvas = image;
      } else {
        canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
      }

      if (offscreenCanvas) {
        offscreen = new OffscreenCanvas(
          offscreenCanvas.width * scaleX,
          offscreenCanvas.height * scaleY,
        );
        ctx = offscreen.getContext('2d');
        ctx?.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          offscreen ? offscreen.width : 0,
          offscreen ? offscreen.height : 0,
        );
      } else {
        ctx = canvas.getContext('2d');
        ctx?.drawImage(image, 0, 0);
      }
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        } else {
          const error = new Error(
            'Failed to convert canvas to Blob. Check if the canvas is tainted.',
          );
          console.error(error);
          reject(new Error('Failed to convert canvas to Blob'));
        }
      }, 'image/png');
    });
  }

  async function onSaveCropClick() {
    setIsLoading(true);
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      enqueueSnackbar('Error loading image canvas', {
        variant: 'error',
      });
      setIsLoading(false);
      return;
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    try {
      const croppedImage = await imageElementToFile(
        previewCanvas,
        `${originalFileName ?? props?.originalPhotoAsset?.fileName ?? ''}-cropped.png`,
        scaleX,
        scaleY,
        completedCrop,
      );
      await createAndUploadPhotoAsset(
        props.userId,
        croppedImage,
        AssetType.UserProfileImageCropped,
        { scale, translateX, translateY, crop, completedCrop },
      );

      if (props?.originalPhotoAsset?.signedDownloadUrl !== imgSrc) {
        const originalImage = await imageElementToFile(
          image,
          `${originalFileName ?? 'Original-Photo'}.png`,
        );
        await createAndUploadPhotoAsset(
          props.userId,
          originalImage,
          AssetType.UserProfileImageOriginal,
        );
      }
      props.onClose(true);
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error saving image', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function resetImageTransforms(ref?: RefObject<HTMLImageElement>, isCompleteReset = false) {
    setScale(!isCompleteReset ? originalScale ?? 1 : 1);
    setTranslateX(!isCompleteReset ? originalTranslateX ?? 0 : 0);
    setTranslateY(!isCompleteReset ? originalTranslateY ?? 0 : 0);

    if (ref && ref.current) {
      const { width, height } = ref.current;
      setCrop(centerAspectCrop(width, height));
      setCompletedCrop(convertToPixelCrop(centerAspectCrop(width, height), width, height));
    } else if (!isCompleteReset) {
      setCrop(metadataCrop ?? undefined);
    }
  }

  function handleScaleChange(event: Event, newScale: number | number[]) {
    if (typeof newScale === 'number') {
      setScale(newScale);
    }
  }

  function clearPhoto() {
    resetImageTransforms(undefined, true);
    setImgSrc(props?.originalPhotoAsset?.signedDownloadUrl ?? '');
    setOriginalFileName('');
    setCrop(undefined);
    setCompletedCrop(undefined);
  }

  function handleOnWheelChange<T extends HTMLElement>(e: React.WheelEvent<T>) {
    const deltaY = e.deltaY;
    if (deltaY) {
      const step = 0.1;
      let newScale = scale;

      if (deltaY < 0 && scale < 3) {
        newScale = Math.min(3, Math.round((scale + step) * 10) / 10);
      } else if (deltaY > 0 && scale > 0.5) {
        newScale = Math.max(0.5, Math.round((scale - step) * 10) / 10);
      }
      setScale(newScale);
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ArrowKeys.Left) {
        setTranslateX((prev) => prev - 10);
      } else if (e.key === ArrowKeys.Right) {
        setTranslateX((prev) => prev + 10);
      } else if (e.key === ArrowKeys.Up) {
        setTranslateY((prev) => prev - 10);
      } else if (e.key === ArrowKeys.Down) {
        setTranslateY((prev) => prev + 10);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        setCanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          undefined,
          translateX,
          translateY,
        );
      }
    },
    100,
    [completedCrop, scale, translateX, translateY],
  );

  return (
    <CustomDialog
      maxWidth={'lg'}
      open={props.open}
      title={'Upload Photo Profile'}
      onClose={() => {
        clearPhoto();
        props.onClose(true);
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={!imgSrc || isSubmitting || isLoading || completedCrop === metadataCompletedCrop}
          onClick={onSaveCropClick}
          label={'Save'}
          loading={isSubmitting || isLoading}
        />,
      ]}
      negativeActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting || isLoading}
          onClick={onSelectFile}
          label={imgSrc ? 'Upload different Photo' : 'Upload Profile Photo'}
          loading={isSubmitting || isLoading}
        />,
      ]}
    >
      <Box>
        <Box p={2}>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-around'}
            alignItems={'stretch'}
            spacing={2}
            rowGap={2}
            flexWrap={'wrap'}
          >
            <Box
              sx={{
                flexShrink: 0,
                padding: '10px',
              }}
            >
              <Stack spacing={1}>
                <Box
                  height={ORIGINAL_CONTAINER_HEIGHT}
                  width={ORIGINAL_CONTAINER_WIDTH}
                  sx={{
                    bgcolor: grey[300],
                  }}
                >
                  {imgSrc || props?.originalPhotoAsset?.signedDownloadUrl ? (
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                      onWheel={handleOnWheelChange}
                    >
                      <Stack justifyContent={'center'} alignItems={'center'}>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => {
                            setCompletedCrop(c);
                          }}
                          aspect={1}
                          minHeight={100}
                          circularCrop
                          style={{}}
                        >
                          <img
                            ref={imgRef}
                            alt="Original Profile"
                            src={imgSrc}
                            onLoad={onImageLoad}
                            crossOrigin="anonymous"
                            style={{
                              objectFit: 'cover',
                              objectPosition: 'center',
                              width: 'auto',
                              height: ORIGINAL_CONTAINER_HEIGHT,
                              transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                            }}
                            onError={() => {
                              if (isLoadingOriginalImage && !imgSrc) {
                                clearPhoto();
                                return;
                              }
                              enqueueSnackbar('Error loading original image', {
                                variant: 'error',
                              });
                              clearPhoto();
                              setIsLoadingOriginalImage(false);
                            }}
                          />
                        </ReactCrop>
                      </Stack>
                    </div>
                  ) : (
                    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                      <FontAwesomeIcon
                        icon={faImageSlash}
                        style={{
                          width: 300,
                          height: 300,
                          backgroundColor: 'inherit',
                          color: grey[50],
                        }}
                      />
                    </Stack>
                  )}
                </Box>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Slider
                    value={scale}
                    aria-label="Scale"
                    defaultValue={1}
                    valueLabelDisplay="off"
                    step={0.1}
                    min={0.05}
                    max={2}
                    onChange={handleScaleChange}
                    disabled={!imgSrc}
                    onKeyDown={(e) => {
                      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <ActionButton
                    onClick={() => resetImageTransforms(imgRef, true)}
                    size={'small'}
                    disabled={!imgSrc}
                  >
                    Reset
                  </ActionButton>
                </Stack>
              </Stack>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ borderWidth: '1.7px' }} />
            <Box sx={{ padding: '10px' }}>
              {completedCrop ? (
                <Stack alignItems={'center'} height={'100%'}>
                  <canvas
                    ref={previewCanvasRef}
                    aria-label="preview canvas"
                    style={{
                      border: `2px solid ${grey[400]}`,
                      objectFit: 'cover',
                      objectPosition: 'center',
                      maxHeight: CROPPED_CONTAINER_SIZE,
                      maxWidth: CROPPED_CONTAINER_SIZE,
                      borderRadius: '50%',
                      height: CROPPED_CONTAINER_SIZE,
                      width: CROPPED_CONTAINER_SIZE,
                      margin: 'auto 0',
                    }}
                  />
                </Stack>
              ) : (
                <Stack alignItems={'center'} height={'100%'}>
                  <Avatar
                    sx={{
                      bgcolor: grey[300],
                      border: `2px solid ${grey[400]}`,
                      maxHeight: CROPPED_CONTAINER_SIZE,
                      maxWidth: CROPPED_CONTAINER_SIZE,
                      height: CROPPED_CONTAINER_SIZE,
                      width: CROPPED_CONTAINER_SIZE,
                      margin: 'auto 0',
                    }}
                  />
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoadingOriginalImage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </CustomDialog>
  );
}
