import { useMutation, useQuery } from '@apollo/client';
import { Timeline } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Switch,
} from '@mui/material';
import {
  ActivityOriginType,
  ActivityType,
  CommentType,
  IComment,
  Permission,
} from '@workflow-nx/common';
import { orderBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useReducer, useState } from 'react';
import { DELETE_COMMENT, FIND_TIMELINE, UPDATE_COMMENT } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { CreateCommentView } from './CreateCommentView';
import { DiscussionTimelineItem } from './DiscussionTimelineItem';

export type TimelineActivity = {
  activityId: number;
  activityType: ActivityType;
  originId: number;
  originType: ActivityOriginType;
  createdAt: string | Date;
  delta?: any;
  entry?: any;
};

type DiscussionTabViewProps = {
  caseId: number;
  readOnly?: boolean;
  isPatientView?: boolean;
};

type ActionType = {
  type: string;
  data: any;
};

type TimelineStateType = {
  timeline: { activities: TimelineActivity[] };
};

export function DiscussionTabView({ caseId, readOnly, isPatientView }: DiscussionTabViewProps) {
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [updateComment, { loading: loadingUpdateComment }] = useMutation(UPDATE_COMMENT);
  const [deleteComment, { loading: loadingDeleteComment }] = useMutation(DELETE_COMMENT);
  const [editComment, setEditComment] = useState<IComment>();
  const [showOnlyComments, setShowOnlyComments] = useState(
    localStorage.getItem('DiscussionTimeline.showOnlyComments') !== 'false',
  );

  const setCommentSwitchState = (option: boolean) => {
    setShowOnlyComments(option);
    localStorage.setItem('DiscussionTimeline.showOnlyComments', String(option));
  };

  const { refetch, loading: loadingTimeline } = useQuery(FIND_TIMELINE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: caseId,
      commentTypeFilter: isPatientView ? [CommentType.Patient] : [CommentType.Case],
    },
    onCompleted: (data) => {
      discussionTabDispatch({ type: 'INIT', data: data });
    },
  });

  const initialState: TimelineStateType = {
    timeline: { activities: [] },
  };

  const discussionTimelineReducer = (
    state: TimelineStateType,
    action: ActionType,
  ): TimelineStateType => {
    let updatedState = state;

    switch (action.type) {
      case 'INIT': {
        const { activities } = action?.data.timeline;
        const sortedActivities = orderBy(
          activities,
          (arr) => {
            return arr.entry?.occurredAt ?? arr.createdAt;
          },
          'asc',
        );

        updatedState = {
          timeline: {
            activities: sortedActivities,
          },
        };
        break;
      }
    }
    return updatedState;
  };

  const [timelineState, discussionTabDispatch] = useReducer(
    discussionTimelineReducer,
    initialState,
  );

  const handlePinComment = async (commentId: number, isPinned: boolean) => {
    try {
      await updateComment({
        variables: { commentId, isPinned },
      });
      enqueueSnackbar('Comment was pinned successfully', {
        variant: 'success',
      });

      setEditComment(undefined);
      refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred pinning a comment for the case', {
        variant: 'error',
      });
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    try {
      await confirm({
        title: 'Delete Comment',
        description: 'Are you sure you want to delete this comment?',
      });

      await deleteComment({
        variables: { commentId },
      });
      enqueueSnackbar('Comment was deleted successfully', {
        variant: 'success',
      });

      setEditComment(undefined);
      refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred deleting a comment for the case', {
        variant: 'error',
      });
    }
  };

  return (
    <Card>
      <CardContent>
        <Box m={1}>
          <>
            {!loadingTimeline ? (
              <Box display={'flex'} justifyContent={'center'}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOnlyComments}
                        onChange={() => {
                          setCommentSwitchState(!showOnlyComments);
                        }}
                      />
                    }
                    label="Only Show Comments?"
                  />
                </FormGroup>
              </Box>
            ) : null}
            <Grid container>
              {!loadingTimeline ? (
                <>
                  <Grid item lg={3} />
                  <Grid item lg={9} md={12}>
                    {timelineState?.timeline.activities.length === 0 ? (
                      <Alert severity={'info'}>
                        There are no comments or activity on this case
                      </Alert>
                    ) : null}

                    <Timeline>
                      {timelineState?.timeline?.activities.map((activity, index) => {
                        if (activity.originType === ActivityOriginType.Comment) {
                          return (
                            <DiscussionTimelineItem
                              key={index}
                              index={index}
                              activity={activity}
                              disabled={
                                !!editComment &&
                                editComment?.commentId !== activity.entry?.commentId
                              }
                              comment={activity.entry}
                              isPatientView={isPatientView}
                              onPinClicked={() =>
                                handlePinComment(
                                  activity.entry?.commentId,
                                  !activity.entry?.isPinned,
                                )
                              }
                              onDeleteClicked={() => {
                                handleDeleteComment(activity.entry?.commentId);
                              }}
                              onEditClicked={() => {
                                setEditComment(activity.entry);
                              }}
                            />
                          );
                        } else {
                          return (
                            <Collapse in={!showOnlyComments} key={index}>
                              <DiscussionTimelineItem
                                key={index}
                                index={index}
                                activity={activity}
                                disabled={false}
                                onUpdate={() => refetch()}
                              />
                            </Collapse>
                          );
                        }
                      })}
                    </Timeline>
                  </Grid>
                </>
              ) : (
                <Grid container>
                  <Grid item lg={3} />
                  <Grid item lg={9} md={12}>
                    <Box
                      flexDirection={'row'}
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      mb={1}
                    >
                      <Skeleton variant={'circular'} width={40} height={40} />
                      <Box mx={1} />
                      <Box width={'100%'}>
                        <Skeleton component={'div'} width={'75%'} height={40} />
                        <Skeleton component={'div'} width={100} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>
              {auth?.hasPermission?.([
                Permission.ManageCase,
                Permission.EditCase,
                Permission.EditDiscussions,
              ]) && !readOnly ? (
                <>
                  <Grid item lg={3} />
                  <Grid item lg={9} md={12}>
                    <CreateCommentView
                      caseId={caseId}
                      commentType={isPatientView ? CommentType.Patient : CommentType.Case}
                      editComment={editComment}
                      onCreate={() => {
                        setEditComment(undefined);
                        refetch();
                      }}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </>
        </Box>
      </CardContent>
    </Card>
  );
}
