import React, { ChangeEvent } from 'react';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';

interface FileInputProps {
  disabled: boolean;
  onFileRead: (text: string) => void;
}

export const FileInput: React.FC<FileInputProps> = ({ disabled, onFileRead }) => {
  const elementId = `file-input${Math.random().toString(36).substring(2)}`;

  const handleFileRead = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const file = input.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const result = event.target?.result as string;
          onFileRead(result);
        } catch (error) {
          console.error('Error reading file:', error);
        } finally {
          input.value = '';
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Box>
      <input
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFileRead(e);
        }}
        style={{ display: 'none' }}
        id={elementId}
      />
      <label htmlFor={elementId}>
        <Button
          variant="text"
          component="span"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
          disabled={disabled}
          sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
        >
          Import nTop File
        </Button>
      </label>
    </Box>
  );
};
