import { useMutation } from '@apollo/client';
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Checkbox as MuiCheckbox,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  caseUtils,
  format,
  ICase,
  ImplantType,
  IPatientRecord,
  IPlan,
  IPlanImplant,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { ProgressButton, SelectField } from '@workflow-nx/ui';
import { file } from '@workflow-nx/utils';
import useAuth from 'apps/workflow-client/src/app/hooks/useAuth';
import * as FileSaver from 'file-saver';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../components/CustomDialog';
import { NumberTextField } from '../../../../components/NumberTextField';
import { TableCell } from '../../../../components/TableCell';
import { TableHeadCell } from '../../../../components/TableHeadCell';
import { EXPORT_ONSHAPE_PARTS } from '../../../../gql';
import { FeatureFlag } from '../../../../utils/featureFlags';

const allLevelTypes = Object.values(LevelType);

export function ExportNTopAssetsDialog(props: {
  open: boolean;
  plan?: IPlan;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [includeOnShapeAssets, setIncludeOnShapeAssets] = useState<boolean>(true);
  const [exportOnShapeParts, { loading: loadingExportOnShapeParts }] =
    useMutation(EXPORT_ONSHAPE_PARTS);
  const { hasFeatureFlag } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [initialState, setInitialState] = useState(
    allLevelTypes.reduce(
      (prev, level) => ({
        ...prev,
        [level]: { ap: 0, ml: 0 },
      }),
      {},
    ),
  );
  const [progressState, setProgressState] = useState('');
  const [downloadProgress, setDownloadProgress] = useState(0);
  const { hasPelvisHighPelvicIncidence, hasPelvisOblique, hasPelvisHighCrest } = props.activeCase
    ?.patient?.patientRecord as IPatientRecord;

  const handleSubmitForm = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting, resetForm }: FormikHelpers<any>,
  ) => {
    try {
      setProgressState('Generating assets for nTop. This may take a while');

      const inputValues: any = {};
      Object.keys(values).forEach((key) => {
        const value: {
          ap: number;
          ml: number;
          obliqueThreadAngle: number | undefined;
          cranialCaudalThreadAngle: number | undefined;
        } = values[key];

        if (Number(value.ap) !== 0 && Number(value.ml) !== 0) {
          inputValues[key] = {
            ap: Number(value.ap),
            ml: Number(value.ml),
            obliqueThreadAngle: value.obliqueThreadAngle,
            cranialCaudalThreadAngle: value.cranialCaudalThreadAngle,
          };
        }
      });

      const { data: exportOnShapePartsData } = await exportOnShapeParts({
        variables: {
          caseId: props.activeCase.caseId,
          input: { planId: props.plan?.planId, includeOnShapeAssets, ...inputValues },
        },
      });

      const zipFilename = exportOnShapePartsData.exportOnShapeParts.filename;
      const zipDownloadUrl = exportOnShapePartsData.exportOnShapeParts.signedUrl;

      setProgressState(`Assets generated, downloading zip file ${zipFilename}`);

      const response = await file.downloadFile(zipDownloadUrl, {
        onDownloadProgress: (percentComplete) => {
          setDownloadProgress(percentComplete);
        },
      });
      FileSaver.saveAs(response.data, zipFilename);

      setStatus({ success: true });
      enqueueSnackbar('nTop GUI assets have been downloaded successfully,', {
        variant: 'success',
      });

      props.onClose(true);
      resetForm();
    } catch (err: any) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      enqueueSnackbar('An error occurred downloading the nTop GUI assets', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      setProgressState('');
    }
  };

  useEffect(() => {
    if (props.plan) {
      setInitialState(
        allLevelTypes.reduce((prev, level) => {
          let implant = {
            ap: 0,
            ml: 0,
            obliqueThreadAngle: 0,
            cranialCaudalThreadAngle: 0,
          };

          if (props.plan?.implants) {
            const planImplant = props.plan.implants.find(
              (planImplant: IPlanImplant) => planImplant.level === level,
            );
            if (planImplant) {
              implant = {
                ap: planImplant.ap,
                ml: planImplant.ml,
                obliqueThreadAngle: isNumber(planImplant.obliqueThreadAngle)
                  ? planImplant.obliqueThreadAngle
                  : 0,
                cranialCaudalThreadAngle: isNumber(planImplant.cranialCaudalThreadAngle)
                  ? planImplant.cranialCaudalThreadAngle
                  : 0,
              };
            }
          }

          return {
            ...prev,
            [level]: implant,
          };
        }, {}),
      );
    }
  }, [props.plan]);

  return (
    <Formik enableReinitialize={true} initialValues={initialState} onSubmit={handleSubmitForm}>
      {({ submitForm, isSubmitting, setFieldValue }) => {
        return (
          <CustomDialog
            maxWidth={'md'}
            open={props.open}
            title={`Download nTop GUI Assets`}
            onClose={() => {
              props.onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loadingExportOnShapeParts}
                onClick={() => submitForm()}
                label={'Export'}
                loading={false}
              />,
            ]}
          >
            {isSubmitting || loadingExportOnShapeParts ? (
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography variant={'body1'}>{progressState}....</Typography>
                <Box mt={1} />
                <Box height={10} width={'100%'}>
                  <LinearProgress
                    variant={downloadProgress === 0 ? 'indeterminate' : 'determinate'}
                    value={downloadProgress}
                  />
                </Box>
              </Box>
            ) : (
              <>
                <Box mb={2}>
                  <Typography variant={'body1'}>
                    Clicking <strong>Export</strong> will download a zip file containing all the
                    assets required to generate an interbody in nTopology.
                  </Typography>
                </Box>
                <Typography variant={'h4'}>
                  <strong>OnShape Part Dimensions</strong>
                </Typography>
                <Box display={'flex'}>
                  <FormControlLabel
                    control={
                      <MuiCheckbox
                        disabled={false}
                        name="includeOnShapeAssets"
                        checked={includeOnShapeAssets}
                        onChange={(e, isChecked) => {
                          setIncludeOnShapeAssets(isChecked);
                        }}
                      />
                    }
                    label={`Include OnShape assets?`}
                  />
                </Box>
                <Box display={'flex'}>
                  <Table>
                    <TableHead>
                      <TableHeadCell>Level</TableHeadCell>
                      <TableHeadCell>Part</TableHeadCell>
                      <TableHeadCell>AP</TableHeadCell>
                      <TableHeadCell>ML</TableHeadCell>
                      {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) && (
                        <TableHeadCell>Angle</TableHeadCell>
                      )}
                    </TableHead>
                    <TableBody>
                      {Object.keys(props.activeCase.levels)
                        .filter((level) => level !== '__typename')
                        .map((level) => {
                          const partType = props.activeCase.levels[level] as PartType;
                          const implantType = caseUtils.getImplantType(partType);

                          if (!caseUtils.isValidLevelPartType(partType)) return null;

                          return (
                            <TableRow>
                              <TableCell>
                                {format.formatInterbodyLevel(level as LevelType)}
                              </TableCell>
                              <TableCell>{format.formatPartType(partType)}</TableCell>
                              <TableCell>
                                <NumberTextField
                                  disabled={!includeOnShapeAssets}
                                  name={level + '.ap'}
                                />
                              </TableCell>
                              <TableCell>
                                <NumberTextField
                                  disabled={!includeOnShapeAssets}
                                  name={level + '.ml'}
                                />
                              </TableCell>
                              {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                                <TableCell align={'center'} width={'250px'}>
                                  <Stack gap={1} direction={'row'}>
                                    {!hasPelvisHighPelvicIncidence &&
                                    !hasPelvisOblique &&
                                    !hasPelvisHighCrest ? (
                                      <>&mdash;</>
                                    ) : null}

                                    {hasPelvisHighPelvicIncidence &&
                                    [PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(partType) ? (
                                      <SelectField
                                        name={`${level}.cranialCaudalThreadAngle`}
                                        label={'Cranial-Caudal'}
                                        fullWidth={false}
                                        menuItems={[
                                          {
                                            key: '0',
                                            value: '0°',
                                          },
                                          {
                                            key: '5',
                                            value: '+5°',
                                          },
                                          {
                                            key: '10',
                                            value: '+10°',
                                          },
                                          {
                                            key: '15',
                                            value: '+15°',
                                          },
                                          {
                                            key: '20',
                                            value: '+20°',
                                          },
                                        ]}
                                        disabled={
                                          isSubmitting ||
                                          loadingExportOnShapeParts ||
                                          !includeOnShapeAssets
                                        }
                                        hideNone={true}
                                      />
                                    ) : null}
                                    {hasPelvisOblique &&
                                    [ImplantType.LLIF].includes(implantType) ? (
                                      <SelectField
                                        onChange={() =>
                                          setFieldValue(`${level}.obliqueThreadAngle`, '0', true)
                                        }
                                        name={`${level}.obliqueThreadAngle`}
                                        fullWidth={false}
                                        label={'Oblique'}
                                        menuItems={[
                                          {
                                            key: '0',
                                            value: '0°',
                                          },
                                          {
                                            key: '5',
                                            value: '+5°',
                                          },
                                          {
                                            key: '10',
                                            value: '+10°',
                                          },
                                          {
                                            key: '15',
                                            value: '+15°',
                                          },
                                          {
                                            key: '20',
                                            value: '+20°',
                                          },
                                        ]}
                                        disabled={
                                          isSubmitting ||
                                          loadingExportOnShapeParts ||
                                          !includeOnShapeAssets
                                        }
                                        hideNone={true}
                                      />
                                    ) : null}
                                    {hasPelvisHighCrest &&
                                    [ImplantType.LLIF].includes(implantType) ? (
                                      <SelectField
                                        onChange={() =>
                                          setFieldValue(`${level}.obliqueThreadAngle`, '0', true)
                                        }
                                        name={`${level}.cranialCaudalThreadAngle`}
                                        fullWidth={false}
                                        label={'Cranial-Caudal'}
                                        menuItems={[
                                          {
                                            key: '0',
                                            value: '0°',
                                          },
                                          {
                                            key: '5',
                                            value: '+5°',
                                          },
                                          {
                                            key: '10',
                                            value: '+10°',
                                          },
                                          {
                                            key: '15',
                                            value: '+15°',
                                          },
                                          {
                                            key: '20',
                                            value: '+20°',
                                          },
                                          {
                                            key: '-5',
                                            value: '-5°',
                                          },
                                          {
                                            key: '-10',
                                            value: '-10°',
                                          },
                                          {
                                            key: '-15',
                                            value: '-15°',
                                          },
                                          {
                                            key: '-20',
                                            value: '-20°',
                                          },
                                        ]}
                                        disabled={
                                          isSubmitting ||
                                          loadingExportOnShapeParts ||
                                          !includeOnShapeAssets
                                        }
                                        hideNone={true}
                                      />
                                    ) : null}
                                    {![
                                      ImplantType.ALIF,
                                      ImplantType.ALIFX,
                                      ImplantType.LLIF,
                                    ].includes(implantType) ? (
                                      <>&mdash;</>
                                    ) : null}
                                  </Stack>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </>
            )}
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
