import React, { useState } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  format,
  PartType,
  LevelSize,
  LevelType,
  ICase,
  CaseStageType,
  ImplantType,
  caseUtils,
  IPlanImplant,
} from '@workflow-nx/common';
import { useConfirm } from 'material-ui-confirm';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useSnackbar } from 'notistack';
import { useSurgeonExcludedImplantSizes } from '../hooks/useSurgeonExcludedImplantSizes';
import ActionButton from './ActionButton';
import { FIND_PLAN } from '../gql';
import useAuth from '../hooks/useAuth';
import { FeatureFlag } from '../utils/featureFlags';

function ExcludedLevelView(props: {
  isExclusionUpdatingAllowed: boolean;
  validExclusionLevels: {
    level: LevelType;
    implantType: ImplantType;
    partType: PartType;
    levelSize: LevelSize;
    planImplantId: number | undefined;
    isExcluded: boolean;
  }[];
  onUpdate: (
    level: LevelType,
    partType: PartType,
    levelSize: LevelSize,
    isExcluded: boolean,
  ) => void;
}) {
  return (
    <Stack spacing={0.5} mt={1}>
      {props.validExclusionLevels.map((validExclusionLevel) => {
        return (
          <Typography variant={'body1'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Box>{format.formatLevelType(validExclusionLevel.level)}</Box>
              <Box>{format.formatPartType(validExclusionLevel.partType)}</Box>
              <strong>{format.formatLevelSize(validExclusionLevel.levelSize)} </strong>
              <Box flexGrow={1} />
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <strong>{validExclusionLevel.isExcluded ? 'Excluded' : 'Included'}</strong>
                {props.isExclusionUpdatingAllowed && validExclusionLevel.planImplantId ? (
                  <ActionButton
                    variant={'text'}
                    size={'small'}
                    onClick={() =>
                      props.onUpdate(
                        validExclusionLevel.level,
                        validExclusionLevel.partType,
                        validExclusionLevel.levelSize,
                        validExclusionLevel.isExcluded,
                      )
                    }
                  >
                    {validExclusionLevel.isExcluded ? 'Include' : 'Exclude'}
                  </ActionButton>
                ) : null}
              </Stack>
            </Stack>
          </Typography>
        );
      })}
    </Stack>
  );
}

export function ImplantSizeExclusionAlert(props: {
  currentStage: CaseStageType;
  activeCase: ICase;
  planId?: number;
  onUpdate?: () => void;
}) {
  const { hasFeatureFlag } = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const allowedImplantExclusionTypes = useSurgeonExcludedImplantSizes(
    props?.activeCase?.levels,
    props?.activeCase?.surgeonUser?.userId,
  );
  const [planImplants, setPlanImplants] = useState<IPlanImplant[]>([]);

  const { loading: loadingFindPlan } = useQuery(FIND_PLAN, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      planId: props.planId,
    },
    onCompleted: (data) => {
      const plan = data.plan;

      setPlanImplants(plan?.implants ?? []);
    },
  });

  const [upsertPlanImplant] = useMutation(gql`
    mutation UpsertPlanImplant($planImplantId: Int, $input: UpsertPlanImplantInput!) {
      upsertPlanImplant(planImplantId: $planImplantId, input: $input) {
        status
      }
    }
  `);

  const handleExclusionUpdate = async (
    levelType: LevelType,
    partType: PartType,
    levelSize: LevelSize,
    isExcluded: boolean,
  ) => {
    try {
      const planImplant = planImplants.find((implant) => implant.level === levelType);

      await confirm({
        allowClose: false,
        title: `${format.formatLevelType(levelType)} exclusion will be ${
          isExcluded ? 'removed' : 'added'
        }`,
        description: isExcluded ? (
          <>
            The {format.formatLevelType(levelType)} {format.formatPartType(partType as PartType)}{' '}
            <strong>{format.formatLevelSize(levelSize)}</strong> size exclusion will be removed. Do
            you wish to continue?.
          </>
        ) : (
          <>
            The {format.formatLevelType(levelType)} {format.formatPartType(partType as PartType)}{' '}
            <strong>{format.formatLevelSize(levelSize)}</strong> size exclusion will be added. Do
            you wish to continue?.
          </>
        ),
      });

      await upsertPlanImplant({
        variables: {
          planImplantId: planImplant?.planImplantId,
          input: {
            planId: props?.planId,
            partType: partType,
            level: levelType,
            ap: planImplant?.ap ?? 0,
            ml: planImplant?.ml ?? 0,
            excludedImplantSizes: isExcluded ? [] : [levelSize],
          },
        },
      });

      props?.onUpdate?.();

      enqueueSnackbar(`Size exclusion successfully ${isExcluded ? 'removed' : 'added'}`, {
        variant: 'success',
      });
    } catch (e) {
      console.log(`Cancelling exclusion ${isExcluded ? 'removed' : 'added'}`, e);
    }
  };

  const isExclusionUpdatingAllowed =
    props.currentStage === CaseStageType.Planning &&
    props.activeCase.stage === CaseStageType.Planning &&
    allowedImplantExclusionTypes.length > 0;

  let validExclusionLevels: {
    level: LevelType;
    implantType: ImplantType;
    partType: PartType;
    levelSize: LevelSize;
    planImplantId: number | undefined;
    isExcluded: boolean;
  }[] = [];

  props.activeCase.caseLevels.forEach((caseLevel) => {
    const implantType = caseUtils.getImplantType(caseLevel.partType as PartType);
    const result = allowedImplantExclusionTypes.find((aiet) => {
      return aiet.implantType === implantType;
    });

    if (result) {
      const planImplant = planImplants.find((implant) => implant.level === caseLevel.level);

      let isExcluded = true;
      if (planImplant?.planImplantId) {
        isExcluded = (planImplant?.excludedImplantSizes ?? []).includes(result.excludedSize);
      }

      if (props.currentStage === CaseStageType.Planning || isExcluded) {
        validExclusionLevels.push({
          level: caseLevel.level,
          implantType: result.implantType,
          partType: caseLevel.partType as PartType,
          levelSize: result.excludedSize,
          planImplantId: planImplant?.planImplantId,
          isExcluded,
        });
      }
    }
  });

  // if there are no valid exclusion levels, check if any are currently set
  if (validExclusionLevels.length === 0) {
    validExclusionLevels = planImplants
      .filter((planImplant) => planImplant?.excludedImplantSizes?.length)
      .map((planImplant) => {
        const implantType = caseUtils.getImplantType(planImplant.partType);
        return {
          level: planImplant.level,
          implantType,
          partType: planImplant.partType as PartType,
          levelSize: planImplant?.excludedImplantSizes?.[0] as LevelSize,
          planImplantId: planImplant?.planImplantId,
          isExcluded: true,
        };
      });
  }

  return (
    <>
      {hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled) ? (
        <Box my={1}>
          {allowedImplantExclusionTypes.length > 0 ? (
            <Alert severity="warning" icon={false}>
              {loadingFindPlan ? <Skeleton /> : null}

              {validExclusionLevels.length ? (
                <>
                  <Typography variant={'h4'}>Implant Size Exclusion</Typography>
                  <Typography variant={'body1'} mt={1}>
                    {[CaseStageType.Planning].includes(props.currentStage) ? (
                      <div>
                        The following implants sizes are available to be excluded from the Kit.
                      </div>
                    ) : null}
                    {![CaseStageType.Planning].includes(props.currentStage) ? (
                      <>
                        <div>The following implants sizes will be excluded from the Kit.</div>
                      </>
                    ) : null}
                  </Typography>
                  <ExcludedLevelView
                    isExclusionUpdatingAllowed={isExclusionUpdatingAllowed}
                    onUpdate={handleExclusionUpdate}
                    validExclusionLevels={validExclusionLevels}
                  />
                </>
              ) : (
                <Typography variant={'body1'}>
                  No level sizes are being excluded for this case.
                </Typography>
              )}
            </Alert>
          ) : null}
          {/* for the case where the surgeon has previously had implants excluded for a case, but has had the option to exclude implants turned off*/}
          {allowedImplantExclusionTypes.length === 0 ? (
            <>
              {validExclusionLevels.length ? (
                <Alert severity="warning" icon={false}>
                  {loadingFindPlan ? <Skeleton /> : null}

                  <>
                    <Typography variant={'h4'}>Implant Size Exclusion</Typography>
                    <Typography variant={'body1'} mt={1}>
                      <div>The following implants sizes will be excluded from the Kit.</div>
                    </Typography>
                    <ExcludedLevelView
                      isExclusionUpdatingAllowed={isExclusionUpdatingAllowed}
                      onUpdate={handleExclusionUpdate}
                      validExclusionLevels={validExclusionLevels}
                    />
                  </>
                </Alert>
              ) : (
                <Typography variant={'body1'}>
                  No level sizes are being excluded for this case.
                </Typography>
              )}
            </>
          ) : null}
        </Box>
      ) : null}
    </>
  );
}
