import {
  Coordinate,
  format,
  ImplantType,
  IPlanImplant,
  LevelType,
  NtopAlifReferencePoints,
  NtopLlifReferencePoints,
  NtopTlifReferencePoints,
  PartType,
} from '@workflow-nx/common';

function parseALIFTextFile(lines: string[]): Partial<IPlanImplant> {
  const ap = Number(lines[4]);
  const ml = Number(lines[5]);

  const [rotX, rotY, rotZ] = lines[8].split(',');
  const rotation = {
    x: Number(rotX),
    y: Number(rotY),
    z: Number(rotZ),
  } as Coordinate;

  const [posX, posY, posZ] = lines[9].split(',');
  const position = {
    x: Number(posX),
    y: Number(posY),
    z: Number(posZ),
  } as Coordinate;

  const bulletAngle = Number(lines[10]);
  const bulletHeight = Number(lines[11]);

  const threadHeight = Number(lines[12]);

  return {
    ap,
    ml,
    bullet: { insertionSide: { angle: bulletAngle, height: bulletHeight } },
    rotation,
    position,
    threadHeight,
  };
}

function toCoordinate(value: string): Coordinate {
  const components = value.split(','); // Splits the string into an array of values
  return {
    x: parseFloat(components[0]),
    y: parseFloat(components[1]),
    z: parseFloat(components[2]),
  };
}

function parseALIFTextFileV3(data: any): Partial<IPlanImplant> {
  const referencePoints: NtopAlifReferencePoints = {
    positionCentroid: toCoordinate(data.POSITION_CENTROID),
    positionX: toCoordinate(data.POSITION_X),
    positionY: toCoordinate(data.POSITION_Y),
    smoothTopAnteriorCentroid: toCoordinate(data.SMOOTH_TOP_ANTERIOR_CENTROID),
    smoothTopAnteriorGrid: Number(data.SMOOTH_TOP_ANTERIOR_GRID),
    smoothTopAnteriorIterations: Number(data.SMOOTH_TOP_ANTERIOR_ITERATIONS),
    smoothTopAnteriorX: toCoordinate(data.SMOOTH_TOP_ANTERIOR_X),
    smoothTopAnteriorY: toCoordinate(data.SMOOTH_TOP_ANTERIOR_Y),
    smoothBottomAnteriorCentroid: toCoordinate(data.SMOOTH_BOTTOM_ANTERIOR_CENTROID),
    smoothBottomAnteriorGrid: Number(data.SMOOTH_BOTTOM_ANTERIOR_GRID),
    smoothBottomAnteriorIterations: Number(data.SMOOTH_BOTTOM_ANTERIOR_ITERATIONS),
    smoothBottomAnteriorX: toCoordinate(data.SMOOTH_BOTTOM_ANTERIOR_X),
    smoothBottomAnteriorY: toCoordinate(data.SMOOTH_BOTTOM_ANTERIOR_Y),
    insertionBulletTopCentroid: toCoordinate(data.INSERTION_BULLET_TOP_CENTROID),
    insertionBulletTopGrid: Number(data.INSERTION_BULLET_TOP_GRID),
    insertionBulletTopIterations: Number(data.INSERTION_BULLET_TOP_ITERATIONS),
    insertionBulletTopX: toCoordinate(data.INSERTION_BULLET_TOP_X),
    insertionBulletTopY: toCoordinate(data.INSERTION_BULLET_TOP_Y),
    insertionBulletBottomCentroid: toCoordinate(data.INSERTION_BULLET_BOTTOM_CENTROID),
    insertionBulletBottomGrid: Number(data.INSERTION_BULLET_BOTTOM_GRID),
    insertionBulletBottomIterations: Number(data.INSERTION_BULLET_BOTTOM_ITERATIONS),
    insertionBulletBottomX: toCoordinate(data.INSERTION_BULLET_BOTTOM_X),
    insertionBulletBottomY: toCoordinate(data.INSERTION_BULLET_BOTTOM_Y),
  };
  return {
    ap: Number(data.AP),
    ml: Number(data.ML),
    bullet: {
      insertionSide: {
        angle: Number(data.INSERTION_BULLET_ANGLE),
        height: Number(data.INSERTION_BULLET_HEIGHT),
      },
    },
    referencePoints: referencePoints,
    rotation: undefined,
    position: undefined,
    threadHeight: Number(data.THREAD_HEIGHT),
  };
}
function validateALIFTextFileV3(data: any): string[] {
  const errors: string[] = [];

  const properties = [
    'POSITION_CENTROID',
    'POSITION_X',
    'POSITION_Y',
    'SMOOTH_TOP_ANTERIOR_CENTROID',
    'SMOOTH_TOP_ANTERIOR_GRID',
    'SMOOTH_TOP_ANTERIOR_ITERATIONS',
    'SMOOTH_TOP_ANTERIOR_X',
    'SMOOTH_TOP_ANTERIOR_Y',
    'SMOOTH_BOTTOM_ANTERIOR_CENTROID',
    'SMOOTH_BOTTOM_ANTERIOR_GRID',
    'SMOOTH_BOTTOM_ANTERIOR_ITERATIONS',
    'SMOOTH_BOTTOM_ANTERIOR_X',
    'SMOOTH_BOTTOM_ANTERIOR_Y',
    'INSERTION_BULLET_TOP_CENTROID',
    'INSERTION_BULLET_TOP_GRID',
    'INSERTION_BULLET_TOP_ITERATIONS',
    'INSERTION_BULLET_TOP_X',
    'INSERTION_BULLET_TOP_Y',
    'INSERTION_BULLET_BOTTOM_CENTROID',
    'INSERTION_BULLET_BOTTOM_GRID',
    'INSERTION_BULLET_BOTTOM_ITERATIONS',
    'INSERTION_BULLET_BOTTOM_X',
    'INSERTION_BULLET_BOTTOM_Y',
    'INSERTION_BULLET_ANGLE',
    'INSERTION_BULLET_HEIGHT',
    'IMPLANT_TYPE',
    'THREAD_HEIGHT',
    'AP',
    'ML',
    'LEVEL',
  ];

  properties.forEach((property) => {
    if (!data[property]) {
      errors.push(property);
    }
  });

  return errors;
}

function validateLLIFTextFileV3(data: any): string[] {
  const errors: string[] = [];

  const properties = [
    'POSITION_CENTROID',
    'POSITION_X',
    'POSITION_Y',
    'INSERTION_BULLET_TOP_CENTROID',
    'INSERTION_BULLET_TOP_GRID',
    'INSERTION_BULLET_TOP_ITERATIONS',
    'INSERTION_BULLET_TOP_X',
    'INSERTION_BULLET_TOP_Y',
    'INSERTION_BULLET_BOTTOM_CENTROID',
    'INSERTION_BULLET_BOTTOM_GRID',
    'INSERTION_BULLET_BOTTOM_ITERATIONS',
    'INSERTION_BULLET_BOTTOM_X',
    'INSERTION_BULLET_BOTTOM_Y',
    'THREAD_BULLET_TOP_CENTROID',
    'THREAD_BULLET_TOP_GRID',
    'THREAD_BULLET_TOP_ITERATIONS',
    'THREAD_BULLET_TOP_X',
    'THREAD_BULLET_TOP_Y',
    'THREAD_BULLET_BOTTOM_CENTROID',
    'THREAD_BULLET_BOTTOM_GRID',
    'THREAD_BULLET_BOTTOM_ITERATIONS',
    'THREAD_BULLET_BOTTOM_X',
    'THREAD_BULLET_BOTTOM_Y',
    'THREAD_BULLET_BOTTOM_Y',
    'INSERTION_BULLET_ANGLE',
    'INSERTION_BULLET_HEIGHT',
    'THREAD_BULLET_ANGLE',
    'THREAD_BULLET_HEIGHT',
    'IMPLANT_TYPE',
    'THREAD_HEIGHT',
    'AP',
    'ML',
    'LEVEL',
  ];

  properties.forEach((property) => {
    if (!data[property]) {
      errors.push(property);
    }
  });

  return errors;
}

function validateTLIFTextFileV3(data: any): string[] {
  const errors: string[] = [];

  const properties = [
    'POSITION_CENTROID',
    'POSITION_X',
    'POSITION_Y',
    'INSERTION_BULLET_TOP_CENTROID',
    'INSERTION_BULLET_TOP_GRID',
    'INSERTION_BULLET_TOP_ITERATIONS',
    'INSERTION_BULLET_TOP_X',
    'INSERTION_BULLET_TOP_Y',
    'INSERTION_BULLET_BOTTOM_CENTROID',
    'INSERTION_BULLET_BOTTOM_GRID',
    'INSERTION_BULLET_BOTTOM_ITERATIONS',
    'INSERTION_BULLET_BOTTOM_X',
    'INSERTION_BULLET_BOTTOM_Y',
    'INSERTION_BULLET_ANGLE',
    'INSERTION_BULLET_HEIGHT',
    'IMPLANT_TYPE',
    'THREAD_HEIGHT',
    'AP',
    'ML',
    'LEVEL',
  ];

  properties.forEach((property) => {
    if (!data[property]) {
      errors.push(property);
    }
  });

  return errors;
}

function parseTLIFTextFile(lines: string[]): Partial<IPlanImplant> {
  const ap = Number(lines[4]);
  const ml = Number(lines[5]);

  const [rotX, rotY, rotZ] = lines[8].split(',');
  const rotation = {
    x: Number(rotX),
    y: Number(rotY),
    z: Number(rotZ),
  } as Coordinate;

  const [posX, posY, posZ] = lines[9].split(',');
  const position = {
    x: Number(posX),
    y: Number(posY),
    z: Number(posZ),
  } as Coordinate;

  const threadHeight = Number(lines[10]);

  return {
    ap,
    ml,
    bullet: undefined,
    rotation,
    position,
    threadHeight,
  };
}

function parseLLIFTextFile(lines: string[], partType: PartType): Partial<IPlanImplant> {
  const ap = Number(lines[4]);
  const ml = Number(lines[5]);

  const [rotX, rotY, rotZ] = lines[8].split(',');
  const rotation = {
    x: Number(rotX),
    y: Number(rotY),
    z: Number(rotZ),
  } as Coordinate;

  const [posX, posY, posZ] = lines[9].split(',');
  const position = {
    x: Number(posX),
    y: Number(posY),
    z: Number(posZ),
  } as Coordinate;

  let rightBulletAngle = Number(lines[10]);
  let rightBulletHeight = Number(lines[11]);

  let leftBulletAngle = Number(lines[12]);
  let leftBulletHeight = Number(lines[13]);

  if (partType === PartType.LLIF_RIGHT) {
    leftBulletAngle = Number(lines[10]);
    leftBulletHeight = Number(lines[11]);

    rightBulletAngle = Number(lines[12]);
    rightBulletHeight = Number(lines[13]);
  }

  const threadHeight = Number(lines[15]);

  return {
    ap,
    ml,
    bullet: {
      insertionSide: { angle: rightBulletAngle, height: rightBulletHeight },
      threadedSide: { angle: leftBulletAngle, height: leftBulletHeight },
    },
    rotation,
    position,
    threadHeight,
  };
}

function parseLLIFTextFileV3(data: any): Partial<IPlanImplant> {
  const referencePoints: NtopLlifReferencePoints = {
    positionCentroid: toCoordinate(data.POSITION_CENTROID),
    positionX: toCoordinate(data.POSITION_X),
    positionY: toCoordinate(data.POSITION_Y),
    insertionBulletTopCentroid: toCoordinate(data.INSERTION_BULLET_TOP_CENTROID),
    insertionBulletTopGrid: Number(data.INSERTION_BULLET_TOP_GRID),
    insertionBulletTopIterations: Number(data.INSERTION_BULLET_TOP_ITERATIONS),
    insertionBulletTopX: toCoordinate(data.INSERTION_BULLET_TOP_X),
    insertionBulletTopY: toCoordinate(data.INSERTION_BULLET_TOP_Y),
    insertionBulletBottomCentroid: toCoordinate(data.INSERTION_BULLET_BOTTOM_CENTROID),
    insertionBulletBottomGrid: Number(data.INSERTION_BULLET_BOTTOM_GRID),
    insertionBulletBottomIterations: Number(data.INSERTION_BULLET_BOTTOM_ITERATIONS),
    insertionBulletBottomX: toCoordinate(data.INSERTION_BULLET_BOTTOM_X),
    insertionBulletBottomY: toCoordinate(data.INSERTION_BULLET_BOTTOM_Y),
    threadBulletTopCentroid: toCoordinate(data.THREAD_BULLET_TOP_CENTROID),
    threadBulletTopGrid: Number(data.THREAD_BULLET_TOP_GRID),
    threadBulletTopIterations: Number(data.THREAD_BULLET_TOP_ITERATIONS),
    threadBulletTopX: toCoordinate(data.THREAD_BULLET_TOP_X),
    threadBulletTopY: toCoordinate(data.THREAD_BULLET_TOP_Y),
    threadBulletBottomCentroid: toCoordinate(data.THREAD_BULLET_BOTTOM_CENTROID),
    threadBulletBottomGrid: Number(data.THREAD_BULLET_BOTTOM_GRID),
    threadBulletBottomIterations: Number(data.THREAD_BULLET_BOTTOM_ITERATIONS),
    threadBulletBottomX: toCoordinate(data.THREAD_BULLET_BOTTOM_X),
    threadBulletBottomY: toCoordinate(data.THREAD_BULLET_BOTTOM_Y),
  };

  return {
    ap: Number(data.AP),
    ml: Number(data.ML),
    bullet: {
      insertionSide: {
        angle: Number(data.INSERTION_BULLET_ANGLE),
        height: Number(data.INSERTION_BULLET_HEIGHT),
      },
      threadedSide: {
        angle: Number(data.THREAD_BULLET_ANGLE),
        height: Number(data.THREAD_BULLET_HEIGHT),
      },
    },
    referencePoints: referencePoints,
    rotation: undefined,
    position: undefined,
    threadHeight: Number(data.THREAD_HEIGHT),
  };
}

function parseTLIFTextFileV3(data: any): Partial<IPlanImplant> {
  const referencePoints: NtopTlifReferencePoints = {
    positionCentroid: toCoordinate(data.POSITION_CENTROID),
    positionX: toCoordinate(data.POSITION_X),
    positionY: toCoordinate(data.POSITION_Y),
    insertionBulletTopCentroid: toCoordinate(data.INSERTION_BULLET_TOP_CENTROID),
    insertionBulletTopGrid: Number(data.INSERTION_BULLET_TOP_GRID),
    insertionBulletTopIterations: Number(data.INSERTION_BULLET_TOP_ITERATIONS),
    insertionBulletTopX: toCoordinate(data.INSERTION_BULLET_TOP_X),
    insertionBulletTopY: toCoordinate(data.INSERTION_BULLET_TOP_Y),
    insertionBulletBottomCentroid: toCoordinate(data.INSERTION_BULLET_BOTTOM_CENTROID),
    insertionBulletBottomGrid: Number(data.INSERTION_BULLET_BOTTOM_GRID),
    insertionBulletBottomIterations: Number(data.INSERTION_BULLET_BOTTOM_ITERATIONS),
    insertionBulletBottomX: toCoordinate(data.INSERTION_BULLET_BOTTOM_X),
    insertionBulletBottomY: toCoordinate(data.INSERTION_BULLET_BOTTOM_Y),
  };

  return {
    ap: Number(data.AP),
    ml: Number(data.ML),
    bullet: {
      insertionSide: {
        angle: Number(data.INSERTION_BULLET_ANGLE),
        height: Number(data.INSERTION_BULLET_HEIGHT),
      },
    },
    referencePoints: referencePoints,
    rotation: undefined,
    position: undefined,
    threadHeight: Number(data.THREAD_HEIGHT ?? 0),
  };
}

export const parseNtopTextFile = (
  text: string,
  levelType: LevelType,
  implantType: ImplantType,
  partType: PartType,
) => {
  const windowsPathRegex = /^(?:[a-zA-Z]:\\|\\\\[^\s\\]+\\[^\s\\]+)/;
  const lines = text.replaceAll('\r', '').split('\n');

  const isWindowsPath = windowsPathRegex.test(lines[0]);

  // The original nTop TXT file format always starts with a file path
  if (isWindowsPath) {
    return parseNtopTextV1File(lines, levelType, partType);
  }
  return parseNtopTextV3File(lines, levelType, implantType, partType);
};

function parseKeyValueFile(lines: string[]) {
  const result: { [key: string]: string } = {};

  lines.forEach((line) => {
    // Remove comments and trim whitespace
    const cleanLine = line.replace(/#.*/, '').trim();

    if (!cleanLine || !cleanLine.includes('=')) return;

    const [key, ...valueParts] = cleanLine.split('=');

    if (!key || key.includes(' ') || valueParts.length === 0) return;

    const value = valueParts.join('='); // Handle values containing '='

    if (key) {
      result[key.trim()] = value.trim();
    }
  });

  return result;
}
export const parseNtopTextV3File = (
  lines: string[],
  levelType: LevelType,
  implantType: ImplantType,
  partType: PartType,
) => {
  const result = parseKeyValueFile(lines);

  if (result.LEVEL && result.LEVEL !== levelType) {
    throw new Error(
      `Level from text file, ${format.formatLevelType(
        result.LEVEL as LevelType,
      )} does not match level being imported for ${format.formatLevelType(levelType)}`,
    );
  }

  if (result.IMPLANT_TYPE && result.IMPLANT_TYPE !== implantType) {
    throw new Error(
      `Implant type from text file, ${format.formatImplantTypes(
        result.IMPLANT_TYPE as ImplantType,
      )} does not match implant type being imported for ${format.formatImplantTypes(implantType)}`,
    );
  }

  let parsedData: Partial<IPlanImplant> = {};

  switch (implantType) {
    case ImplantType.ALIF:
    case ImplantType.ALIFX: {
      const errors = validateALIFTextFileV3(result);
      if (errors.length > 0) {
        throw new Error(`Missing required properties in nTop file: ${errors.join(', ')}`);
      }
      parsedData = parseALIFTextFileV3(result);
      break;
    }
    case ImplantType.LLIF: {
      const errors = validateLLIFTextFileV3(result);
      if (errors.length > 0) {
        throw new Error(`Missing required properties in nTop file: ${errors.join(', ')}`);
      }
      parsedData = parseLLIFTextFileV3(result);
      break;
    }
    case ImplantType.TLIFCA:
    case ImplantType.TLIFO:
    case ImplantType.TLIFC: {
      const errors = validateTLIFTextFileV3(result);
      if (errors.length > 0) {
        throw new Error(`Missing required properties in nTop file: ${errors.join(', ')}`);
      }
      parsedData = parseTLIFTextFileV3(result);
      break;
    }
  }

  parsedData.metadata = lines.join('\n');
  return parsedData;
};

export const parseNtopTextV1File = (lines: string[], levelType: LevelType, partType: PartType) => {
  const textFileLevel = `${lines[2].trim()}_${lines[3].trim()}` as LevelType;

  if (textFileLevel !== levelType) {
    throw new Error(
      `Level from text file, ${format.formatLevelType(
        textFileLevel,
      )} does not match level being imported for ${format.formatLevelType(levelType)}`,
    );
  }

  let parsedData: Partial<IPlanImplant> = {};

  switch (partType) {
    case PartType.ALIF:
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      parsedData = parseALIFTextFile(lines);
      break;
    case PartType.LLIF_RIGHT:
    case PartType.LLIF_LEFT:
      parsedData = parseLLIFTextFile(lines, partType);
      break;
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFO_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_OFFSET_LEFT:
      parsedData = parseTLIFTextFile(lines);
      break;
  }

  parsedData.metadata = lines.join('\n');
  return parsedData;
};
