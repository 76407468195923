import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { testing } from '@workflow-nx/utils';
import { useField } from 'formik';
import lodash from 'lodash';
import React from 'react';

const { groupBy } = lodash;
export function SelectField({
  disabled,
  fullWidth = true,
  required,
  multiple,
  hideNone,
  grouped,
  label,
  menuItems,
  name,
  onChange,
}: {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  hideNone?: boolean;
  grouped?: boolean;
  menuItems: { key: string; value: string; parent?: string }[];
  onChange?: (e: SelectChangeEvent<any>) => void;
}) {
  const [input, meta, helpers] = useField(name);
  const inputLabel = React.useRef(null);

  const groupedMenuItems = groupBy(menuItems, 'parent');

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={'outlined'}
      error={Boolean(meta.error)}
      required={required}
      size={'small'}
    >
      <InputLabel id={`outlined-${name}-always-notched`} ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        id={name}
        data-test-id={`${testing.toKebabCase(label ?? name)}-select-field`}
        type="text"
        displayEmpty={true}
        onBlur={input.onBlur}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          input.onChange(e);
        }}
        disabled={Boolean(disabled)}
        multiple={multiple}
        value={meta?.value ?? ''}
        input={<OutlinedInput name={name} id={`outlined-${name}-always-notched`} label={label} />}
        renderValue={(selected: any) => {
          const value = menuItems.find(
            (menuItem) => menuItem.key.toString() === selected?.toString(),
          )?.value;

          return multiple ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((key: string) => {
                const menuItem = menuItems.find(
                  (menuItem) => menuItem.key.toString() === key.toString(),
                );
                if (!menuItem) {
                  return;
                }

                return (
                  <Chip
                    key={menuItem.key}
                    label={menuItem.value}
                    size={'small'}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      const removed = selected.filter((s: string) => s !== menuItem.key);
                      helpers.setValue(removed);
                    }}
                  />
                );
              })}
            </Box>
          ) : (
            value
          );
        }}
      >
        {!hideNone ? <MenuItem value={''}>None</MenuItem> : null}

        {grouped &&
          Object.keys(groupedMenuItems)?.map((value) => {
            const groupMenuItems = groupedMenuItems[value];
            return [
              <ListSubheader>{value}</ListSubheader>,
              groupMenuItems?.map(({ key, value }) => (
                <MenuItem key={key} value={key} sx={{ pl: 3 }}>
                  {value}
                </MenuItem>
              )),
            ];
          })}

        {!grouped &&
          menuItems?.map(({ key, value }) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
