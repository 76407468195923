import { AssetMappingOriginType, AssetType, IAsset } from '@workflow-nx/common';
import { useState } from 'react';
import { file as fileUtils } from '@workflow-nx/utils';
import { useMutation } from '@apollo/client';
import { CREATE_PHOTO_ASSET, DELETE_ASSET_AND_MAPPING } from '../gql';
import { AxiosError } from 'axios';

const useCreateAndUploadPhotoAsset = (): {
  createAndUploadPhotoAsset: (
    originId: number,
    file: File,
    assetType: AssetType | undefined,
    metaData?: any,
  ) => Promise<{ createdAsset: { asset: IAsset; signedUrl: string } | null; error: string | null }>;
  uploadProgress: number;
} => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [createAsset] = useMutation(CREATE_PHOTO_ASSET);
  const [deleteAssetAndMapping] = useMutation(DELETE_ASSET_AND_MAPPING);

  const createAndUploadPhotoAsset = async (
    originId: number,
    file: File,
    assetType: AssetType | undefined,
    metaData?: any,
  ): Promise<{
    createdAsset: { asset: IAsset; signedUrl: string } | null;
    error: string | null;
  }> => {
    let createdAsset: { asset: IAsset; signedUrl: string } | null = null;
    let errorMessage: string | null = null;

    if (file) {
      try {
        const { data: assetData } = await createAsset({
          variables: {
            originId: originId,
            originType: AssetMappingOriginType.User,
            fileName: file.name,
            size: file.size,
            assetType: assetType,
            metadata: metaData ?? undefined,
          },
        });
        createdAsset = assetData?.createAssetMapping;

        if (createdAsset?.signedUrl) {
          await fileUtils.uploadFile(createdAsset.signedUrl, file, (percentComplete) => {
            setUploadProgress(percentComplete);
          });
        }
      } catch (e) {
        if (createdAsset) {
          await deleteAssetAndMapping({
            variables: {
              assetId: createdAsset?.asset?.assetId,
            },
          });
        }
        console.error(e);
        errorMessage = (e as AxiosError)?.message;
      }
    }
    return { createdAsset, error: errorMessage };
  };

  return { createAndUploadPhotoAsset, uploadProgress };
};

export default useCreateAndUploadPhotoAsset;
