import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Chip,
  TextField,
  useTheme,
} from '@mui/material';
import { DEFAULT_TAG_CATEGORY_COLOR, ITag } from '@workflow-nx/common';
import { FIND_TAGS } from '../gql';
import { useField, Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import _ from 'lodash';

export type TagOption = {
  key: number;
  value: string;
  color: string;
  categoryName: string;
};

export const transformTagToTagOption = (tag: ITag): TagOption => ({
  key: tag.tagId,
  value: tag.label,
  color: tag.tagCategory?.color || DEFAULT_TAG_CATEGORY_COLOR,
  categoryName: tag.tagCategory?.label || 'Uncategorized',
});

const TagAutocomplete = ({
  name,
  label,
  disabled,
  required,
  options,
}: {
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  onChange?: (e: ChangeEvent) => void;
  options: TagOption[];
}) => {
  const theme = useTheme();
  const [, meta] = useField(name);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <FormLabel component="legend">{label}</FormLabel>
      <Box mt={1}>
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            return (
              <Autocomplete
                clearOnBlur={true}
                clearOnEscape={true}
                disabled={disabled}
                multiple
                options={options}
                value={(fieldProps.field.value ?? []).map((fieldValue: { key: number }) => ({
                  ...fieldValue,
                  value:
                    options.find((option) => option.key === fieldValue.key)?.value ?? 'Unknown',
                }))}
                onChange={(e, value) => {
                  fieldProps.form.setFieldValue(name, value);
                }}
                onBlur={() => {
                  fieldProps.form.setFieldTouched(name, true);
                }}
                groupBy={(option) => {
                  return option.categoryName;
                }}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                getOptionLabel={(option) => option.value}
                renderTags={(value: readonly TagOption[], getTagProps) =>
                  value.map((option: TagOption, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });

                    return (
                      <Chip
                        key={key}
                        variant={'outlined'}
                        label={
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography>{option.value}</Typography>
                          </Box>
                        }
                        style={{
                          backgroundColor: option.color,
                          color: theme.palette.getContrastText(option.color),
                        }}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    tabIndex={1}
                    placeholder="Click to add tags"
                    required={required}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    onKeyDown={(e) => {
                      // stop enter from submitting a form
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            );
          }}
        </Field>
      </Box>
    </FormControl>
  );
};

export const TagDetailsForm: React.FC = () => {
  const [tagOptions, setTagOptions] = React.useState<TagOption[]>([]);

  const { loading: tagsLoading } = useQuery(FIND_TAGS, {
    onCompleted: (tagsData) => {
      if (!tagsData || !tagsData?.tags?.length) return;
      const sortedTags = _.sortBy(tagsData.tags, ['tagCategory.label', 'label']);

      setTagOptions(sortedTags.map(transformTagToTagOption));
    },
  });

  return <TagAutocomplete disabled={tagsLoading} name="tags" options={tagOptions} />;
};
