import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableType } from '@workflow-nx/common';
import { LumbarMeasurements, MeasurementValues } from '@workflow-nx/core-algorithms';
import * as math from '@workflow-nx/math';
import lodash from 'lodash';

const { isNumber } = lodash;
const useStyles = makeStyles<Theme, { color: 'invert' | 'default'; size: 'medium' | 'small' }>(
  (theme: Theme) => ({
    headerLabelRoot: {
      '& .MuiTypography-root ': {
        fontSize: ({ size }: { size: number | string }) =>
          size === 'medium' ? theme.typography.fontSize : '0.8rem',
        marginBottom: ({ size }: { size: number | string }) =>
          size === 'medium' ? theme.spacing(1) : 0,
      },
    },
  }),
);

type LumbarMeasurementSummaryViewProps = {
  color: 'invert' | 'default';
  disableToggle: boolean;
  isExpanded: boolean;
  pelvicIncidence?: number;
  setExpansion: (expanded: boolean) => void;
  size: 'small' | 'medium';
  tableType: TableType;
  data?: LumbarMeasurements;
};

function formatTableType(tableType: TableType) {
  switch (tableType) {
    case TableType.PlanDiff:
      return 'PLAN DIFF';
    default:
      return tableType;
  }
}

export function LumbarMeasurementSummaryView({
  color,
  disableToggle,
  isExpanded,
  pelvicIncidence,
  setExpansion,
  size,
  tableType,
  data,
}: LumbarMeasurementSummaryViewProps) {
  const styles = useStyles({ size, color });

  const handleExpansion = () => {
    isExpanded ? setExpansion(false) : setExpansion(true);
  };

  const renderExpansionToggleButton = () => {
    let result = null;

    if (!disableToggle) {
      result = (
        <IconButton color={'inherit'} onClick={handleExpansion}>
          {isExpanded ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </IconButton>
      );
    }

    return result;
  };

  const getTableValue = (values: MeasurementValues | undefined) => {
    if (!values) return null;

    switch (tableType) {
      case TableType.Preop:
      case TableType.Daisy:
        return values.preOp;
      case TableType.Plan:
        return values.plan;
      case TableType.PlanDiff:
        return values.delta;
      default:
        return null;
    }
  };

  const lumbarLordosis = getTableValue(data?.lumbarLordosis);
  const lumbarCoronalAngle = getTableValue(data?.lumbarCoronalAngle);

  const piMinusLL = isNumber(pelvicIncidence) ? pelvicIncidence - (lumbarLordosis || 0) : null;

  return (
    <Box display={'flex'} alignItems={'center'} minWidth={'475px'}>
      <Box mr={3} display={'flex'} alignItems={'center'}>
        <Typography variant={'h4'}>
          <Box fontWeight={'bold'}>{formatTableType(tableType)}</Box>
        </Typography>
      </Box>
      <Box flexGrow={1} />
      {data ? (
        <Box display={'flex'} justifyContent={'space-between'} className={styles.headerLabelRoot}>
          <Typography
            component={'div'}
            noWrap
            variant={'body1'}
            data-testid={`${tableType.toLowerCase()}-evaluated-PI`}
          >
            <Box fontWeight={'bold'} fontSize={16} lineHeight={'normal'}>
              {math.formatNumber(pelvicIncidence ?? null, '—', 0, true)}
            </Box>
            <Box>PI</Box>
          </Typography>
          <Box mx={1} />
          <Typography
            component={'div'}
            noWrap
            variant={'body1'}
            data-testid={`${tableType.toLowerCase()}-evaluated-LL`}
          >
            <Box fontWeight={'bold'} fontSize={16} lineHeight={'normal'}>
              {math.formatNumber(lumbarLordosis, '—', 0, true)}
            </Box>
            <Box>LL</Box>
          </Typography>
          <Box mx={1} />
          <Typography
            component={'div'}
            noWrap
            variant={'body1'}
            data-testid={`${tableType.toLowerCase()}-evaluated-PI-LL`}
          >
            <Box fontWeight={'bold'} fontSize={16} lineHeight={'normal'}>
              {math.formatNumber(piMinusLL, '—', 0, true)}
            </Box>
            <Box>PI - LL</Box>
          </Typography>
          <Box mx={1} />
          <Typography
            component={'div'}
            noWrap
            variant={'body1'}
            data-testid={`${tableType.toLowerCase()}-evaluated-Coronal`}
          >
            <Box fontWeight={'bold'} fontSize={16} lineHeight={'normal'}>
              {math.formatNumber(lumbarCoronalAngle, '—', 0, true)}
            </Box>
            <Box>CORONAL</Box>
          </Typography>
        </Box>
      ) : null}
      {renderExpansionToggleButton()}
    </Box>
  );
}
