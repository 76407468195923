import { format, IUser, UserRoleType } from '@workflow-nx/common';
import jwtDecode from 'jwt-decode';
import { sortBy } from 'lodash';

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const groupUsersByRoleIntoSelectOptions = (
  users: IUser[],
  startWithLastName = false,
): {
  surgeons: { key: string; value: string }[];
  assignees: { key: string; value: string }[];
  fieldReps: { key: string; value: string }[];
} => {
  const surgeons: { key: string; value: string }[] = [];
  const assignees: { key: string; value: string }[] = [];
  const fieldReps: { key: string; value: string }[] = [];

  if (users?.length > 0) {
    for (const user of users) {
      const key = user.userId.toString();
      const value = format.formatName(user, startWithLastName);

      if (user.role === UserRoleType.Surgeon) {
        surgeons.push({ key, value });
      }
      if (user.role === UserRoleType.CaseEngineer || user.role === UserRoleType.CaseAdmin) {
        assignees.push({ key, value });
      }
      if (user.role === UserRoleType.FieldRep) {
        fieldReps.push({ key, value });
      }
    }
  }

  return {
    surgeons: sortBy(surgeons, ['value']),
    assignees: sortBy(assignees, ['value']),
    fieldReps: sortBy(fieldReps, ['value']),
  };
};

export const groupUsersByRole = (
  users: IUser[],
): {
  surgeons: IUser[];
  assignees: IUser[];
  fieldReps: IUser[];
  salesReps: IUser[];
} => {
  const surgeons: IUser[] = [];
  const assignees: IUser[] = [];
  const fieldReps: IUser[] = [];
  const salesReps: IUser[] = [];

  if (users?.length > 0) {
    for (const user of users) {
      if (user.role === UserRoleType.Surgeon) {
        surgeons.push(user);
      }
      if (user.role === UserRoleType.SalesRep) {
        salesReps.push(user);
      }
      if (user.role === UserRoleType.CaseEngineer || user.role === UserRoleType.CaseAdmin) {
        assignees.push(user);
      }
      if (user.role === UserRoleType.FieldRep) {
        fieldReps.push(user);
      }
    }
  }

  return {
    surgeons,
    assignees,
    fieldReps,
    salesReps,
  };
};

export const getSurgeonFilteredUserRolesKVPSorted = () => {
  return sortBy(
    Object.values(UserRoleType)
      .filter(
        (userRoleType: UserRoleType) =>
          ![UserRoleType.System, UserRoleType.Demo, UserRoleType.Surgeon].includes(userRoleType),
      )
      .map((userRoleType) => ({
        key: userRoleType,
        value: format.formatUserRole(userRoleType),
      })),
    'value',
  );
};

export const getValidUserRolesKVPSorted = () => {
  return sortBy(
    Object.values(UserRoleType)
      .filter(
        (userRoleType: UserRoleType) =>
          ![UserRoleType.System, UserRoleType.Demo].includes(userRoleType),
      )
      .map((userRoleType) => ({
        key: userRoleType,
        value: format.formatUserRole(userRoleType),
      })),
    'value',
  );
};
