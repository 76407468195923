import {
  caseUtils,
  ImplantType,
  IPlanImplant,
  LevelSize,
  NtopAlifReferencePoints,
  NtopLlifReferencePoints,
} from '@workflow-nx/common';
import { getImplantSizeNumber } from './nTopCuts';
import { getImplantSize } from './nTopUtils';
import { nTopUtils } from '..';

export const alifTemplateV3: nTopUtils.NtopConfiguration = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'CASE_DIRECTORY',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'IMPLANT_NAME',
      type: 'text',
      value: 'TBD',
    },
    {
      description: '',
      name: 'PARASOLID_NAME',
      type: 'text',
      value: 'plan',
    },
    {
      description: '',
      name: 'POSITION_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'POSITION_X',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'POSITION_Y',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_TOP_ANTERIOR_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_TOP_ANTERIOR_X',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_TOP_ANTERIOR_Y',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_TOP_ANTERIOR_GRID',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'SMOOTH_TOP_ANTERIOR_ITERATIONS',
      type: 'integer',
      value: 0,
    },
    {
      description: '',
      name: 'SMOOTH_BOTTOM_ANTERIOR_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_BOTTOM_ANTERIOR_X',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_BOTTOM_ANTERIOR_Y',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'SMOOTH_BOTTOM_ANTERIOR_GRID',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'SMOOTH_BOTTOM_ANTERIOR_ITERATIONS',
      type: 'integer',
      value: 0,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_X',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_Y',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_GRID',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_ITERATIONS',
      type: 'integer',
      value: 0,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_X',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_Y',
      type: 'vector',
      units: 'mm',
      value: [0, 0, 0],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_GRID',
      type: 'real',
      units: 'mm',
      value: 0.0,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_ITERATIONS',
      type: 'integer',
      value: 0,
    },
    {
      description: '',
      name: 'THREAD_HEIGHT',
      type: 'real',
      units: 'mm',
      value: 0,
    },
    {
      description: '',
      name: 'IMPLANT_SIZE',
      type: 'real',
      units: 'mm',
      value: 0,
    },
  ],
  title: 'ALIF_OP2',
};

export const llifTemplateV3: nTopUtils.NtopConfiguration = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'CASE_DIRECTORY',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'IMPLANT_NAME',
      type: 'text',
      value: '241122.SB.01_D.L5S1.02_Rev0',
    },
    {
      description: '',
      name: 'PARASOLID_NAME',
      type: 'text',
      value: 'plan',
    },
    {
      description: '',
      name: 'POSITION_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.44, -150.1, -323.94],
    },
    {
      description: '',
      name: 'POSITION_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'POSITION_Y',
      type: 'vector',
      units: 'mm',
      value: [0.004519, 0.8788, 0.4772],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.48593, -140.27, -316.895],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_Y',
      type: 'vector',
      units: 'mm',
      value: [-0.007017, -0.9799, 0.1993],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.50252, -138.839, -319.532],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_Y',
      type: 'vector',
      units: 'mm',
      value: [-9.3742e-5, 0.3665, 0.9304],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'THREAD_BULLET_TOP_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.48593, -140.27, -316.895],
    },
    {
      description: '',
      name: 'THREAD_BULLET_TOP_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'THREAD_BULLET_TOP_Y',
      type: 'vector',
      units: 'mm',
      value: [-0.007017, -0.9799, 0.1993],
    },
    {
      description: '',
      name: 'THREAD_BULLET_TOP_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'THREAD_BULLET_TOP_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'THREAD_BULLET_BOTTOM_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.50252, -138.839, -319.532],
    },
    {
      description: '',
      name: 'THREAD_BULLET_BOTTOM_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'THREAD_BULLET_BOTTOM_Y',
      type: 'vector',
      units: 'mm',
      value: [-9.3742e-5, 0.3665, 0.9304],
    },
    {
      description: '',
      name: 'THREAD_BULLET_BOTTOM_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'THREAD_BULLET_BOTTOM_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'THREAD_HEIGHT',
      type: 'real',
      units: 'mm',
      value: 0,
    },
    {
      description: '',
      name: 'IMPLANT_SIZE',
      type: 'real',
      units: 'mm',
      value: 0,
    },
  ],
  title: 'LLIF_OP2',
};

export const tlifTemplateV3: nTopUtils.NtopConfiguration = {
  description: '',
  inputs: [
    {
      description: '',
      name: 'CASE_DIRECTORY',
      type: 'text',
      value: 'C:\\Users\\Administrator\\project\\implant_level',
    },
    {
      description: '',
      name: 'IMPLANT_NAME',
      type: 'text',
      value: '241122.SB.01_D.L5S1.02_Rev0',
    },
    {
      description: '',
      name: 'PARASOLID_NAME',
      type: 'text',
      value: 'plan',
    },
    {
      description: '',
      name: 'POSITION_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.44, -150.1, -323.94],
    },
    {
      description: '',
      name: 'POSITION_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'POSITION_Y',
      type: 'vector',
      units: 'mm',
      value: [0.004519, 0.8788, 0.4772],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.48593, -140.27, -316.895],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_Y',
      type: 'vector',
      units: 'mm',
      value: [-0.007017, -0.9799, 0.1993],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_TOP_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_CENTROID',
      type: 'vector',
      units: 'mm',
      value: [9.50252, -138.839, -319.532],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_X',
      type: 'vector',
      units: 'mm',
      value: [1, -0.00661, 0.002704],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_Y',
      type: 'vector',
      units: 'mm',
      value: [-9.3742e-5, 0.3665, 0.9304],
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_GRID',
      type: 'real',
      units: 'mm',
      value: 0.25,
    },
    {
      description: '',
      name: 'INSERTION_BULLET_BOTTOM_ITERATIONS',
      type: 'integer',
      value: 3,
    },
    {
      description: '',
      name: 'THREAD_HEIGHT',
      type: 'real',
      units: 'mm',
      value: 0,
    },
    {
      description: '',
      name: 'IMPLANT_SIZE',
      type: 'real',
      units: 'mm',
      value: 0,
    },
  ],
  title: 'TLIF_OP2',
};

export const getNTopCallFileV3 = (implantType: ImplantType, size: string, environment: string) => {
  let nTopLatticeFile = 'ALIF_OP2.ntop';

  switch (implantType) {
    case ImplantType.ALIF:
      nTopLatticeFile = 'ALIF_OP2.ntop';
      break;
    case ImplantType.ALIFX:
      nTopLatticeFile = 'ALIFX_OP2.ntop';
      break;
    case ImplantType.LLIF:
      nTopLatticeFile = 'LLIF_OP2.ntop';
      break;
    case ImplantType.TLIFO:
      nTopLatticeFile = 'TLIFO_OP2.ntop';
      break;
    case ImplantType.TLIFC:
      nTopLatticeFile = 'TLIFC_OP2.ntop';
      break;
    case ImplantType.TLIFCA:
      nTopLatticeFile = 'TLIFCA_OP2.ntop';
      break;
  }

  if (environment) {
    nTopLatticeFile = `${environment}/v3/${nTopLatticeFile}`;
  }

  return `ntopcl.exe -v 2 -u $env:NTOP_USERNAME -w $env:NTOP_PASSWORD -j input_${size}.json --logfile=ntopcl_${size}.log ${nTopLatticeFile}`;
};

export const getNTopJsonFileV3 = (
  caseNumber: string,
  planImplant: IPlanImplant,
  levelSize: LevelSize,
  plusLevelSize: number,
): nTopUtils.NtopConfiguration => {
  const implantType = caseUtils.getImplantType(planImplant.partType);
  const [child, parent]: string[] = planImplant.level.split('_');
  const partTypeCode: string = caseUtils.getPartTypeCode(planImplant.partType);
  const implantSizeNumber = getImplantSizeNumber(levelSize);
  const implantName = `${caseNumber}_${partTypeCode}.${child}${parent}.${implantSizeNumber}_Rev0`;
  const folder = `C:\\Users\\Administrator\\project\\implant_level`;

  let parasolidName = 'unknown_size';

  if (levelSize === LevelSize.Minus) {
    parasolidName = 'minus';
  } else if (levelSize === LevelSize.Normal) {
    parasolidName = 'plan';
  } else if (levelSize === LevelSize.Plus) {
    parasolidName = 'plus';
  }

  let jsonTemplate: nTopUtils.NtopConfiguration = { inputs: [] };

  function updateTemplateProperty(
    template: { inputs: { name: string; value: string | number | number[] }[] },
    propertyName: string,
    value: string | number | number[],
  ) {
    const targetInput = template.inputs.find((input) => input.name === propertyName);
    if (targetInput) {
      targetInput.value = value;
    }
  }

  switch (implantType) {
    case ImplantType.ALIF:
    case ImplantType.ALIFX: {
      jsonTemplate = structuredClone(alifTemplateV3);

      updateTemplateProperty(jsonTemplate, 'CASE_DIRECTORY', folder);
      updateTemplateProperty(jsonTemplate, 'IMPLANT_NAME', implantName);
      updateTemplateProperty(jsonTemplate, 'PARASOLID_NAME', parasolidName);
      updateTemplateProperty(jsonTemplate, 'THREAD_HEIGHT', planImplant.threadHeight);

      updateTemplateProperty(
        jsonTemplate,
        'IMPLANT_SIZE',
        getImplantSize(levelSize, plusLevelSize),
      );

      const referencePoints = planImplant.referencePoints as NtopAlifReferencePoints;

      updateTemplateProperty(
        jsonTemplate,
        'POSITION_CENTROID',
        Object.values(referencePoints.positionCentroid),
      );

      updateTemplateProperty(jsonTemplate, 'POSITION_X', Object.values(referencePoints.positionX));

      updateTemplateProperty(jsonTemplate, 'POSITION_Y', Object.values(referencePoints.positionY));

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_TOP_ANTERIOR_CENTROID',
        Object.values(referencePoints.smoothTopAnteriorCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_TOP_ANTERIOR_X',
        Object.values(referencePoints.smoothTopAnteriorX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_TOP_ANTERIOR_Y',
        Object.values(referencePoints.smoothTopAnteriorY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_TOP_ANTERIOR_GRID',
        referencePoints.smoothTopAnteriorGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_TOP_ANTERIOR_ITERATIONS',
        referencePoints.smoothTopAnteriorIterations,
      );
      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_BOTTOM_ANTERIOR_CENTROID',
        Object.values(referencePoints.smoothBottomAnteriorCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_BOTTOM_ANTERIOR_X',
        Object.values(referencePoints.smoothBottomAnteriorX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_BOTTOM_ANTERIOR_Y',
        Object.values(referencePoints.smoothBottomAnteriorY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_BOTTOM_ANTERIOR_GRID',
        referencePoints.smoothBottomAnteriorGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'SMOOTH_BOTTOM_ANTERIOR_ITERATIONS',
        referencePoints.smoothBottomAnteriorIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_CENTROID',
        Object.values(referencePoints.insertionBulletTopCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_X',
        Object.values(referencePoints.insertionBulletTopX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_Y',
        Object.values(referencePoints.insertionBulletTopY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_GRID',
        referencePoints.insertionBulletTopGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_ITERATIONS',
        referencePoints.insertionBulletTopIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_CENTROID',
        Object.values(referencePoints.insertionBulletBottomCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_X',
        Object.values(referencePoints.insertionBulletBottomX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_Y',
        Object.values(referencePoints.insertionBulletBottomY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_GRID',
        referencePoints.insertionBulletBottomGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_ITERATIONS',
        referencePoints.insertionBulletBottomIterations,
      );

      break;
    }
    case ImplantType.LLIF: {
      jsonTemplate = structuredClone(llifTemplateV3);

      updateTemplateProperty(jsonTemplate, 'CASE_DIRECTORY', folder);
      updateTemplateProperty(jsonTemplate, 'IMPLANT_NAME', implantName);
      updateTemplateProperty(jsonTemplate, 'PARASOLID_NAME', parasolidName);
      updateTemplateProperty(jsonTemplate, 'THREAD_HEIGHT', planImplant.threadHeight);

      updateTemplateProperty(
        jsonTemplate,
        'IMPLANT_SIZE',
        getImplantSize(levelSize, plusLevelSize),
      );

      const referencePoints = planImplant.referencePoints as NtopLlifReferencePoints;

      updateTemplateProperty(
        jsonTemplate,
        'POSITION_CENTROID',
        Object.values(referencePoints.positionCentroid),
      );

      updateTemplateProperty(jsonTemplate, 'POSITION_X', Object.values(referencePoints.positionX));

      updateTemplateProperty(jsonTemplate, 'POSITION_Y', Object.values(referencePoints.positionY));

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_CENTROID',
        Object.values(referencePoints.insertionBulletTopCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_X',
        Object.values(referencePoints.insertionBulletTopX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_Y',
        Object.values(referencePoints.insertionBulletTopY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_GRID',
        referencePoints.insertionBulletTopGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_ITERATIONS',
        referencePoints.insertionBulletTopIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_CENTROID',
        Object.values(referencePoints.insertionBulletBottomCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_X',
        Object.values(referencePoints.insertionBulletBottomX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_Y',
        Object.values(referencePoints.insertionBulletBottomY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_GRID',
        referencePoints.insertionBulletBottomGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_ITERATIONS',
        referencePoints.insertionBulletBottomIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_TOP_CENTROID',
        Object.values(referencePoints.threadBulletTopCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_TOP_X',
        Object.values(referencePoints.threadBulletTopX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_TOP_Y',
        Object.values(referencePoints.threadBulletTopY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_TOP_GRID',
        referencePoints.threadBulletTopGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_TOP_ITERATIONS',
        referencePoints.threadBulletTopIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_BOTTOM_CENTROID',
        Object.values(referencePoints.threadBulletBottomCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_BOTTOM_X',
        Object.values(referencePoints.threadBulletBottomX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_BOTTOM_Y',
        Object.values(referencePoints.threadBulletBottomY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_BOTTOM_GRID',
        referencePoints.threadBulletBottomGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'THREAD_BULLET_BOTTOM_ITERATIONS',
        referencePoints.threadBulletBottomIterations,
      );
      break;
    }
    case ImplantType.TLIFO:
    case ImplantType.TLIFC:
    case ImplantType.TLIFCA: {
      jsonTemplate = structuredClone(tlifTemplateV3);

      updateTemplateProperty(jsonTemplate, 'CASE_DIRECTORY', folder);
      updateTemplateProperty(jsonTemplate, 'IMPLANT_NAME', implantName);
      updateTemplateProperty(jsonTemplate, 'PARASOLID_NAME', parasolidName);
      updateTemplateProperty(jsonTemplate, 'THREAD_HEIGHT', planImplant.threadHeight);

      updateTemplateProperty(
        jsonTemplate,
        'IMPLANT_SIZE',
        getImplantSize(levelSize, plusLevelSize),
      );

      const referencePoints = planImplant.referencePoints as NtopAlifReferencePoints;

      updateTemplateProperty(
        jsonTemplate,
        'POSITION_CENTROID',
        Object.values(referencePoints.positionCentroid),
      );

      updateTemplateProperty(jsonTemplate, 'POSITION_X', Object.values(referencePoints.positionX));

      updateTemplateProperty(jsonTemplate, 'POSITION_Y', Object.values(referencePoints.positionY));

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_CENTROID',
        Object.values(referencePoints.insertionBulletTopCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_X',
        Object.values(referencePoints.insertionBulletTopX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_Y',
        Object.values(referencePoints.insertionBulletTopY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_GRID',
        referencePoints.insertionBulletTopGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_TOP_ITERATIONS',
        referencePoints.insertionBulletTopIterations,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_CENTROID',
        Object.values(referencePoints.insertionBulletBottomCentroid),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_X',
        Object.values(referencePoints.insertionBulletBottomX),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_Y',
        Object.values(referencePoints.insertionBulletBottomY),
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_GRID',
        referencePoints.insertionBulletBottomGrid,
      );

      updateTemplateProperty(
        jsonTemplate,
        'INSERTION_BULLET_BOTTOM_ITERATIONS',
        referencePoints.insertionBulletBottomIterations,
      );

      break;
    }
  }

  return jsonTemplate;
};
