import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Card, CardContent, Typography } from '@mui/material';
import {
  ActivityType,
  AssetType,
  CaseStageType,
  IAsset,
  ICase,
  IPlan,
  Permission,
  PlanStatusType,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { pdf } from '@workflow-nx/utils';
import * as FileSaver from 'file-saver';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Dispatch, useReducer, useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import { AssetGridTable } from '../../../components/AssetGridTable/AssetGridTable';
import { ImplantSizeExclusionAlert } from '../../../components/ImplantSizeExclusionAlert';
import config from '../../../extras/config';
import { APPROVE_FORM_19, FIND_FORM19_BY_CASE_ID, FIND_PLAN_BY_STATUS } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { ActivityTable } from './ActivityTable';
import { Form19FillDialog } from './CaseDesignTab/Form19FillDialog';
import { CaseExpiryDateAlert } from './CaseExpiryDateAlert';
import { CaseViewActionType } from './CaseView';
import { Form19RejectionDialog } from './Form19RejectionDialog';
import { TabLoadingView } from './TabLoadingView';
import { getGraphQLErrorMessages } from '../../../utils/error';

type ActionType = {
  type: string;
  data: any;
};

type StateType = {
  plan?: IPlan;
  planAssets?: IAsset[];
  isStageComplete: boolean;
  canMoveToProduction: boolean;
  isForm19Approved: boolean;
  form19Data: any;
};

export function CaseQaReviewTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const auth = useAuth();
  const [openForm19RejectionDialog, setOpenForm19RejectionDialog] = useState(false);
  const [approveForm19, { loading: loadingApproveForm19 }] = useMutation(APPROVE_FORM_19);
  const { enqueueSnackbar } = useSnackbar();

  const { loading: loadingPlanByStatus } = useQuery(FIND_PLAN_BY_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      status: [PlanStatusType.Proposed, PlanStatusType.Approved],
    },
    onCompleted: (data) => {
      qaReviewTabDispatch({
        type: 'INIT',
        data: data.planByStatus,
      });
    },
  });

  const { refetch: refetchForm19, loading: loadingForm19 } = useQuery(FIND_FORM19_BY_CASE_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      deletedAt: null,
    },
    onCompleted: (data) => {
      const form19 = data.form19;
      qaReviewTabDispatch({
        type: 'FORM19_READY',
        data: form19,
      });
    },
  });

  const confirm = useConfirm();
  const [openForm19FillDialog, setOpenForm19FillDialog] = useState(false);

  const handleApproveForm19Click = async () => {
    try {
      await confirm({
        title: 'Approve Form 19',
        description:
          'Are you sure you want to approve the Form 19? Please confirm that this Form has been routed in Greenlight Guru before continuing.',
      });

      await approveForm19({ variables: { caseId: props.activeCase.caseId } });

      enqueueSnackbar(`The Form 19 for case ${props.activeCase.number} has been approved`, {
        variant: 'success',
      });

      props.dispatch({ type: 'refetch' });
    } catch (error) {
      const message = getGraphQLErrorMessages(
        error as Error,
        `An error occurred approving the Form 19`,
      );

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  const handleDownloadForm19 = async () => {
    try {
      const form19Pdf = await pdf.form19(state.form19Data?.data);
      FileSaver.saveAs(
        form19Pdf,
        `${props.activeCase?.number} FORM-019 Patient Specific Workflow Approval.pdf`,
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred downloading the FORM-019 pdf file', {
        variant: 'error',
      });
    }
  };

  const initialState: StateType = {
    canMoveToProduction: false,
    isStageComplete: false,
    plan: undefined,
    planAssets: undefined,
    isForm19Approved: false,
    form19Data: undefined,
  };

  const qaReviewReducer = (state: StateType, action: ActionType): StateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_DELETED': {
        const deletedAsset = action.data;
        let updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));

        updatedPlanAssets = updatedPlanAssets.filter(
          (ca: IAsset) => ca.assetId !== deletedAsset.assetId,
        );

        updatedState = {
          canMoveToProduction: caseUtils.isCaseStageQaReviewComplete(updatedPlanAssets),
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: updatedPlanAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };
        break;
      }
      case 'ASSET_UPLOADED': {
        const updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));
        const createdAsset = action.data;

        updatedPlanAssets.push(createdAsset);
        updatedState = {
          canMoveToProduction: caseUtils.isCaseStageQaReviewComplete(updatedPlanAssets),
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: updatedPlanAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };

        break;
      }
      case 'FORM19_READY': {
        const form19Data = action.data;
        const isForm19Approved =
          !!form19Data?.engineeringApprovedAt && !!form19Data?.qualityApprovedAt;

        updatedState = {
          canMoveToProduction: caseUtils.isCaseStageQaReviewComplete(state.planAssets),
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: state.planAssets,
          isForm19Approved,
          form19Data,
        };
        break;
      }
      case 'INIT': {
        const plan = action?.data;
        const planAssets = action?.data?.assets ?? [];

        updatedState = {
          canMoveToProduction: caseUtils.isCaseStageQaReviewComplete(planAssets),
          isStageComplete:
            props.activeCase.stage !== CaseStageType.Open &&
            props.activeCase.stage !== CaseStageType.Segmentation &&
            props.activeCase.stage !== CaseStageType.Planning &&
            props.activeCase.stage !== CaseStageType.Proposed &&
            props.activeCase.stage !== CaseStageType.Design &&
            props.activeCase.stage !== CaseStageType.QaReview,
          plan,
          planAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };
        break;
      }
    }
    return updatedState;
  };
  const [state, qaReviewTabDispatch] = useReducer(qaReviewReducer, initialState);
  const canApproveForm19 = auth?.hasPermission?.([Permission.QaReviewCase]);

  const validAssets = config.featureFlags.designRequireTem013
    ? [AssetType.Tem013, AssetType.Form19ApprovalSignature, AssetType.Form19]
    : [AssetType.Form18, AssetType.Form19ApprovalSignature, AssetType.Form19];

  const shouldDisableBasedOnExpiryDate = caseUtils.isCaseExpiryDateApproachingOrAlreadyExpired(
    props?.activeCase?.studyDate?.expiryDate,
    0,
  );
  return (
    <>
      {loadingPlanByStatus || loadingForm19 ? (
        <TabLoadingView />
      ) : (
        <Card>
          <CardContent>
            {!state.isStageComplete ? (
              <CaseExpiryDateAlert expiryDate={props.activeCase.studyDate?.expiryDate} />
            ) : null}
            <Box my={2}>
              <Typography variant={'h4'}>
                <strong>Step 1: Perform QA Review</strong>
              </Typography>
            </Box>
            <ImplantSizeExclusionAlert
              activeCase={props.activeCase}
              currentStage={CaseStageType.QaReview}
              planId={state?.plan?.planId}
            />
            {state.plan && state.planAssets && canApproveForm19 ? (
              <>
                {state.form19Data?.engineeringApprovedAt ? (
                  <Box mb={2}>
                    <Alert severity={'success'}>
                      {format.formatName(state.form19Data?.engineeringApprovedByUser)} has given
                      engineering approval for the FORM-019 on{' '}
                      {format.formatDate(state.form19Data?.engineeringApprovedAt)}
                    </Alert>
                  </Box>
                ) : !state.canMoveToProduction ? (
                  <Box mb={2}>
                    <Alert severity={'warning'}>
                      This FORM-019 has not been approved by engineering yet
                    </Alert>
                  </Box>
                ) : null}
                {state.form19Data?.qualityApprovedAt ? (
                  <Box mb={2}>
                    <Alert severity={'success'}>
                      {format.formatName(state.form19Data?.qualityApprovedByUser)} has given quality
                      approval for the FORM-019 on{' '}
                      {format.formatDate(state.form19Data?.qualityApprovedAt)}
                    </Alert>
                  </Box>
                ) : null}
                {!state.isStageComplete ? (
                  <Box display={'flex'}>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => setOpenForm19FillDialog(true)}
                      disabled={
                        !canApproveForm19 ||
                        !state.form19Data?.engineeringApprovedAt ||
                        !!props?.activeCase?.caseCancellation
                      }
                    >
                      Verify FORM-019
                    </Button>
                    <Box mx={1} />
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => handleDownloadForm19()}
                      disabled={!canApproveForm19 || !state.isForm19Approved}
                    >
                      Download FORM-019
                    </Button>
                  </Box>
                ) : null}
                {canApproveForm19 &&
                [CaseStageType.Production, CaseStageType.Ready].includes(
                  props?.activeCase?.stage,
                ) ? (
                  <Box display={'flex'}>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => setOpenForm19FillDialog(true)}
                      disabled={
                        !canApproveForm19 ||
                        !state.form19Data?.engineeringApprovedAt ||
                        !!props?.activeCase?.caseCancellation
                      }
                    >
                      Regenerate FORM-019
                    </Button>
                    <Box mx={1} />
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => handleDownloadForm19()}
                      disabled={!canApproveForm19 || !state.isForm19Approved}
                    >
                      Download FORM-019
                    </Button>
                  </Box>
                ) : null}
              </>
            ) : null}
            <Box mb={2}>
              <AssetGridTable
                dispatch={qaReviewTabDispatch}
                assets={state.planAssets || []}
                caseId={props.activeCase.caseId}
                planId={state.plan?.planId}
                readOnly={
                  !canApproveForm19 ||
                  (state.isStageComplete && props?.activeCase?.stage !== CaseStageType.Production)
                }
                showDownloadAll={true}
                onZipComplete={(zipFile) =>
                  FileSaver.saveAs(zipFile, `${props.activeCase.number}-qa-review-assets.zip`)
                }
                validAssets={validAssets}
              />
            </Box>

            <ActivityTable
              label={'Form 19 Activity'}
              caseId={props.activeCase.caseId}
              activityTypes={[
                ActivityType.CaseStageDesignComplete,
                ActivityType.Form19UpdateStatusApproved,
                ActivityType.Form19UpdateStatusRejected,
              ]}
            />

            {!state.isStageComplete ? (
              <Box display={'flex'} my={2} justifyContent={'center'}>
                <ActionButton
                  disabled={!canApproveForm19 || !!props?.activeCase?.caseCancellation}
                  variant={'outlined'}
                  onClick={() => setOpenForm19RejectionDialog(true)}
                >
                  Reject Form 19
                </ActionButton>
                <Box mx={1} />
                <ActionButton
                  disabled={
                    !canApproveForm19 ||
                    !state.canMoveToProduction ||
                    !!props?.activeCase?.caseCancellation ||
                    shouldDisableBasedOnExpiryDate
                  }
                  variant={'outlined'}
                  onClick={handleApproveForm19Click}
                  loading={loadingApproveForm19 || loadingForm19}
                >
                  Approve Form 19
                </ActionButton>
              </Box>
            ) : null}
          </CardContent>
        </Card>
      )}
      <Form19RejectionDialog
        open={openForm19RejectionDialog}
        activeCase={props.activeCase}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }
          setOpenForm19RejectionDialog(false);
        }}
      />
      {state.plan ? (
        <Form19FillDialog
          activeCase={props.activeCase}
          plan={state.plan}
          open={openForm19FillDialog}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetchForm19();
            }
            setOpenForm19FillDialog(false);
          }}
        />
      ) : null}
    </>
  );
}
