// BabylonSceneManager.ts
import {
  AssetPositions,
  AssetPositionsPoints,
  AssetPositionsVertebralBody,
  AssetType,
  Axis,
  CaseSpineProfile,
  caseUtils,
  EndPlate,
  getAssetVertebralBody,
  IMeasure,
  Position,
  SPINE_PROFILE_CONFIG_MAP,
  VertebralBody,
} from '@workflow-nx/common';
import {
  IAngleOriginVectorData,
  IAngleVectorData,
  ILineVectorData,
  IPlumbLineDistanceVectorData,
  projectPointOntoPlane,
  projectVectorOntoPlane,
} from '@workflow-nx/core-algorithms';
import * as BABYLON from 'babylonjs';
import { Angle } from 'babylonjs';
import { CustomMaterial } from 'babylonjs-materials';

import { calculateMidpoint } from '@workflow-nx/math';
import { AdvancedDynamicTexture, Rectangle, TextBlock } from 'babylonjs-gui';
import * as BabylonLoaders from 'babylonjs-loaders';
import { STLExport } from 'babylonjs-serializers';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { PlanAssetColorType, VertebraePositionColor } from '../enums';
import { add, divide, getMidpoint, multiply } from '../utils';
import { OrientationControl } from './OrientationControl';

export type OperationEntry = Operation & {
  operationId: string;
};

type MeshTransformation = {
  rotationQuaternion: BABYLON.Quaternion;
  position: BABYLON.Vector3;
};

export type BabylonSceneManagerOptions = {
  perspectiveCamera?: boolean;
  debug?: boolean;
};

export type PointerHandlerEventType = 'dblclick' | 'click';

export type PointerHandler = (point: Vector3 | null, mesh: MeshIdentification | null) => void;

export type MeshIdentification = {
  meshName: string;
  tags?: string[];
};

export type Operation = {
  mesh: MeshIdentification;
  type: 'TRANSLATE' | 'ROTATE';
  axis: Axis;
  value: number; // Degrees are used for rotation
};

export type Plane = {
  origin: Vector3;
  normal: Vector3;
};

export type Vector3 = {
  x: number;
  y: number;
  z: number;
};

export type Vector4 = {
  x: number;
  y: number;
  z: number;
  w: number;
};

export type MeshData = {
  parent: MeshIdentification | null;
  editedMesh: MeshIdentification | null;
  mesh: MeshIdentification;
  annotationType: AnnotationType | null;
  absolutePosition: Vector3;
  position: Vector3;
  opacity: number;
  isVisible: boolean;
  eulerAngles: Vector3;
  absoluteEulerAngles: Vector3;
  quaternion: Vector4;
  absoluteQuaternion: Vector4;
};

export type MeshConfig = {
  opacity?: number;
  color?: string;
  highlight?: boolean;
  disabled?: boolean;
  parent?: {
    meshName: string;
    tags?: string[];
  };
  isPickable?: boolean;
  showBoundingBox?: boolean;
  isVisible?: boolean;
  isEnabled?: boolean;
  excludeAnnotations?: boolean;
};

export type MeshUpdatePayload = Omit<MeshConfig, 'tags' | 'parentName'>;

export type AnnotationMeshUpdatePayload = Pick<MeshConfig, 'opacity'>;

export type AnnotationType = 'sphere' | 'billboard' | 'angle' | 'octahedron' | 'line';

export type AnnotationConfig = {
  tags?: string[];
} & (
  | {
      type: 'billboard';
      origin: Vector3;
      lineLength: number;
      size: number;
      opacity: number;
      hexColor: string;
      text: string;
    }
  | {
      type: 'sphere';
      position: Vector3;
      size: number;
      opacity: number;
      hexColor: string;
    }
  | {
      type: 'line';
      points: Vector3[];
      diameter: number;
      opacity: number;
      hexColor: string;
    }
  | {
      type: 'octahedron';
      position: Vector3;
      size: number;
      opacity: number;
      hexColor: string;
    }
  | {
      type: 'angle';
      position: Vector3;
      opacity: number;
      hexColor: string;
      origin: Vector3;
      direction: Vector3;
      angleDegrees: number;
      lineLength: number;
    }
);

export type LoadedAssetConfig = {
  assetUrl: string;
  mesh: MeshIdentification;
  meshConfig?: MeshConfig;
  position?: Vector3;
  rotation?: Vector3;
};

export type LandmarkConfig = {
  meshId: MeshIdentification;
  point: Vector3;
  endPlate: EndPlate;
  vertebralBody: VertebralBody;
  landmarkPosition: Position;
};

export type LoadedSpineConfig = {
  assetType: AssetType;
  opacity?: number;
  assetUrl: string;
  tag: string;
  otherTags?: string[];
  editedAssetUrl?: string;
};

export type BabylonCameraView =
  | 'AXIAL_TOP'
  | 'AXIAL_BOTTOM'
  | 'CORONAL'
  | 'SAGITTAL_LEFT'
  | 'SAGITTAL_RIGHT';

export enum CameraMode {
  Perspective,
  Orthographic,
}

export enum SpineAssetTags {
  PreOp = 'PREOP',
  Plan = 'PLAN',
  Daisy = 'DAISY',
  Vertebrae = 'VERTEBRAE',
  Implant = 'IMPLANT',
  Hardware = 'HARDWARE',
  Screws = 'SCREWS',
  PlanDiff = 'PLAN_DIFF',
}

export class CustomLoadingScreen implements BABYLON.ILoadingScreen {
  //optional, but needed due to interface definitions
  // @ts-ignore
  public displayLoadingUI: () => void;
  public hideLoadingUI: () => void;
  // @ts-ignore
  public loadingUIBackgroundColor: string;
  // @ts-ignore
  public loadingUIText: string;

  private textBlock: TextBlock;
  private background: Rectangle;

  constructor(private gui: AdvancedDynamicTexture) {
    this.displayLoadingUI = () => this.displayUIInternal();
    this.hideLoadingUI = () => this.hideLoadingUIInternal();

    this.background = new Rectangle('StateChangeLoadingScreen Rectangle');
    this.background.top = 0;
    this.background.left = 0;
    this.background.width = '100%';
    this.background.height = '100%';
    this.background.background = '#0d47a1';
    this.background.isVisible = false;
    gui.addControl(this.background);

    this.textBlock = new TextBlock('StateChangeLoadingScreen TextBlock', 'Loading...');
    this.textBlock.color = 'white';
    this.textBlock.isVisible = false;
    gui.addControl(this.textBlock);
  }

  private displayUIInternal(): void {
    this.textBlock.isVisible = true;
    this.background.isVisible = true;
  }

  private hideLoadingUIInternal(): void {
    this.textBlock.isVisible = false;
    this.background.isVisible = false;
  }
}

export class BabylonSceneManager {
  static FRAMES_PER_SECOND = 60;
  static SPEED_RATIO = 4;
  static CAMERA_ROTATION_SENSITIVITY = 75;
  static LOOP_MODE = false;
  static FROM_FRAME = 0;
  static TO_FRAME = 100;
  static MAIN_LIGHT_NAME = 'main-light';
  static MAIN_CAMERA_NAME = 'camera';
  static ALTERNATE_CAMERA_NAME = 'split-screen-camera';
  static CLIPPING_PLANE_NAME = 'clipping-plane';
  static CLIPPING_PLANE_LIGHT = 'clipping-plane-light';
  static CLIPPING_PLANE_SCALE_RATIO = 1.5;

  static PREOP_LAYER_MASK = 1;
  static PLAN_LAYER_MASK = 2;

  static INTERNAL_TAG_PREFIX = '_INTERNAL';
  static LANDMARK_TAG = `${this.INTERNAL_TAG_PREFIX}_LANDMARK`;
  static ORIGINAL_TAG = `${this.INTERNAL_TAG_PREFIX}_ORIGINAL`;
  static EDITED_TAG = `${this.INTERNAL_TAG_PREFIX}_EDITED`;
  static ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_ANNOTATION`;
  static SPHERE_ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_SPHERE_ANNOTATION`;
  static LINE_ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_SPHERE_ANNOTATION`;
  static ANGLE_ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_ANGLE_ANNOTATION`;
  static OCTAHEDRON_ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_OCTAHEDRON_ANNOTATION`;
  static BILLBOARD_ANNOTATION_TAG = `${this.INTERNAL_TAG_PREFIX}_BILLBOARD_ANNOTATION`;

  static LOADED_ASSET_TAG = `${this.INTERNAL_TAG_PREFIX}_LOADED_ASSET`;
  static AXIS_TAG = `${this.INTERNAL_TAG_PREFIX}_AXIS`;
  static MESH_NAME_TAGS_DELIMITER = '_TAGS_';

  private debug: boolean;
  private engine: BABYLON.Engine;
  private scene: BABYLON.Scene;
  private pointerObserver: BABYLON.Nullable<BABYLON.Observer<BABYLON.PointerInfo>>;
  private pointerHandlers: {
    eventType: PointerHandlerEventType;
    callback: PointerHandler;
  }[];
  private _operations: OperationEntry[];
  private originalTransformations: Map<string, MeshTransformation>;
  private originalMeshConfig: Map<string, MeshConfig>;
  private landmarkConfigMap: Map<string, LandmarkConfig>;
  private clippingPlaneConfig: BABYLON.Nullable<{
    plane: BABYLON.Plane;
    initialPosition: BABYLON.Vector3;
    scaleFactor: number;
  }>;

  private cameraTarget: MeshIdentification | null;

  private pointerPosCamRot: {
    x: number;
    y: number;
  } | null;

  private splitScreenEnabled: boolean;

  constructor(
    canvas?: HTMLCanvasElement,
    options?: BabylonSceneManagerOptions,
    scene?: BABYLON.Scene,
  ) {
    this.debug = options?.debug ?? false;

    if (scene) {
      this.engine = scene.getEngine();
      this.scene = scene;
    } else {
      if (canvas) {
        this.engine = new BABYLON.Engine(canvas, true, undefined, true);
      } else {
        this.engine = new BABYLON.NullEngine();
      }
      this.scene = new BABYLON.Scene(this.engine);

      // NOTE: For playwright testing, we need to get the scene for the canvas to get
      //       able to retrieve the meshes to validate them
      if (canvas) {
        (
          canvas as HTMLCanvasElement & {
            scene: BABYLON.Scene;
          }
        ).scene = this.scene;
      }
    }

    this.pointerObserver = this.scene.onPointerObservable.add((evt) => {
      this.handlePointerEvent(evt, this.scene);
    });

    this.pointerHandlers = [];

    this._operations = [];

    this.originalTransformations = new Map();

    this.originalMeshConfig = new Map();

    this.landmarkConfigMap = new Map();

    this.cameraTarget = null;

    this.clippingPlaneConfig = null;

    this.pointerPosCamRot = null;

    this.splitScreenEnabled = false;

    this.setupScene();
    this.setupCamera(
      options?.perspectiveCamera === true
        ? BABYLON.Camera.PERSPECTIVE_CAMERA
        : BABYLON.Camera.ORTHOGRAPHIC_CAMERA,
    );
    this.setupLights();
  }

  get operations() {
    return this._operations;
  }

  private setupScene(): void {
    if (!(this.engine instanceof BABYLON.NullEngine)) {
      if (this.engine.getRenderingCanvas()) {
        this.engine.loadingScreen = new CustomLoadingScreen(
          AdvancedDynamicTexture.CreateFullscreenUI('myUI'),
        );
      }
    }

    BABYLON.SceneLoader.RegisterPlugin(new BabylonLoaders.OBJFileLoader());
    BABYLON.SceneLoader.RegisterPlugin(new BabylonLoaders.STLFileLoader());

    if (!window) return;

    window.addEventListener('mousewheel', this.onMouseWheel.bind(this));

    window.addEventListener('resize', this.onResize.bind(this));
  }

  private convertPointerEventType(
    eventType: BABYLON.PointerEventTypes,
  ): PointerHandlerEventType | null {
    switch (eventType) {
      case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
        return 'dblclick';
      case BABYLON.PointerEventTypes.POINTERTAP:
        return 'click';
    }

    return null;
  }

  private handlePointerEvent(evt: BABYLON.PointerInfo, scene: BABYLON.Scene) {
    const camera = scene?.cameras?.[0] as BABYLON.ArcRotateCamera;

    const eventType = this.convertPointerEventType(evt.type);

    if (!camera || !eventType) return;

    if (camera.inertialAlphaOffset || camera.inertialBetaOffset) {
      return;
    }

    const ray = scene.createPickingRay(
      scene.pointerX,
      scene.pointerY,
      BABYLON.Matrix.Identity(),
      camera,
    );

    const hitPoint = scene.pickWithRay(ray);

    const hit = hitPoint?.pickedMesh ?? null;

    if (!hit?.isPickable) return;

    this.pointerHandlers.forEach((handler) => {
      if (handler.eventType !== eventType) return;

      handler.callback(
        hitPoint?.pickedPoint
          ? {
              x: hitPoint?.pickedPoint.x,
              y: hitPoint?.pickedPoint.y,
              z: hitPoint?.pickedPoint.z,
            }
          : null,
        this.deserializeMeshId(hit.id) ?? null,
      );
    });
  }

  private setupLights(): void {
    const camera = this.scene.activeCamera as BABYLON.Nullable<BABYLON.ArcRotateCamera>;

    if (!camera) return;

    // Add lights to the scene
    const light = new BABYLON.DirectionalLight(
      BabylonSceneManager.MAIN_LIGHT_NAME,
      new BABYLON.Vector3(0, 0, 0),
      this.scene,
    );

    // Synchronize the spotlight with the camera
    this.scene.registerBeforeRender(function () {
      light.position.copyFrom(camera.position);
      light.setDirectionToTarget(camera.getTarget());
    });
  }

  private getOrthoCameraProperties(radius: number) {
    const canvas = this.scene.getEngine().getRenderingCanvas();
    const distance = radius;
    if (!canvas) return null;
    const dimRatio = canvas.height / canvas.width;
    return {
      orthoLeft: -distance / (this.splitScreenEnabled ? 2 : 1),
      orthoRight: distance / (this.splitScreenEnabled ? 2 : 1),
      orthoBottom: -distance * dimRatio,
      orthoTop: distance * dimRatio,
    };
  }

  private onMouseWheel(event: any) {
    const mcamera = this.scene.activeCamera as BABYLON.Nullable<BABYLON.ArcRotateCamera>;
    if (!mcamera) return;
    let delta = 0;
    let bump = 0.1;

    if (event.wheelDelta !== undefined) {
      // WebKit / Opera / Explorer 9
      delta = event.wheelDelta;
    } else if (event.detail !== undefined) {
      // Firefox
      delta = -event.detail;
    }

    if (delta > 0) bump = -bump;

    if (mcamera.mode == BABYLON.Camera.ORTHOGRAPHIC_CAMERA) {
      const orthoProperties = this.getOrthoCameraProperties((mcamera.radius ?? 0) + bump);

      if (!orthoProperties) return;
      mcamera.orthoTop = orthoProperties.orthoTop;
      mcamera.orthoBottom = orthoProperties.orthoBottom;
      mcamera.orthoLeft = orthoProperties.orthoLeft;
      mcamera.orthoRight = orthoProperties.orthoRight;
    } else mcamera.radius += bump;
  }

  private setupCamera(cameraMode: BABYLON.Camera['mode']): void {
    // Add a camera to the scene
    const camera = new BABYLON.ArcRotateCamera(
      BabylonSceneManager.MAIN_CAMERA_NAME,
      -Math.PI / 2,
      Math.PI / 4,
      4,
      BABYLON.Vector3.Zero(),
      this.scene,
    );

    camera.lowerBetaLimit = -Number.MAX_VALUE;
    camera.upperBetaLimit = +Number.MAX_VALUE;

    camera.attachControl(this.engine.getRenderingCanvas(), true);

    camera.lowerRadiusLimit = 5;

    camera.mode = cameraMode;

    camera.panningInertia = 0;

    camera.panningSensibility = 5;
  }

  public changeCameraMode(cameraMode: CameraMode): void {
    const camera = this.scene.activeCamera as BABYLON.Nullable<BABYLON.ArcRotateCamera>;

    if (!camera) return;

    camera.mode =
      cameraMode === CameraMode.Perspective
        ? BABYLON.Camera.PERSPECTIVE_CAMERA
        : BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
  }

  private setOrthoCameraProperties() {
    const camera = this.scene.activeCamera;

    if (camera) {
      const orthoProperties = this.getOrthoCameraProperties(
        (this.scene.activeCamera as BABYLON.ArcRotateCamera).radius,
      );

      if (camera.orthoLeft && camera.orthoRight && camera.orthoTop && camera.orthoBottom) {
        camera.orthoLeft = orthoProperties?.orthoLeft ?? null;
        camera.orthoRight = orthoProperties?.orthoRight ?? null;
        camera.orthoTop = orthoProperties?.orthoTop ?? null;
        camera.orthoBottom = orthoProperties?.orthoBottom ?? null;
      }
    }
  }

  private onResize() {
    this.scene.getEngine().resize();

    this.setOrthoCameraProperties();
  }

  private createAnimation({ property, from, to }: any) {
    const ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

    const animation = BABYLON.Animation.CreateAnimation(
      property,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BabylonSceneManager.FRAMES_PER_SECOND,
      ease,
    );
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  }

  private convertPublicVector3(v: Vector3): BABYLON.Vector3 {
    return new BABYLON.Vector3(v.x, v.y, v.z);
  }

  private convertPublicVector4(v: Vector4): BABYLON.Quaternion {
    return new BABYLON.Quaternion(v.x, v.y, v.z, v.w);
  }

  private convertBabylonVector3(v: BABYLON.Vector3): Vector3 {
    return {
      x: v.x,
      y: v.y,
      z: v.z,
    };
  }

  private convertBabylonVector4(v: BABYLON.Quaternion): Vector4 {
    return {
      x: v.x,
      y: v.y,
      z: v.z,
      w: v.w,
    };
  }

  private getSceneCameras(): BABYLON.ArcRotateCamera[] {
    const scene = this.scene;

    const cameras: BABYLON.ArcRotateCamera[] = [];

    if (scene.activeCameras?.length)
      cameras.push(...(scene.activeCameras as BABYLON.ArcRotateCamera[]));
    else if (scene.activeCamera) cameras.push(scene.activeCamera as BABYLON.ArcRotateCamera);

    return cameras;
  }
  private async rotateCamera(
    alphaDegrees: number,
    betaDegrees: number,
    distance: number,
    disableAnimation: boolean = false,
  ): Promise<void> {
    const scene = this.scene;

    // set up initial camera view based on a typical patient scan
    const alpha = BABYLON.Angle.FromDegrees(alphaDegrees).radians();
    const beta = BABYLON.Angle.FromDegrees(betaDegrees).radians();
    const cameras: BABYLON.ArcRotateCamera[] = this.getSceneCameras();

    const _rotateCamera = async (camera: BABYLON.ArcRotateCamera): Promise<void> => {
      if (!camera) return new Promise((resolve) => resolve());

      const orthoProperties = this.getOrthoCameraProperties(distance);

      const isOrtho = camera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

      if (isOrtho && !orthoProperties) return new Promise((resolve) => resolve());

      const currentTarget = camera.target;

      if (disableAnimation) {
        camera.radius = distance;

        camera.beta = beta;

        camera.alpha = alpha;

        camera.target.x = currentTarget.x;

        camera.target.y = currentTarget.y;

        camera.target.z = currentTarget.z;

        if (isOrtho) {
          camera.orthoBottom = orthoProperties?.orthoBottom ?? null;
          camera.orthoTop = orthoProperties?.orthoTop ?? null;
          camera.orthoLeft = orthoProperties?.orthoLeft ?? null;
          camera.orthoRight = orthoProperties?.orthoRight ?? null;
        }

        return new Promise((resolve) => resolve());
      }

      camera.animations = [
        ...(isOrtho
          ? [
              this.createAnimation({
                property: 'orthoBottom',
                from: camera.orthoBottom,
                to: orthoProperties?.orthoBottom,
              }),
              this.createAnimation({
                property: 'orthoTop',
                from: camera.orthoTop,
                to: orthoProperties?.orthoTop,
              }),
              this.createAnimation({
                property: 'orthoRight',
                from: camera.orthoRight,
                to: orthoProperties?.orthoRight,
              }),
              this.createAnimation({
                property: 'orthoLeft',
                from: camera.orthoLeft,
                to: orthoProperties?.orthoLeft,
              }),
            ]
          : []),
        this.createAnimation({
          property: 'radius',
          from: camera.radius,
          to: distance,
        }),
        this.createAnimation({
          property: 'beta',
          from: camera.beta,
          to: beta,
        }),
        this.createAnimation({
          property: 'alpha',
          from: camera.alpha,
          to: alpha,
        }),
        this.createAnimation({
          property: 'target.x',
          from: camera.target.x,
          to: currentTarget.x,
        }),
        this.createAnimation({
          property: 'target.y',
          from: camera.target.y,
          to: currentTarget.y,
        }),
        this.createAnimation({
          property: 'target.z',
          from: camera.target.z,
          to: currentTarget.z,
        }),
      ];

      camera.upVector = BABYLON.Axis.Y.clone();

      camera.rebuildAnglesAndRadius();

      return new Promise((resolve) => {
        scene.beginAnimation(
          camera,
          BabylonSceneManager.FROM_FRAME,
          BabylonSceneManager.TO_FRAME,
          BabylonSceneManager.LOOP_MODE,
          BabylonSceneManager.SPEED_RATIO,
          () => {
            resolve();
          },
        );
      });
    };

    await Promise.all(cameras.map((c) => _rotateCamera(c)));

    return;
  }

  private getLoadedMeshTags(id: MeshIdentification) {
    return [id.meshName, ...(id.tags ?? []), BabylonSceneManager.LOADED_ASSET_TAG];
  }

  private getMeshByIdentification(id: MeshIdentification) {
    const tags = this.getLoadedMeshTags(id);

    const matches = this.scene.getMeshesByTags(tags.join(' && ')).filter((m) => {
      const meshId = this.getMeshIdentification(m);

      return meshId.tags?.every((t) => tags.includes(t));
    });

    return matches?.[0] as BABYLON.Mesh | undefined;
  }

  private applyOperationToMesh(operation: Operation): MeshTransformation | null {
    const mesh = this.getMeshByIdentification(operation.mesh);

    if (!mesh) return null;

    let axis = BABYLON.Axis.Y;

    switch (operation.axis) {
      case 'X':
        axis = BABYLON.Axis.X;
        break;
      case 'Y':
        axis = BABYLON.Axis.Z;
        break;
      case 'Z':
        axis = BABYLON.Axis.Y;
        break;
    }

    /**
     * Y and Z axes are swapped due to CT orientation
     */
    switch (operation.type) {
      case 'TRANSLATE':
        mesh.translate(axis, operation.value);

        break;
      case 'ROTATE':
        const currentRotationQuaternion =
          mesh.rotationQuaternion?.clone() ?? BABYLON.Quaternion.Identity();

        // Define the rotation in radians (one degree in radians)
        const rotationIncrement = BABYLON.Tools.ToRadians(operation.value); // Convert degrees to radians

        // Create a quaternion representing the desired rotation increment
        const rotationIncrementQuaternion = BABYLON.Quaternion.RotationAxis(
          axis,
          rotationIncrement,
        );

        // Combine the current rotation quaternion with the rotation increment quaternion
        const newRotationQuaternion = currentRotationQuaternion.multiply(
          rotationIncrementQuaternion,
        );

        mesh.rotationQuaternion = newRotationQuaternion;

        break;
    }

    return null;
  }

  private applyTransformation(mesh: BABYLON.Mesh, transformation: MeshTransformation) {
    mesh.rotationQuaternion = transformation.rotationQuaternion.clone();

    mesh.position = transformation.position.clone();
  }

  public localTranslate(meshId: MeshIdentification, v: Vector3) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    if (!BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.LOADED_ASSET_TAG)) return;

    let offset = this.convertPublicVector3(v);

    mesh.position.x += offset.x;
    mesh.position.y += offset.y;
    mesh.position.z += offset.z;

    mesh.computeWorldMatrix(true);
  }

  public localRotateAroundCenter(meshId: MeshIdentification, axis: Vector3, degrees: number) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    if (!BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.LOADED_ASSET_TAG)) return;

    // Apply rotation to the mesh

    const currentRotationQuaternion =
      mesh.rotationQuaternion?.clone() ?? BABYLON.Quaternion.Identity();

    const axisVector = this.convertPublicVector3(axis);

    const radians = BABYLON.Tools.ToRadians(degrees);

    // Create a quaternion representing the desired rotation increment
    const rotationIncrementQuaternion = BABYLON.Quaternion.RotationAxis(axisVector, radians);

    // Combine the current rotation quaternion with the rotation increment quaternion
    mesh.rotationQuaternion = currentRotationQuaternion.multiply(rotationIncrementQuaternion);
  }

  public localRotateByQuat(meshId: MeshIdentification, quat: Vector4) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    if (!BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.LOADED_ASSET_TAG)) return;

    // Apply rotation to the mesh

    const currentRotationQuaternion =
      mesh.rotationQuaternion?.clone() ?? BABYLON.Quaternion.Identity();

    // Combine the current rotation quaternion with the rotation increment quaternion
    mesh.rotationQuaternion = currentRotationQuaternion.multiply(this.convertPublicVector4(quat));
  }

  public getWorldCoordinates(meshId: MeshIdentification, v: Vector3) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    mesh.computeWorldMatrix(true);

    if (!BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.LOADED_ASSET_TAG)) return;

    let worldCoordinate = BABYLON.Vector3.TransformCoordinates(
      this.convertPublicVector3(v),
      mesh.getWorldMatrix(),
    );

    return this.convertBabylonVector3(worldCoordinate);
  }

  public calculateQuaternion(from: Vector3, to: Vector3): Vector4 {
    const resQ = BABYLON.Quaternion.Zero();

    BABYLON.Quaternion.FromUnitVectorsToRef(
      this.convertPublicVector3(from).normalizeToNew(),
      this.convertPublicVector3(to).normalizeToNew(),
      resQ,
    );

    return this.convertBabylonVector4(resQ);
  }

  private _calculatePlaneNormal(v1: Vector3, v2: Vector3, v3: Vector3): Vector3 {
    const planeNormal = BABYLON.Plane.FromPoints(
      this.convertPublicVector3(v1),
      this.convertPublicVector3(v2),
      this.convertPublicVector3(v3),
    ).normal;

    return this.convertBabylonVector3(planeNormal);
  }

  public calculatePlaneNormal(v1: Vector3, v2: Vector3, v3: Vector3, v4: Vector3): Vector3 {
    let p1 = this._calculatePlaneNormal(v1, v2, v3);

    let p2 = this._calculatePlaneNormal(v1, v2, v4);

    let p3 = this._calculatePlaneNormal(v2, v3, v4);

    let p4 = this._calculatePlaneNormal(v1, v3, v4);

    if (p1.y < 0) p1 = multiply(p1, -1);
    if (p2.y < 0) p2 = multiply(p2, -1);
    if (p3.y < 0) p3 = multiply(p3, -1);
    if (p4.y < 0) p4 = multiply(p4, -1);

    const p12 = add(p1, p2);
    const p34 = add(p3, p4);

    return divide(add(p12, p34), 4);
  }

  public projectPointOntoPlane(point: Vector3, plane: Plane) {
    const babylonPlane = BABYLON.Plane.FromPositionAndNormal(
      this.convertPublicVector3(plane.origin),
      this.convertPublicVector3(plane.normal),
    );

    const babylonPoint = this.convertPublicVector3(point);

    const proj = projectPointOntoPlane(babylonPoint, babylonPlane);

    return this.convertBabylonVector3(proj);
  }

  public calculatePlane(v1: Vector3, v2: Vector3, v3: Vector3, v4: Vector3): Plane {
    const normal = this.calculatePlaneNormal(v1, v2, v3, v4);

    const v12 = getMidpoint(v1, v2);

    const v34 = getMidpoint(v3, v4);

    const origin = getMidpoint(v12, v34);

    return {
      origin,
      normal,
    };
  }

  private getMeshTransformation(mesh: BABYLON.Mesh): MeshTransformation {
    mesh.computeWorldMatrix(true);

    return {
      rotationQuaternion: mesh.absoluteRotationQuaternion.clone(),
      position: mesh.position.clone(),
    };
  }

  private performTransformationTransaction(transform: () => void) {
    this.scene.meshes.forEach((mesh) => {
      mesh.freezeWorldMatrix();
    });

    transform();

    this.scene.meshes.forEach((mesh) => {
      mesh.unfreezeWorldMatrix();
    });
  }

  private createLine(
    start: BABYLON.Vector3,
    end: BABYLON.Vector3,
    lineName: string,
    lineColor: string = '#ffffff',
    tags: string[] = [],
  ) {
    const points = [start, end];

    // Set line color
    const material = new BABYLON.StandardMaterial(lineName + '_MATERIAL', this.scene);

    let lineColorObj = BABYLON.Color3.FromHexString(lineColor);

    material.diffuseColor = lineColorObj;
    material.ambientColor = lineColorObj;
    material.specularColor = lineColorObj;
    material.emissiveColor = lineColorObj;

    const line = BABYLON.MeshBuilder.CreateLines(
      lineName,
      {
        points,
        material,
        updatable: true,
      },
      this.scene,
    );

    this.addTags(line, tags);

    return line;
  }

  private addTags(mesh: BABYLON.Mesh, tags: string[]) {
    BABYLON.Tags.AddTagsTo(mesh, tags.join(' '));

    if (tags.includes(SpineAssetTags.PreOp)) {
      mesh.layerMask = BabylonSceneManager.PREOP_LAYER_MASK;
    } else if (tags.includes(SpineAssetTags.Plan)) {
      mesh.layerMask = BabylonSceneManager.PLAN_LAYER_MASK;
    }
  }

  private createPipe(
    points: BABYLON.Vector3[],
    lineName: string,
    radius: number = 0.5,
    lineColor: string = '#ffffff',
    tags: string[] = [],
  ) {
    const material = new BABYLON.StandardMaterial(lineName + '_MATERIAL', this.scene);

    let lineColorObj = BABYLON.Color3.FromHexString(lineColor);

    material.diffuseColor = lineColorObj;
    material.ambientColor = lineColorObj;
    material.specularColor = BABYLON.Color3.Black();
    material.emissiveColor = BABYLON.Color3.Black();

    const lineMesh = BABYLON.MeshBuilder.CreateTube(
      lineName,
      {
        path: points,
        radius,
        tessellation: 8,
        updatable: false,
        cap: BABYLON.Mesh.CAP_ALL,
      },
      this.scene,
    );

    lineMesh.material = material;

    this.addTags(lineMesh, tags);

    return lineMesh;
  }

  private createBillboardLabel(
    position: BABYLON.Vector3,
    direction: BABYLON.Vector3,
    meshName: string,
    tags: string[],
    length: number,
    text: string,
    size: number = 5,
    widthScale: number = 3,
    lineColor: string = '#ffffff',
    billboardColor: string = '#000080',
    textColor: string = '#000000',
  ): BABYLON.Mesh | null {
    // Calculate the end position based on the given length
    const endPosition = position.add(direction.scale(length));

    const lineName = meshName;

    const billboardName = lineName + '_BILLBOARD_CHILD';

    // Set line color
    const line = this.createLine(position, endPosition, lineName, lineColor, tags);

    // Create the billboard plane with text
    const billboard = this.createBillboard(
      billboardName,
      endPosition,
      billboardColor,
      textColor,
      text,
      size,
      widthScale,
      tags,
    );

    // Attach the billboard to the end of the line
    billboard.parent = line;

    return line;
  }

  private createBillboard(
    meshName: string,
    position: BABYLON.Vector3,
    color: string,
    textColor: string,
    text: string,
    size: number,
    scale: number = 3,
    tags: string[] = [],
  ): BABYLON.Mesh {
    const ratio = 1 / scale;

    // Create a plane panel
    const billboard = BABYLON.MeshBuilder.CreatePlane(
      meshName,
      {
        width: size * scale,
        height: size,
      },
      this.scene,
    );

    billboard.setAbsolutePosition(position);

    const textureWidth = size * 30;

    // Create a dynamic texture for rendering text
    const dynamicTexture = new BABYLON.DynamicTexture(
      `${meshName}_TEXTURE`,
      {
        width: textureWidth,
        height: textureWidth * ratio,
      },
      this.scene,
      true,
    );

    const textureContext = dynamicTexture.getContext();

    // Set the background color of the dynamic texture
    textureContext.fillStyle = color;
    textureContext.fillRect(0, 0, dynamicTexture.getSize().width, dynamicTexture.getSize().height);

    // Set the font and text properties
    const fontSize = dynamicTexture.getSize().height / 2;
    textureContext.font = `${fontSize}px Arial`;
    textureContext.fillStyle = textColor;

    // Calculate the position to center the text on the dynamic texture
    const textWidth = textureContext.measureText(text).width;
    const xPosition = (dynamicTexture.getSize().width - textWidth) / 2;
    const yPosition = (dynamicTexture.getSize().height + fontSize) / 2;

    // Draw the text on the dynamic texture
    textureContext.fillText(text, xPosition, yPosition);

    // Update the dynamic texture
    dynamicTexture.update();

    // Create a material for the billboard
    const material = new BABYLON.StandardMaterial(meshName + '_MATERIAL', this.scene);
    material.diffuseTexture = dynamicTexture;
    material.specularColor = BABYLON.Color3.Black();
    material.useAlphaFromDiffuseTexture = true;

    // Apply the material to the billboard
    billboard.material = material;

    // Make the billboard always face the camera
    billboard.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

    this.addTags(billboard, tags);

    return billboard;
  }

  private createSphere(
    meshName: string,
    tags: string[] = [],
    position: BABYLON.Vector3,
    diameter: number,
    color: string,
  ): BABYLON.Mesh | null {
    // Create a sphere with MeshBuilder
    const sphere = BABYLON.MeshBuilder.CreateSphere(meshName, { diameter }, this.scene);

    this.addTags(sphere, tags);

    // Set the position of the sphere
    sphere.position = position;

    // Create a material for the sphere
    const material = new BABYLON.StandardMaterial(meshName + '_MATERIAL', this.scene);

    const sphereColor = BABYLON.Color3.FromHexString(color);
    // Set the diffuse color of the material based on the provided color
    material.diffuseColor = sphereColor;
    material.ambientColor = sphereColor;
    material.specularColor = BABYLON.Color3.Black();
    material.emissiveColor = BABYLON.Color3.Black();
    // Apply the material to the sphere
    sphere.material = material;

    return sphere;
  }

  private lightenColor(hexColor: string, opacity: number): string {
    // Parse the hex color string to Color3
    const baseColor = BABYLON.Color3.FromHexString(hexColor);

    // Lighten the color by reducing its intensity
    const lightenedColor = baseColor
      .scale(1 - opacity)
      .add(new BABYLON.Color3(opacity, opacity, opacity));

    // Convert the Color3 back to hex string
    return lightenedColor.toHexString();
  }

  private getAnnotationTags(annotationType: AnnotationType): string[] {
    switch (annotationType) {
      case 'line':
        return [BabylonSceneManager.LINE_ANNOTATION_TAG, BabylonSceneManager.ANNOTATION_TAG];
      case 'sphere':
        return [BabylonSceneManager.SPHERE_ANNOTATION_TAG, BabylonSceneManager.ANNOTATION_TAG];
      case 'billboard':
        return [BabylonSceneManager.BILLBOARD_ANNOTATION_TAG, BabylonSceneManager.ANNOTATION_TAG];
      case 'angle':
        return [BabylonSceneManager.ANGLE_ANNOTATION_TAG, BabylonSceneManager.ANNOTATION_TAG];
      case 'octahedron':
        return [BabylonSceneManager.OCTAHEDRON_ANNOTATION_TAG, BabylonSceneManager.ANNOTATION_TAG];
    }
  }

  private visualizeAngle(
    meshName: string,
    tags: string[],
    origin: BABYLON.Vector3,
    direction: BABYLON.Vector3,
    angleDegrees: number,
    lineLength: number,
    arcRadius: number,
    arcSegments: number,
    color: string = '#ffffff',
  ): BABYLON.Mesh {
    const angleMesh = new BABYLON.Mesh(meshName, this.scene);

    this.addTags(angleMesh, tags);

    // Normalize the direction vector
    const normalizedDirection = direction.normalize();

    // Create the first line starting at the origin
    const lineA = BABYLON.MeshBuilder.CreateLines(
      meshName + '_LINE_A_CHILD',
      {
        points: [origin, origin.add(normalizedDirection.scale(lineLength))],
      },
      this.scene,
    );
    lineA.color = BABYLON.Color3.FromHexString(color);
    lineA.parent = angleMesh;

    // Calculate the second line direction based on the specified angle
    const angleRadians = BABYLON.Tools.ToRadians(angleDegrees);
    const rotationAxis = BABYLON.Vector3.Cross(normalizedDirection, BABYLON.Axis.Y);
    const rotatedDirection = BABYLON.Vector3.TransformCoordinates(
      normalizedDirection,
      BABYLON.Matrix.RotationAxis(rotationAxis, angleRadians),
    );

    // Create the second line starting at the origin
    const lineB = BABYLON.MeshBuilder.CreateLines(
      meshName + '_LINE_B_CHILD',
      {
        points: [origin, origin.add(rotatedDirection.scale(lineLength))],
      },
      this.scene,
    );
    lineB.color = BABYLON.Color3.FromHexString(color);
    lineB.parent = angleMesh;

    // Create an arc between line1 and line2
    const arcPoints = [];
    const arcMidpointIndex = arcSegments / 2;

    for (let i = 0; i <= arcSegments; i++) {
      const angle = (i / arcSegments) * angleRadians;
      const rotatedPoint = BABYLON.Vector3.TransformCoordinates(
        normalizedDirection,
        BABYLON.Matrix.RotationAxis(rotationAxis, angle),
      );
      arcPoints.push(origin.add(rotatedPoint.scale(arcRadius)));
    }

    // Create the arc
    const arcMesh = BABYLON.MeshBuilder.CreateLines('arc', { points: arcPoints }, this.scene);
    arcMesh.color = BABYLON.Color3.FromHexString(color);
    arcMesh.parent = angleMesh;

    // Calculate the midpoint of the arc
    const arcMidpoint = arcPoints[arcMidpointIndex];

    const sphereMesh = this.createSphere(
      meshName + '_ARC_MIDPT_CHILD',
      [],
      arcMidpoint,
      0.5,
      color,
    );

    if (sphereMesh) sphereMesh.parent = angleMesh;

    const bisectingVector = rotatedDirection
      .add(normalizedDirection)
      .divide(new BABYLON.Vector3(2, 2, 2))
      .normalize();

    let billboardMesh = this.createBillboardLabel(
      arcMidpoint,
      bisectingVector,
      meshName,
      [],
      lineLength,
      `${angleDegrees}°`,
      undefined,
      undefined,
      this.lightenColor(color, 0.7),
      undefined,
      undefined,
    );

    if (billboardMesh) billboardMesh.parent = angleMesh;

    return arcMesh;
  }

  private createOctahedron(
    meshName: string,
    parent: BABYLON.Nullable<BABYLON.Mesh>,
    tags: string[],
    position: BABYLON.Vector3,
    size: number,
    color: string,
  ): BABYLON.Mesh {
    // Create an octahedron using MeshBuilder
    const octahedron = BABYLON.MeshBuilder.CreatePolyhedron(
      meshName,
      {
        type: 1,
        size,
      },
      this.scene,
    );

    this.addTags(octahedron, tags);

    octahedron.parent = parent;

    // Set the position of the octahedron
    octahedron.setAbsolutePosition(position);

    // Create a material for the octahedron
    const material = new BABYLON.StandardMaterial(meshName + '_MATERIAL', this.scene);
    material.diffuseColor = BABYLON.Color3.FromHexString(color);

    // Apply the material to the octahedron
    octahedron.material = material;

    return octahedron;
  }

  private debugLog(...args: Parameters<typeof console.debug>) {
    if (this.debug) console.debug('BabylonSceneManager:', ...args);
  }

  private computeBoundingInfo(
    mesh: BABYLON.Mesh,
    includeChildMeshes = false,
  ): BABYLON.BoundingInfo {
    mesh.computeWorldMatrix(true);

    const boundingBox = mesh.getBoundingInfo().boundingBox;

    let min = boundingBox.minimumWorld;

    let max = boundingBox.maximumWorld;

    if (includeChildMeshes) {
      const childMeshes = mesh.getChildMeshes(false);

      childMeshes.forEach((m) => {
        m.computeWorldMatrix(true);

        const boundingInfo = m.getBoundingInfo().boundingBox;

        min = BABYLON.Vector3.Minimize(min, boundingInfo.minimumWorld);
        max = BABYLON.Vector3.Maximize(max, boundingInfo.maximumWorld);
      });
    }

    return new BABYLON.BoundingInfo(min, max);
  }

  private setupMesh(mesh: BABYLON.Mesh, loadedAssetConfig: LoadedAssetConfig) {
    mesh.computeWorldMatrix(true);

    const meshId = loadedAssetConfig.mesh;

    const meshConfig = loadedAssetConfig.meshConfig;

    const meshName = meshId.meshName;

    const standardMaterial = new BABYLON.StandardMaterial(meshName + '_MATERIAL', this.scene);

    // when the mesh is transparent, these settings will make it so that the
    // back of the mesh doesn't make it through the front of the mesh
    standardMaterial.backFaceCulling = true;
    standardMaterial.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
    standardMaterial.needDepthPrePass = true;

    mesh.material = standardMaterial;

    standardMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

    this._updateMesh(mesh, meshConfig);

    mesh.setPivotPoint(mesh.getBoundingInfo().boundingBox.center);

    const serializedName = this.serializeMeshId(meshId);

    mesh.id = serializedName;

    // NOTE - Position and rotation are set after parents are assigned to ensure absolute values are set correctly
    if (loadedAssetConfig.position)
      mesh.position = this.convertPublicVector3(loadedAssetConfig.position);

    const loadedRotation = loadedAssetConfig.rotation;
    if (loadedRotation) {
      mesh.rotationQuaternion = this.convertPublicVector3({
        x: Angle.FromDegrees(loadedRotation.x).radians(),
        y: Angle.FromDegrees(loadedRotation.y).radians(),
        z: Angle.FromDegrees(loadedRotation.z).radians(),
      }).toQuaternion();
    }

    this.originalTransformations.set(serializedName, this.getMeshTransformation(mesh));

    this.originalMeshConfig.set(serializedName, meshConfig || {});

    this.debugLog(`Mesh ${serializedName} loaded!`);
  }

  public resetMeshTransformation(meshId: MeshIdentification) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    const serializedName = this.serializeMeshId(meshId);

    const originalTransformation = this.originalTransformations.get(serializedName);

    if (originalTransformation) {
      this.applyTransformation(mesh, originalTransformation);
    }
  }

  public setInitialMeshTransformation(meshId: MeshIdentification) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    const serializedName = this.serializeMeshId(meshId);

    this.originalTransformations.set(serializedName, this.getMeshTransformation(mesh));

    mesh.bakeCurrentTransformIntoVertices();
  }

  private assignMeshParent(mesh: BABYLON.Mesh, parent: MeshIdentification) {
    const parentMesh = this.getMeshByIdentification(parent);

    if (parentMesh) mesh.parent = parentMesh;
  }

  private findAnnotations(
    meshId?: MeshIdentification,
    annotationType?: AnnotationType,
    tags?: string[],
    requireAllTags: boolean = true,
  ): BABYLON.Mesh[] {
    const tagsQuery =
      tags && tags?.length ? ' && ' + tags?.join(requireAllTags ? ' && ' : ' || ') : '';

    if (meshId) {
      const mesh = this.getMeshByIdentification(meshId);

      if (!mesh) return [];

      const annotationNodes = mesh.getChildMeshes(true, (mesh) =>
        BABYLON.Tags.MatchesQuery(
          mesh,
          annotationType
            ? this.getAnnotationTags(annotationType).join(' && ') + tagsQuery
            : BabylonSceneManager.ANNOTATION_TAG + tagsQuery,
        ),
      );

      return annotationNodes as BABYLON.Mesh[];
    } else {
      return this.scene.getMeshesByTags(BabylonSceneManager.ANNOTATION_TAG + tagsQuery);
    }
  }

  private _getMeshData(mesh: BABYLON.Mesh): MeshData {
    const editedMesh = mesh.getChildMeshes(true, (m) =>
      BABYLON.Tags.MatchesQuery(m, BabylonSceneManager.EDITED_TAG),
    )?.[0];

    return {
      absoluteEulerAngles: this.convertBabylonVector3(
        mesh.absoluteRotationQuaternion.toEulerAngles(),
      ),
      eulerAngles: this.convertBabylonVector3(
        mesh.rotationQuaternion?.toEulerAngles() ?? mesh.rotation,
      ),
      absolutePosition: this.convertBabylonVector3(mesh.absolutePosition),
      position: this.convertBabylonVector3(mesh.position),
      absoluteQuaternion: this.convertBabylonVector4(mesh.absoluteRotationQuaternion),
      quaternion: this.convertBabylonVector4(
        mesh.rotationQuaternion ?? mesh.rotation.toQuaternion(),
      ),
      parent: mesh.parent ? this.getMeshIdentification(mesh.parent as BABYLON.Mesh) : null,
      editedMesh: editedMesh ? this.getMeshIdentification(editedMesh as BABYLON.Mesh) : null,
      mesh: this.getMeshIdentification(mesh),
      opacity: mesh.visibility ?? 0,
      isVisible: mesh.visibility !== 1,
      annotationType: this.getAnnotationType(mesh),
    };
  }

  private removeMesh(mesh: BABYLON.Mesh) {
    mesh.setParent(null);

    this.scene.removeMesh(mesh);

    mesh.dispose();

    this.debugLog(`Removed mesh ${mesh.name}.`);

    if (this.originalTransformations.has(mesh.id)) this.originalTransformations.delete(mesh.id);

    if (this.originalMeshConfig.has(mesh.id)) this.originalMeshConfig.delete(mesh.id);
  }

  private _updateMesh(mesh: BABYLON.Mesh, config?: MeshUpdatePayload) {
    if (!mesh.material) return;

    const material = mesh.material as BABYLON.StandardMaterial;
    if (config?.color) {
      material.diffuseColor = BABYLON.Color3.FromHexString(config?.color);
      mesh.metadata = { originalDiffuseColor: BABYLON.Color3.FromHexString(config?.color) };
    }

    if (!_.isNil(config?.highlight)) {
      if (config?.highlight) {
        if (!mesh.metadata) {
          mesh.metadata = {};
        }

        mesh.metadata.originalDiffuseColor = material.diffuseColor;
        material.diffuseColor = BABYLON.Color3.FromHexString('#FFFF99');
      } else {
        if (mesh?.metadata?.originalDiffuseColor) {
          material.diffuseColor = mesh.metadata.originalDiffuseColor;
        }
      }
    }

    if (!_.isNil(config?.isPickable)) {
      if (BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.EDITED_TAG)) {
        // edited meshes should never be pickable
        mesh.isPickable = false;
      } else {
        mesh.isPickable = !!config?.isPickable;
      }
    }

    if (!_.isNil(config?.showBoundingBox)) {
      mesh.showBoundingBox = !!config?.showBoundingBox;
    }

    if (!_.isNil(config?.isVisible)) {
      mesh.visibility = config?.isVisible ? 1 : 0;
    }

    if (!_.isNil(config?.isEnabled)) {
      mesh.setEnabled(config?.isEnabled);
    }

    function updateInheritableProperties(m: BABYLON.Mesh, inheritedConfig?: MeshUpdatePayload) {
      if (!m.material) return;

      if (inheritedConfig?.opacity !== undefined && !_.isNil(inheritedConfig?.opacity)) {
        m.visibility = inheritedConfig.opacity;
      }

      if (!_.isNil(inheritedConfig?.disabled)) {
        if (inheritedConfig?.disabled) {
          m.overlayColor = BABYLON.Color3.Black();
          m.renderOverlay = true;
          m.overlayAlpha = 0.5;
        } else {
          m.renderOverlay = false;
        }
      }
    }

    updateInheritableProperties(mesh, config);

    // NOTE - Also update mesh children properties if the mesh was a loaded asset
    if (BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.LOADED_ASSET_TAG)) {
      const meshChildren = mesh.getChildMeshes(true, (m) =>
        BABYLON.Tags.MatchesQuery(
          m,
          `!${BabylonSceneManager.LOADED_ASSET_TAG}${
            config?.excludeAnnotations ? `&& !${BabylonSceneManager.ANNOTATION_TAG}` : ''
          }`,
        ),
      );

      meshChildren.forEach((childMesh) => {
        updateInheritableProperties(childMesh as BABYLON.Mesh, config);
      });
    }
  }

  private _updateMeshesByTags(tagQuery: string, config: MeshUpdatePayload): BABYLON.Mesh[] {
    const meshes = this.scene.getMeshesByTags(tagQuery);

    meshes.forEach((m) => this._updateMesh(m, config));

    return meshes;
  }

  private _removeMeshesByTags(tagQuery: string): MeshIdentification[] {
    const meshes = this.scene.getMeshesByTags(tagQuery);

    const removedMeshes: MeshIdentification[] = [];

    meshes.forEach((m) => {
      this.removeMesh(m);

      removedMeshes.push(this.getMeshIdentification(m));
    });

    return removedMeshes;
  }

  private resetTransformations() {
    this.originalTransformations.forEach((transformation, serialized) => {
      const deserializedMeshId = this.deserializeMeshId(serialized);

      if (!deserializedMeshId) return;

      const mesh = this.getMeshByIdentification(deserializedMeshId);

      if (!mesh) return;

      this.applyTransformation(mesh, transformation);
    });
  }

  private addLocalAxes(name: string, position: BABYLON.Vector3, size: number) {
    const zero = BABYLON.Vector3.Zero();

    const localAxisX = BABYLON.MeshBuilder.CreateLines(
      'localAxisX',
      {
        points: [
          zero,
          new BABYLON.Vector3(size, 0, 0),
          new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
          new BABYLON.Vector3(size, 0, 0),
          new BABYLON.Vector3(size * 0.95, -0.05 * size, 0),
        ],
      },
      this.scene,
    );
    localAxisX.isPickable = false;
    localAxisX.color = new BABYLON.Color3(1, 0, 0);

    const localAxisY = BABYLON.MeshBuilder.CreateLines(
      'localAxisY',
      {
        points: [
          zero,
          new BABYLON.Vector3(0, size, 0),
          new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
          new BABYLON.Vector3(0, size, 0),
          new BABYLON.Vector3(0.05 * size, size * 0.95, 0),
        ],
      },
      this.scene,
    );
    localAxisY.isPickable = false;
    localAxisY.color = new BABYLON.Color3(0, 1, 0);

    const localAxisZ = BABYLON.MeshBuilder.CreateLines(
      'localAxisZ',
      {
        points: [
          zero,
          new BABYLON.Vector3(0, 0, size),
          new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
          new BABYLON.Vector3(0, 0, size),
          new BABYLON.Vector3(0, 0.05 * size, size * 0.95),
        ],
      },
      this.scene,
    );
    localAxisZ.isPickable = false;
    localAxisZ.color = new BABYLON.Color3(0, 0, 1);

    const localOrigin = BABYLON.MeshBuilder.CreateBox('local_origin', { size: 1 }, this.scene);
    localOrigin.isPickable = false;
    localOrigin.isVisible = false;

    localAxisX.setParent(localOrigin);
    localAxisY.setParent(localOrigin);
    localAxisZ.setParent(localOrigin);

    localOrigin.position = position;
    localOrigin.name = name;
    return localOrigin;
  }

  private serializeMeshId(meshId: MeshIdentification): string {
    const sortedTagStr = meshId.tags?.sort((a, b) => a.localeCompare(b)).join('+');

    return meshId.meshName + BabylonSceneManager.MESH_NAME_TAGS_DELIMITER + (sortedTagStr ?? '');
  }

  private deserializeMeshId(serialized: string): MeshIdentification | null {
    const split = serialized.split(BabylonSceneManager.MESH_NAME_TAGS_DELIMITER);

    const meshName = split[0];

    if (!meshName) return null;

    const tagStr = split[1];

    return {
      meshName: split[0],
      tags: tagStr ? tagStr?.split('+') : [],
    };
  }

  private getAnnotationType(mesh: BABYLON.Mesh): AnnotationType | null {
    const meshTags = this.getMeshTags(mesh);

    if (!meshTags.includes(BabylonSceneManager.ANNOTATION_TAG)) return null;

    if (meshTags.includes(BabylonSceneManager.SPHERE_ANNOTATION_TAG)) {
      return 'sphere';
    } else if (meshTags.includes(BabylonSceneManager.ANGLE_ANNOTATION_TAG)) {
      return 'angle';
    } else if (meshTags.includes(BabylonSceneManager.BILLBOARD_ANNOTATION_TAG)) {
      return 'billboard';
    } else if (meshTags.includes(BabylonSceneManager.OCTAHEDRON_ANNOTATION_TAG)) {
      return 'octahedron';
    } else {
      return null;
    }
  }

  private getMeshIdentification(mesh: BABYLON.Mesh): MeshIdentification {
    return {
      meshName: mesh.name,
      tags: this.getMeshTags(mesh).filter(
        (t: string) => !t.startsWith(BabylonSceneManager.INTERNAL_TAG_PREFIX) && t !== mesh.name,
      ),
    };
  }

  private getLandmarkColor(endPlate: EndPlate, position: Position): string {
    switch (position) {
      case Position.Posterior:
        if (endPlate === EndPlate.Superior) return VertebraePositionColor.PosteriorTop;
        else return VertebraePositionColor.PosteriorBottom;
      case Position.Anterior:
        if (endPlate === EndPlate.Superior) return VertebraePositionColor.AnteriorTop;
        else return VertebraePositionColor.AnteriorBottom;
      case Position.PatientRight:
        if (endPlate === EndPlate.Superior) return VertebraePositionColor.PatientRightTop;
        else return VertebraePositionColor.PatientRightBottom;
      case Position.PatientLeft:
        if (endPlate === EndPlate.Superior) return VertebraePositionColor.PatientLeftTop;
        else return VertebraePositionColor.PatientLeftBottom;
      default:
        return '#FFFFFF';
    }
  }

  private setPreopAssetPositionsPoints(
    assetPositions: AssetPositions,
    caseMeasurements: IMeasure[],
  ): void {
    caseMeasurements.forEach(({ body, endPlate, point, position }) => {
      const targetMeasurementPoint: AssetPositionsPoints = {
        position: position,
        name: body as VertebralBody,
        endPlate: endPlate,
        point: {
          x: point[0],
          y: point[1],
          z: point[2],
        },
      };
      assetPositions.preop.points.push(targetMeasurementPoint);
    });
  }

  private setPlanAssetPositionsPoints(assetPositions: AssetPositions): void {
    const landmarks: LandmarkConfig[] = this.getLandmarks();

    landmarks.forEach((landmark) => {
      const point = this.scene
        .getMeshesByTags(
          [
            ...this.getAnnotationTags('sphere'),
            BabylonSceneManager.LANDMARK_TAG,
            landmark.vertebralBody,
            landmark.landmarkPosition,
            landmark.endPlate,
          ].join(' && '),
        )
        .filter((p) => p.name.includes('_PLAN_'))?.[0];

      if (!point) return;

      point.computeWorldMatrix(true);

      const absolutePosition = point?.getAbsolutePosition() ?? landmark.point;

      const targetMeasurementPoint: AssetPositionsPoints = {
        position: landmark.landmarkPosition,
        name: landmark.vertebralBody,
        endPlate: landmark.endPlate,
        point: {
          x: absolutePosition.x,
          y: absolutePosition.y,
          z: absolutePosition.z,
        },
      };
      assetPositions.plan.points.push(targetMeasurementPoint);
    });
  }

  private setPlanAssetPositionsVertebralBodies(
    assetPositions: AssetPositions,
    spineProfile: CaseSpineProfile,
  ) {
    const caseSpineProfile = caseUtils.getCaseSpineProfile(spineProfile);

    const targetVertebralBodyMeshes = this.scene.getMeshesByTags(
      `${SpineAssetTags.Plan} && ${
        BabylonSceneManager.LOADED_ASSET_TAG
      } && (${caseSpineProfile.validVertebralBodies.join(' || ')})`,
    );

    targetVertebralBodyMeshes.forEach((vertebralBodyMesh: BABYLON.AbstractMesh) => {
      vertebralBodyMesh.computeWorldMatrix(true);

      const meshQuaternion = vertebralBodyMesh.absoluteRotationQuaternion;

      const position = vertebralBodyMesh.position;

      const meshEulerAngles = meshQuaternion?.toEulerAngles();

      const targetVertebralBody: AssetPositionsVertebralBody = {
        name: vertebralBodyMesh.name as VertebralBody,
        position: {
          x: position.x,
          y: position.y,
          z: position.z,
        },
        rotation: {
          x: meshEulerAngles.x,
          y: meshEulerAngles.y,
          z: meshEulerAngles.z,
        },
      };

      assetPositions.plan.vertebrae.push(targetVertebralBody);
    });

    assetPositions.plan.vertebrae = _.sortBy(assetPositions.plan.vertebrae, 'name');
  }

  private getMeshTags(mesh: BABYLON.Mesh): string[] {
    return BABYLON.Tags.GetTags(mesh, true).split(' ') as string[];
  }

  /**
   * PUBLIC METHODS
   */

  public getPreopMeasurements(): IMeasure[] {
    return this.getLandmarks().map((landmark, index) => ({
      body: landmark.vertebralBody,
      endPlate: landmark.endPlate,
      point: [landmark.point.x, landmark.point.y, landmark.point.z],
      position: landmark.landmarkPosition,
      measurementId: index,
    }));
  }

  public getPlanMeasurements() {
    const landmarks: LandmarkConfig[] = this.getLandmarks();
    const planMeasurements: IMeasure[] = [];

    landmarks.forEach((landmark, index) => {
      const point = this.scene
        .getMeshesByTags(
          [
            ...this.getAnnotationTags('sphere'),
            BabylonSceneManager.LANDMARK_TAG,
            landmark.vertebralBody,
            landmark.landmarkPosition,
            landmark.endPlate,
          ].join(' && '),
        )
        .filter((p) => p.name.includes('_PLAN_'))?.[0];

      if (!point) return;

      point.computeWorldMatrix(true);

      const absolutePosition = point?.getAbsolutePosition() ?? landmark.point;

      const targetMeasurementPoint: IMeasure = {
        position: landmark.landmarkPosition,
        body: landmark.vertebralBody,
        endPlate: landmark.endPlate,
        point: [absolutePosition.x, absolutePosition.y, absolutePosition.z],
        measurementId: index,
      };
      planMeasurements.push(targetMeasurementPoint);
    });
    return planMeasurements;
  }

  // Covered in /tests/babylon/export.spec.ts
  public exportAssetPositions(spineProfile: CaseSpineProfile): AssetPositions {
    const caseMeasurements: IMeasure[] = this.getLandmarks().map((landmark, index) => ({
      body: landmark.vertebralBody,
      endPlate: landmark.endPlate,
      point: [landmark.point.x, landmark.point.y, landmark.point.z],
      position: landmark.landmarkPosition,
      measurementId: index,
    }));

    // initialize empty asset positions
    const assetPositions: AssetPositions = {
      preop: {
        points: [],
        vertebrae: [],
      },
      plan: {
        vertebrae: [],
        points: [],
      },
      version: 1,
    };

    // populate pre-op measurements from case measurements
    this.setPreopAssetPositionsPoints(assetPositions, caseMeasurements);

    // populate plan points from scene
    this.setPlanAssetPositionsPoints(assetPositions);

    // populate plan vertebral bodies from scene
    this.setPlanAssetPositionsVertebralBodies(assetPositions, spineProfile);

    return assetPositions;
  }

  // Covered in /tests/babylon/editor.spec.ts
  public updateMesh(meshId: MeshIdentification, config?: Omit<MeshUpdatePayload, 'highlight'>) {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh || !mesh.material) return;

    if (BABYLON.Tags.MatchesQuery(mesh, BabylonSceneManager.ANNOTATION_TAG)) return;

    this._updateMesh(mesh, config);
  }

  public updateMeshes(
    updates: {
      meshId: MeshIdentification;
      config?: Omit<MeshUpdatePayload, 'highlight'>;
    }[],
  ) {
    updates.forEach((u) => this.updateMesh(u.meshId, u.config));
  }

  // Covered in /tests/babylon/editor.spec.ts
  public highlightMesh(meshId: MeshIdentification | null) {
    this.scene.meshes.forEach((m) => this._updateMesh(m as BABYLON.Mesh, { highlight: false }));

    if (!meshId) {
      return;
    }

    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    this._updateMesh(mesh, { highlight: true });
  }

  // Covered in /tests/babylon/editor.spec.ts
  public disableMesh(meshId: MeshIdentification) {
    if (!meshId) {
      return;
    }

    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    this._updateMesh(mesh, { disabled: true });
  }

  public disableMeshes(meshIds: MeshIdentification[]) {
    meshIds.forEach((meshId) => this.disableMesh(meshId));
  }

  // Covered in /tests/babylon/editor.spec.ts
  public enableMesh(meshId: MeshIdentification) {
    if (!meshId) {
      return;
    }

    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    this._updateMesh(mesh, { disabled: false });
  }

  public enableMeshes(meshIds: MeshIdentification[]) {
    meshIds.forEach((meshId) => this.enableMesh(meshId));
  }

  // Covered in /tests/babylon/editor.spec.ts
  public resetMesh(meshId: MeshIdentification) {
    const originalMeshConfig = this.originalMeshConfig.get(this.serializeMeshId(meshId));

    if (!originalMeshConfig) return;

    this.updateMesh(meshId, {
      color: originalMeshConfig.color,
      opacity: originalMeshConfig.opacity,
    });
  }

  public resetMeshes(meshIds: MeshIdentification[]) {
    meshIds.forEach((meshId) => this.resetMesh(meshId));
  }

  // Covered in /tests/babylon/editor.spec.ts
  public async zoom(options: {
    cameraView: BabylonCameraView;
    distance?: number;
    target?: MeshIdentification;
    includeTargetChildren?: boolean;
    disableAnimation?: boolean;
  }) {
    const {
      cameraView,
      distance,
      target,
      disableAnimation = false,
      includeTargetChildren = false,
    } = options;

    if (target) {
      this.setCameraTarget(target, includeTargetChildren);
    }

    if (!this.cameraTarget) {
      console.warn('No camera target defined. zoom operation did not complete.');
      return;
    }

    const mesh = this.getMeshByIdentification(this.cameraTarget);

    if (!mesh) return;

    const camera = this.scene.activeCamera as BABYLON.ArcRotateCamera | null;

    if (!this.scene || !camera) return;

    let cameraDist = distance;

    if (_.isNil(cameraDist)) {
      const boundingInfo = this.computeBoundingInfo(mesh, includeTargetChildren);

      const radius = boundingInfo.boundingSphere.radiusWorld;

      if (camera.mode === BABYLON.Camera.PERSPECTIVE_CAMERA) {
        cameraDist = (radius * 2) / Math.sin(camera.fov / 2);
      } else {
        cameraDist = radius * 2;
      }
    }

    switch (cameraView) {
      case 'AXIAL_TOP':
        await this.rotateCamera(-90, 0, cameraDist, disableAnimation);
        break;

      case 'AXIAL_BOTTOM':
        await this.rotateCamera(-90, 180, cameraDist, disableAnimation);
        break;

      case 'CORONAL':
        await this.rotateCamera(-90, 90, cameraDist, disableAnimation);
        break;

      case 'SAGITTAL_LEFT':
        await this.rotateCamera(0, 90, cameraDist, disableAnimation);
        break;

      case 'SAGITTAL_RIGHT':
        await this.rotateCamera(180, 90, cameraDist, disableAnimation);
        break;
    }
  }

  public on(eventType: PointerHandlerEventType, callback: PointerHandler) {
    this.pointerHandlers.push({
      eventType,
      callback,
    });
  }

  public off(eventType: PointerHandlerEventType, callback: PointerHandler) {
    const matchIndex = this.pointerHandlers.findIndex(
      (h) => h.eventType === eventType && h.callback === callback,
    );

    if (matchIndex < 0) return;

    this.pointerHandlers.splice(matchIndex, 1);
  }

  // Covered in /tests/babylon/setup.spec.ts
  public start(): void {
    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  }

  // Covered in /tests/babylon/setup.spec.ts
  public dispose(): void {
    this.engine.dispose();

    this.scene.onPointerObservable.remove(this.pointerObserver);

    if (!window) return;

    window.removeEventListener('mousewheel', this.onMouseWheel);

    window.removeEventListener('resize', this.onResize);
  }

  // Covered in /tests/babylon/assets.spec.ts
  public async loadAsset(assetConfig: LoadedAssetConfig): Promise<MeshIdentification | null> {
    const assets = await this.loadAssets([assetConfig]);

    return assets[0] ?? null;
  }

  // Covered in /tests/babylon/assets.spec.ts
  public async loadAssets(
    assets: LoadedAssetConfig[],
    showLoadingScreen = true,
  ): Promise<MeshIdentification[]> {
    if (showLoadingScreen) this.engine.displayLoadingUI();

    const assetsManager = new BABYLON.AssetsManager(this.scene);

    const assetsMap = _.keyBy(assets, (a) => this.serializeMeshId(a.mesh));

    const loadedAssets: MeshIdentification[] = [];

    assets.forEach((a) => {
      // if any mesh already exists for this asset, remove it first. This
      // can happen when toggling between edited and non-edited assets
      this.remove(a.mesh);

      assetsManager.addMeshTask(this.serializeMeshId(a.mesh), '', a.assetUrl, '');
    });

    assetsManager.useDefaultLoadingScreen = false;

    let taskError: any;

    assetsManager.onTaskError = function (error) {
      taskError = error;

      /**
       * If any task fails, remove remaining tasks
       */
      assetsManager.reset();
    };

    const thisClass = this;

    assetsManager.onFinish = async function (tasks: BABYLON.AbstractAssetTask[]) {
      const meshTasks = tasks as BABYLON.MeshAssetTask[];

      const loadedMeshes = _.compact(
        meshTasks.map((task) => {
          const loadedMesh = task.loadedMeshes[0] as BABYLON.Mesh | undefined;

          if (!loadedMesh) return;

          const assetData = assetsMap[task.name];

          return {
            loadedMesh,
            assetData,
          };
        }),
      );

      // 1. Initial pass to set mesh names and tags
      loadedMeshes.forEach(({ loadedMesh, assetData }) => {
        const meshId = assetData.mesh;

        loadedMesh.name = meshId.meshName;

        const tags = thisClass.getLoadedMeshTags(meshId);

        thisClass.addTags(loadedMesh, tags);
      });

      // 2. Now that all mesh names are set, assign parents
      loadedMeshes.forEach(({ loadedMesh, assetData }) => {
        if (!assetData.meshConfig?.parent) return;

        thisClass.assignMeshParent(loadedMesh, assetData.meshConfig.parent);
      });

      // 3. Now that all mesh parents are set, set absolute positions & rotations
      loadedMeshes.forEach(({ loadedMesh, assetData }) => {
        thisClass.setupMesh(loadedMesh, assetData);

        loadedAssets.push(assetData.mesh);
      });

      assetsManager.reset();
    };

    await assetsManager.loadAsync();

    this.engine.hideLoadingUI();

    this.onResize();

    if (taskError) throw taskError;

    return loadedAssets;
  }

  // Covered in /tests/babylon/editor.spec.ts
  public remove(meshId: MeshIdentification): MeshIdentification | null {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return null;

    this.removeMesh(mesh);

    this.debugLog(`Removed mesh ${mesh.name}.`);

    return meshId;
  }

  public removeMeshes(meshIds: MeshIdentification[]): MeshIdentification[] {
    const removedMeshes = meshIds.map((meshId) => this.remove(meshId));

    return _.compact(removedMeshes);
  }

  // Covered in /tests/babylon/editor.spec.ts
  public setCameraTarget(
    meshId: MeshIdentification | null,
    includeTargetChildren: boolean = false,
    maintainTarget: boolean = false,
  ) {
    if (!meshId) {
      this.cameraTarget = null;
      return;
    }

    const camera = this.scene.activeCamera as BABYLON.ArcRotateCamera;

    const mesh = this.getMeshByIdentification(meshId);

    if (!camera || !mesh) return;

    this.cameraTarget = meshId;

    const boundingSphere = this.computeBoundingInfo(mesh, includeTargetChildren).boundingSphere;

    camera.target = maintainTarget
      ? boundingSphere.centerWorld
      : boundingSphere.centerWorld.clone();
  }

  // Covered in /tests/babylon/editor.spec.ts
  public addOperation(operation: Operation): string | null {
    const mesh = this.getMeshByIdentification(operation.mesh);

    if (!mesh) return null;

    const operationId = uuidv4();

    this._operations.push({
      ...operation,
      operationId,
    });

    this.applyOperationToMesh(operation);

    return operationId;
  }

  public addOperations(operations: Operation[]): string[] {
    const operationResults = operations.map((o) => this.addOperation(o));

    return _.compact(operationResults);
  }

  // Covered in /tests/babylon/editor.spec.ts
  public insertOperation(operation: Operation, insertOperationId: string | undefined) {
    if (!insertOperationId) {
      console.error(`Insert Operation not provided.`);
      return;
    }

    const insertOperationIndex: number = this._operations.findIndex(
      (o) => o.operationId === insertOperationId,
    );

    if (insertOperationIndex < 0) {
      console.error(`Insert Operation with ID ${insertOperationId} not found.`);
      return;
    }

    const mesh = this.getMeshByIdentification(operation.mesh);

    if (!mesh) return;

    const operationId = uuidv4();

    this._operations.splice(insertOperationIndex + 1, 0, {
      ...operation,
      operationId,
    });

    this.performTransformationTransaction(() => {
      this.resetTransformations();

      this._operations.forEach((operation) => {
        this.applyOperationToMesh(operation);
      });
    });
  }

  // Covered in /tests/babylon/editor.spec.ts
  public updateOperation(operationEntry: OperationEntry) {
    const operationId = operationEntry.operationId;
    const operationIndex = this._operations.findIndex((o) => o.operationId === operationId);

    if (operationIndex < 0) {
      console.error(`Operation with ID ${operationId} not found.`);
      return;
    }

    this._operations[operationIndex] = operationEntry;

    this.performTransformationTransaction(() => {
      this.resetTransformations();

      this._operations.forEach((operation) => {
        this.applyOperationToMesh(operation);
      });
    });
  }

  // Covered in /tests/babylon/editor.spec.ts
  public removeOperation(operationId: string) {
    const operationIndex = this._operations.findIndex((o) => o.operationId === operationId);

    if (operationIndex < 0 || operationIndex >= this._operations.length) {
      console.error(`Operation with ID ${operationId} not found.`);
      return;
    }

    this._operations.splice(operationIndex, 1);

    this.performTransformationTransaction(() => {
      this.resetTransformations();

      this._operations.forEach((operation) => {
        this.applyOperationToMesh(operation);
      });
    });
  }

  // Covered in /tests/babylon/assets.spec.ts
  public resetOperations() {
    this._operations = [];

    this.performTransformationTransaction(() => {
      this.resetTransformations();
    });
  }

  public reapplyOperations() {
    this.performTransformationTransaction(() => {
      this.resetTransformations();

      this._operations.forEach((operation) => {
        this.applyOperationToMesh(operation);
      });
    });
  }

  public addAxisGizmo() {
    const camera = this.scene.activeCamera as BABYLON.ArcRotateCamera;
    if (camera) {
      new OrientationControl(camera, {}, this.scene);
    }
  }

  // Covered in /tests/babylon/editor.spec.ts
  public addAnnotation(meshId: MeshIdentification, config: AnnotationConfig): MeshData | null {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return null;

    const annotationId = Date.now();

    const tags = [...(config.tags ?? []), ...this.getAnnotationTags(config.type)];

    const serializedMeshName = this.serializeMeshId(meshId);

    switch (config.type) {
      case 'billboard': {
        const convertedOrigin = this.convertPublicVector3(config.origin);

        const centerWorld = mesh.getBoundingInfo().boundingSphere.centerWorld;

        const direction = convertedOrigin.subtract(centerWorld).normalize();

        const billboardMesh = this.createBillboardLabel(
          convertedOrigin,
          direction,
          `${serializedMeshName}_${annotationId}`,
          tags,
          config.lineLength,
          config.text,
          undefined,
          undefined,
          config.hexColor,
          config.hexColor,
          undefined,
        );

        if (billboardMesh) {
          billboardMesh.parent = mesh;
          billboardMesh.isPickable = false;
        }

        if (billboardMesh?.material) {
          billboardMesh.material.alpha = config?.opacity;
        }

        return billboardMesh ? this._getMeshData(billboardMesh) : null;
      }
      case 'sphere': {
        const convertedPosition = this.convertPublicVector3(config.position);
        const sphereMesh = this.createSphere(
          `${serializedMeshName}_${annotationId}`,
          tags,
          convertedPosition,
          config.size,
          config.hexColor,
        );

        if (sphereMesh) {
          sphereMesh.parent = mesh;
          sphereMesh.isPickable = false;
        }

        if (sphereMesh?.material) {
          sphereMesh.material.alpha = config?.opacity;
        }

        return sphereMesh ? this._getMeshData(sphereMesh) : null;
      }
      case 'angle': {
        const convertedOrigin = this.convertPublicVector3(config.origin);

        const convertedDirection = this.convertPublicVector3(config.direction);

        const angleMesh = this.visualizeAngle(
          `${serializedMeshName}_${annotationId}`,
          tags,
          convertedOrigin,
          convertedDirection,
          config.angleDegrees,
          config.lineLength,
          2,
          50,
          config.hexColor,
        );

        if (angleMesh) {
          angleMesh.parent = mesh;
          angleMesh.isPickable = false;
        }

        if (angleMesh?.material) {
          angleMesh.material.alpha = config?.opacity;
        }

        return angleMesh ? this._getMeshData(angleMesh) : null;
      }
      case 'octahedron':
        const convertedPosition = this.convertPublicVector3(config.position);

        const octahedronMesh = this.createOctahedron(
          `${serializedMeshName}_${annotationId}`,
          mesh,
          tags,
          convertedPosition,
          config.size,
          config.hexColor,
        );

        if (octahedronMesh) {
          octahedronMesh.isPickable = false;
        }

        if (octahedronMesh?.material) {
          octahedronMesh.material.alpha = config?.opacity;
        }

        return octahedronMesh ? this._getMeshData(octahedronMesh) : null;
      case 'line':
        const points = config.points.map((p) => this.convertPublicVector3(p));

        const lineMesh = this.createPipe(
          points,
          `${serializedMeshName}_${annotationId}`,
          0.5,
          config.hexColor,
          tags,
        );

        if (lineMesh?.material) {
          lineMesh.material.alpha = config?.opacity;
        }

        if (lineMesh) {
          lineMesh.isPickable = false;
        }

        return lineMesh ? this._getMeshData(lineMesh) : null;
    }
  }

  // Covered in /tests/babylon/editor.spec.ts
  public removeAnnotations(
    meshId?: MeshIdentification,
    annotationType?: AnnotationType,
    tags?: string[],
    requireAllTags: boolean = true,
  ): MeshIdentification[] {
    if (meshId) {
      const annotationNodes = this.findAnnotations(meshId, annotationType, tags, requireAllTags);

      this.debugLog(`Removing annotations for mesh ${meshId.meshName}...`);

      annotationNodes.forEach((n) => {
        this.removeMesh(n);
      });

      return annotationNodes.map((n) => this.getMeshIdentification(n));
    } else {
      const tagQuery = `${tags?.join(' && ')} && ${
        annotationType
          ? this.getAnnotationTags(annotationType).join(' && ')
          : BabylonSceneManager.ANNOTATION_TAG
      }`;

      return this._removeMeshesByTags(tagQuery);
    }
  }

  // Covered in /tests/babylon/editor.spec.ts
  public updateAnnotations(
    annotationType: AnnotationType,
    config: AnnotationMeshUpdatePayload,
    meshId?: MeshIdentification,
    tags?: string[],
    requireAllTags: boolean = true,
  ): string[] {
    if (meshId) {
      const annotationNodes = this.findAnnotations(meshId, annotationType, tags, requireAllTags);

      this.debugLog(`Updating annotations for mesh ${meshId.meshName}...`);

      annotationNodes.forEach((n) => {
        this._updateMesh(n, _.pick(config, 'opacity'));
      });

      return annotationNodes.map((n) => n.name);
    } else {
      return this._updateMeshesByTags(
        tags?.join(' || ') +
          (annotationType
            ? this.getAnnotationTags(annotationType).join(' && ')
            : BabylonSceneManager.ANNOTATION_TAG),
        config,
      ).map((m) => m.name);
    }
  }

  // Covered in /tests/babylon/editor.spec.ts
  public getAnnotations(
    meshId?: MeshIdentification,
    annotationType?: AnnotationType,
    tags?: string[],
    requireAllTags: boolean = true,
  ): MeshData[] {
    const annotationNodes = this.findAnnotations(meshId, annotationType, tags, requireAllTags);

    return annotationNodes.map((n) => this._getMeshData(n));
  }

  // Covered in /tests/babylon/editor.spec.ts
  public getMeshData(meshId: MeshIdentification): MeshData | null {
    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return null;

    return this._getMeshData(mesh);
  }

  // Covered in /tests/babylon/editor.spec.ts
  public updateMeshesByTags(
    tags: string[],
    config: MeshUpdatePayload,
    requireAllTags: boolean = true,
  ): string[] {
    return this._updateMeshesByTags(
      tags.join(requireAllTags ? ' && ' : ' || ') + ` && !${BabylonSceneManager.ANNOTATION_TAG}`,
      config,
    ).map((n) => n.name);
  }

  public getMeshesByTags(tagQuery: string): MeshData[] {
    const meshes = this.scene.getMeshesByTags(
      `${tagQuery} && !${BabylonSceneManager.ANNOTATION_TAG}`,
    );

    return meshes.map((m) => this._getMeshData(m));
  }

  public updateMeshesByTagQuery(
    tagQuery: string,
    handleUpdate: (mesh: MeshData) => MeshUpdatePayload,
  ): string[] {
    const meshes = this.scene.getMeshesByTags(
      `${tagQuery} && !${BabylonSceneManager.ANNOTATION_TAG}`,
    );

    meshes.forEach((m) => {
      const config = handleUpdate(this._getMeshData(m));

      this._updateMesh(m, config);
    });

    return meshes.map((m) => m.name);
  }

  // Covered in /tests/babylon/editor.spec.ts
  public removeMeshesByTags(tags: string[], requireAllTags: boolean = true): MeshIdentification[] {
    return this._removeMeshesByTags(
      tags.join(requireAllTags ? ' && ' : ' || ') + ` && !${BabylonSceneManager.ANNOTATION_TAG}`,
    );
  }

  // Covered in /tests/babylon/export.spec.ts
  public async createScreenshot(
    desiredWidth: number,
    desiredHeight: number,
    cameraView?: BabylonCameraView,
  ): Promise<Blob | null> {
    function getSceneBoundingBox(scene: BABYLON.Scene): {
      min: BABYLON.Vector3;
      max: BABYLON.Vector3;
    } {
      let min = new BABYLON.Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
      let max = new BABYLON.Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

      scene.meshes.forEach((mesh) => {
        if (mesh.isVisible && mesh.isEnabled()) {
          const boundingInfo = mesh.getBoundingInfo();
          const boundingBox = boundingInfo.boundingBox;

          min = BABYLON.Vector3.Minimize(min, boundingBox.minimumWorld);
          max = BABYLON.Vector3.Maximize(max, boundingBox.maximumWorld);
        }
      });

      return { min, max };
    }

    function frameAllMeshes(scene: BABYLON.Scene) {
      const camera = scene.activeCamera as BABYLON.ArcRotateCamera;

      const boundingBox = getSceneBoundingBox(scene);
      const center = boundingBox.min.add(boundingBox.max).scale(0.5);
      const size = boundingBox.max.subtract(boundingBox.min);

      // Set the camera's target to the center of the bounding box
      camera.setTarget(center);

      // Get the camera's aspect ratio and field of view
      const aspectRatio = camera.getEngine().getAspectRatio(camera);
      const verticalFOV = camera.fov; // Vertical field of view (in radians)
      const horizontalFOV = 2 * Math.atan(Math.tan(verticalFOV / 2) * aspectRatio); // Calculate horizontal FOV

      // Find the required distance to fit the bounding box in the camera's view
      const fitDistanceX = size.x / 2 / Math.tan(horizontalFOV / 2);
      const fitDistanceY = size.y / 2 / Math.tan(verticalFOV / 2);

      // Use the larger of the two distances to ensure the entire bounding box fits
      const requiredDistance = Math.max(fitDistanceX, fitDistanceY);
      const scalingFactor = 0.35;

      return requiredDistance * scalingFactor;
    }

    function base64ToBlob(
      base64: string,
      contentType = 'application/octet-stream',
      sliceSize = 512,
    ) {
      const byteCharacters = atob(base64); // Decode the base64 string
      const byteArrays = [];

      // Split the byteCharacters into slices to create byteArrays
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i); // Convert each character to its byte representation
        }

        const byteArray = new Uint8Array(byteNumbers); // Create a typed array of bytes
        byteArrays.push(byteArray); // Add to the array of byteArrays
      }

      return new Blob(byteArrays, { type: contentType });
    }

    if (!this.scene.activeCamera) {
      return null;
    }

    this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

    const canvas = this.scene.getEngine().getRenderingCanvas();
    if (!canvas) return null;

    canvas.width = desiredWidth;
    canvas.height = desiredHeight;

    this.scene.getEngine().resize(true);

    // const canvasWidth = canvas.width;
    // const canvasHeight = canvas.height;
    // const aspectRatio = canvasWidth / canvasHeight;

    let width = desiredWidth;
    let height = desiredHeight;

    /*
    if (desiredWidth >= desiredHeight) {
      width = desiredWidth;
      height = desiredHeight / aspectRatio;
    } else {
      width = desiredWidth / aspectRatio;
      height = desiredHeight;
    }
*/

    if (cameraView) {
      const cameraDistance = frameAllMeshes(this.scene);
      await this.zoom({
        cameraView: cameraView,
        distance: cameraDistance,
      });
    }

    this.scene.getEngine().resize(true);
    const base64 = await BABYLON.ScreenshotTools.CreateScreenshotUsingRenderTargetAsync(
      this.scene.getEngine(),
      this.scene.activeCamera,
      { width, height },
    );

    return base64ToBlob(base64.split(',')[1], 'image/png');
  }

  // Covered in /tests/babylon/export.spec.ts
  public getMeshBuffer(meshId: MeshIdentification, fileType: 'STL' = 'STL'): DataView | null {
    let buffer: DataView | null = null;

    const targetMesh = this.getMeshByIdentification(meshId);

    if (!targetMesh) return buffer;

    switch (fileType) {
      case 'STL':
        buffer = STLExport.CreateSTL([targetMesh], false, undefined, true, true, true, true);
        break;
    }

    return buffer;
  }

  public getMeshBuffers(
    meshIds: MeshIdentification[],
    fileType: 'STL' = 'STL',
  ): {
    buffer: DataView;
    meshId: MeshIdentification;
  }[] {
    const meshBuffers = meshIds.map((meshId) => {
      const buffer = this.getMeshBuffer(meshId, fileType);

      if (!buffer) return null;

      return {
        buffer,
        meshId,
      };
    });

    return _.compact(meshBuffers);
  }

  // Covered in /tests/babylon/editor.spec.ts
  public clearScene() {
    const loadedMeshes = this.scene.getMeshesByTags(BabylonSceneManager.LOADED_ASSET_TAG);

    loadedMeshes.forEach((m) => this.removeMesh(m));
  }

  public toggleLocalAxis(meshId: MeshIdentification, visible: boolean) {
    this.scene
      .getMeshesByTags(BabylonSceneManager.AXIS_TAG)
      .forEach((axisMesh) => this.removeMesh(axisMesh));

    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    if (visible) {
      const localOrigin = this.addLocalAxes(
        meshId.meshName + '_' + Date.now(),
        mesh.getBoundingInfo().boundingBox.center,
        75,
      );

      this.addTags(localOrigin, [BabylonSceneManager.AXIS_TAG]);

      localOrigin.setParent(mesh);
    }
  }

  public createLandmark(config: LandmarkConfig, opacity: number = 1) {
    if (config.landmarkPosition === Position.PosteriorEdge) {
      return;
    }

    const landmarkKey = [config.vertebralBody, config.endPlate, config.landmarkPosition].join('-');

    this.deleteLandmark(config);

    this.landmarkConfigMap.set(landmarkKey, config);

    return this.addAnnotation(config.meshId, {
      type: 'sphere',
      hexColor: this.getLandmarkColor(config.endPlate, config.landmarkPosition),
      position: config.point,
      opacity,
      tags: [
        BabylonSceneManager.LANDMARK_TAG,
        config.vertebralBody,
        config.landmarkPosition,
        config.endPlate,
      ],
      size: 2,
    });
  }

  public deleteLandmark(config: Omit<LandmarkConfig, 'point'>) {
    const landmarkKey = [config.vertebralBody, config.endPlate, config.landmarkPosition].join('-');

    const currentLandmark = this.landmarkConfigMap.get(landmarkKey);

    if (currentLandmark) {
      this.removeAnnotations(config.meshId, 'sphere', [
        BabylonSceneManager.LANDMARK_TAG,
        currentLandmark.vertebralBody,
        currentLandmark.landmarkPosition,
        currentLandmark.endPlate,
      ]);
    }

    this.landmarkConfigMap.delete(landmarkKey);
  }

  public deleteLandmarks() {
    const landmarks = this.getLandmarks();
    for (const landmark of landmarks) {
      this.deleteLandmark(landmark);
    }
  }

  public getLandmarks(): LandmarkConfig[] {
    return [...this.landmarkConfigMap.values()];
  }

  public getLandmark(
    body: VertebralBody,
    endPlate: EndPlate,
    position: Position,
  ): LandmarkConfig | undefined {
    return this.getLandmarks().find(
      (landmark) =>
        landmark.vertebralBody === body &&
        landmark.endPlate === endPlate &&
        landmark.landmarkPosition === position,
    );
  }

  public createLandmarks(configs: LandmarkConfig[], opacity: number = 1) {
    const createdLandmarks = configs.map((config) => this.createLandmark(config, opacity));

    return _.compact(createdLandmarks);
  }

  public drawAngleFromSingleOrigin({
    plane,
    drawingConfig,
    color,
    tags,
    lineLength,
    value,
    valueName,
  }: {
    plane: {
      point: Vector3;
      normal: Vector3;
    };
    drawingConfig: IAngleOriginVectorData;
    color: string;
    tags: string[];
    lineLength: number;
    value: string;
    valueName: string;
  }) {
    const originVector = this.convertPublicVector3(drawingConfig.origin);
    const topVector = this.convertPublicVector3(drawingConfig.topVector);
    const bottomVector = this.convertPublicVector3(drawingConfig.bottomVector);

    const interfacePlane = BABYLON.Plane.FromPositionAndNormal(
      this.convertPublicVector3(plane.point),
      this.convertPublicVector3(plane.normal),
    );

    const projectedPoints = {
      origin: projectPointOntoPlane(originVector, interfacePlane),
      topVector: projectVectorOntoPlane(topVector, interfacePlane),
      bottomVector: projectVectorOntoPlane(bottomVector, interfacePlane),
    };

    const topEndPoint = projectedPoints.origin.add(projectedPoints.topVector.scale(lineLength));

    const topBottomPoint = projectedPoints.origin.add(
      projectedPoints.bottomVector.scale(lineLength),
    );

    const lineMidpoint = topEndPoint.add(topBottomPoint).scale(0.5);

    this.createBillboardLabel(
      lineMidpoint,
      projectedPoints.bottomVector,
      `${valueName}.billboard`,
      tags,
      0,
      `${valueName}: ${value}°`,
      20,
      undefined,
      color,
      color,
      undefined,
    );

    this.createSphere(`${valueName}.origin`, tags, projectedPoints.origin, 1, color);

    this.createLine(projectedPoints.origin, topEndPoint, `${valueName}.topLine`, color, tags);

    this.createLine(projectedPoints.origin, topBottomPoint, `${valueName}.bottomLine`, color, tags);
  }

  public drawPlumbLineDistance({
    plane,
    drawingConfig,
    color,
    tags,
    lineLength,
    value,
    valueName,
  }: {
    plane: {
      point: Vector3;
      normal: Vector3;
    };
    drawingConfig: IPlumbLineDistanceVectorData;
    color: string;
    tags: string[];
    lineLength: number;
    value: string;
    valueName: string;
  }) {
    const pointA = this.convertPublicVector3(drawingConfig.pointA);
    const pointB = this.convertPublicVector3(drawingConfig.pointB);
    const upVector = this.convertPublicVector3(drawingConfig.upVector);

    const interfacePlane = BABYLON.Plane.FromPositionAndNormal(
      this.convertPublicVector3(plane.point),
      this.convertPublicVector3(plane.normal),
    );

    const projectedPoints = {
      pointA: projectPointOntoPlane(pointA, interfacePlane),
      pointB: projectPointOntoPlane(pointB, interfacePlane),
      upVector: projectVectorOntoPlane(upVector, interfacePlane).negate(),
    };

    this.createSphere(`${valueName}.pointA`, tags, projectedPoints.pointA, 1, color);

    this.createSphere(`${valueName}.pointB`, tags, projectedPoints.pointB, 1, color);

    const cSVAPointAEndLine = projectedPoints.pointA.add(
      projectedPoints.upVector.scale(lineLength),
    );
    const cSVAPointBEndLine = projectedPoints.pointB.add(
      projectedPoints.upVector.scale(lineLength),
    );

    this.createLine(
      projectedPoints.pointA,
      cSVAPointAEndLine,
      `${valueName}.pointALine`,
      color,
      tags,
    );

    this.createLine(
      projectedPoints.pointB,
      projectedPoints.pointB.add(projectedPoints.upVector.scale(lineLength)),
      `${valueName}.pointBLine`,
      color,
      tags,
    );

    const lineMidpoint = cSVAPointAEndLine
      .add(cSVAPointBEndLine)
      .divide(new BABYLON.Vector3(2, 2, 2));

    this.createBillboardLabel(
      lineMidpoint,
      projectedPoints.upVector,
      `${valueName}.billboard`,
      tags,
      0,
      `${valueName}: ${value}mm`,
      20,
      4,
      color,
      color,
      undefined,
    );
  }

  public drawAngleFromTwoOrigins({
    plane,
    drawingConfig,
    color,
    tags,
    lineLength,
    value,
    valueName,
  }: {
    plane: {
      point: Vector3;
      normal: Vector3;
    };
    drawingConfig: IAngleVectorData;
    color: string;
    tags: string[];
    lineLength: number;
    value: string;
    valueName: string;
  }) {
    const pointA = this.convertPublicVector3(drawingConfig.pointA);
    const pointB = this.convertPublicVector3(drawingConfig.pointB);
    const vectorA = this.convertPublicVector3(drawingConfig.vectorA);
    const vectorB = this.convertPublicVector3(drawingConfig.vectorB);

    const interfacePlane = BABYLON.Plane.FromPositionAndNormal(
      this.convertPublicVector3(plane.point),
      this.convertPublicVector3(plane.normal),
    );

    const projectedPoints = {
      pointA: projectPointOntoPlane(pointA, interfacePlane),
      pointB: projectPointOntoPlane(pointB, interfacePlane),
      vectorA: projectVectorOntoPlane(vectorA, interfacePlane),
      vectorB: projectVectorOntoPlane(vectorB, interfacePlane),
    };

    this.createSphere(`${valueName}.pointA`, tags, projectedPoints.pointA, 1, color);

    this.createSphere(`${valueName}.pointB`, tags, projectedPoints.pointB, 1, color);

    const clPointAEndLine = projectedPoints.pointA.add(projectedPoints.vectorA.scale(lineLength));
    const clPointBEndLine = projectedPoints.pointB.add(projectedPoints.vectorB.scale(lineLength));

    this.createLine(
      projectedPoints.pointA,
      clPointAEndLine,
      `${valueName}.pointALine`,
      color,
      tags,
    );

    this.createLine(
      projectedPoints.pointB,
      clPointBEndLine,
      `${valueName}.pointBLine`,
      color,
      tags,
    );

    const lineMidpoint = clPointAEndLine.add(clPointBEndLine).divide(new BABYLON.Vector3(2, 2, 2));

    this.createBillboardLabel(
      lineMidpoint,
      new BABYLON.Vector3(0, 0, 1),
      `${valueName}.billboard`,
      tags,
      0,
      `${valueName}: ${value}°`,
      20,
      undefined,
      color,
      color,
      undefined,
    );
  }

  public drawLine({
    drawingConfig,
    color,
    tags,
    valueName,
  }: {
    drawingConfig: ILineVectorData;
    color: string;
    tags: string[];
    valueName: string;
  }) {
    const pointA = this.convertPublicVector3(drawingConfig.pointA);

    const pointB = this.convertPublicVector3(drawingConfig.pointB);

    this.createPipe([pointA, pointB], `${valueName}.pointABLine`, 0.5, color, tags);
  }

  public loadSpine(
    spineProfile: CaseSpineProfile,
    assets: LoadedSpineConfig[],
    colorTagMap: Record<string, string>,
  ) {
    const config = SPINE_PROFILE_CONFIG_MAP[spineProfile];

    const assetConfigs: LoadedAssetConfig[] = assets.flatMap((asset) => {
      const vertebralBody = getAssetVertebralBody(asset.assetType);

      let parent: MeshIdentification | undefined;

      let mesh: MeshIdentification;

      let color: string | undefined = colorTagMap[asset.tag];

      const tags = [asset.tag, ...(asset.otherTags ?? [])];

      if (vertebralBody) {
        const parentVertebralBody = config.vertebralInfoMap[vertebralBody]?.parent;

        parent = parentVertebralBody
          ? {
              meshName: parentVertebralBody,
              tags,
            }
          : undefined;

        mesh = {
          meshName: vertebralBody,
          tags,
        };
      } else {
        mesh = {
          meshName: asset.assetType,
          tags,
        };
      }

      const meshConfigs = [
        {
          assetUrl: asset.assetUrl,
          mesh: {
            ...mesh,
            tags: [...(mesh.tags ?? []), BabylonSceneManager.ORIGINAL_TAG],
          },
          meshConfig: {
            parent: parent,
            color,
            opacity: asset.opacity,
            isVisible: true,
          },
        },
      ];

      if (asset.editedAssetUrl) {
        meshConfigs.push({
          assetUrl: asset.editedAssetUrl,
          mesh: {
            meshName: `${mesh.meshName}_EDITED`,
            tags: [...(mesh.tags ?? []), BabylonSceneManager.EDITED_TAG],
          },
          meshConfig: {
            parent: mesh,
            color,
            opacity: 0,
            isVisible: false,
          },
        });
      }

      return meshConfigs;
    });

    return this.loadAssets(assetConfigs);
  }

  public focusMesh(
    meshId: MeshIdentification,
    cameraView: BabylonCameraView = 'AXIAL_TOP',
    otherOpacity: number = 0,
    disableCameraAnimation = false,
  ) {
    this.scene.meshes.forEach((m) => {
      this._updateMesh(m as BABYLON.Mesh, { opacity: otherOpacity, isPickable: false });
    });

    const mesh = this.getMeshByIdentification(meshId);

    if (!mesh) return;

    this._updateMesh(mesh, {
      opacity: 1,
      isPickable: true,
    });

    this.zoom({
      cameraView,
      target: meshId,
      disableAnimation: disableCameraAnimation,
    });
  }

  public resetMeshFocus(isPickable: boolean = true) {
    this.scene.meshes.forEach((m) =>
      this._updateMesh(m as BABYLON.Mesh, {
        opacity: 1,
        isPickable,
      }),
    );
  }

  public toggleEditedSpine(visible: boolean, selectedBody?: VertebralBody) {
    this.originalMeshConfig.forEach((_, serialId) => {
      const deserializedMeshId = this.deserializeMeshId(serialId);

      if (!deserializedMeshId) return;

      const mesh = this.getMeshByIdentification(deserializedMeshId);

      if (!mesh) return;

      if (!!selectedBody && mesh.name !== selectedBody) {
        return;
      }

      const meshTags = this.getMeshTags(mesh);

      if (meshTags.includes(BabylonSceneManager.EDITED_TAG)) return;

      const editedMesh = mesh.getChildMeshes(true, (m) =>
        BABYLON.Tags.MatchesQuery(m, BabylonSceneManager.EDITED_TAG),
      )?.[0];

      if (editedMesh) {
        this._updateMesh(editedMesh as BABYLON.Mesh, {
          isVisible: visible,
        });

        this._updateMesh(mesh, {
          isVisible: !visible,
        });
      } else {
        // NOTE - If there is no edited mesh, ensure that the opacity gets set to 1 to match the opacity of any visible edited assets
        this._updateMesh(mesh, {
          isVisible: true,
        });
      }
    });
  }

  public toggleClippingPlane(
    visible: boolean,
    clipPlaneConfig?: {
      cameraView: BabylonCameraView;
      targetMeshId: MeshIdentification;
    },
  ) {
    const getStencilData = (mesh: BABYLON.Mesh) => {
      const uniqueMeshId = mesh.name + this.getMeshTags(mesh).sort().join('+');

      const stencilPlaneMeshName = uniqueMeshId + '-stencil';

      const meshInsideName = uniqueMeshId + '-inside';

      let stencilMask = 0xff;

      let stencilColor = BABYLON.Color3.White();

      const meshTags = this.getMeshTags(mesh);

      if (meshTags.includes(SpineAssetTags.Plan)) {
        stencilMask = 0x0001;
        stencilColor = BABYLON.Color3.FromHexString(PlanAssetColorType.PlanVertebrae);
      } else if (meshTags.includes(SpineAssetTags.PreOp)) {
        stencilMask = 0x0003;
        stencilColor = BABYLON.Color3.FromHexString(PlanAssetColorType.PreopVertebrae);
      }

      return {
        meshNames: {
          stencil: stencilPlaneMeshName,
          inside: meshInsideName,
        },
        stencilMask,
        stencilColor,
      };
    };

    const existingClipPlane = this.scene.getMeshByName(BabylonSceneManager.CLIPPING_PLANE_NAME);

    if (existingClipPlane) this.scene.removeMesh(existingClipPlane);

    const allLoadedMeshes = this.scene.getMeshesByTags(BabylonSceneManager.LOADED_ASSET_TAG);

    let min = new BABYLON.Vector3(Infinity, Infinity, Infinity);
    let max = new BABYLON.Vector3(-Infinity, -Infinity, -Infinity);

    allLoadedMeshes.forEach((mesh) => {
      if (!mesh.material) return;

      mesh.material.clipPlane = null;
      mesh.material.backFaceCulling = true;

      const minWorld = mesh.getBoundingInfo().boundingBox.minimumWorld;
      const maxWorld = mesh.getBoundingInfo().boundingBox.maximumWorld;

      min = BABYLON.Vector3.Minimize(min, minWorld);
      max = BABYLON.Vector3.Maximize(max, maxWorld);

      const stencilData = getStencilData(mesh);

      this.scene.getMeshByName(stencilData.meshNames.stencil)?.dispose(undefined, true);

      this.scene.getMeshByName(stencilData.meshNames.inside)?.dispose(undefined, true);

      mesh.onBeforeRenderObservable.clear();
    });

    const existingLight = this.scene.getLightByName(BabylonSceneManager.CLIPPING_PLANE_LIGHT);

    if (existingLight) this.scene.removeLight(existingLight);

    this.clippingPlaneConfig = null;

    if (!visible || !clipPlaneConfig) return;

    let planeNormal = BABYLON.Vector3.Zero();
    let heightDist = 0;
    let widthDist = 0;
    let crossSectionDist = 0;

    switch (clipPlaneConfig.cameraView) {
      case 'AXIAL_TOP':
        planeNormal.y = 1;
        heightDist = max.z - min.z;
        widthDist = max.x - min.x;
        crossSectionDist = max.y - min.y;
        break;
      case 'AXIAL_BOTTOM':
        planeNormal.y = -1;
        heightDist = max.z - min.z;
        widthDist = max.x - min.x;
        crossSectionDist = max.y - min.y;
        break;

      case 'CORONAL':
        planeNormal.z = -1;
        heightDist = max.y - min.y;
        widthDist = max.x - min.x;
        crossSectionDist = max.z - min.z;

        break;

      case 'SAGITTAL_LEFT':
        planeNormal.x = 1;
        heightDist = max.y - min.y;
        widthDist = max.z - min.z;
        crossSectionDist = max.x - min.x;

        break;

      case 'SAGITTAL_RIGHT':
        planeNormal.x = -1;
        heightDist = max.y - min.y;
        widthDist = max.z - min.z;
        crossSectionDist = max.x - min.x;

        break;
    }

    const dPlane = -1;

    const clippingPlaneObj = new BABYLON.Plane(planeNormal.x, planeNormal.y, planeNormal.z, dPlane);

    // NOTE - The ratio determines how the clipping plane dimensions should be scaled (larger values indicate more padding around the mesh)
    const ratio = BabylonSceneManager.CLIPPING_PLANE_SCALE_RATIO;

    const planeMesh = BABYLON.MeshBuilder.CreatePlane(
      BabylonSceneManager.CLIPPING_PLANE_NAME,
      {
        sourcePlane: clippingPlaneObj,
        width: widthDist * ratio,
        height: heightDist * ratio,
      },
      this.scene,
    );

    const planeMat = new BABYLON.StandardMaterial(
      BabylonSceneManager.CLIPPING_PLANE_NAME + 'mat',
      this.scene,
    );

    planeMat.backFaceCulling = false;

    planeMat.alpha = 0.2;

    planeMesh.material = planeMat;

    planeMesh.renderingGroupId = 1;

    const matchingMesh = this.getMeshByIdentification(clipPlaneConfig.targetMeshId);

    if (!matchingMesh) return;

    const targetMeshCenter = calculateMidpoint(min, max);

    planeMesh.setAbsolutePosition(targetMeshCenter);

    clippingPlaneObj.d = -BABYLON.Vector3.Dot(targetMeshCenter, clippingPlaneObj.normal);

    this.clippingPlaneConfig = {
      plane: clippingPlaneObj,
      initialPosition: targetMeshCenter,
      // NOTE - Add a little padding such that the edges are not cut off when clipping
      scaleFactor: crossSectionDist,
    };

    let previousStencilFunction = this.engine.getStencilFunction();
    let previousStencilMask = this.engine.getStencilMask();

    allLoadedMeshes.forEach((mesh, index) => {
      const stencilData = getStencilData(mesh);

      const stencilMask = stencilData.stencilMask;

      if (!mesh.material || !this.clippingPlaneConfig?.plane) return;

      mesh.material.backFaceCulling = false;

      const meshColor = stencilData.stencilColor;

      const createStencilPlane = () => {
        const stencilPlaneMeshName = stencilData.meshNames.stencil;

        this.scene.getMeshByName(stencilPlaneMeshName)?.dispose(undefined, true);

        const stencilPlane = BABYLON.MeshBuilder.CreatePlane(
          stencilPlaneMeshName,
          {
            sourcePlane: clippingPlaneObj,
            width: widthDist * ratio,
            height: heightDist * ratio,
            updatable: true,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          },
          this.scene,
        );
        let stencilPlaneMaterial = new BABYLON.StandardMaterial(
          stencilPlaneMeshName + '-mat',
          this.scene,
        );

        // NOTE - Set zOffset different for every mesh; prevent flickering; 2 is added to appear above the mesh and mesh inside
        stencilPlaneMaterial.zOffset = index + 2;

        stencilPlaneMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        stencilPlaneMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        stencilPlaneMaterial.emissiveColor = meshColor;
        stencilPlaneMaterial.ambientColor = new BABYLON.Color3(0, 0, 0);

        // NOTE - Create striped texture
        const size = 512;
        const lineGap = 10;

        const dynamicTexture = new BABYLON.DynamicTexture(
          stencilPlaneMeshName + '-texture',
          {
            width: size,
            height: size,
          },
          this.scene,
          false,
        );

        const context = dynamicTexture.getContext();

        context.fillStyle = meshColor.toHexString();

        context.fillRect(0, 0, size, size);

        // Create diagonal stripes
        context.strokeStyle = meshColor.scale(0.9).toHexString();

        context.lineWidth = 1;

        for (let i = -size; i < size; i += lineGap) {
          context.beginPath();
          context.moveTo(i, 0);
          context.lineTo(i + size, size);
          context.stroke();
        }

        dynamicTexture.update();
        stencilPlaneMaterial.diffuseTexture = dynamicTexture;

        stencilPlane.material = stencilPlaneMaterial;

        return stencilPlane;
      };

      const createMeshInside = () => {
        const meshInsideName = stencilData.meshNames.inside;

        this.scene.getMeshByName(meshInsideName)?.dispose(undefined, true);

        const meshInside = mesh.clone(meshInsideName, undefined, true);

        BABYLON.Tags.RemoveTagsFrom(meshInside, BabylonSceneManager.LOADED_ASSET_TAG);

        let meshInsideMaterial = new CustomMaterial(meshInsideName + '-mat', this.scene);
        meshInsideMaterial.diffuseColor = (
          mesh.material as BABYLON.StandardMaterial
        ).diffuseColor.clone();
        meshInsideMaterial.alpha = 0;
        meshInsideMaterial.backFaceCulling = false;
        meshInsideMaterial.Fragment_Before_FragColor('\
      if(gl_FrontFacing) discard;\
        ');

        meshInside.material = meshInsideMaterial;

        return meshInside;
      };

      const meshInside = createMeshInside();
      const stencilPlane = createStencilPlane();

      // render in this order:
      // 1) outside of the mesh - front faces
      // 2) inside of the mesh - back faces
      // 3) stencil plane material on top of the inside of the mesh
      this.scene.setRenderingAutoClearDepthStencil(0, false);
      this.scene.setRenderingAutoClearDepthStencil(1, false);
      this.scene.setRenderingAutoClearDepthStencil(2, false);

      mesh.renderingGroupId = 0;
      meshInside.renderingGroupId = 1;
      stencilPlane.renderingGroupId = 2;

      mesh.onBeforeRenderObservable.add(() => {
        const plane = this.clippingPlaneConfig!.plane;

        mesh.material!.clipPlane = plane;

        const point = mesh.getBoundingInfo().boundingSphere.centerWorld.clone();

        const planeProj = point.projectOnPlane(plane, point.add(plane.normal));

        stencilPlane.setAbsolutePosition(planeProj);

        stencilPlane.setEnabled(mesh.material!.alpha !== 0);
      });

      // mesh inside observables
      meshInside.onBeforeRenderObservable.add(() => {
        meshInside.material!.clipPlane = this.clippingPlaneConfig!.plane;

        if (mesh.material!.alpha) {
          this.engine.setStencilBuffer(true);
          this.engine.setStencilMask(stencilMask);
        }
      });

      meshInside.onAfterRenderObservable.add(() => {
        meshInside.material!.clipPlane = null;
        this.engine.setStencilBuffer(false);
        this.engine.setStencilMask(previousStencilMask);
      });

      stencilPlane.onBeforeRenderObservable.add(() => {
        this.engine.setStencilBuffer(true);
        this.engine.setStencilMask(stencilMask);
        this.engine.setStencilFunctionReference(stencilMask);
        this.engine.setStencilFunction(BABYLON.Engine.EQUAL);
      });

      stencilPlane.onAfterRenderObservable.add(() => {
        this.engine.setStencilBuffer(false);
        this.engine.setStencilFunction(previousStencilFunction);
      });
    });
  }

  // Diff should start at 0
  public moveClippingPlane(diff: number) {
    if (!this.clippingPlaneConfig) return;

    const existingClipPlane = this.scene.getMeshByName(BabylonSceneManager.CLIPPING_PLANE_NAME);

    if (!existingClipPlane) return;

    const newPlanePos = this.clippingPlaneConfig.initialPosition.add(
      this.clippingPlaneConfig.plane.normal
        .clone()
        .scale(diff * this.clippingPlaneConfig.scaleFactor),
    );

    existingClipPlane.setAbsolutePosition(newPlanePos);

    this.clippingPlaneConfig.plane.d = -BABYLON.Vector3.Dot(
      newPlanePos,
      this.clippingPlaneConfig.plane.normal,
    );
  }

  public toggleSplitScreen(enabled: boolean) {
    this.splitScreenEnabled = enabled;

    if (!enabled) {
      this.scene.getCameraByName(BabylonSceneManager.ALTERNATE_CAMERA_NAME)?.dispose();

      if (!this.scene.activeCamera) return;

      this.scene.activeCamera.layerMask = 0x0fffffff;

      this.scene.activeCamera.viewport = new BABYLON.Viewport(0, 0, 1, 1);

      const mainLight = this.scene.getLightByName(
        BabylonSceneManager.MAIN_LIGHT_NAME,
      ) as BABYLON.DirectionalLight;

      if (!mainLight) return;

      this.setOrthoCameraProperties();

      this.scene.registerBeforeRender(() => {
        const activeCamera = this.scene.activeCamera as BABYLON.ArcRotateCamera;

        if (!activeCamera) return;

        mainLight.position.copyFrom(activeCamera.position);
        mainLight.setDirectionToTarget(activeCamera.getTarget());
      });

      return;
    }

    const mainCamera = this.scene.activeCamera as BABYLON.ArcRotateCamera;

    let alternateCamera = new BABYLON.ArcRotateCamera(
      BabylonSceneManager.ALTERNATE_CAMERA_NAME,
      0,
      0,
      0,
      BABYLON.Vector3.Zero(),
      this.scene,
    );

    alternateCamera.mode = mainCamera.mode;

    this.setOrthoCameraProperties();

    this.scene.registerBeforeRender(() => {
      alternateCamera.target = mainCamera.target;
      alternateCamera.radius = mainCamera.radius;
      alternateCamera.alpha = mainCamera.alpha;
      alternateCamera.beta = mainCamera.beta;

      alternateCamera.rotation = mainCamera.rotation;
      alternateCamera.rotationQuaternion = mainCamera.rotationQuaternion;

      alternateCamera.orthoLeft = mainCamera.orthoLeft;
      alternateCamera.orthoRight = mainCamera.orthoRight;
      alternateCamera.orthoBottom = mainCamera.orthoBottom;
      alternateCamera.orthoTop = mainCamera.orthoTop;
      alternateCamera.upVector = mainCamera.upVector;
    });

    // This attaches the camera to the canvas
    mainCamera.layerMask = BabylonSceneManager.PREOP_LAYER_MASK;
    alternateCamera.layerMask = BabylonSceneManager.PLAN_LAYER_MASK;

    mainCamera.viewport = new BABYLON.Viewport(0, 0, 0.5, 1);
    alternateCamera.viewport = new BABYLON.Viewport(0.5, 0, 0.5, 1);

    this.scene.activeCameras?.push(alternateCamera);

    this.scene.activeCameras?.push(mainCamera);

    this.scene.activeCamera = mainCamera;
  }

  private handlePointerDownCamRot = ((event: PointerEvent) => {
    this.pointerPosCamRot = {
      x: event.clientX,
      y: event.clientY,
    };
  }).bind(this);

  private handlePointerUpCamRot = (() => {
    this.pointerPosCamRot = null;
  }).bind(this);

  private handlePointerMoveCamRot = ((event: PointerEvent) => {
    if (!this.pointerPosCamRot) return;

    const cameras = this.getSceneCameras();

    if (!cameras?.length) return;

    const sensitivity = BabylonSceneManager.CAMERA_ROTATION_SENSITIVITY;
    const delta = event.clientX - this.pointerPosCamRot.x;

    function handleRotateCamera(camera: BABYLON.ArcRotateCamera) {
      const localAxis = camera.getDirection(BABYLON.Axis.Z);

      const upVector = camera.upVector.clone();
      const axis = BABYLON.Quaternion.RotationAxis(localAxis, delta / sensitivity);
      upVector.applyRotationQuaternionInPlace(axis);

      camera.upVector = upVector;

      camera.rebuildAnglesAndRadius();
    }

    cameras.forEach((c) => handleRotateCamera(c));

    this.pointerPosCamRot = {
      x: event.clientX,
      y: event.clientY,
    };
  }).bind(this);

  public toggleCameraRotation(enabled: boolean) {
    const camera = this.scene?.activeCamera as BABYLON.ArcRotateCamera;

    const canvas = this.scene?.getEngine().getRenderingCanvas();

    if (!camera || !canvas) return;

    camera.upVector = BABYLON.Axis.Y.clone();
    camera.rebuildAnglesAndRadius();

    if (enabled) {
      camera.inputs.attached.pointers.detachControl();

      canvas.addEventListener('pointerdown', this.handlePointerDownCamRot);
      canvas.addEventListener('pointermove', this.handlePointerMoveCamRot);
      canvas.addEventListener('pointerup', this.handlePointerUpCamRot);
    } else {
      camera.inputs.attachInput(camera.inputs.attached.pointers);

      canvas.removeEventListener('pointerdown', this.handlePointerDownCamRot);
      canvas.removeEventListener('pointermove', this.handlePointerMoveCamRot);
      canvas.removeEventListener('pointerup', this.handlePointerUpCamRot);
    }
  }
}
