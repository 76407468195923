import { LevelSize } from '@workflow-nx/common';

export type NtopConfigurationInput = {
  description: string;
  name: string;
  type: 'text' | 'vector' | 'real' | 'integer';
  units?: 'rad' | 'mm' | 'deg';
  value: string | number | number[];
};

export type NtopConfiguration = {
  description?: string;
  title?: string;
  inputs: NtopConfigurationInput[];
};

export function getImplantSize(size: LevelSize, plusLevelSize: number) {
  let implantSizeValue = 0; // plan
  switch (size) {
    case LevelSize.Plus:
      // default is 2 (for 2mm) bigger than the plan size
      // the implant will grow (plusLevelSize / 2) in
      // each direction
      implantSizeValue = -1 * (plusLevelSize / 2);
      break;
    case LevelSize.Minus:
      implantSizeValue = 0.5;
      break;
  }
  return implantSizeValue;
}
