import { useQuery } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material';
import { IOrganization, OrganizationType } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { FIND_ORGANIZATIONS } from '../gql';
import { Select } from './Select';

export function OrganizationSelect(props: {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  disabled: boolean;
}) {
  const [menuItems, setMenuItems] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  useQuery(FIND_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { organizationTypeFilter: [OrganizationType.Hospital] },
    onCompleted: (orgData) => {
      const organizations = orgData.organizations.organizations || [];
      setMenuItems(
        sortBy(
          organizations.map((org: IOrganization) => {
            return {
              key: org.organizationId,
              value: org.name,
            };
          }),
          ['value'],
        ),
      );
    },
  });

  return (
    <Select
      name={'organization'}
      label={'Institution'}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      menuItems={menuItems}
      fullWidth={true}
    />
  );
}
