import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  CaseApproachType,
  CaseSpineProfile,
  CaseSpineType,
  caseUtils,
  format,
  IForm20Product,
  Permission,
} from '@workflow-nx/common';
import { SelectField } from '@workflow-nx/ui';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { FIND_SETTINGS } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';

export function AdditionalCaseDetailsForm(props: {
  disabled: boolean;
  caseSpineType: CaseSpineType;
  caseSpineProfile: CaseSpineProfile;
}) {
  const auth = useAuth();
  const [excludableInstruments, setExcludableInstruments] = useState<
    { key: string; value: string }[]
  >([]);
  const hasManageCaseInventoryPermission = !!auth?.hasPermission?.([
    Permission.ManageCaseInventory,
  ]);

  useQuery(FIND_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: (settingsData) => {
      const form20Products = settingsData?.settings?.form20 || [];
      const foundExcludableInstruments = sortBy(
        caseUtils
          .getValidExcludableInstruments(form20Products)
          .map((form20Product: IForm20Product) => {
            return {
              key: form20Product.sku,
              value: form20Product.sku + ' - ' + form20Product.description,
            };
          }),
        ['value'],
      );
      setExcludableInstruments(foundExcludableInstruments);
    },
  });

  const spineProfileConfig = caseUtils.getCaseSpineProfile(props.caseSpineProfile);

  const cervicalLivLevels = caseUtils
    .getVertebralBodiesSortedByHierarchy(
      props.caseSpineType === CaseSpineType.Cervical
        ? props.caseSpineProfile
        : CaseSpineProfile.CervicalStandard,
      'desc',
    )
    .map((vBody) => ({ key: vBody, value: vBody }));

  const lumbarLivLevels = caseUtils
    .getVertebralBodiesSortedByHierarchy(
      props.caseSpineType === CaseSpineType.Lumbar
        ? props.caseSpineProfile
        : CaseSpineProfile.LumbarStandard,
      'desc',
    )
    .map((vBody) => ({ key: vBody, value: vBody }));

  const uivProcedureLevelMenuItems = [
    ...cervicalLivLevels,
    { key: 'T1', value: 'T1' },
    { key: 'T2', value: 'T2' },
    { key: 'T3', value: 'T3' },
    { key: 'T4', value: 'T4' },
    { key: 'T5', value: 'T5' },
    { key: 'T6', value: 'T6' },
    { key: 'T7', value: 'T7' },
    { key: 'T8', value: 'T8' },
    { key: 'T9', value: 'T9' },
    { key: 'T10', value: 'T10' },
    { key: 'T11', value: 'T11' },
    { key: 'T12', value: 'T12' },
    ...lumbarLivLevels,
  ];

  const livProcedureLevelMenuItems = [
    ...uivProcedureLevelMenuItems,
    { key: 'PELVIS', value: 'Pelvis' },
  ];

  const pcoLevelMenuItems = [
    { key: 'T1_T2', value: 'T1/T2' },
    { key: 'T2_T3', value: 'T2/T3' },
    { key: 'T3_T4', value: 'T3/T4' },
    { key: 'T4_T5', value: 'T4/T5' },
    { key: 'T5_T6', value: 'T5/T6' },
    { key: 'T6_T7', value: 'T6/T7' },
    { key: 'T7_T8', value: 'T7/T8' },
    { key: 'T8_T9', value: 'T8/T9' },
    { key: 'T9_T10', value: 'T9/T10' },
    { key: 'T10_T11', value: 'T10/T11' },
    { key: 'T11_T12', value: 'T11/T12' },
    { key: 'T12_L1', value: 'T12/L1' },
    ...spineProfileConfig.validLevels.map((level) => ({
      key: level,
      value: format.formatLevelType(level),
    })),
  ];

  const psoLevelMenuItems = [
    { key: 'T1', value: 'T1' },
    { key: 'T2', value: 'T2' },
    { key: 'T3', value: 'T3' },
    { key: 'T4', value: 'T4' },
    { key: 'T5', value: 'T5' },
    { key: 'T6', value: 'T6' },
    { key: 'T7', value: 'T7' },
    { key: 'T8', value: 'T8' },
    { key: 'T9', value: 'T9' },
    { key: 'T10', value: 'T10' },
    { key: 'T11', value: 'T11' },
    { key: 'T12', value: 'T12' },
    ...spineProfileConfig.validVertebralBodies.map((vBody) => ({ key: vBody, value: vBody })),
  ];

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item xs>
        <SelectField
          name={'excludedInstruments'}
          label={'Excluded Instruments'}
          multiple={true}
          hideNone={true}
          menuItems={excludableInstruments}
          disabled={props.disabled || !hasManageCaseInventoryPermission}
        />
      </Grid>
      <Grid item xs>
        <SelectField
          name={'approach'}
          label={'Surgical Goal'}
          menuItems={[
            {
              key: CaseApproachType.ZeroMismatch,
              value: format.formatCaseApproach(CaseApproachType.ZeroMismatch),
            },
            {
              key: CaseApproachType.AgeAdjusted,
              value: format.formatCaseApproach(CaseApproachType.AgeAdjusted),
            },
            {
              key: CaseApproachType.GapScore,
              value: format.formatCaseApproach(CaseApproachType.GapScore),
            },
            {
              key: CaseApproachType.FocalAlignment,
              value: format.formatCaseApproach(CaseApproachType.FocalAlignment),
            },
          ]}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs>
        <SelectField
          name={'uiv'}
          label={'UIV'}
          menuItems={uivProcedureLevelMenuItems}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs>
        <SelectField
          name={'liv'}
          label={'LIV'}
          menuItems={livProcedureLevelMenuItems}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs>
        <SelectField
          name={'pco'}
          label={'PCO'}
          multiple={true}
          hideNone={true}
          menuItems={pcoLevelMenuItems}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs>
        <SelectField
          name={'pso'}
          label={'PSO'}
          multiple={true}
          hideNone={true}
          menuItems={psoLevelMenuItems}
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  );
}
