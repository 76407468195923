import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import { DashboardCardView } from './DashboardCardView';
import { Box, Link, Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client/core';
import { DaisySegmentationStatus, IDaisySegmentationQueue, format } from '@workflow-nx/common';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCircleExclamation,
  faCircleNotch,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';

const DaisyCaseTracker = () => {
  const [groupedQueueItems, setGroupedQueueItems] = useState<{
    [key: string]: IDaisySegmentationQueue[];
  }>({});
  const { loading } = useQuery(
    gql`
      query {
        daisySegmentationQueues {
          status
          sacrumStatus
          vertebraeStatus
          createdAt
          startedAt
          finishedAt
          case {
            caseId
            number
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: 120000, // poll every 2 minutes
      onCompleted: (data) => {
        const daisySegmentationQueues = (data.daisySegmentationQueues ??
          []) as (IDaisySegmentationQueue & { createdDate: string })[];
        const mappedQueueItems = daisySegmentationQueues.map((dsq) => {
          return {
            ...dsq,
            createdDate: format.formatDate(dsq.createdAt),
          };
        }) as unknown as (IDaisySegmentationQueue & { createdDate: string })[];
        const groupedQueueItems = _.groupBy(mappedQueueItems, 'createdDate');

        setGroupedQueueItems(groupedQueueItems);
      },
    },
  );

  function getBackgroundColor(status: DaisySegmentationStatus) {
    switch (status) {
      case 'PENDING':
        return 'grey';
      case 'PROCESSING':
        return 'yellow';
      case 'ERROR':
        return 'red';
      case 'COMPLETED':
        return 'green';
      default:
        return 'transparent';
    }
  }

  return (
    <DashboardCardView label={'Daisy Segmentations'}>
      {loading ? (
        <Box height={'inherit'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {Object.entries(groupedQueueItems).map(([key, queueItems]) => {
            return (
              <Box>
                <Typography key={key} my={1}>
                  <strong>{key}</strong>
                </Typography>

                <Stack spacing={1}>
                  {((queueItems ?? []) as IDaisySegmentationQueue[]).map(
                    (queueDatum: IDaisySegmentationQueue) => {
                      function getIconNameAndProperties(sacrumStatus: DaisySegmentationStatus): {
                        icon: IconProp;
                        spin?: boolean;
                      } {
                        switch (sacrumStatus) {
                          case 'PENDING':
                            return { icon: faCircleNotch, spin: true };
                          case 'PROCESSING':
                            return { icon: faCircleNotch, spin: true };
                          case 'ERROR':
                            return { icon: faCircleExclamation };
                          case 'COMPLETED':
                            return { icon: faCheck };
                          default:
                            return { icon: faQuestion };
                        }
                      }

                      return (
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          direction={'row'}
                          p={1}
                          spacing={1}
                          style={{
                            border: '1px solid #f9f9f9',
                            borderLeft: `5px solid ${getBackgroundColor(queueDatum.status)}`,
                          }}
                        >
                          <Typography variant={'body1'}>
                            <Link
                              underline={'none'}
                              variant={'body1'}
                              href={`/app/cases/${queueDatum?.case?.caseId}`}
                              onClick={(e) => {
                                if (e.button === 2) {
                                  e.preventDefault();
                                }
                              }}
                            >
                              {queueDatum?.case?.number}
                            </Link>
                          </Typography>
                          <Box flexGrow={1} />
                          <Typography variant={'button'}>
                            Sacrum{' '}
                            <FontAwesomeIcon
                              {...getIconNameAndProperties(queueDatum.sacrumStatus)}
                            />
                          </Typography>
                          <Box mx={2} />
                          <Typography variant={'button'}>
                            Vertebrae{' '}
                            <FontAwesomeIcon
                              {...getIconNameAndProperties(queueDatum.vertebraeStatus)}
                            />
                          </Typography>
                        </Stack>
                      );
                    },
                  )}
                </Stack>
              </Box>
            );
          })}
        </>
      )}
    </DashboardCardView>
  );
};
export default DaisyCaseTracker;
