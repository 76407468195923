import { useQuery } from '@apollo/client';
import { Alert, Box, Chip, Divider, Grid, Paper, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CaseSpineType,
  Form20ProductStatusType,
  Form20ProductType,
  format,
  ICase,
  IForm20Product,
  IUser,
  ORDERED_MANUAL_VENDOR_CATEGORIES,
  PlanStatusType,
  UserRoleType,
  VendorTaskCategory,
  ISurgeonPreferences,
  CaseStageType,
  Permission,
} from '@workflow-nx/common';
import _ from 'lodash';
import React, { useState } from 'react';
import { FormattedFieldView } from '../../../../components/FormattedFieldView';
import { TagChip } from '../../../../components/TagChip';
import { FIND_LOCATION_AND_REGIONS_AND_USERS, FIND_SETTINGS, FIND_USER } from '../../../../gql';
import { BookingSheetAssetGrid } from './BookingSheetAssetGrid';
import { CaseStudyDateExpiryView } from './CaseStudyDateExpiryView';
import { ViewCaseDetailsHeaderView } from './ViewCaseDetailsHeaderView';
import { InterbodyLevelsDetailView } from './InterbodyLevelsDetailView';
import useAuth from '../../../../hooks/useAuth';
import EditCaseLocationButton from '../EditCaseLocationButton';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.spacing(1.75),
    color: '#546e7a',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

export function ViewCaseDetails({
  activeCase,
  onChange,
  onEdit,
}: {
  activeCase: ICase;
  onChange?: () => void;
  onEdit?: () => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [excludedInstruments, setExcludedInstruments] = useState<string[]>([]);
  const [locationUsers, setLocationUsers] = useState<string[]>([]);
  const [surgeonPreferences, setSurgeonPreferences] = useState<ISurgeonPreferences>();
  const { hasPermission } = useAuth();

  useQuery(FIND_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: (settingsData) => {
      const form20Products = settingsData?.settings?.form20 || [];
      const foundExcludedInstruments = form20Products
        .filter(
          (element: IForm20Product) =>
            element.status === Form20ProductStatusType.InUse &&
            element.type === Form20ProductType.Instrument &&
            activeCase.excludedInstruments?.includes(element.sku),
        )
        .map((form20Produc: IForm20Product) => {
          return form20Produc.sku + ' - ' + form20Produc.description;
        });
      setExcludedInstruments(foundExcludedInstruments);
    },
  });

  useQuery(FIND_LOCATION_AND_REGIONS_AND_USERS, {
    variables: {
      locationId: activeCase?.location?.locationId || 0,
      roleFilter: [UserRoleType.CustomerRep],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const cssUsers: string[] = (data.location?.locationUser || []).map(
        (user: { user: IUser }) => {
          return format.formatName({
            firstName: user.user.firstName,
            lastName: user.user.lastName,
          });
        },
      );
      const sortedUsers = _.sortBy(cssUsers);
      setLocationUsers(sortedUsers);
    },
  });

  useQuery(FIND_USER, {
    variables: {
      userId: Number(activeCase?.surgeonUser?.userId),
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSurgeonPreferences(data?.user?.preferences as ISurgeonPreferences);
    },
  });

  const getVendor = (taskCategory: VendorTaskCategory) =>
    activeCase?.vendorAssignments?.find((v) => v.taskCategory === taskCategory);

  const isCaseEditable = Boolean(onChange) && Boolean(onEdit);

  return (
    <>
      <Box mb={2}>
        <ViewCaseDetailsHeaderView
          activeCase={activeCase}
          open={open}
          onClick={() => setOpen(!open)}
          onEdit={onEdit}
        />
        {activeCase?.caseTags?.length ? (
          <Box my={1}>
            {_.sortBy(activeCase.caseTags, 'tag.tagCategoryId').map((caseTag) => {
              return <TagChip key={caseTag.caseTagId} tag={caseTag.tag} className={classes.chip} />;
            })}
          </Box>
        ) : null}
        {excludedInstruments.length ? (
          <Box my={1} display="flex" gap={1} alignItems={'center'}>
            <Typography variant={'h5'}>{'Excluded Instruments: '}</Typography>
            {excludedInstruments.map((excludedInstrument, index) => (
              <Typography key={excludedInstrument} variant={'body1'}>
                {excludedInstrument}
                {index !== excludedInstruments.length - 1 && ', '}
              </Typography>
            ))}
          </Box>
        ) : null}
        {surgeonPreferences?.reusableInstruments ? (
          <Box my={1} display="flex" gap={1} alignItems={'center'}>
            <Typography variant={'h5'}>{'Reusable Instruments: '}</Typography>
            <Typography variant={'body1'}>Yes</Typography>
          </Box>
        ) : null}
      </Box>
      {open ? (
        <>
          <Box mb={2}>
            <Divider />
          </Box>
          <Stack
            direction={'row'}
            gap={5}
            justifyContent={'space-between'}
            divider={<Divider variant="middle" orientation="vertical" flexItem />}
          >
            <Stack gap={1}>
              <FormattedFieldView
                label={'Interbody Levels'}
                value={
                  activeCase?.levels ? (
                    <InterbodyLevelsDetailView
                      levels={activeCase.levels}
                      plan={activeCase?.plans?.find((plan) =>
                        [
                          PlanStatusType.Draft,
                          PlanStatusType.Proposed,
                          PlanStatusType.Approved,
                        ].includes(plan.status),
                      )}
                      spineProfile={activeCase.spineProfile}
                    />
                  ) : null
                }
              />
            </Stack>

            <Stack gap={1}>
              <FormattedFieldView
                label={'Surgery Date'}
                value={
                  <Typography variant={'body1'} noWrap={true}>
                    {format.formatISODate(activeCase?.surgeryDate as unknown as string) || 'TBD'}
                    {activeCase?.isSurgeryDateTentative ? (
                      <>
                        <Box pl={1} display={'inline'} />
                        <Chip size={'small'} color={'secondary'} label={'TENTATIVE'} />
                      </>
                    ) : null}
                  </Typography>
                }
              />
              <FormattedFieldView
                label={'Surgeon'}
                value={
                  <Typography variant={'body1'} noWrap={true}>
                    {activeCase?.surgeonUser ? (
                      format.formatName(activeCase?.surgeonUser)
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </Typography>
                }
              />
              <FormattedFieldView
                label={'Surgical Goal'}
                value={format.formatCaseApproach(activeCase.approach)}
              />
              <FormattedFieldView
                label={'Surgery Location'}
                value={
                  <Typography variant={'body1'} noWrap={true}>
                    {activeCase?.location ? (
                      <Box>
                        <Typography variant={'body1'}>
                          {activeCase?.location.description}
                        </Typography>
                        <Typography color={'textSecondary'} variant={'button'}>
                          {activeCase?.location?.organization?.name}
                        </Typography>
                      </Box>
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </Typography>
                }
              />
              {activeCase.stage === CaseStageType.Complete &&
              hasPermission?.([Permission.ManageCaseLocation]) ? (
                <EditCaseLocationButton activeCase={activeCase} />
              ) : null}
            </Stack>
            <Stack gap={1}>
              <FormattedFieldView
                label={'Assigned To'}
                value={
                  activeCase?.assignedUser ? (
                    <Typography variant={'body1'} noWrap={true}>
                      {format.formatName(activeCase?.assignedUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )
                }
              />
              <FormattedFieldView
                label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
                value={
                  activeCase?.fieldRepUser ? (
                    <Typography variant={'body1'} noWrap={true}>
                      {format.formatName(activeCase?.fieldRepUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )
                }
              />
              {locationUsers.length > 0 ? (
                <FormattedFieldView
                  label={`${format.formatUserRole(UserRoleType.CustomerRep)}`}
                  value={
                    <Typography variant={'body1'} noWrap={true}>
                      {locationUsers.join(', ')}
                    </Typography>
                  }
                />
              ) : null}
            </Stack>
            <Stack gap={1}>
              {ORDERED_MANUAL_VENDOR_CATEGORIES.map((category) => {
                const vendor = getVendor(category);
                return (
                  <>
                    <FormattedFieldView
                      label={`${format.formatVendorTaskCategory(category)} Vendor`}
                      value={
                        vendor?.organization?.name ? (
                          <Typography variant={'body1'} noWrap={true}>
                            {vendor?.organization?.name}
                          </Typography>
                        ) : (
                          <span>&mdash;</span>
                        )
                      }
                    />
                  </>
                );
              })}
            </Stack>
            <Stack gap={1}>
              <CaseStudyDateExpiryView
                activeCase={activeCase}
                caseEditable={isCaseEditable}
                onChange={onChange}
              />
              {activeCase.isXrayNotRequired ? (
                <Box display={'flex'} justifyContent={'center'} mt={1}>
                  <Alert severity={'info'}>
                    Surgeon has confirmed that no DICOM X-Ray is required for this case
                  </Alert>
                </Box>
              ) : null}
            </Stack>
          </Stack>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs>
              {activeCase?.spineType === CaseSpineType.Lumbar ? (
                <FormattedFieldView
                  label={'Pelvic Incidence'}
                  value={activeCase?.patient?.patientRecord?.pelvicIncidence ?? <>&mdash;</>}
                />
              ) : activeCase?.spineType === CaseSpineType.Cervical ? (
                <FormattedFieldView
                  label={'Slope of Line of Sight'}
                  value={activeCase?.patient?.patientRecord?.slopeOfLineOfSight ?? <>&mdash;</>}
                />
              ) : null}
              <Box mb={1} />
              <FormattedFieldView
                label={'Lumbar Coronal Angle'}
                value={activeCase?.patient?.patientRecord?.lumbarCoronalCobb ?? <>&mdash;</>}
              />
            </Grid>
            <Grid item xs>
              <FormattedFieldView
                label={'Lumbar Lordosis'}
                value={activeCase?.patient?.patientRecord?.lumbarLordosis ?? <>&mdash;</>}
              />
              <Box mb={1} />
              <FormattedFieldView
                label={'Coronal Balance'}
                value={activeCase?.patient?.patientRecord?.coronalBalance ?? <>&mdash;</>}
              />
            </Grid>
            <Grid item xs>
              <FormattedFieldView
                label={'SVA'}
                value={activeCase?.patient?.patientRecord?.sagittalVerticalAxis ?? <>&mdash;</>}
              />
            </Grid>
            <Grid item xs>
              <FormattedFieldView
                label={'PCO'}
                value={activeCase?.pco.map((pco) => pco.replace('_', '/')).join(', ')}
              />
              <Box mb={1} />
              <FormattedFieldView
                label={'PSO'}
                value={activeCase?.pso.map((pso) => pso.replace('_', '/')).join(', ')}
              />
            </Grid>

            <Grid item xs>
              <FormattedFieldView label={'UIV'} value={activeCase?.uiv} />
              <Box mb={1} />
              <FormattedFieldView label={'LIV'} value={activeCase?.liv} />
            </Grid>
          </Grid>
          <Box my={2}>
            <Divider />
          </Box>
          <Stack spacing={2} my={2} border={'thick'}>
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                mt: 2,
                mx: 2,
              }}
              alignItems={'center'}
            >
              <Typography variant={'h4'}>Booking Sheet</Typography>
            </Stack>
            <Paper>
              <Box p={1}>
                <BookingSheetAssetGrid activeCase={activeCase} />
              </Box>
            </Paper>
          </Stack>
        </>
      ) : null}
    </>
  );
}
