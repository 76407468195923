import { Box, Typography } from '@mui/material';
import {
  format,
  ImplantType,
  ISurgeonPreferences,
  NavigationSystemType,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { IMPLANT_TYPE_CUSTOM_SORT } from '../../../../../utils/surgeonPreferences';

interface ViewSurgeonPreferencesMainProps {
  preferences: ISurgeonPreferences;
}

export function ViewSurgeonPreferencesMain({ preferences }: ViewSurgeonPreferencesMainProps) {
  const sortedPreferredProcedures = sortBy(preferences?.preferredProcedures, (item) =>
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item),
  );

  return (
    <>
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong> Preferred Procedures: </strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {
              <>
                {(sortedPreferredProcedures as ImplantType[])
                  .map(format.formatImplantTypes)
                  .join(', ')}
                {preferences?.preferredProcedures?.length === 0 ? <span>&mdash;</span> : null}
              </>
            }{' '}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong> Sagittal Goal Primary: </strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {preferences?.sagittalGoalPrimary ? (
              format.formatSagittalGoalType(preferences?.sagittalGoalPrimary)
            ) : (
              <span>&mdash;</span>
            )}
          </Typography>
        </Box>
      </Box>
      {preferences?.sagittalGoalSecondary && (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Sagittal Goal Secondary: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {format.formatSagittalGoalType(preferences?.sagittalGoalSecondary)}{' '}
            </Typography>
          </Box>
        </Box>
      )}
      {preferences?.sagittalGoalOther && (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Sagittal Goal Other: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}> {preferences?.sagittalGoalOther}</Typography>
          </Box>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong> Coronal Goal Primary: </strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {' '}
            {preferences?.coronalGoalPrimary ? (
              format.formatCoronalGoalType(preferences?.coronalGoalPrimary)
            ) : (
              <span>&mdash;</span>
            )}{' '}
          </Typography>
        </Box>
      </Box>
      {preferences?.coronalGoalSecondary && (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Coronal Goal Secondary: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {' '}
              {format.formatCoronalGoalType(preferences?.coronalGoalSecondary)}{' '}
            </Typography>
          </Box>
        </Box>
      )}
      {preferences?.navigationSystems?.length > 0 && (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Navigation Systems: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {' '}
              {
                <>
                  {(preferences?.navigationSystems as NavigationSystemType[])
                    .map(format.formatNavigationSystem)
                    .join(', ')}
                  {preferences?.navigationSystems.length === 0 ? <span>&mdash;</span> : null}
                </>
              }{' '}
            </Typography>
          </Box>
        </Box>
      )}
      {preferences?.navigationSystemOther && (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Navigation System Other: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}> {preferences?.navigationSystemOther}</Typography>
          </Box>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong> Target Height Metric: </strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {preferences?.targetHeightMetric ? (
              format.formatTargetHeightMetricType(preferences?.targetHeightMetric)
            ) : (
              <span>&mdash;</span>
            )}
          </Typography>
        </Box>
      </Box>
      {preferences?.excludedInstruments?.length ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> Excluded Instruments: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {' '}
              {
                <>
                  {(preferences?.excludedInstruments as string[])
                    .map(format.formatInstrumentSkuType)
                    .join(', ')}
                  {preferences?.excludedInstruments?.length === 0 ? <span>&mdash;</span> : null}
                </>
              }{' '}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box display={'flex'} alignItems={'flex-start'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong>Reusable Instruments: </strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'} whiteSpace={'pre-wrap'}>
            {preferences?.reusableInstruments ? 'Yes' : 'No'}
          </Typography>
        </Box>
      </Box>
      {preferences?.note && (
        <Box display={'flex'} alignItems={'flex-start'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong> General Note: </strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'} whiteSpace={'pre-wrap'}>
              {preferences?.note ? preferences?.note : <span>&mdash;</span>}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
